'use strict';

define('vb/private/stateManagement/appPackage',[
  'vb/errors/httpError',
  'vb/helpers/mixin',
  'vb/private/constants',
  'vb/private/stateManagement/appPackageExtension',
  'vb/private/stateManagement/containerRjsInitMixin',
  'vb/private/stateManagement/packageFlow',
  'vb/private/stateManagement/context/appPackageContext',
], (HttpError, Mixin, Constants, AppPackageExtension, ContainerRjsInitMixin, PackageFlow, AppPackageContext) => {
  /**
   * AppPackage class
   */
  class AppPackage extends Mixin(PackageFlow).with(ContainerRjsInitMixin) {
    /**
     * AppPackage constructor
     *
     * @param  {String}     id     a string id to identify the appPackage
     * @param  {Page}       page   the page that contain this appPackage
     * @return {AppPackage}        appPackage object
     */
    constructor(extension, id, page, className = 'AppPackage') {
      super(id, page, `${Constants.DefaultPaths.APPLICATIONS}${id}/`, className);

      // Make readonly properties for safety
      Object.defineProperties(this, {
        extension: {
          value: extension,
          enumerable: true,
        },
        // The name is always app, app.json, app.js
        name: {
          value: 'app',
          enumerable: true,
        },
        // The default event prefix is the lowercase class name (see container.js) but for
        // appPackage we want to use the same event prefix as application
        eventPrefix: {
          value: 'application',
          enumerable: true,
        },
      });
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor
     *
     * @return {String} the descriptor loader function name
     */
    // eslint-disable-next-line class-methods-use-this
    get descriptorLoaderName() {
      return 'getAppUiDescriptor';
    }

    /**
     * The name of the runtime environment function to be used to load the module functions
     *
     * @return {String} the module loader function name
     */
    // eslint-disable-next-line class-methods-use-this
    get functionsLoaderName() {
      return 'getAppUiFunctions';
    }

    static get extensionClass() {
      return AppPackageExtension;
    }

    /**
     * returns the LayoutContext constructor used to create the '$' expression context
     * @return { LayoutContext.constructor }
     * @override
     */
    static get ContextType() {
      return AppPackageContext;
    }

    /**
     * Override the flow createService to define the services from the right place.
     * @return {Promise}
     */
    createServices() {
      return Promise.resolve()
        .then(() => {
          // App UI services are the services defined in the extension containing
          // this App UI.
          this.services = this.application.extensions[this.extensionId].services;
        });
    }

    /**
     * @returns {string}
     */
    get fullName() {
      return this.name;
    }

    // eslint-disable-next-line class-methods-use-this
    isNavigable() {
      // App UIs are always navigable
      return true;
    }

    /**
     * Generate a unique name to be used for the scope.
     * The name is unique and has the name of the class and the path to make it easier to
     * find the scope owner when debugging.
     *
     * @return {String} a new scope name
     */
    getNewScopeName() {
      return `${this.className}/${this.id}`;
    }

    initDefault(definition) {
      const def = definition;

      return super.initDefault(def);
    }

    // eslint-disable-next-line class-methods-use-this
    defineCurrentPageBuiltinVariable() {
      return null;
    }

    // eslint-disable-next-line class-methods-use-this
    updateCurrentPageVariable() {
      // no-op
    }

    loadFlow() {
      // Loads the extension bundle first
      return this.extension.init().then(() => super.loadFlow());
    }

    getScopeResolverMap() {
      return {
        [Constants.APP_UI_PREFIX]: this,
        [Constants.GLOBAL_PREFIX]: this.application,
      };
    }

    /**
     * Check that a file exist in the this App UI
     * Use the files list provided by he extension
     * Throws a HttpError if the file does not exist
     *
     * @param  {String} path file path to check
     * @param  {String} extensionId the id of the extension in which to search for the file
     */
    checkFileExist(path, extensionId = this.extensionId) {
      // App UIs are using extension with a manifest containing a list of files,
      // so we can check if the file exist before doing a fetch that may fail.
      if (!this.application.fileExistsInExtension(extensionId, path)) {
        throw new HttpError(404, null, `${path} not found.`);
      }
    }
  }

  return AppPackage;
});

