/* global cordova:false */

'use strict';

define('vbc/private/utils',[], () => {
  class Utils {
    /**
     * Generate a string unique Id.
     * @return {string} a unique id
     */
    static generateUniqueId() {
      return Math.random().toString(36).substr(2, 9);
    }

    /**
     * A very simplistic check to determine whether code is running on SW vs. UI thread
     *
     * @returns {boolean} true, if the code is executing on the service worker thread in the absence of a global
     * window object.
     */
    static isWorkerThread() {
      return globalThis.window === undefined;
    }

    /**
     * @param userAgent an optional userAgent string. If not specified, <code>navigator.userAgent</code> is used
     * @returns {boolean} true if browser is non Chromium based Edge
     */
    static isOldEdge(userAgent = navigator.userAgent) {
      return /Edge\//i.test(userAgent);
    }

    /**
     * Determines whether an app is running as a hybrid mobile application on a device.
     * @returns {boolean}
     */
    static isMobile() {
      return typeof cordova !== 'undefined';
    }

    /**
     * Determines whether a mobile app is running on iOS.
     * @returns {boolean}
     */
    static isIos() {
      return Utils.isMobile() && cordova.platformId === 'ios';
    }

    /**
     * Determines whether a mobile app is running on Android.
     * @returns {boolean}
     */
    static isAndroid() {
      return Utils.isMobile() && cordova.platformId === 'android';
    }

    /**
     * Returns true if the value is an object. An array is not counted as an object for this
     * evaluation.
     *
     * @param value The object to test
     * @returns {boolean} True if the object is non-null, defined, and an object
     */
    static isObject(value) {
      return value && value !== null && typeof value === 'object' && !Array.isArray(value);
    }

    /**
     * Return a promise to load a resource.
     * Reject with the error if there was an error or the file doesn't exist
     *
     * @param  {String} resource the path to the resource to load
     * @returns {Promise} a promise resolving to the content of the resource
     */
    static getResource(resource) {
      return new Promise((resolve, reject) => {
        requirejs([resource],
          (loaded) => {
            resolve(loaded);
          },
          (reason) => {
            reject(reason);
          });
      });
    }

    /**
     * Trace options correspond to vbInitConfig.TRACE_CONFIG. If vbInitConfig.TRACE_CONFIG.tracerOptions.applicationId
     * is not specified, vbInitConfig.APP_ID will be used instead.
     * @returns {Object|undefined} trace options extracted from vbInitConfig
     */
    static getTraceOptions(vbInitConfig) {
      if (vbInitConfig && vbInitConfig.TRACE_CONFIG) {
        // eslint-disable-next-line prefer-object-spread
        const tracerOptions = Object.assign({}, vbInitConfig.TRACE_CONFIG.tracerOptions);
        tracerOptions.applicationId = tracerOptions.applicationId || vbInitConfig.APP_ID;
        // eslint-disable-next-line prefer-object-spread
        return Object.assign({}, vbInitConfig.TRACE_CONFIG, { tracerOptions });
      }
      return undefined;
    }

    /**
     * @returns {boolean} a boolean indicating whether the browser is online.
     */
    static isOnline() {
      // if navigator.onLine is false, it is accurate
      if (!navigator.onLine) {
        return false;
      }
      return !(navigator.connection && navigator.connection.type === 'none');
    }

    /**
     * Add a slash to a path if the path is not empty
     * @param {String} path
     * @return {String}
     */
    static addTrailingSlash(path) {
      let newPath = path || '';
      if (newPath && newPath[newPath.length - 1] !== '/') {
        newPath = `${newPath}/`;
      }

      return newPath;
    }
  }
  return Utils;
});

