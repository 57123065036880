'use strict';

define('vb/private/stateManagement/containerExtension',[
  'vb/private/stateManagement/container',
  'vb/private/log',
  'vb/private/constants',
  'vb/private/monitoring/loadMonitorOptions',
  'vb/helpers/mixin',
  'vb/private/stateManagement/packageAndExtensionContainerMixin',
], (Container, Log, Constants, LoadMonitorOptions, Mixin, PackageAndExtensionContainerMixin) => {
  const logger = Log.getLogger('/vb/stateManagement/containerExtension', [
    // Register a custom logger
    {
      name: 'greenInfo',
      severity: 'info',
      style: 'green',
    },
  ]);
  /**
   * ContainerExtension class
   */
  class ContainerExtension extends Mixin(Container).with(PackageAndExtensionContainerMixin) {
    constructor(extension, parent, path, base, className = 'ContainerExtension') {
      super(base.name, parent, className);

      // Readonly properties for safety
      Object.defineProperties(this, {
        // a reference to the base object
        base: {
          value: base,
          enumerable: true,
        },
        extension: {
          value: extension,
          enumerable: true,
        },
        resourceLoc: {
          value: path, // path is the fully computed resource path relative to the base url
          enumerable: true,
        },
        // from this point on the path value cannot be modified.
        path: {
          value: base.path,
          enumerable: true,
        },
      });

      this.fullPath = this.base.fullPath;

      this.view = {};
      this.log = logger;
    }

    /**
     * Returns the name of the function used to load the chain file.
     *
     * @returns {string}
     */
    // eslint-disable-next-line class-methods-use-this
    get chainLoaderName() {
      return 'getExtensionTextResource';
    }

    /**
     * Used by events.
     * @returns {boolean}
     * @override
     */
    // eslint-disable-next-line class-methods-use-this
    isExtension() {
      return true;
    }

    /**
     * The router path is the same for base and extension.
     * @return {String} the path
     */
    getContainerPath() {
      return this.base && this.base.fullPath;
    }

    /**
     * Add the extension id as part of the name to make it unique between
     * multiple extensions.
     * @return {String} a new scope name
     */
    getNewScopeName() {
      return `${this.className}/${this.extensionId}/${this.fullPath}`;
    }

    initDefault(definition) {
      const def = definition;

      def.extensions = def.extensions || {};

      super.initDefault(definition);
    }

    load() {
      const mo = new LoadMonitorOptions(`${this.className}Load`, `${this.className} load ${this.fullPath}`, this);
      // eslint-disable-next-line arrow-body-style
      return this.log.monitor(mo, (extensionLoadTimer) => {
        return this.loadDescriptor()
          .then(() => {
            // This will show something like "vx/opty/ui/base/app loaded."
            // This concisely describe the type of container and its exact location
            this.log.greenInfo(this.getResourcePath(), 'loaded.', extensionLoadTimer());
          })
          .catch((error) => {
            extensionLoadTimer(error);
            throw error;
          });
      });
    }

    // eslint-disable-next-line class-methods-use-this
    checkAccess() {
      return Promise.resolve();
    }

    /**
     * Utility method to load a module, and log errors
     *
     * @param {Object} components
     * @param {Boolean} storeImport true when the module should be stored in this.imports
     * @return {Promise|undefined} a promise to load module if there are module to import
     * @private
     */
    importModule(components, storeImport) {
      const baseUrl = this.baseUrl || '';
      if (baseUrl.length) {
        const baseUrlPath = require.toUrl(baseUrl);
        const processedComponents = {};

        Object.keys(components).forEach((key) => {
          const loaderDef = components[key];
          const path = loaderDef && loaderDef.path && loaderDef.path.trim();
          if (path) {
            // need to convert local path to the app based path

            const localPath = `${baseUrl}${path}`;
            const localUrl = require.toUrl(localPath);
            // check if local path resolves into location within the extension
            // if localPath resolves into the same URL as resolved base + path it means no custom path mapping was found
            if (localUrl !== (baseUrlPath + path)) {
              processedComponents[key] = { path: localPath };
            } else {
              // leave it as is
              processedComponents[key] = loaderDef;
            }
          }
        });
        return super.importModule(processedComponents, storeImport);
      }
      return super.importModule(components, storeImport);
    }
  }

  return ContainerExtension;
});

