'use strict';

define('vb/extensions/dynamic/private/models/translationsModel',[
  'vb/private/translations/bundlesModel',
  'vb/private/translations/translationContext',
  'vb/private/constants',
  'vb/private/configLoader',
  'vb/private/utils',
  'vb/extensions/dynamic/private/models/emptyModel',
], (BundlesModel, TranslationContext, Constants, ConfigLoader, Utils, EmptyModel) => {
  /**
   * This provides the 'viewmodel', aka 'expression model', that is provided to JET.
   * Contains:
   * - $translations
   */

  class TranslationsModel extends EmptyModel {
    /**
     * @param definition {object} the VB model declaration, which contains the "translations" declaration.
     * @param folder {string}
     * @param ownerName {string} file name (may have extension, will be stripped for namespacing)
     */
    constructor(definition, folder, ownerName) {
      super(definition, folder, ownerName);

      this.definition = definition;
    }

    /**
     * calls load(), and saves the Promise
     * @returns {Promise<object>}
     */
    init() {
      if (!this.loadPromise) {
        this.loadPromise = this.load();
      }
      return this.loadPromise;
    }

    /**
     * this is the set of properties for expressions
     *
     * { translations: {object} }
     *
     * creates: {
     *   translations: { format: function |  object }
     * }
     * @returns {object}
     */
    getContext() {
      if (!this.context) {
        const bundles = this.bundles;

        let bundleContext;

        this.context = {
          // $translations
          get [Constants.TRANSLATIONS_CONTEXT]() {
            if (!bundleContext) {
              bundleContext = TranslationContext.createContext(bundles);
            }
            return bundleContext;
          },
        };
      }

      return this.context;
    }

    /**
     * This should only be called ONCE, by init() (this class, or subclasses)
     *
     * load all bundles.
     * allows use of $initParams in path expressions
     *
     * note: dynamic UI artifacts are not allowed to use self-relative paths; iow, paths that start with "./".
     * the paths must either be absolute (https://..) , or relative to the app root (resources/...)
     *
     *
     * @returns {*}
     * @protected
     */
    load() {
      return Utils.getRuntimeEnvironment()
        .then((re) => BundlesModel.loadBundlesModel(re, this.definition, this.folder,
          { initParams: ConfigLoader.initParams, allowSelfRelative: false }))
        .then((bundlesModel) => {
          this.bundles = bundlesModel;
          return this;
        });
    }
  }

  return TranslationsModel;
});

