'use strict';

define('vb/private/vx/v1/remoteExtensionAdapter',[
  'vb/private/vx/baseExtensionAdapter',
  'urijs/URI',
  'vbc/private/monitorOptions',
], (BaseExtensionAdapter, URI, MonitorOptions) => {
  /**
   * The extension adapter used to load extension from the extension manager
   */
  class RemoteExtensionAdapter extends BaseExtensionAdapter {
    constructor(registryUrl) {
      super(BaseExtensionAdapter.fixRegistryUrl(registryUrl));
    }

    /**
     * Return a promise to load the manifest given the extensionId.
     * @param  {String} applicationId the id of the application for which we load an extension
     * @return {Promise<Object>} a promise that resolves to the manifest
     */
    loadManifest(applicationId) {
      return Promise.resolve().then(() => {
        if (!applicationId) {
          throw new Error('Cannot load extension, application id is not defined');
        }

        this.log.info('Loading extensions from', this.registryUrl, 'for application', applicationId,
          'using extension manager v1.');

        const version = this.constructor.getExtensionVersion();
        const request = new Request(this.buildUrl(applicationId, version));
        const mo = new MonitorOptions('extensionManifestLoad', `extension manifest load ${request.url}`);
        return this.log.monitor(mo, (registryLoadTimer) => fetch(request)
          .then((response) => {
            if (!response.ok) {
              throw new Error('Response not OK when fetching extension at:', request.url);
            }

            return response.json();
          })
          .then((manifest) => {
            manifest = manifest || {};
            manifest.extensions = manifest.extensions || [];

            this.log.greenInfo('Found', manifest.extensions.length, 'extension(s) for application',
              applicationId, 'and preferred version', version, registryLoadTimer());
            return manifest;
          }));
      });
    }

    /**
     * Build the extension URL based on the baseUrl and the extension id
     * A typical extension URL with a sandbox defined looks like:
     * https://<fa-server>/fscmUI/vxmanifest/v1/applications/<app name>/ui-manifest?preferExtensionVersion=$<sandboxId>
     * @param  {String} applicationId the id of the application for which we load an extension
     * @param  {String} version       the preferred extension version
     * @return {String}               the extension URL
     */
    buildUrl(applicationId, version) {
      const uri = new URI(`${this.registryUrl}vxmanifest/v1/applications`);

      // Append the applicationId and method
      uri.segmentCoded(applicationId);
      uri.segment('ui-manifest');

      if (version) {
        // Use this syntax because we don't want to encode the $ in sandbox
        uri.search(`preferExtensionVersion=${version}`);
      }

      return uri.href();
    }
  }

  return RemoteExtensionAdapter;
});

