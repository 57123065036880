'use strict';

define('vb/private/vx/v1/extensionRegistry',[
  'vb/private/vx/baseExtensionRegistry',
  'vb/private/vx/v1/extension',
  'vb/private/utils',
  'vb/private/log',
], (BaseExtensionRegistry, ExtensionV1, Utils, Log) => {
  const logger = Log.getLogger('/vb/private/vx/v1/extensionRegistry');

  const serviceRegex = /^self\/services\/([\w_$-]*)\/openapi[\w.]*\.json$/;
  const catalogRegex = /^self\/services\/catalog.json$/;

  /**
   * A class to retrieve the extensions for the current application from the extension manager
   * The extension manager URL is defined in the app-flow.json under the extension property.
   */
  class ExtensionRegistry extends BaseExtensionRegistry {
    constructor(application) {
      super(application);

      this.log = logger;
    }

    static get extensionManagerVersion() {
      return 'v1';
    }

    /**
     * The regex to find openapi3 files; in V1 Extension Registry format, they lived in "self".
     * The () group is used to capture the service name from the path.
     * @return {RegExp}
     */
    static get serviceRegex() {
      return serviceRegex;
    }

    /**
     * The regular expresion to find a catalog in a list of extension files
     * @return {RegExp}
     */
    static get catalogRegex() {
      return catalogRegex;
    }

    createExtension(def) {
      return new ExtensionV1(def, this);
    }

    initialize(registryUrl) {
      super.initialize(registryUrl);

      // Load the application metadata first in order to get the application id.
      this.loadManifestPromise = this.loadManifestPromise || this.application.loadMetadata()
        .then((definition) => this.loadManifest(registryUrl, definition.id))
        .catch((err) => {
          // Swallow the error so that it doesn't break the application, but no extension will be loaded
          this.log.error('Error loading extension registry, running baseline application', err);
          return {};
        });
    }

    /**
     * For v1, the base prefix for container ('ui'), is not in the base path
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    getBasePathForUi(path, container) {
      return this.getBasePath(path, container);
    }

    /**
     * For v1, the base path for layout is the path prefixed by the extension id
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    getBasePathForLayout(path, container) {
      return this.getBasePath(path, container);
    }

    /**
     * Create a dependency graph from the given extension as the root.
     *
     * @param extensions all extension
     * @param extension the root extension
     */
    getExtensionDependencies(extensions, extension) {
      const dependencies = [];

      // TODO: Return a flat list for V1 for now. Should we get this to work for V1?
      extensions.forEach((ext) => {
        const dependency = {
          extension: ext,
          dependencies: [],
        };

        dependencies.push(dependency);
      });

      return dependencies;
    }
  }

  return ExtensionRegistry;
});

