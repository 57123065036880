'use strict';

define('vb/extensions/dynamic/private/types/bridges/dynamicLayoutMetadataProviderFactoryBridge',[
  'vb/extensions/dynamic/private/types/bridges/serviceMetadataProviderFactoryBridge',
], (ServiceMetadataProviderFactoryBridge) => {
  /**
   * a 'bridge' for DynamicLayoutMetadataProviderDescriptor, extends the ServiceMetadataProviderFactoryBridge.
   *
   * - specifies the JET metadata provider factory class
   * - converts the VB declaration into options JET provider understands.
   * - configures the MetadataProviderHelper passed to the JET provider.
   *
   * In addition to what the "Service" one provides, this adds support for JSON-based layout information,
   * and support for configuring the "helper" to load layout-related resources for the JET provider.
   *
   * (also has some deprecated support for specifying an alternate locations for the layout-related files).
   */
  class DynamicLayoutMetadataProviderFactoryBridge extends ServiceMetadataProviderFactoryBridge {
    // eslint-disable-next-line class-methods-use-this
    getDelegatePath() {
      return 'oj-dynamic/providers/DynamicLayoutMetadataProviderFactory';
    }

    /**
     * two possible cases: service-based, or JSON-based.
     * service: we have an 'endpoint', and we will let our parent (ServiceMetadataProviderDescriptor) handle it
     * JSON: we have a 'path', and we will process it here
     *
     * @param options
     * @returns {Promise<unknown>}
     * @override
     */
    translateOptions(options) {
      const newOptions = options;

      return Promise.resolve()
        .then(() => {
          if (newOptions.path) {
            // remove 'endpoint', in case we have both
            delete newOptions.endpoint;
            // and use the 'options' to specify the helper class
            newOptions.helper = {
              path: 'vb/extensions/dynamic/private/helpers/dataDescriptionMetadataProviderHelper',
            };
          }

          return super.translateOptions(options);
        });
    }


    /**
     *
     * @param options
     * @override
     * @returns {Promise}
     */
    getHelper(options) {
      return super.getHelper(options)
        .then((helper) => {
          // optional path override, normally not used
          const path = (options.layoutConfig && options.layoutConfig.path);
          // optional default root path override, normally not used
          const root = options.layoutConfig && options.layoutConfig.root;

          /**
           * 'layoutRoot' has different meaning, depending on the helper:
           * - for 'base' helper, its the absolute path to the JSON file.
           * - for 'service' helper, it is the root of the endpoint-derived paths
           * 'layoutPath' is specific to 'service' helper, and overrides the root and endpoint-derived path.
           */

          if (options.path) {
            helper.layoutRoot(options.path);
          } else if (path) {
            helper.layoutPath(path);
          } else if (root !== undefined && root !== null) {
            helper.layoutRoot(root); // allow '' for root
          }
          return helper;
        });
    }
  }

  return DynamicLayoutMetadataProviderFactoryBridge;
});

