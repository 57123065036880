'use strict';

define('vb/private/stateManagement/pageExtension',[
  'vb/private/stateManagement/containerExtension',
  'vb/private/stateManagement/context/pageExtensionContext',
  'vb/private/constants',
], (ContainerExtension, PageExtensionContext, Constants) => {
  /**
   * PageExtension class
   */
  class PageExtension extends ContainerExtension {
    constructor(extension, path, base, className = 'PageExtension') {
      // parent of the pageExtension is the potential extension of the base page parent flow
      super(extension, base.getParentFlow().extensions[extension.id], path, base, className);
    }

    static get extensionClass() {
      return PageExtension;
    }

    static get resourceSuffix() {
      return '-page-x.json';
    }

    /**
     * The folder where the dynamic layouts are defined
     * For pages in an App UI, it's "dynamicLayouts/self/"
     * @return {String}
     */
    get layoutRoot() {
      return this.base ? `${Constants.DefaultPaths.LAYOUTS}${Constants.ExtensionFolders.SELF}/`
        : Constants.DefaultPaths.LAYOUTS;
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor.
     * @returns {string}
     */
    get fullName() {
      return `${this.id}-page`;
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor
     *
     * @return {String} the descriptor loader function name
     */
    // eslint-disable-next-line class-methods-use-this
    get descriptorLoaderName() {
      return 'getPageExtensionDescriptor';
    }

    /**
     * The name of the runtime environment function to be used to load the module functions.
     *
     * @return {String} the module loader function name
     */
    // eslint-disable-next-line class-methods-use-this
    get functionsLoaderName() {
      return 'getPageExtensionFunctions';
    }

    /**
     * The name of the chain folder is the page name with '-page-x-chains' appended.
     * @returns {string}
     */
    get chainsFolderName() {
      return `${this.id}-page-x-chains`;
    }

    /**
     * The class to use to create layout mode.
     * @return {String}
     */
    get layoutModelClass() {
      // Since PageExtension is used for both extension of Page and PackagePage, we need to
      // determine which layout model class to return base on if this is an extension of an
      // app ui page or a normal page.
      // TODO: In order to avoid this check, we will have to add PackagePageExtension.
      return (this.base && this.base.package) ? 'vb/private/stateManagement/packageLayout'
        : super.layoutModelClass;
    }

    /**
     * Returns the ExtensionContext constructor used to create the '$' expression context.
     * @return {ExtensionContext.constructor}
     * @override
     */
    static get ContextType() {
      return PageExtensionContext;
    }

    /**
     *
     * @returns {*}
     */
    getViewModel() {
      return this.getAvailableContexts();
    }

    /**
     * Returns a scope resolver map where keys are scope name ("page", "flow" or "application")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @private
     * @return {Object} an object which properties are scope
     */
    getScopeResolverMap() {
      const map = {
        [Constants.PAGE_PREFIX]: this,
      };

      // if the parent of the pageExtension is defined we need to merge scope resolvers
      // so that the pageExtension has access to its parent's scopes
      if (this.parent) {
        return Object.assign(map, this.parent.getScopeResolverMap());
      }

      return map;
    }
  }

  return PageExtension;
});

