'use strict';

define('vb/private/vx/baseExtension',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/log',
  'vb/private/monitoring/loadMonitorOptions',
], (Constants, Utils, Log, LoadMonitorOptions) => {
  const logger = Log.getLogger('/vb/private/vx/baseExtension', [
    // Register a custom logger
    {
      name: 'greenInfo',
      severity: 'info',
      style: 'green',
    },
  ]);

  /**
   * The name given to the extension bundle stored in the extension.
   * @type {String}
   */
  const VB_APP_BUNDLE_NAME = 'vb-app-bundle.js';

  /**
   * The base class for an extension.
   * This class provide a way to interact with extensions independently of the version.
   * There are 2 types of extension v1 and v2:
   *   v1 subclass is in vx/v1/extension.js
   *   v2 subclass is in vx/v2/extension.js
   */
  class BaseExtension {
    /**
     * Construct the extension
     * @param  {Object} def        the descriptor of the extension retrieved from the extension manager
     * @param  {String} baseUrlDef the location of the resources in the extension manager
     */
    constructor(def, baseUrlDef, registry) {
      this.id = def.id;

      this.registry = registry;

      this.version = def.version;

      // the baseUrl defined by the extension manager. This is where resources are retrieved from
      // the extension manager
      this.baseUrlDef = baseUrlDef;

      // the simplified base URL used to defined a requirejs mapping to baseUrlDef
      // this is used so resource can be accessed using a path starting with "vx/ext-id/..."
      this.baseUrl = `${Constants.EXTENSION_PATH}${this.id}/`;

      this.files = def.files || [];
      this.initBundlesPromise = null;
      this.log = logger;
    }

    /**
     * Cache the init bundle promise
     * @return {Promise} a promise that resolve when the bundle is initialized
     */
    init() {
      this.initBundlesPromise = this.initBundlesPromise || this.initBundles();
      return this.initBundlesPromise;
    }

    /**
     * Load the extension bundle if there is one or create the require mapping so that artifacts in
     * the extension can be loaded.
     * @return {Promise} a promise that resolve when the bundle is loaded
     */
    initBundles() {
      return Promise.resolve()
        .then(() => {
          // If a bundle is defined in the list of files, use it.
          const bundleUrl = this.findBundleUrl();

          let promise;

          // Either load the extension bundle or create a require mapping vx/extId/
          if (bundleUrl) {
            promise = this.loadBundle(bundleUrl);
          } else {
            this.log.info('Applying extension', this.id, 'version:', this.version, 'without bundle');
            this.registry.addRequireMapping(this.buildMapping());
          }

          return promise;
        });
    }

    /**
     * Returns the full URL to access an artifact in this extension
     * @return {String}
     */
    // eslint-disable-next-line class-methods-use-this
    getAbsoluteUrl() {
      // implemented by subclass
    }

    /**
     * The default implementation assume any extensions extends the base, this is to match the v1 behavior
     * The v2 implementation is more restrictive
     * @return {Boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    extendsBaseArtifact() {
      return true;
    }

    /**
     * Verify the validity of the extension metadata returned by the extension manager
     * @return {Boolean}
     */
    isValid() {
      return (this.baseUrlDef && this.id && this.files && this.files.length > 0);
    }

    /**
     * Check if a resource file exist in the extension
     * @param  {String} path
     * @return {Boolean}
     */
    fileExists(path) {
      return !!(this.files.indexOf(path) >= 0);
    }

    /**
     * Retrieve the extensions that this extension requires to work.
     * @return {Array<Extension>}
     */
    getRequiredExtensions() {
      return [];
    }

    /**
     * Retrieve the URL of the bundle if there is one defined in the extension
     * @return {String} the bundle URL or undefined
     * @private
     */
    findBundleUrl() {
      let bundleUrl;

      const bundlePath = this.files.find((path) => path.indexOf(VB_APP_BUNDLE_NAME) > 0);
      if (bundlePath) {
        bundleUrl = `${this.baseUrlDef}/${bundlePath}`;
        bundleUrl = requirejs.toUrl(bundleUrl);
      }

      return bundleUrl;
    }

    /**
     * Load and monitor the extension bundle
     * @param  {String} bundleUrl
     * @return {Promise} a promise that resolve when the bundle is loaded
     */
    loadBundle(bundleUrl) {
      const mo = new LoadMonitorOptions('extensionBundleLoading', `Extension ${this.id} bundle load`, this);
      return this.log.monitor(mo, (extensionLoadTimer) => Utils.getResource(bundleUrl)
        .then(() => {
          this.log.greenInfo('Extension', this.id, 'version:', this.version, 'is forcing a bundle load', extensionLoadTimer());
        })
        .catch((error) => {
          extensionLoadTimer(error);
          throw error;
        }));
    }

    /**
     * Given an extension id and its base URL this function returns a mapping (object with properties/values)
     * to be used with requirejs like:
     * {
     *   'vx/ext-id': 'file://.../sources'
     * }
     * @return {String} a mapping string
     */
    buildMapping() {
      const mapping = {};

      let path = this.baseUrl;
      // Remove ending slash
      if (path.slice(-1) === '/') {
        path = path.substring(0, path.length - 1);
      }
      mapping[path] = this.baseUrlDef;

      return mapping;
    }
  }

  return BaseExtension;
});

