'use strict';

define('vbsw/private/plugins/implicitFlowHandlerPlugin',['vbsw/api/fetchHandlerPlugin', 'vbsw/private/utils', 'vbsw/private/constants'],
  (FetchHandlerPlugin, Utils, Constants) => {
    const IMPLICIT_FLOW_CACHED_TOKEN = 'vbImplicitFlowCachedToken';

    // skew tolerance for the token expiration time
    const SKEW_TOLERANCE = 100;

    /**
     * Handler plugin for handling implicit grant flow.
     */
    class ImplicitFlowHandlerPlugin extends FetchHandlerPlugin {
      /**
       * Constructor
       *
       * @param context the context for the plugin
       * @param params an object containing the access token and scope
       */
      constructor(context, params) {
        super(context);

        const allowedScopes = params.allowedScopes || [];

        this.allowedScopes = allowedScopes.map((allowedScope) => {
          // in a hybrid saas environment, the actual scope is appended after 'fqs://'
          const parts = allowedScope.split('fqs://');
          const host = parts[0];

          // if nothing comes after fqs://, simply use the host as the scope
          const scope = (parts.length > 1 && parts[1]) ? parts[1] : host;

          // make :443 port optional in matching
          const hostWithOptionalPort = host.replace(':443', '(:443)?');

          return {
            scope,
            regEx: new RegExp(hostWithOptionalPort, 'i'), // case-insensitive match
          };
        });

        // used to cached in-memory version of the token for each scope
        this.cachedTokenPromises = {};

        // used to keep track of which token is currently invalid
        this.invalidateTokenPromises = {};
      }

      // the following static getters are used by unit tests
      static get skewTolerance() {
        return SKEW_TOLERANCE;
      }

      /**
       * Get the authorization token header either from cache or from the client hosting the main application.
       *
       * @param client the client associated with the main application
       * @returns {Promise}
       */
      getAuthHeader(client, scope) {
        // cache the promise for getting the token so we don't make multiple calls to get the access token
        let cachedTokenPromise = this.cachedTokenPromises[scope];

        if (!cachedTokenPromise) {
          const cachedTokenUrl = ImplicitFlowHandlerPlugin.getCachedTokenUrl(scope);

          // first try retrieving the cached token from the state cache
          cachedTokenPromise = this.stateCache.get(cachedTokenUrl).then((cachedToken) => {
            if (cachedToken) {
              return cachedToken;
            }

            // post a message to the main application to get the access token
            const msg = {
              method: 'vbRefreshImplicitFlowAccessToken',
              args: [scope],
            };
            return Utils.postMessage(client, msg)
              .then(token => this.cacheAuthToken(cachedTokenUrl, token));
          }).catch((err) => {
            // log the error for debugging purpose
            console.log(err);

            // delete the promise if there's any error so we don't cache the failure state
            delete this.cachedTokenPromises[scope];
          });

          this.cachedTokenPromises[scope] = cachedTokenPromise;
        }

        return cachedTokenPromise.then((cachedToken) => {
          if (cachedToken) {
            if (Utils.checkJwtExpiration(cachedToken.expiration, SKEW_TOLERANCE)) {
              // the token has expired, refresh it
              return this.refreshAuthHeader(client, scope);
            }

            // return the actual token
            return cachedToken.token;
          }

          return null;
        });
      }

      /**
       * Invalidate and refresh the cached auth header.
       *
       * @param client the client associated with the main application
       * @returns {Promise.<Boolean>}
       */
      refreshAuthHeader(client, scope) {
        let invalidateTokenPromise = this.invalidateTokenPromises[scope];

        if (!invalidateTokenPromise) {
          const cachedTokenUrl = ImplicitFlowHandlerPlugin.getCachedTokenUrl(scope);

          invalidateTokenPromise = this.stateCache.delete(cachedTokenUrl).then(() => {
            delete this.cachedTokenPromises[scope];

            return this.getAuthHeader(client, scope).then((authHeader) => {
              delete this.invalidateTokenPromises[scope];

              return authHeader;
            });
          });

          this.invalidateTokenPromises[scope] = invalidateTokenPromise;
        }

        return invalidateTokenPromise;
      }

      /**
       * Match the given url to an allowed scope and return the matched scope.
       *
       * @param url the url to match
       * @returns {string}
       */
      matchScope(url) {
        const result = this.allowedScopes.find(scope => url.match(scope.regEx));
        return result ? result.scope : null;
      }

      /**
       * Return an URL for the given scope that can be used for caching the authorization header
       *
       * @param scope the scope for the authorization header
       * @returns {string}
       */
      static getCachedTokenUrl(scope) {
        return `${scope}${IMPLICIT_FLOW_CACHED_TOKEN}`;
      }

      /**
       * The cached token is a wrapped version of the JWT token containing the extracted expiration
       * time and calculated server skew. This method will return a promise that resolves to the
       * wrapped token.
       *
       * @param cacheTokenUrl the url used to cache the token
       * @param token the JWT token
       * @returns {Promise}
       */
      cacheAuthToken(cachedTokenUrl, token) {
        const expiration = Utils.extractJwtExpiration(token);
        const cachedToken = {
          token,
          expiration,
        };

        return this.stateCache.put(cachedTokenUrl, cachedToken).then(() => cachedToken);
      }

      handleRequestHook(request, client) {
        const authenticationType = request.headers.get(Constants.AUTHENTICATION_TYPE_HEADER);

        if (authenticationType === Constants.AuthenticationType.IMPLICIT) {
          const headers = request.headers;

          // look up the scope this request falls under
          const matchedScope = this.matchScope(request.url);

          if (matchedScope) {
            return this.getAuthHeader(client, matchedScope).then((authHeader) => {
              // BUFP-26511: use an alternate name for the authorization header if provided
              const altAuthHeaderName = headers.get(Constants.ALT_AUTHORIZATION_HEADER_NAME);
              const authHeaderName = altAuthHeaderName || 'Authorization';

              headers.set(authHeaderName, authHeader);
            });
          }
          // else do nothing and let it fail
        }

        return Promise.resolve();
      }
    }

    return ImplicitFlowHandlerPlugin;
  });

