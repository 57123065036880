'use strict';

define('vb/private/stateManagement/context/flowContext',[
  'vb/private/constants',
  'vb/private/stateManagement/context/containerContext',
  'vb/private/stateManagement/context/flowBaseContext',
], (Constants, ContainerContext, FlowBaseContext) => {
  const symbol = Symbol('flow-accessor');

  class FlowContext extends ContainerContext {
    /**
     *
     * @param flow
     */
    constructor(flow) {
      super(flow);

      Object.defineProperty(this, symbol,
        {
          value: {
            /**
             * Shortcut to retrieve the builtins variable map
             */
            get builtins() {
              return flow.scope.variableNamespaces[Constants.VariableNamespace.BUILTIN];
            },
          },
        });
    }

    static get BaseContextType() {
      return FlowBaseContext;
    }

    /*
     * $flow.path (or $application.path)
     */
    get [Constants.PATH_VARIABLE]() {
      return this[symbol].builtins[Constants.PATH_VARIABLE];
    }

    /*
     * $flow.currentPage
     */
    get [Constants.CURRENT_PAGE_VARIABLE]() {
      return this[symbol].builtins[Constants.CURRENT_PAGE_VARIABLE];
    }

    /*
     * $flow.info
     */
    get [Constants.INFO_CONTEXT]() {
      return this[symbol].builtins[Constants.INFO_CONTEXT];
    }

    static getAvailableContexts(flow) {
      const availableContexts = super.getAvailableContexts(flow);

      Object.defineProperties(availableContexts, {
        $flow: {
          enumerable: true,
          configurable: true,
          get: () => flow.expressionContext,
        },
      });


      return availableContexts;
    }
  }

  return FlowContext;
});

