/* eslint-disable no-underscore-dangle */

'use strict';

define('vb/private/services/endpoint',['vb/private/log',
  'vb/private/services/serviceProvider',
  'vb/private/services/uriTemplate',
  'vb/private/services/swaggerUtils',
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/services/definitionObject',
  'vb/private/services/servicesLoader',
  'vb/private/services/endpointMetadata',
  'urijs/URI',
  'vb/private/stateManagement/router',
  'vbc/private/constants',
  'vb/private/services/serviceUtils'],
(Log, ServiceProvider, UriTemplate, SwaggerUtils, Constants, Utils, DefinitionObject, ServicesLoader, EndpointMetadata,
  URI, Router, CommonConstants, ServiceUtils) => {
  /**
   * Endpoint
   *
   * example access:
   * ServicesManager.getServices().then((services) => {
   *   const endpoint = service.getEndpoint('servicename/endpointname');
   *   ...
   * });
   */

  class Endpoint extends DefinitionObject {
    constructor({
      name,
      service,
      protocolRegistry,
      pathKey,
      pathObject, // eslint-disable-line no-unused-vars
      operationKey,
      operationObject,
      isUnrestrictedRelative,
    }) {
      // for now, no catalogInfo
      super(name, operationObject, service, service._namespace,
        (service ? service._relativePath : ''), null, isUnrestrictedRelative);

      this.service = service || { name: '', _catalogInfo: { chain: [] } }; // provide a stub if undefined/null

      this._protocolRegistry = protocolRegistry;

      this.method = (operationKey && operationKey.toUpperCase()) || 'GET';
      this.description = operationObject.description || '';

      // full url is the service's baseUri, plus the (swagger) Path Object key
      let baseUri = (service && service.baseUri) || '';
      if (baseUri.endsWith('/')) {
        baseUri = baseUri.substring(0, baseUri.length - 1);
      }

      this.baseUri = baseUri;
      this.urlUnresolved = baseUri + pathKey || '';

      // parameters.path, parameters.query, parameters.body, etc.
      // note: header parameters are currently unused; to define a header, use the x-vb.headers extension
      // swagger headers only allow specifying the server default for a value, and not the value the client should use

      // array of parameter definitions (swagger/openapi)
      this.parameterDefs = operationObject.parameters.slice();

      // an object, with parameters separated by type (path, query, etc)
      this.parameters = SwaggerUtils.separateParameters(this.parameterDefs);

      // this EndpointMetadata is not usable until load() has been called, to resolve the url
      this._metadata = new EndpointMetadata(this, operationObject);

      // create a parameter validation method; definitionObject does not currently keep a reference to the
      // pathObject or operationObject, to try to limit the dependencies.

      // this is a merge of the application-level header extensions with the operation header extensions
      // this.headers = Object.assign({}, this._parentExtensions[VB_HEADERS], this._extensions[VB_HEADERS]);
      const openApiHeaders = operationObject.getStaticHeaderValues();

      // no need to merge with parent (Service), this is only valid at the operationId (endpoint) level
      // this.staticQueryParams = this._extensions[VB_STATIC_QUERY_PARAMS] || {};
      const openApiStaticQueryParams = operationObject.getStaticQueryParameterValues();

      // a simple combination of 'info' level extensions, overridden by 'endpoint' extensions.
      // note, this is not a 'deep' merge of inner objects; its just using Object.assign.
      const openApiCombinedExtensions = operationObject.getCombinedExtensions();

      // now merge the ones from the service definition (openApi) with any we might have gotten from the catalog
      const parentExtensions = (this._parent && this._parent._extensions) || {};
      const extensions = this._extensions || {};

      this.staticQueryParams = Object.assign({}, openApiStaticQueryParams,
        parentExtensions.queryParameters || {}, extensions.queryParameters || {});

      // note: this is before catalog resolution; when we load the endpoint,
      // we may get more headers and extensions from the catalog.
      this.headers = Object.assign({}, openApiHeaders, parentExtensions.headers || {}, extensions.headers || {});
      this.combinedExtensions = Object.assign({}, openApiCombinedExtensions,
        parentExtensions, extensions, { headers: this.headers }); // headers are merged
    }

    load() {
      // ask the runtime environment for any extension override
      // TODO: ideally, this should be handled by the deployment profile specific for the page designer
      if (!this._loadPromise) {
        this._loadPromise = Utils.getRuntimeEnvironment()
          .then((rtEnv) => rtEnv.getServiceExtensionOverride())
          .then((extOverride) => {
            if (extOverride) {
              this.combinedExtensions = Object.assign(this.combinedExtensions, extOverride);
            }
            return ServicesLoader
              .getCatalogExtensions(this._protocolRegistry, this.urlUnresolved, this.service.nameForProxy,
                this._namespace);
          })
          .then((catalogInfo) => {
            this._catalogInfo = catalogInfo; // this was set to null in the constructor, update it

            // the original URL, or the resolved url if the protocol was 'vb-catalog'
            this.url = catalogInfo.url || this.urlUnresolved;

            // now, merge extension info
            const catalogExtensions = (catalogInfo.backends && catalogInfo.backends.extensions) || {};
            // merge headers, and merge/override the rest.
            // service def extensions take precedence over catalog extensions,
            // and the 'closest' catalog object in the chain has highest precedence.
            // service def and catalog 'headers' are merged with container model declaration (app-flow) 'headers'.
            const catalogBackendHeaders = (catalogExtensions && catalogExtensions.headers) || {};
            const mergedHeaders = Object.assign({}, catalogBackendHeaders, this.combinedExtensions.headers || {});
            this.combinedExtensions = Object.assign({},
              catalogExtensions, this.combinedExtensions, { headers: mergedHeaders });
            this.headers = this.combinedExtensions.headers;

            return super.load();
          })
          .then((ep) => {
            // create our replacement utility early
            this.uriTemplate = new UriTemplate(this.url, this.parameters);

            // complete/resolve the metadata
            this._metadata.setUrl(this.url);

            return ep;
          });
      }
      return this._loadPromise;
    }

    /**
     * gets the url, method, and headers defined in the endpoint.
     *
     * @param {object} uriVariables, map of parameter values.
     * @param options {Object} optional. possible properties:
     *  - ignoreMissingParams: {boolean} if true, URL can have unreplaced templates.
     *                         otherwise, rejects when params are missing
     * @returns {Promise<url: string, method: (string|*), headers: *>}
     * @private
     */
    getConfig(uriVariablesArg = {}, options = {}) {
      return this.load()
        .then(() => {
          if (!this.url || !this.uriTemplate) {
            // this should never happen, this error is to make sure its not even possible
            throw new Error(`getConfig called for endpoint ${this.name} before loading`);
          }

          const uriVariables = (typeof uriVariablesArg === 'object' && !Array.isArray(uriVariablesArg))
            ? uriVariablesArg : {};

          // check for all required params, and throw an error if any are missing (new to 19.1.1)
          // unless options.ignoreMissingParams is true (used for toUrl()/toRelativeUrl())
          if (!options.ignoreMissingParams) {
            let missingParams = this.uriTemplate.getMissingRequiredParameters(uriVariables, this.parameters);
            // ignore missing query params here: BUFP-32240
            missingParams = missingParams.filter((def) => def.type !== 'query');
            if (missingParams.length) {
              const names = missingParams.map((def) => def.name).join('", "');
              throw new Error(`getConfig() for "${this.name}" is missing required parameters: "${names}"`);
            }
          }

          // uriVariables (passed in) will overwrite static query parameters with the same name
          const variables = Object.assign({}, this.staticQueryParams, uriVariables || {});

          // the ignoreMissingParams is true for Rest.toUrl()/toRelativeUrl(), and makes sure the behavior
          // is the same as it was before BUFP-30950
          let url = this.uriTemplate.replace(variables, options.ignoreMissingParams);
          const headers = this.getAllHeaders(variables);

          // We might need to override the protocol, because you cannot call a http URL from
          // a https page, but we need this workaround for legacy purposes, so we use a header
          // to pass this information onto the service worker
          //
          // bufp-25294
          // @TODO: abstract the use of router, so services may be used without
          // pulling in JET by replacing the implementation of the abstraction.

          const page = Router.getCurrentPage();
          // headers may be modified
          url = ServiceUtils.getHeadersAndUrlForPreprocessing(headers, url, page && !page.isAuthenticationRequired());

          // Return configuration

          return {
            url,
            method: this.method,
            headers,
            requestContentTypes: this._metadata.requestContentTypes,
            responseContentTypes: this._metadata.responseContentTypes,
          };
        });
    }

    /**
     * get all headers; includes headers defined by x-vb extension, and parameters defined as in: "header"
     * @param variables
     * @returns {*}
     */
    getAllHeaders(variables) {
      // the pre-defined ones
      const headers = Object.assign({}, this.headers);

      // now check for header parameters
      const headerParamDefs = this.parameters.header || {};
      Object.keys(headerParamDefs).forEach((name) => {
        const headerParamDef = headerParamDefs[name];
        const defaultValue = SwaggerUtils.getParameterDefault(headerParamDef);

        if (defaultValue || Object.prototype.hasOwnProperty.call(variables, name)) {
          // replace any existing one
          headers[name] = variables[name] || defaultValue || '';
        }
      });

      // add the service's name and baseUrl to the vb-info-extension header,
      // in case the "authorization" block indicates we need a proxy or token relay url.
      // but first, make a chain that has only services in the endpoint chain (if any), followed by
      // only services in the service chain (if any); the first service in that list is the name for the proxy URL.
      const wholeChain = this._catalogInfo.chain.slice().concat(this.service._catalogInfo.chain)
        .filter((chainLink) => chainLink && chainLink.type === 'services');

      this.combinedExtensions = ServiceUtils
        .augmentExtension(this.service.nameForProxy, wholeChain, this.combinedExtensions);

      // Pass on the extension information to the service worker.
      headers[CommonConstants.Headers.VB_INFO_EXTENSION] = JSON.stringify(this.combinedExtensions);

      return headers;
    }


    /**
     * map of transforms applied to the request, with disabled transforms filtered
     */
    getRequestTransforms() {
      return this._getFilteredTransforms('request');
    }

    /**
     * map of transforms applied to the response
     */
    getResponseTransforms() {
      return this._getFilteredTransforms('response');
    }

    /**
     * map of transforms relevant to the endpoint. called once before the first request made to an endpoint
     */
    getMetadataTransforms() {
      return this._getFilteredTransforms('metadata');
    }

    /**
     * get a public copy of the endpoint metadata
     * @returns {EndpointMetadata}
     * @throws {Error}
     */
    getMetadata() {
      return this._metadata.get();
    }


    /**
     * return an object that contains all transforms, except the disabled ones, for a category
     * example syntax:
     * "x-vb": {
            "transforms": {
              "path": ""tests/test-transforms",
              "disabled": {
                "request": ["paginate", "sort", "filter"],
                "response": ["paginate"]
              }
            }
          },
     * @param category 'request', 'response'.
     * @returns {*}
     * @private
     */
    _getFilteredTransforms(category) {
      const disabled = this._disabledTransforms[category] || [];
      // note: this only supports one level of containment; doesn't walk parents
      const parentDisabled = (this._parent && this._parent._disabledTransforms
        && this._parent._disabledTransforms[category]) || []; // eslint-disable-line no-underscore-dangle, max-len
      const transforms = Object.assign({}, this._transforms[category]);
      const allDisabled = disabled.slice();
      allDisabled.push(...parentDisabled);
      allDisabled.forEach((name) => {
        delete transforms[name];
      });
      return transforms;
    }

    /**
     * utility method for creating a 'stub' service when creating an Endpoint without a Service context,
     * as we do when creating an Endpoint for catalog.json "paths".
     *
     * Endpoint knows what it needs for a 'parent' service, at a minimum
     * @param name
     * @returns {{_catalogInfo: {chain: []}, name: *}}
     */
    static createEmptyService(name) {
      return {
        name,
        nameForProxy: name,
        _catalogInfo: {
          chain: [],
        },
        load: () => Promise.resolve(),
      };
    }
  }

  return Endpoint;
});

