'use strict';

define('vb/private/stateManagement/variable',[
  'knockout', 'signals', 'vb/private/log', 'vb/private/utils', 'jsondiff', 'vb/private/constants',
], (ko, signals, Log, Utils, JsonDiff, Constants) => {
  const logger = Log.getLogger('/vb/stateManagement/variable');
  /**
   * The type for the redux action used to update a variable in the store
   */
  const UPDATE_ACTION_TYPE = 'update';
  /**
   * A marker to represent an undefined value in the redux store
   */
  const UNDEFINED_MARKER = Symbol('undefined');

  const SKIP_EVAL_EXPRESSIONS_MARKER = '__vb_skip_eval_expr';
  const RETR_OLD_EVAL_MARKER = '__vb_retrieve_old_eval_value';
  const RETR_NEW_VALUE_MARKER = '__vb_retrieve_new_value';

  const isPrototypeInstance = (c) => (!(Utils.isPrimitive(c) || Utils.isPrototypeOfObject(c) || Array.isArray(c)));

  const jsonDiff = JsonDiff.create({
    arrays: {
      detectMove: false,
    },
    cloneDiffValues: false,
  });

  // this skips diffing functions, and instance types as diffing fails or goes into weird recursions
  jsonDiff.processor.pipes.diff.before('trivial', (context) => {
    const cl = context.left;
    const cr = context.right;
    // ignore diffing functions or instance types.
    if (typeof cl === 'function' || typeof cr === 'function') {
      context.setResult(undefined).exit();
    } else if (isPrototypeInstance(cl) || isPrototypeInstance(cr)) {
      let res;
      if (cl !== cr) {
        res = 'isDifferent';
      }
      context.setResult(res).exit();
    }
  });

  /**
   * A variable is a basic unit of state. It can be a primitive, structure, collection, or Jet
   * data type.
   *
   * At runtime it does not have any intrinsic structure, although once set, it will freeze
   * the structure so that it cannot be modified unless set otherwise.
   *
   * FIXME add more doc when this is fleshed out...
   */
  class Variable {
    constructor(scope, name, namespace, type, defaultValue, initialValue, descriptor) {
      /**
       * Creates a new variable in the given scope and name. An initial value is optional, but
       * when provided will initialize the state in the store. The default value is what is specified
       * in the variable definition. It is the same as the initial value except for the case where
       * the variable is persisted, in which case, the initial value would be the persisted value.
       *
       * If the variable has a structure in the initial value, the object will be frozen on get,
       * such that adding new properties will not be possible unless a new structure is set on the
       * variable.
       *
       * @param {Scope}  scope          The scope on which this variable is active on // FIXME
       * @param {string} name           The name of the variable in the scope
       * @param {string} namespace      The namespace of the variable in the scope
       * @param {*}      type           The type as described in getType()
       *                                used when creating new instances of this variable
       * @param {*}      defaultValue   An optional value used for the variable default value
       * @param {*}      initialValue   An optional value used for the variable initial value
       * @param {*}      descriptor     An object whose properties defines the behavior of the
       *                                variable, for example,
       *                                { writable: true }, whether variable can be written into,
       *                                { rateLimit: 100 } the rate limit in milliseconds for
       *                                limiting how often onValueChanged should be fired.
       *                                { input: String } the input value for the variable
       *                                descriptor ('fromUrl', 'fromCaller', ...)
       *                                { writableOptions: object }, where one of the options
       *                                is 'propertiesWritable', which can be 'all' or 'none'.
       *                                'all' means all properties can be set, 'none' implies none
       *                                can be set.
       *                                { dependencies: Object } a Map of dependencies (on other variables) for the
       *                                current variable. Contains 2 properties
       *                                - expressions - Array of (variable) expressions that this variable
       *                                configuration references
       *                                - variables - Array of Objects representing the referenced variable info;
       *                                where each Object contains the following properties - scopeName, variableName
       */
      this.log = logger;
      this.scope = scope;
      this.name = name;
      this.namespace = namespace || '';
      this.descriptor = descriptor;

      this.type = type;
      this.initialValue = initialValue;
      this.defaultValue = defaultValue;

      if (this.scope.silent === false) {
        this.onValueChanged = new signals.Signal();
      }
      this.triggerObservable = ko.observable();
      this.isDirty = false;
      this.extendedType = false;

      this.eventThrottle = null;
      this.writableOptions = (descriptor && descriptor.writableOptions) || {};

      if (!this.namespace) {
        this.namespace = Constants.VariableNamespace.VARIABLES;
        this.log.warn('variable created without a namespace, using', this.namespace, ':', name);
      }
      // variable is considered to be in 'init' stage at this point. Though primitive and object variable types may
      // not have an explicit init() method, extended types do.
      this.lifecycleStage = Constants.VariableLifecycleStage.INIT;
      this.log.finer('initializing variable', this.name);
      this.typeClassification = Constants.VariableClassification.REGULAR;
    }

    /**
     * Name of the property to address the value of the variable in the redux store
     * Format for a variables named foo is 'variables@foo'.
     * @return {String} the name of the property used to address the value of this variable in the redux store
     */
    get stateProperty() {
      return `${this.namespace}@${this.name}`;
    }

    static get UNDEFINED_MARKER() {
      return UNDEFINED_MARKER;
    }

    static get UPDATE_ACTION_TYPE() {
      return UPDATE_ACTION_TYPE;
    }

    static isPrototypeInstance(c) {
      return isPrototypeInstance(c);
    }

    /**
     * Create a reducer for this variable. This is used by the storage manager to combine all
     * the reducers in a scope.
     * @return {Function} the reducer function
     */
    createReducer() {
      return (state, action) => {
        // When the state is undefined, the reducer must return the initial state
        if (state === undefined) {
          // undefined is not a valid value for the redux state, so use a marker
          if (this.initialValue === undefined) {
            return UNDEFINED_MARKER;
          }
          return this.getReducerInitialValue();
        }

        if (action.type === UPDATE_ACTION_TYPE && this === action.variable) {
          this.isDirty = true;
          // undefined is not a valid value for the redux state, so use a marker
          if (action.value === undefined) {
            return UNDEFINED_MARKER;
          }

          return Utils.cloneObject(action.value);
        }

        return state;
      };
    }

    /**
     * returns the initial value to store in redux
     * @return {*}
     */
    getReducerInitialValue() {
      return this.initialValue;
    }

    /**
     * Create a redux action to be used in a call to the redux store.dispatch
     * @param  {Object} value the new value for the variable
     * @returns {{variable: Variable, type: string, value: *}}
     */
    createUpdateAction(value) {
      return { type: UPDATE_ACTION_TYPE, variable: this, value };
    }

    /**
     * Returns the value of the variable, or null if the variable is set but does not
     * currently have a value.
     *
     * This is an observable value - meaning that if the value changes the events will be
     * propagated automatically when referenced within an expression.
     *
     * @param raw Whether or not to return the object 'as is' from the store
     * @returns {*} The value of the variable
     */
    getValue(raw = false) {
      if (!this.computedValue) {
        this.computedValue = this.getComputedValueObservable(raw);

        // normally we can detect events from the redux store - however if we are bound to an
        // expression, and that expression changes, the redux value for this variable will not
        // change (it will remain the expression).
        // We therefore rely on ko to detect these other changes so that we can continue to fire
        // the right event. We will cancel this event if there is a real 'set' event so only one
        // event is thrown.
        this.computedValue.subscribe((oldValue) => {
          this.oldComputedValue = oldValue;
        }, null, 'beforeChange');
        this.computedValue.subscribe((newValue) => {
          // since we lose the old value, we capture the last evaulated expressions to use for
          // the old value for these types of events
          const oldValue = this.oldComputedValue;
          delete this.oldComputedValue;
          const lastEvaluatedValue = Variable.cloneWithMarkers(oldValue, [RETR_OLD_EVAL_MARKER]);
          this.handleValueObservableChange(lastEvaluatedValue, newValue);
        });
      }

      return this.computedValue();
    }

    getComputedValueObservable(raw = false) {
      return ko.computed(() => {
        this.triggerObservable();

        return this.getValueFromStore(raw);
      });
    }

    /**
     * Gets the value directly from the store. This will always recompute expressions.
     *
     * @param raw Whether or not to return the object 'as is' from the store
     * @returns {*}
     */
    getValueFromStore(raw = false) {
      const state = this.scope.getState();
      if (state) {
        let val = state[this.stateProperty];

        if (val === UNDEFINED_MARKER) {
          return undefined;
        }

        if (!raw) {
          val = this.getWrappedValue(val);
        }

        // seal the object so that new properties cannot be added
        // return val !== null ? Object.seal(val) : null;
        // FIXME action chains require these to be updated
        return val;
      }

      return null;
    }

    /**
     * Given a value returns the wrapped value
     * @param value
     * @return {*}
     */
    getWrappedValue(value) {
      let val = value;
      val = Utils.resolveIfObservable(val);

      // clone the object so no one mutates the store directly, then wrap so we are
      // notified when the properties are changed so we can update the base object
      if (this.extendedType || Utils.isExtendedType(val)) {
        this.extendedType = true;
        // though we don't clone the extended type variable by calling cloneObject(), we
        // also don't want to wrap it, as only its value and internalState variables are
        // mutable.
        return val;
      }
      val = Utils.cloneObject(val);
      this.wrapObject(val);
      return val;
    }

    /**
     * returns the serialized state for this variable. Discard any properties that are class instances because there
     * might be circular dependencies
     * @param value
     * @return {*}
     */
    // eslint-disable-next-line class-methods-use-this
    serialize(value) {
      // TODO:  are there issues with dropping instances entirely?
      const REPLACER_FUNC = (k, v) => ((Utils.isCloneable(v) || Utils.isPrimitive(v)) ? v : undefined);
      return JSON.stringify(value, REPLACER_FUNC);
    }

    /**
     * Sets a new value for the variable. If the value has a structure, the structure will be
     * frozen until the next time a value is set.
     *
     * To unset the variable, assign the new value as 'null'.
     *
     * @param value The new value.
     */
    setValueInternal(value) {
      // if the scope has not yet been hooked up with the store, simply change the initialValue
      // this can occur in a personalization variable if retrieval of the data happens quickly
      if (!this.scope.store) {
        this.initialValue = value;
        return;
      }

      const currentValue = this.getValueFromStore(); // get it directly from the store

      // clone the object to get rid of getters and setters and restore the original values
      // (which will have expressions) - this will only clone the object if it was previously
      // returned from a getValue(). otherwise this will just return the object
      // the idea is to remove the evaluated expression values from the object and restore
      // the raw values, which can include the raw expression functions.
      // since brand new values are not wrapped (and have their expressions evaluated, we
      // do nothing for those cases
      // furthermore, this is only called when the entire object is set, which would be
      // very rare. if a user sets a property of that object, we will optimize so that we
      // do not have to do a deep clone
      const unwrappedValue = Variable.unwrapAndClone(value);

      // Only log when level is finer. This is to avoid fetching the value unnecessarily.
      if (this.log.isFiner) {
        if (ko.isObservable(value)) {
          this.log.finer('Updating variable', this.name, 'to an expression =', value());
        } else {
          this.log.finer('Updating variable', this.name, 'to', unwrappedValue);
        }
      }

      // update the store and event
      this.scope.store.dispatch(this.createUpdateAction(unwrappedValue));
      if (this.scope.silent === false) {
        // we need to refetch the variable otherwise we will not evaulate expressions
        // contained within it (then freeze it to make sure no one attempts to write to it)
        const newValue = this.getValue();
        this.dispatchChangeEvent(currentValue, newValue);
      }
    }

    /**
     * Sets a new value for variable if the variable is not read-only.
     * @param {*} value The new value.
     */
    setValue(value) {
      if (this.isWritable()) {
        this.setValueInternal(value);
      }
    }

    /**
     * Fires an event when the observable has detected a change from ko. If this change was
     * caused by a 'set' operation, the eventing from that operation will cancel out this event
     * (so only one event is sent). Thus, any events from this method that are actually sent
     * *should* be for the following scenario:
     *
     * - We have an expression bound to some other variable
     * - That variable's value has changed
     *
     * @private
     * @param oldValue The old value of this variable
     * @param value The new value of this variable
     */
    handleValueObservableChange(oldValue, value) {
      if (!this.onValueChanged) {
        return;
      }
      // Here we record events that are caused with no explicit set (indirect write) - for
      // example our value contains an expression that uses some other variable - and that
      // variable value change.
      // if there is already an event throttle timer in queue this event value is set on the
      // same event throttle timer.

      this._throttleValueChangeEvent(oldValue, value);
    }

    /**
     * Fires an event that the value has changed. This will throttle events so that multiple
     * simultaneous events will be attempted to be delivered together. This is called only when
     * the value of the variable is directly set.
     *
     * @private
     * @param oldValue The old value of this variable
     * @param value The new value of this variable
     */
    dispatchChangeEvent(oldValue, value) {
      if (!this.onValueChanged) {
        return;
      }

      // Here we record events that are caused with an explicit set (direct write). When a previous
      // ko change started a timer that is pending and we have a set coming in, then the new
      // changes are piggy backed on the same eventThottle state. Otherwise a new timer is created.

      this._throttleValueChangeEvent(oldValue, value);
    }

    /**
     * Throttles dispatching the onValueChanged event by starting a timer using the identifier
     * to capture extra state including the actual timer, if a timer with that identifier doesn't
     * already exist. The timeout used by default is 1ms unless the variable is explicitly
     * configured with a rateLimit.timeout value.
     * If a timer with that identifier was already started the new value is updated on the timer
     * state.
     *
     * Note: If page author were to set timeout to 0 or a negative value the actual delay
     * browser uses varies and is at least 4 ms.
     * See MDN reference - the specification requires that there is a minimum timeout.
     * (https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout#Specification)
     * If you provide something less than this (HTML5 spec says 4ms) then the browser will just
     * ignore your delay and use the minimum. The schema description has been updated to reflect
     * this.
     *
     * Often page authors end up updating multiple properties on complex variables like an SDP
     * variable and expect the changes to be reflected on it and UI right away (iow,
     * synchronously). But it's ok for valueChanged event firing to be throttled. VB recommends
     * user specify a throttle using rateLimit.timeout on the variable. Throttling must work
     * consistently in all of these cases (see below). For the above SDP example there are at
     * least 4 ways the variable properties can be changed. These rules are applicable to all
     * complex variable updates.
     *
     * Example: an SDP variable that is defined like below
     * sdp: {
       *   defaultValue: {
       *     sort: "{{ $variables.foo.sort }}"
       *     filter: "{{ $variables.foo.filter }}"
       *   },
       *   rateLimit: {
       *     timeout: 50
       *   }
       * }
     *
     * Case 1: Direct Updates
     * here multiple SDP properties are updated directly via assignVariables action or
     * some UI writing to the property directly
     *
     * Case 2: Indirect Updates
     * here multiple SDP properties are updated indirectly, by writing to the referenced
     * variables via assignVariables action, or some UI writing to the variable directly
     *
     * Case 3: Mixed Update: Direct update followed by Indirect
     * here one property is updated directly using assignVars action or a UI write -
     * $variables.sdp.sortCriteria, and the second SDP property updated indirectly -
     * $variables.foo.filter.
     *
     * Case 4: Mixed Update: Indirect Update followed by Direct
     * - Same as Case 3 but the order is flipped.
     *
     * @param oldValue
     * @param value
     * @param {String} throttleTimer identifier of the timer
     * @private
     */
    _throttleValueChangeEvent(oldValue, value, throttleTimer = 'eventThrottle') {
      const hasValueChangedListeners = this.onValueChanged.getNumListeners() > 0;
      if (hasValueChangedListeners) {
        if (!this[throttleTimer]) {
          // use the rateLimit specified in the descriptor or the default rate limit
          const rateLimit = this.descriptor && this.descriptor.rateLimit
            ? this.descriptor.rateLimit : {};
          const timeout = rateLimit.timeout !== undefined
            ? rateLimit.timeout : Constants.DEFAULT_RATE_LIMIT;

          this[throttleTimer] = {
            oldValue,
            value,
            timer: setTimeout(() => {
              const ov = this[throttleTimer].oldValue;
              const nv = this[throttleTimer].value;
              this[throttleTimer] = null;

              // calculate the diffs once;
              let diff;
              if (typeof ov !== 'function' && typeof nv !== 'function') {
                diff = Variable.diff(ov, nv);
              }

              // dispatch the event if there's a diff
              if (diff) {
                this.onValueChanged.dispatch({
                  name: this.name,
                  namespace: this.namespace,
                  type: Constants.VALUE_CHANGED,
                  oldValue: ov,
                  value: nv,
                  diff,
                });
              }
            }, timeout),
          };
        } else if (Variable.diff(this[throttleTimer].value, value)) {
          // note: we do not update the old value since we want the old value to
          // come from the original event, since we are throttling the events
          // sometimes we get multiple updates for the same value; guard against that
          this[throttleTimer].value = value;
        }
      }
    }

    /**
     * Creates a new value for the Variable. If 'withDefaults' is true, this will include the default
     * value(s) for this variable, otherwise, just an empty structure will be returned.
     *
     * @returns {*}
     */
    createNewValue() {
      return Utils.cloneObject(this.defaultValue);
    }

    /**
     * Returns the type for this variable.
     *
     * A type can be a primitive, i.e. "string", "boolean", or "number".
     *
     * It can also be an object. In this case, the type will be returned as an object, where the
     * keys are the names of the properties, and the values are the types for those keys. The
     * types for keys can also be objects or any other type.
     *
     * A a type can also be an array, in which case it will be represented as a single item array
     * where that item describes the type of each item in the array. If the array is an array of
     * primitives, that will be returned as a string such as "string[]", "boolean[]", or "number[]".
     *
     * A type can be an extended type (meaning it is an instance of some class). In this case, the
     * type is the string representation of the module (i.e. "vb/ServiceDataProvider").
     *
     * Finally, a type can be a wildcard object. In this case the type is a string, "any". If it's
     * an array whose items can be wildcards, this is represented as "any[]".
     *
     * @return {*} The variable type
     */
    getType() {
      return this.type;
    }

    subscribeToStore(store) {
      this.disposeStateSubscription = store.subscribe(() => {
        // if (this.scope.silent === false) {
        if (this.isDirty === true) {
          // trigger side effect
          // this.getValue();
          this.trigger();
          this.isDirty = false;
        }
        // }
      });

      // force an initial getValue to set up the observable and establish dependencies
      this.getValue();
    }

    /**
     * Notifies the variable that it's underlying state has changed. Should only be called
     * by the scope to notify about a change in the store.
     */
    trigger() {
      this.triggerObservable.notifySubscribers();
    }

    /**
     * Returns the change set between the old and new values.
     *
     * See: https://github.com/benjamine/jsondiffpatch/blob/master/docs/deltas.md
     *
     * @private
     * @param oldValue The old value
     * @param newValue The new value
     * @returns {boolean} A delta format for what has changed
     */
    static diff(oldValue, newValue) {
      // If a value is not cloneable, it means that it's an instance of a class and may have functions.
      // JSON diff will throw an exception when comparing values containing functions. Therefore, perform
      // idenity comparison instead.
      if (!Utils.isCloneable(oldValue) || !Utils.isCloneable(newValue)) {
        return oldValue !== newValue;
      }
      return jsonDiff.diff(oldValue, newValue);
    }

    /**
     * Sets up getter and setter wrappers for all properties of the variable's value object.
     * Watches all properties of struct like value, such that modifying any one of the properties
     * will result in the the whole variable being resent to the store. If set is disabled the
     * setter logs an error.
     *
     * @private
     * @param obj The root or parent object that represents the value of this variable
     */
    wrapObject(obj) {
      this.traverseObject(obj, obj, (po, propKey, pv) => {
        const root = obj;
        const propValue = pv;
        const parentObject = po;

        const propDescriptor = Object.getOwnPropertyDescriptor(po, propKey);
        if (propDescriptor.configurable) {
          const deletedProp = delete parentObject[propKey];

          if (deletedProp) {
            const val = propValue;
            let newValue;
            let isDirty = false;

            // we sometimes rely on knockout to deliver change events - when this happens, we lose
            // the 'old' value since the old value will be evaluated in the context of the new
            // value. we therefore keep the last evaluated value in order to provide the correct
            // change events (we can consider moving this to the redux store directly for
            // improved debugging).
            let lastEvaluatedValue = val;

            // when the propVal is an expression, evaluate the ko computed. Only do this for ko observables.
            if (ko.isObservable(lastEvaluatedValue)) {
              // Cache the initial evaluation so we can establish the proper old value when
              // notified of beforeChange by knockout. We also need to clone the value to
              // prevent the side effect of updates to the original value changing the
              // lastEvaluatedValue.
              lastEvaluatedValue = Utils.cloneObject(lastEvaluatedValue());
            }

            const propertyDescriptorDef = {
              get: () => {
                if (Object.prototype.hasOwnProperty.call(root, RETR_NEW_VALUE_MARKER) === true
                  && isDirty) {
                  return newValue;
                }

                if (Object.prototype.hasOwnProperty.call(root, RETR_OLD_EVAL_MARKER) === true) {
                  return lastEvaluatedValue;
                }

                if (typeof val === 'function'
                  && typeof val.subscribe === 'function'
                  && Object.prototype.hasOwnProperty.call(root, SKIP_EVAL_EXPRESSIONS_MARKER) === false) {
                  lastEvaluatedValue = Utils.cloneObject(val());
                  return lastEvaluatedValue;
                }

                return val;
              },
              enumerable: true,
              configurable: true,
            };

            if (this.writableOptions.propertiesWritable === Constants.VariableWritablePropertyOptions.NONE) {
              propertyDescriptorDef.set = (nv) => {
                // explicit writes to properties are disallowed when disabled
                this.log.error('Cannot set the value', nv, 'to property ', propKey, 'of the'
                  + ' variable \'', this.name, '\'as it disallows property writes. Use VB'
                  + ' actions like assignVariablesAction to update the variable instead.');
              };
            } else {
              propertyDescriptorDef.set = (nv) => {
                isDirty = true;
                // when a new value is set, it is important that we not mutate the original
                // object in redux (i.e., val), instead we track the new value (via closure).
                // This is done to not break the immutability requirement of redux state. E.g.,
                // one place where this manifests is a JET component holding on to the old
                // property (object) because it is needing to compare with a new value.
                newValue = nv;

                // TODO - we should do an optimized set here to avoid the deep clone
                this.setValue(obj);
              };
            }
            Object.defineProperty(parentObject, propKey, propertyDescriptorDef);
          }
        }
      });
    }

    traverseObject(root, parentObject = root, propertyHandler) {
      if (Array.isArray(parentObject)
        || (Utils.isObject(parentObject) && Utils.isPrototypeOfObject(parentObject))) {
        Object.keys(parentObject).forEach((propKey) => {
          this.setupPropertyHandler(parentObject, propKey, propertyHandler, root);
        });
      }
    }

    setupPropertyHandler(parentObject, propKey, propertyHandler, root) {
      const propValue = parentObject[propKey];
      const propType = typeof propValue;

      if (propType === 'boolean' || propType === 'number' || propType === 'string'
        || propType === 'function' || propValue === null || propValue === undefined) {
        propertyHandler.call(this, parentObject, propKey, propValue);
      } else if (propType === 'object' && Array.isArray(propValue)) {
        // handle the array itself
        propertyHandler.call(this, parentObject, propKey, propValue);

        // handle all the items in the array
        this.traverseObject(root, propValue, propertyHandler);
      } else if (propType === 'object') {
        this.traverseObject(root, propValue, propertyHandler);
      }
    }

    isWritable() {
      return !this.descriptor || this.descriptor.writable !== false;
    }

    /**
     * Called during the 'activate' lifecycle stage. This stage is particularly important for extended types so they
     * can use this opportunity to read property values and other activation related tasks.
     * @param {Scope} currentScope
     * @param {Object} availableContexts
     *
     * @return {Promise} resolves when variable is active. Most variables only perform synchronous activation today,
     * but if the activate returns a Promise wait for it to be resolved before returning.
     */
    // eslint-disable-next-line no-unused-vars
    activate(currentScope, availableContexts) {
      // only activate if it has not been previously
      if (this.lifecycleStage === Constants.VariableLifecycleStage.INIT) {
        if (this.extendedType || Utils.isExtendedType(this.initialValue)) {
          // explicit check to ensure duck typed extended types don't break!
          if (typeof this.initialValue.activate === 'function') {
            const ret = this.initialValue.activate();
            // currently activate method on extended types can be synch. If it returns a Promise then wait for
            // that to resolve before finishing activation
            return Promise.resolve(ret).then(() => {
              this.lifecycleStage = Constants.VariableLifecycleStage.ACTIVE;
            });
          }
        }
        this.lifecycleStage = Constants.VariableLifecycleStage.ACTIVE;
      }
      return Promise.resolve();
    }

    /**
     * Called during the 'dispose' lifecycle stage of a variable, typically when a variable is being torn down.
     * Particularly of use to extended types that might need to perform cleanup tasks like unwind async tasks or
     * unregister listeners or notify their subscribers.
     */
    dispose() {
      this.log.finer('disposing variable', this.name);
      this.lifecycleStage = Constants.VariableLifecycleStage.DISPOSE;
      // call dispose on builtin type variables
      if (this.extendedType || Utils.isExtendedType(this.initialValue)) {
        // explicit check to ensure duck typed extended types don't break!
        if (typeof this.initialValue.dispose === 'function') {
          this.initialValue.dispose();
        }
      }

      if (this.disposeStateSubscription) {
        this.disposeStateSubscription();
      }

      if (this.scope.silent === false) {
        this.onValueChanged.removeAll();
      }

      // Dispose of observable to remove dependencies
      if (this.computedValue) {
        this.computedValue.dispose();
      }
    }

    /**
     * Clones the variable's data. This will result in a disconnected structure that can be modified
     * or used independently of this variables data.
     *
     * If the variable was a complex object containing expressions, those expressions would not be
     * evaluated, but rather preserved in the cloned structure.
     *
     * @returns {*} The cloned data structure or primitive
     */
    cloneData() {
      return Variable.unwrapAndClone(this.getValue());
    }

    static unwrapAndClone(value) {
      return Variable.cloneWithMarkers(value, [SKIP_EVAL_EXPRESSIONS_MARKER, RETR_NEW_VALUE_MARKER]);
    }

    /**
     * Called to clone the variable value with a special marker property defined on the object
     * before cloning. This marker is used for optimizing accessors for property (see wrapObject).
     * so that when the clone recurses through the property accessors, the presence of this
     * marker will return the last evaluated value (or whatever the presence of the marker
     * indicates) rather than re-evaluate every time.
     * marker will return the last evaluated value, rather than re-evaluate every time. Once the
     * cloning is done we delete the marker.
     * Note 1: deleting the marker invalidates the Inline Caches, that JS engine sets up for
     * optimization, negatively affecting performance
     * Note 2: When defineProperty is called on the instance aka the literal value, the call reverts
     * the property getters and setters back for other properties.
     *
     * @param value
     * @param marker
     * @returns {*}
     */
    static cloneWithMarkers(value, markers = []) {
      // for builtin types no cloning with markers is necessary
      if (Utils.isCloneable(value)) {
        const obj = value;

        // When defineProperty is called on the instance aka the literal value, the call reverts
        // the property getters and setters back for other properties.
        // Another problem is we define the marker property on obj and then immediately delete the
        // marker both from the original obj (in finally) and on the cloned value (in the try).
        // The cloned object doesn't even have it to begin with.
        markers.forEach((marker) => {
          Object.defineProperty(obj, marker, {
            enumerable: false,
            configurable: true,
            value: true,
          });
        });

        try {
          const newObj = Array.isArray(obj) ? [] : {};
          Utils.cloneObject(obj, newObj);

          markers.forEach((marker) => {
            delete newObj[marker];
          });

          return newObj;
        } finally {
          markers.forEach((marker) => {
            delete obj[marker];
          });
        }
      }
      return value;
    }
  }

  return Variable;
});

