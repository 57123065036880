/* eslint-disable no-underscore-dangle */

'use strict';

define('vb/private/services/extensionServices',[
  'vb/private/services/services',
  'vb/private/utils',
  'vb/private/constants',
  'vbc/private/constants',
  'vb/private/configLoader',
  'vb/private/services/serviceUtils',
], (Services, Utils, Constants, CommonConstants, ConfigLoader, ServiceUtils) => {
  /**
   * this is used to find the openapi3 path for a given service name for an extension.
   *
   * overrides getDefinitionPath() to skip a lot of path processing that isn't necessary;
   *
   * @param name
   * @returns {{path: string}}
   */

  class ExtensionServices extends Services {
    /**
     * @param options
     * @param options.relativePath
     * @param options.serviceFileMap
     * @param options.expressionContext
     * @param options.protocolRegistry
     * @param options.isUnrestrictedRelative
     * @param options.namespace
     * @param options.extensions
     * @param options.extensions.catalogPaths
     */
    constructor(options) {
      super(options);

      this.catalogPaths = options.extensions && options.extensions.catalogPaths;

      this._initializeServiceMapPromise = null;

      // a temporary variable storing the serviceMap computed from the extension descriptor files (like the manifest)
      this._extensionServiceMap = options.extensionServiceMap;
    }

    _initializeServiceMap() {
      if (!this._initializeServiceMapPromise) {
        // first we load the catalog then add any new service entries to the serviceMap
        // then we add the new entries from the extensionServiceMap

        this._initializeServiceMapPromise = Promise.resolve()
          .then(() => {
            if (this.catalogPaths) {
              Object.keys((this.catalogPaths))
                .forEach((key) => {
                  ConfigLoader.catalogRegistry.register(key, this.catalogPaths[key]);
                });
              return ConfigLoader.catalogRegistry.getNames(this.namespace);
            }
            return null;
          })
          .then((catalogNames) => {
            const serviceFileMap = this._serviceFileMap;

            if (catalogNames) {
              // and add catalog "services", if any
              if (catalogNames && catalogNames.services
                && Array.isArray(catalogNames.services) && catalogNames.services.length) {
                catalogNames.services.forEach((name) => {
                  if (!serviceFileMap[name]) {
                    const serviceName = this.namespace ? `${this.namespace}:${name}` : name;
                    serviceFileMap[name] = {
                      path: `${CommonConstants.VbProtocols.CATALOG}://services/${serviceName}`,
                    };
                  }
                });
              }
            }

            if (this._extensionServiceMap) {
              Object.keys(this._extensionServiceMap).forEach((name) => {
                if (!serviceFileMap[name]) {
                  serviceFileMap[name] = {
                    path: this._extensionServiceMap[name],
                  };
                }
              });
              delete this._extensionServiceMap;
            }

            // We need to make sure that we executed _initializeServiceMap on the delegates. If we don't do this,
            // their backends are only "activated" if at least one of their services was used before
            // (if the backend is not "activated", it's not visible to other extensions).
            // We need to check if _initializeServiceMap is available because that method is not on the base class.
            return this.searchDelegates(
              (delegate) => delegate._initializeServiceMap && delegate._initializeServiceMap().then(() => false),
            ).then(() => undefined);
          });
      }
      return this._initializeServiceMapPromise;
    }

    findDeclaration(name, endpointReference) {
      return super.findDeclaration(name, endpointReference)
        .then((declaration) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          declaration || this._initializeServiceMap().then(() => super.findDeclaration(name, endpointReference)));
    }

    /**
     * @param namesToLoad
     * @param forceReload
     * @param endpointReference
     * @override
     */
    load(namesToLoad, forceReload, endpointReference) {
      return Promise.resolve()
        .then(() => this._initializeServiceMap())
        .then(() => super.load(namesToLoad, forceReload, endpointReference));
    }

    /**
     *
     * normally for flows, paths that don't start with './' log a warning, and get prefixed with the
     * container's relative path, because flows aren't allowed to reference services outside of their folder.
     *
     * it our case, we're referencing something in the extension registry, so skip the checks.
     *
     * @param filename {string}
     * @param namespace {string} unused
     * @returns {string}
     * @override
     */
    // eslint-disable-next-line class-methods-use-this,no-unused-vars
    getDefinitionPath(filename, namespace) {
      // the extension name is the folder name

      // if the path starts with "." (./some/path/openapi3.json)
      const prefix = filename.startsWith(Constants.RELATIVE_FOLDER_PREFIX)
        ? this._relativePath : this.namespace;

      // if it is an absolute URL (including vb-catalog://), leave it alone
      // vb-catalog://services/foo
      if (ServiceUtils.isAbsolute(filename)) {
        return filename;
      }

      // EXTENSION_PATH has ending slash
      return `${Constants.EXTENSION_PATH}${prefix}/${filename}`;
    }
  }
  return ExtensionServices;
});

