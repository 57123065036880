'use strict';

define('vb/private/types/factoryMetadataProviderDescriptor',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/types/metadataDescriptor',
], (Constants, Utils, MetadataDescriptor) => {
  class FactoryMetadataProviderDescriptor extends MetadataDescriptor {
    /**
     * accessor for <variable>.provider property; this is the provider created by the factory
     *
     */
    get provider() {
      // @todo: should this be an observable, in anticipation of expressions, and change support?
      return this._provider;
    }

    /**
     * @override
     * @param id
     * @param variableDef
     * @param value
     * @param container
     * @return {Promise} declaration, with expressions resolved
     */
    init(id, variableDef, value, container) {
      return super.init(id, variableDef, value, container)
        .then((options) => this.createProvider(options));
    }

    /**
     *
     * @param options from createOptions(), typically derived from the variable declaration.
     * @return {Promise} declaration
     */
    createProvider(options) {
      if (options) {
        return this.getProviderFactory(options)
          .then((factory) => {
            const newOptions = {
              context: this.getVbContext(),
              container: this.container,
            };

            Object.assign(newOptions, options.options);

            return factory.createMetadataProvider(newOptions);
          })
          .then((provider) => {
            this._provider = provider;
            return options; // todo: should this return newOptions? leave it as-is for now
          });
      }

      return Promise.reject(new Error('No definition for MetadataDescriptor.'));
    }

    /**
     * @returns {Object}
     */
    getVbContext() {
      const appContext = this.container.application.expressionContext || {};

      // normally this would be an object, but we are defining it as a function to prevent
      // a provider MetadataUtils.cloneObject call, that ends up getting the current value of the getters
      // that wrap the responsive observables (we use getters so we don't need parens in expressions).
      const filteredResponsive = () => ({});

      const responsiveKeys = Object.keys(appContext.responsive).filter((key) => key !== 'screenRange');
      responsiveKeys.forEach((key) => {
        // use a 'get'ter, not a 'value', because we need to preserve the (ko) observable behind
        // the $application.responsive.XXX getters
        Object.defineProperty(filteredResponsive, key, {
          get: () => appContext.responsive[key],
          enumerable: true,
        });
      });

      const context = {};
      // context.application = {}; // move properties to the top level
      Object.defineProperties(context, {
        user: {
          value: appContext.user,
          enumerable: true,
        },
        responsive: {
          value: filteredResponsive,
          enumerable: true,
        },
      });

      return context;
    }

    /**
     * @todo: support defining the "options" be getting a type from the factory, if available (requires Promise).
     * @returns {{type: {factory: string, options: string}}}
     */
    // eslint-disable-next-line class-methods-use-this
    getTypeDefinition() {
      return {
        type: {
          factory: 'string',
          options: 'object',
        },
      };
    }


    /**
     * subclasses may override this if the 'factory' property is not used to provide the factory implementation.
     * @param options
     * @param options.factory path to (requireJS) JET provider factory, or a VB 'bridge' factory wrapper
     * @returns {Promise}
     */
    // eslint-disable-next-line class-methods-use-this
    getProviderFactory(options) {
      return Promise.resolve()
        .then(() => {
          if (!options || !options.factory) {
            throw new Error('MetadataDescriptor requires a "factory" for configuration.');
          }
          return Utils.getResource(options.factory).then((Factory) => new Factory());
        });
    }
  }

  return FactoryMetadataProviderDescriptor;
});

