'use strict';

define('vbsw/api/fetchHandlerPlugin',[], () => {
  /**
   * Base class for implementing plugins that can be registered with the fetch handler.
   */
  class FetchHandlerPlugin {
    /**
     * Constructor for a handler plugin.
     *
     * @param context the context for the plugin which currently contains the fetchHandler and stateCache
     * @param params an optional params object
     */
    constructor(context, params = {}) {
      // extract the fetchHandler and stateCache from the context
      if (context) {
        this.fetchHandler = context.fetchHandler;
        this.stateCache = context.stateCache;
      }

      this.params = params;
    }

    /**
     * This method is called when the plugin is (re)installed to give it a chance to clean up previously cached
     * data.
     *
     * @returns {*}
     */
    initCache() {
      if (this.stateCache) {
        return this.stateCache.clear();
      }

      return Promise.resolve();
    }

    /**
     * Called by the fetch handler before calling the browser fetch on the request. The plugins are free to modify
     * the request such as appending new headers.
     *
     * @param request to request to be modified by the plugin
     * @param client the client for the originator of the request
     * @returns {Promise} That optionally contains a new instance of the request
     */
    handleRequestHook(request, client) {
      return Promise.resolve();
    }

    /**
     * Called by the fetch handler after getting a response from the browser fetch. The fetch handler will retry
     * the request if any handleResponseHook call returns true. This method returns a promise that resolves to
     * either true or false.
     *
     * @param response to response to be handled by the plugin
     * @param origRequest the original request
     * @param request the modified request
     * @param client the client id for the originator of the request
     * @returns {Promise.<Boolean>}
     */
    handleResponseHook(response, origRequest, request, client) {
      return Promise.resolve(false);
    }

    /**
     * Called by the fetch handler after getting an error from the browser fetch. The fetch handler will retry
     * the request if any handleErrorHook call returns true. This method returns a promise that resolves to
     * either true or false.
     *
     * @param error the error from the fetch request
     * @param origRequest the original request
     * @param request the modified request
     * @param client the client for the originator of the request
     * @returns {Promise.<Boolean>}
     */
    handleErrorHook(error, origRequest, request, client) {
      return Promise.resolve(false);
    }

    /**
     * Since a request is immutable, we serialize it into a config object which can be used to clone the request.
     *
     * @param request the request from which to serialize the configuration
     * @param fetchHandler fetch handler instance
     * @returns {Promise} resolved with a config object that can be used to construct a new Request. rejected on errors.
     */
    static getRequestConfig(request, fetchHandler) {
      let config;
      return Promise.resolve()
        .then(() => {
          // copy the existing headers
          const headers = {};
          for (const entry of request.headers.entries()) {
            if (entry && entry.length >= 2) {
              headers[entry[0]] = entry[1];
            }
          }

          config = {
            url: request.url,
            method: request.method,
            mode: request.mode,
            credentials: request.credentials,
            cache: request.cache,
            redirect: request.redirect,
            referrer: request.referrer,
            headers,
          };

          // copy the abort signal only if it exists
          if (request.signal) {
            config.signal = request.signal;
          }

          // Get the body if the method is PUT, POST or PATCH
          if (request.method === 'PUT' || request.method === 'POST' || request.method === 'PATCH') {
            // this is a workaround due to the fact that whatwg-fetch
            // polyfill can't clone request with FormData
            // this can be removed, once the original issue is addressed
            if (request._bodyFormData && fetchHandler.isFetchPolyfill()) {
              config.bodyFormData = request._bodyFormData;
              config.bodyInit = request._bodyInit;
            } else if (request.originalBody) {
              // BUFP-34920: This is a workaround for the issue with safari not supporting extraction of a request body
              // that can be used to create a proxy request. The body is directly attached to the request as
              // originalBody and we extract it here.
              config.body = request.originalBody;
              config.attachOrignalBody = true;

              // need to delete the content-type if body is a FormData
              if (config.body instanceof FormData) {
                delete config.headers['content-type'];
              }
            } else {
              // need to clone the request before calling arrayBuffer so we don't use the body on the original request
              return request.clone().arrayBuffer().then((body) => {
                config.body = body;
                return config;
              });
            }
          }
          return config;
        })
        .catch((err) => {
          console.warn(`attempt to clone request failed, using original: ${err}`);
          // if config wasn't created for some weird reason, rethrow the error
          throw err;
        });
    }
  }

  return FetchHandlerPlugin;
});

