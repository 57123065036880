'use strict';

define('vb/action/builtin/navigateToExternalAction',[
  'vb/action/action',
  'vb/helpers/navigate',
],
(Action, Navigate) => {
  /**
   * Navigate to an external site with given a set of input parameters.
   *
   * @param {String}            url     The url to navigate to (required)
   * @param {Array<key, value>} params  A map of URL parameters (optional)
   * @param {String}            hash    The hash entry (optional)
   * @param {String}            history Effect on the browser history. Allowed value are 'replace' or 'push'.
   *                                    If the value is 'replace', the current browser history entry is replace,
   *                                    meaning that back button will not go back to it. (optional and default is push)
   *
   * Always return {'success'}.
   *
   * An example of entry as seen in a page descriptor:
   *
   * "parameters": {
   *   "url": "'http://mysite/index.html'",
   *   "params": "{ id: $variables.id, name: 'Charles' }",
   *   "hash": "'details'"
   * }
   *
   */
  class NavigateToExternalAction extends Action {

    perform(parameters) {
      Navigate.toUrl(parameters);
      return Action.createSuccessOutcome();
    }
  }

  return NavigateToExternalAction;
});

