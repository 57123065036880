'use strict';

define('vb/action/builtin/fireCustomEventAction',['vb/action/eventAction', 'vb/action/action', 'vb/private/log', 'vb/private/constants',
  'vb/private/events/eventRegistry',
  'vb/private/action/assignmentHelper',
], (EventAction, Action, Log, Constants, EventRegistry, AssignmentHelper) => {
  const logger = Log.getLogger('/vb/private/stateManagement/fireCustomEventAction');

  // provided by JET
  const DISPATCH_NAME = '$dispatchEvent';

  class FireCustomEventAction extends EventAction {
    perform(parameters) {
      const { name, payload } = parameters;

      logger.info('FireCustomEventAction', this.logLabel, 'with event name', name, 'and payload', payload);

      if (!EventAction.isValid(name)) {
        return EventAction.createFailureOutcome(`unable to fire invalid event: ${name}`);
      }

      // look for a declaration; we need to determine if this is a 'normal' VB event,
      // or the dynamic-UI 'dynamicComponent' behavior event.
      const dcEventModel = EventRegistry
        .get(this.context.container, this.context.container, name, Constants.EventBehaviors.DYNAMIC_COMP);

      if (dcEventModel) {
        if (!dcEventModel.isInterface) {
          return EventAction
            .createFailureOutcome('Events with "template" behavior must be declared in the "interface" section: '
              + `${parameters.target}`);
        }

        return this.performDynamicComponentBehavior(name, payload, dcEventModel);
      }

      if (parameters && !EventAction.isValidTarget(parameters.target)) {
        return EventAction.createFailureOutcome(`invalid "target" for event action: ${parameters.target}`);
      }

      if (this.eventContainer) {
        // ignore returned Promise; we don't wait for event processing
        const result = EventAction.fireEvent(this.eventContainer, name, payload, parameters);
        if (!result) {
          return EventAction.createFailureOutcome(`Unable to find target: ${parameters.target}`);
        }

        // action waits for promises to resolve
        const promise = Promise.resolve(result);
        return promise
          .then((resolved) => EventAction.createSuccessOutcome(resolved));
      }

      return EventAction
        .createFailureOutcome(`FireCustomEventAction ${this.logLabel}: unable to fire event ${name}, no page context`);
    }


    /**
     * @param name {string} the event name; must be declared in "events", and accessible to this container
     * @param payload {object}
     * @param eventModel {EventModel} the one for 'behavior:dynamicComponent'
     */
    performDynamicComponentBehavior(name, payload, eventModel) {
      if (!name || !EventAction.isValid(name)) {
        return EventAction.createFailureOutcome(`Unable to fire invalid event: ${name}`);
      }

      if (!this.eventContainer || this.eventContainer.className !== 'Layout') {
        const msg = `Unable to fire event: ${name}. Events with 'dynamicComponent' `
          + 'behavior must be fired from a Layout';
        logger.error(msg);

        return EventAction.createFailureOutcome(msg);
      }

      if (name.toLowerCase() !== name) {
        logger.warn('About to fire an event with a name that is not all lower case;'
          + 'make sure your DOM listener uses lower case for the attribute name.');
      }

      // there is no good reason this should ever happen
      if (!this.context) {
        return EventAction
          .createFailureOutcome(`vb/fireCustomEventAction does not have a valid context for event: ${name}.`);
      }

      if (!eventModel || !eventModel.isDeclared) {
        return EventAction.createFailureOutcome(`Unable to find event declaration for: ${name}`);
      }

      const coercedPayload = eventModel.payloadType
        ? AssignmentHelper.coerceType(payload, eventModel.payloadType) : payload;

      return this.raiseEvent(name, coercedPayload);
    }


    /**
     * uses the JET-provided abstraction (defined to the VB layout model) to raise the event
     * @param name
     * @param payload
     * @returns {{result: *, name: string}}
     */
    raiseEvent(name, payload) {
      const dispatch = this.getDispatcher();

      if (!dispatch) {
        return EventAction.createFailureOutcome(`unable to fire event "${name}", no JET context provided.`);
      }

      try {
        // the JET-provided function will raise the event on the dynamic UI component

        const isIE = console.table === undefined;
        let event;
        if (!isIE) {
          event = new Event(name, { bubbles: false });
        } else {
          event = document.createEvent('Event');
          event.initEvent(name, false, true);
        }

        event.detail = payload;

        dispatch(event);

        logger.info('Dispatched Dynamic Container event: ', name, 'result');
      } catch (e) {
        return EventAction.createFailureOutcome(`Exception firing DOM event: ${name}`, e);
      }

      return EventAction.createSuccessOutcome();
    }

    /**
     * return the JET-provided function
     * @returns {*|mockScopes.$bindingContext|{$data}|{$dispatchEvent}}
     */
    getDispatcher() {
      // get the JET interface / abstraction for raising the event on the dynamic container
      const $bindingContext = this.internalContext && this.internalContext.$bindingContext;

      // @todo: the name & shape of this property will be defined by JET
      return $bindingContext && $bindingContext.$data && $bindingContext.$data[DISPATCH_NAME];
    }

    /**
     *
     * @param context
     * @param internalContext
     */
    setInternalContext(context, internalContext) {
      super.setContext(context);
      this.internalContext = internalContext;
    }
  }

  return FireCustomEventAction;
});

