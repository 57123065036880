'use strict';

define('vb/private/stateManagement/scope',[
  'knockout',
  'vb/private/stateManagement/variable',
  'vb/private/stateManagement/stateUtils',
  'vb/private/stateManagement/router',
  'vb/private/utils',
  'vb/private/constants',
  'vb/private/log',
  'vb/private/action/assignmentHelper',
  'vb/private/history',
  'vb/private/stateManagement/variableFactory',
], (ko, Variable, StateUtils, Router, Utils, Constants, Log, AssignmentHelper, History, VariableFactory) => {
  const logger = Log.getLogger('/vb/scope');

  class Scope {
    /**
     * A scope is a used to manage the state of a collection variables and constants.
     * The scope defines the lifespan and the storage of a variable.
     * @param  {String} name         A string use to name the scope. This will be used by the storage
     * mechanism. The name can be made unique using the { unique: true } option.
     * @param  {Container} container the container object (page, flow, etc) that this scope is build for.
     * It can be null, like for action chain.
     * @param  {Object} options      An object with property describing how the scope should be built.
     * There are 2 options available:
     *   { unique: true } build a scope using a unique name, default is false.
     *   { silent: true } variables in the scope will not dispatch a change event
     * @return {Scope}               the new scope object
     */
    constructor(name, container, options = {}) {
      this.log = logger;

      if (options.unique === true) {
        this.name = `${name}/${Utils.generateUniqueId()}`;
      } else {
        this.name = name;
      }

      this.variablesDef = [];
      this.variableNamespaces = {};
      // namespace is one of 'variables, 'builtin', ...
      Object.values(Constants.VariableNamespace).forEach((namespace) => {
        this.variableNamespaces[namespace] = {};
        Object.defineProperty(this, namespace, {
          value: this.variableNamespaces[namespace],
          enumerable: true,
        });
      });

      this.silent = options.silent || false;

      // When creating a scope for a chain, container is null
      if (container) {
        // Used by getWebStorageItemName to generated an application specific storage key
        this.appId = container.application.definition.id;

        // store access to the container directly
        this.container = container;
      }

      // change listeners for persisted variables
      this.persistedVariablesListeners = [];

      // The object where sessionStorage and localStorage API exist
      // Having it defined as a property allows tests to inject a mock storage
      this.storageInterface = window;
    }

    /**
     * Collect all the variable reducers and return them in an array
     * @return {Array[function]} the reducers array
     */
    initVariableReducers() {
      const reducers = {};

      this.variablesDef.forEach((variable) => {
        // Redux does not have context when calling the reducers. so we need to bind
        // each reducer to the variable they are used for.
        reducers[variable.stateProperty] = variable.createReducer().bind(variable);
      });

      return reducers;
    }

    syncWithStore(store) {
      this.store = store;

      // When an extension is present, all the extension scopes are synced to the store
      // before the variables in the scope subscribe to the store
      if (!this.container || !this.container.extensions || Object.keys(this.container.extensions).length === 0) {
        this.subscribeVariablesToStore();
      }
    }

    subscribeVariablesToStore() {
      // For each variable, listen to state change and update the observable.
      // Since it's an observable, we don't worry about duplicate update.
      this.variablesDef.forEach((variable) => variable.subscribeToStore(this.store));
    }

    getState() {
      let state;

      if (this.store) {
        state = this.store.getState()[this.name];
      }

      return state;
    }

    /**
     * Create a variable in the scope.
     * If the variable already exist, nothing happens.
     *
     * @param  {string}  name       The name of the variable
     * @param  {string}  namespace  the namespace that this variable belongs to. Valid namespaces
     *                              are: state, data and metadata. A variable can only belong to one namespace.
     * @param  {*}  type            The type of the variable (see StateUtils.getType() for type details)
     * @param  {*}  defaultValue    The default value created from the variable definition (may
     *                              be a primitive, struct, or expr)
     * @param  {*}  inputParamValue Value passed in from the caller
     * @param  {*}  descriptor      An object to defines the behavior of the variable.
     *                              The values can be:
     *                              { writable: Boolean } to create a read-only variable.
     *                              { persisted: String } to define the type of persistence to use.
     *                              The type is a string that can take the value "none", "device",
     *                              "session" or "history".
     *                              { rateLimit: { timeout: Number } } to specify the timeout in milliseconds for
     *                              { input: String } the input value for the variable descriptor ('fromUrl',
     *                              'fromCaller', ...) limiting how often onValueChanged should be fired.
     *                              { dependencies: Object}, a Map of dependencies (on other variables) for the current
     *                              variable. Contains 2 properties
     *                              expressions - Array of (variable) expressions that this variable configuration
     *                              references.
     *                              variables - Array of Objects representing the referenced variable info; where
     *                              each Object contains the following properties - scopeName, variableName. See
     *                              StateUtils.buildVariablesDependenciesGraph
     * @param  {*}  variableDef
     * @return {Variable}           A Variable object
     */
    createVariable(name, namespace, type, defaultValue, inputParamValue, descriptor = {}, variableDef) {
      let variable = this.getVariable(name, namespace);

      if (variable) {
        return variable;
      }

      // This scenario should not occur and trying to make it work is not performant since the
      // redux reducer would have to be combined again.
      if (this.store) {
        throw new Error(`Cannot create a variable ${name} because the scope ${this.name} has been added to the store.`);
      }

      let persisted;
      const fromUrl = descriptor.input === 'fromUrl';
      const inputType = descriptor.persisted;
      if (inputType && inputType !== 'none') {
        persisted = inputType;
      }

      if (!namespace) {
        this.log.error('A namespace should be provided for the variable', name);
      } else if (this[namespace] === undefined) {
        this.log.error('The namespace', namespace, 'is not valid for the variable', name);
      }

      // Retrieve the initial value from the defaultValue, input parameter or persisted value
      // TODO: pavi: calculating initial value for type instances does not work
      //  - for instance factory types the initialValues for constructorParams must be determined using
      //  inputParamValue and persisted.
      //  - for extended types it's the same behavior as instance factory types. It may also be required to apply
      //  the new value on the sibling variables.
      //  - for type instances initialized with no constructors it's a no op
      const initialValue = this.calculateInitialValue(name, namespace, type, defaultValue, inputParamValue, persisted);

      variable = VariableFactory.createVariable(type, {
        scope: this,
        name,
        namespace,
        type,
        defaultValue,
        initialValue,
        descriptor,
        variableDef,
      });

      this.variablesDef.push(variable);

      // variable created under namespace
      Object.defineProperty(this.variableNamespaces[namespace], name,
        {
          get() {
            return variable.getValue();
          },
          set(value) {
            variable.setValue(value);
          },
          enumerable: true,
        });

      // Listen to variable change to update persisted storage
      if (persisted || fromUrl) {
        const varChangeTracker = {
          eventSource: variable.onValueChanged,
          eventListener: (payload) => {
            // only persist the value if there is a diff
            if (payload.diff) {
              if (persisted) {
                const state = variable.serialize(payload.value);
                try {
                  this.storePersistedValue(this.name, payload.namespace, payload.name, persisted, state);
                } catch (error) {
                  this.log.error(error);
                }
              }
              if (fromUrl) {
                const defValue = Utils.resolveIfObservable(defaultValue);
                if (payload.value !== defValue) {
                  // Update input parameters so that they can be restored on the back button
                  const inputParameters = History.getInputParameters();
                  inputParameters[payload.name] = payload.value;
                  History.setInputParameters(inputParameters);

                  History.setUrlParameter(payload.name, payload.value);
                  // Immediately update the URL
                  History.sync();
                }
              }
            }
          },
        };
        variable.onValueChanged.add(varChangeTracker.eventListener, this);
        this.persistedVariablesListeners.push(varChangeTracker);
      }

      return variable;
    }

    /**
     * Retrieve the initial value from the defaultValue, input parameter or persisted value
     * @param  {string} name       name of the variable
     * @param  {string} namespace  namespace for this variable
     * @param  {string} type       type
     * @param  {*} defaultValue    the default value
     * @param  {*} inputParamValue the input parameter
     * @param  {string} persisted  the persisted type, either "local", "session", "device" or undefined
     * @return {*}                 the initial value
     */
    calculateInitialValue(name, namespace, type, defaultValue, inputParamValue, persisted) {
      let initialValue;
      try {
        // input parameter value takes precedence over persisted value
        if (inputParamValue !== undefined) {
          initialValue = inputParamValue;
        } else if (persisted) {
          // Load the persisted value before creating the variable so that the initial value is set
          const persistedValue = this.loadPersistedValue(this.name, namespace, name, persisted);

          if (persistedValue !== undefined && persistedValue !== null) {
            initialValue = StateUtils.restorePersistedValue(persistedValue, defaultValue);
            this.log.info('Loaded', persisted, 'persisted', namespace,
              Utils.getWebStorageItemName(this.appId, this.name, namespace, name),
              'value:', initialValue);
          }
        }

        // make sure the initialValue is coerced into the variable's type
        // Note: This code cannot be in StateUtils.createVariableInitialValue because it introduces a circular
        // require dependency between StateUtils and AssignmentHelper.
        if (initialValue !== undefined) {
          initialValue = AssignmentHelper.coerceType(initialValue, type);
        }

        initialValue = StateUtils.createVariableInitialValue(defaultValue, initialValue);
      } catch (error) {
        // Do not break when the variable cannot be loaded/parsed, just log the error
        this.log.error(error);
      }

      return initialValue;
    }

    /**
     * Return the own property descriptors for the given variable.
     *
     * @param variable the variable from which to get the property descriptors
     * @returns {{[P in keyof any]: TypedPropertyDescriptor<any[P]>} & {[p: string]: PropertyDescriptor}}
     */
    static getVariablePropertyDescriptors(variable) {
      if (typeof Object.getOwnPropertyDescriptors === 'function') {
        return Object.getOwnPropertyDescriptors(variable);
      }

      // IE11 - Object.getOwnPropertyDescriptors is not implemented in IE so we create the descriptors
      // by iterating over the property names returned from Object.getOwnPropertyNames.
      const descriptors = {};
      Object.getOwnPropertyNames(variable).forEach((name) => {
        descriptors[name] = Object.getOwnPropertyDescriptor(variable, name);
      });
      return descriptors;
    }

    /**
     * Create and add a variable that references another variable.
     *
     * @param variableName the name of the reference variable
     * @param namespace the namespace for the reference variable
     * @param referencedVariable the referenced variable
     */
    createReferenceVariable(variableName, namespace, referencedVariable) {
      // create a shallow clone of the referenced variable
      // Note: Ideally, we would create a Proxy. However, since Proxy is not supported for IE11. we'll just create
      // a shallow clone.
      const referenceVariable = Object.create(
        Object.getPrototypeOf(referencedVariable),
        Scope.getVariablePropertyDescriptors(referencedVariable),
      );

      // set the name and namespace for the reference variable
      referenceVariable.name = variableName;
      referenceVariable.namespace = namespace;

      // create an empty dispose function so we don't actually dispose the referenced variable
      referenceVariable.dispose = () => {};

      // add the variable proxy to the scope
      this.variablesDef.push(referenceVariable);

      // variable created under namespace
      Object.defineProperty(this.variableNamespaces[namespace], variableName,
        {
          get() {
            return referencedVariable.getValue();
          },
          set(value) {
            referencedVariable.setValue(value);
          },
          enumerable: true,
        });
    }

    /**
     * Remove a variable in the scope.
     * @param  {string} name        name of the variable to remove
     * @param {string} namespace
     * @return {boolean}            true is a variable was removed
     */
    removeVariable(name, namespace = Constants.VariableNamespace.VARIABLES) {
      for (let i = 0; i < this.variablesDef.length; i += 1) {
        const variable = this.variablesDef[i];
        if (variable.name === name && variable.namespace === namespace) {
          this.variablesDef.splice(i, 1);

          if (this.variableNamespaces[namespace]) {
            delete this.variableNamespaces[namespace].name;
          } else {
            this.log.error('Unable to remove variable', name, 'in the namespace', namespace,
              'because this namespace does not exist');
          }

          variable.dispose();

          return true;
        }
      }

      return false;
    }

    /**
     * Return a variable in the scope given its name.
     * @param  {string} name the name of the variable
     * @param  {string} namespace the namespace of the variable
     * @return {Variable}    a Variable object
     */
    getVariable(name, namespace = Constants.VariableNamespace.VARIABLES) {
      for (let i = 0; i < this.variablesDef.length; i += 1) {
        const variable = this.variablesDef[i];
        if (variable.name === name && variable.namespace === namespace) {
          return variable;
        }
      }

      return undefined;
    }

    /**
     * Retrieve the value of a variable from one of three storage type.
     * 1) session: value is stored on the browser sessionStorage.
     * 2) device: value is stored on browser localStorage
     * 3) history: value is stored on the browser history state.
     * If the type is not any of those storage type, undefine is returned.
     *
     * @param  {string} scopeName    the name of scope for this variable
     * @param  {string} namespace    the namespace of the variable
     * @param  {string} variableName the name of the variable
     * @param  {string} type         the type of persistence, 'session','device' or 'history'.
     * @return {*}                   the stored value
     */
    loadPersistedValue(scopeName, namespace, variableName, type) {
      let webStorageType;
      switch (type) {
        case 'history': {
          const serializedValue = History.getVariable(namespace, variableName);
          let value;
          if (serializedValue) {
            value = JSON.parse(serializedValue);
          }
          return value;
        }
        case 'device': webStorageType = 'local';
          break;

        case 'local': // backward compatibility
        case 'session': webStorageType = type;
          break;

        default:
          return undefined;
      }

      const newName = Utils.getWebStorageItemName(this.appId, scopeName, namespace, variableName);
      // storage is either local or session
      const storage = `${webStorageType}Storage`;

      let value = this.storageInterface[storage].getItem(newName);
      // For backward compatibility, look if the value exist under the old name and
      // convert it to use the new name
      if (value === null || value === undefined) {
        const oldName = Utils.getWebStorageItemName('app', scopeName, namespace, variableName);
        value = this.storageInterface[storage].getItem(oldName);
        if (value) {
          // Store the value using the new name
          this.storageInterface[storage].setItem(newName, value);
          // and remove the old one
          this.storageInterface[storage].removeItem(oldName);
        }
      }

      // getItem never returns undefined, so can safely call JSON.parse with its result
      return JSON.parse(value);
    }

    /**
     * Save the value of a variable on one of three storage type.
     * 1) session: value is stored on the browser sessionStorage.
     * 2) device: value is stored on browser localStorage
     * 3) history: value is stored on the browser history state.
     * If the type is not any of those storage type, nothing is saved.
     *
     * @param  {string} scopeName    the name of scope for this variable
     * @param  {string} namespace    the namespace of the variable
     * @param  {string} variableName the name of the variable
     * @param  {string} type         the type of persistence, 'session'. 'device' or 'history'
     * @param  {*}      value        the serialized value to store
     */
    storePersistedValue(scopeName, namespace, variableName, type, value) {
      let webStorageType;
      const serializedValue = value;

      switch (type) {
        case 'history': {
          History.setVariable(namespace, variableName, serializedValue);
          // Immediately update the history
          History.sync();
          return;
        }
        case 'device': webStorageType = 'local';
          break;

        case 'local': // backward compatibility
        case 'session': webStorageType = type;
          break;

        default:
          return;
      }

      const itemName = Utils.getWebStorageItemName(this.appId, scopeName, namespace, variableName);
      this.storageInterface[`${webStorageType}Storage`].setItem(itemName, serializedValue);
    }

    /**
     * Constants are stored directly in the scope, not in the redux store.
     * For maximum performance, constants are evaluated lazily and once evaluated,
     * they are redefined as readonly property.
     *
     * @param {string} name            the constant name
     * @param {string} namespace       the namespace that this constants belongs to.
     *                                 Valid namespaces are "constants" and "builtin"
     * @param {string} type            the type of the constant
     * @param {*}      value           the constant value (primitive or object)
     * @param {*}      inputParamValue the value from the input parameter
     * @param {string} inputType       either "none", "local", "device" or undefined
     * @param {Object} options         an object to specify the behavior of the constant.
     *                                 { freeze: false } specify if nested properties of the
     *                                 constant should be frozen (immutable). Default is true
     * @return {Variable}              A Variable object representing a constant if its defaultValue
     *                                 is a live expression, undefined otherwise
     */
    createConstant(name, namespace, type, value, inputParamValue, inputType, options = { freeze: true }) {
      let persisted;

      if (inputType && inputType !== 'none') {
        persisted = inputType;
      }

      if (namespace !== Constants.VariableNamespace.CONSTANTS
        && namespace !== Constants.VariableNamespace.BUILTIN) {
        // No need to throw an error. VB internal code controls what's the namespace is.
        return undefined;
      }

      const initialValue = this.calculateInitialValue(name, namespace, type, value, inputParamValue, persisted);
      const variable = new Variable(this, name, namespace, type, value, initialValue);
      let getter;

      // this is to support constant with live expression.
      // Skip constants defined in a chain/action (when container is null)
      if (this.container && ko.isObservable(initialValue)) {
        // Create a variable to handle expression updates
        this.variablesDef.push(variable);

        // but only define a getter
        getter = () => variable.getValue();

        // variable created under namespace
        Object.defineProperty(this.variableNamespaces[namespace], name, {
          get: getter,
          enumerable: true,
        });

        return variable;
      }

      // Uses the lazy value pattern. The expression is evaluated only the first time
      // it is accessed then the getter become read-only with the finalValue.
      Object.defineProperty(this.variableNamespaces[namespace],
        name, {
          get() {
            // Evaluate any observable from expression in nested object properties and
            // deep freeze the object to enforce no changes
            const finalValue = Utils.deepResolve(initialValue, { freeze: options.freeze });

            // Once the final value is calculated, redefine the property as a value, so the
            // next time it is read, the value will be returned immediately.
            // Note that "this" here is this.variableNamespaces.constants
            Object.defineProperty(this, name, {
              value: finalValue,
              enumerable: true,
            });

            return finalValue;
          },
          configurable: true,
          enumerable: true,
        });

      // this is a constant with a non-live expression
      variable.onValueChanged = null;

      return variable;
    }

    /**
     * Called when a variable has been initialized and added to scope and is considered 'active', so subscribers can
     * be be notified that it's safe tor read the (variable) value
     * @param availableContexts
     */
    activateVariables(availableContexts) {
      this.log.info('Activate variables for scope', this.name);
      const promises = [];

      this.variablesDef.forEach((variable) => {
        promises.push(variable.activate(this, availableContexts));
      });
      return Promise.all(promises);
    }

    dispose() {
      this.log.info('Disposing scope', this.name);

      this.variablesDef.forEach((variable) => {
        variable.dispose();
      });

      this.variablesDef = [];
      this.variableNamespaces = {};
      Object.values(Constants.VariableNamespace).forEach((namespace) => {
        this.variableNamespaces[namespace] = {};
      });

      // clean up persisted variables listeners
      this.persistedVariablesListeners.forEach((tracker) => {
        tracker.eventSource.remove(tracker.eventListener);
      });
    }
  }

  return {
    createScope(name, container, options) {
      return new Scope(name, container, options);
    },
  };
});

