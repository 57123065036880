/* eslint-disable max-classes-per-file */
'use strict';

define('vb/private/action/actionChain',[
  'ojs/ojcontext',
  'vb/action/action',
  'vb/helpers/actionHelpers',
  'vb/private/stateManagement/scope',
  'vb/private/stateManagement/redux/storeManager',
  'vb/private/stateManagement/stateUtils',
  'vb/private/utils',
  'vb/private/stateManagement/context/actionChainContext',
  'vb/private/log',
  'vbc/private/logConfig',
  'vb/private/constants',
  'vb/private/action/assignmentHelper',
  'vb/private/debug/actionChainDebugStream',
  'vbc/private/monitorOptions',
], (ojContext, Action, ActionHelpers, Scope, StoreManager, StateUtils, Utils, ActionChainContext, Log,
  LogConfig, Constants, AssignmentHelper, ActionChainDebugStream, MonitorOptions) => {
  const logger = Log.getLogger('/vb/action/actionChain', [
    // Register custom loggers
    {
      name: 'startChain',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.actionChainStart,
    },
    {
      name: 'endChain',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.actionChainEnd,
    },
  ]);

  const PARAMETERS_VARIABLE_KEY = 'vb_parameters';

  // bufp-17642: list of dollar-variables to skip when injecting the context for the current page into the
  // context for the chain. These should only be available to the event listener, and passed to chains if needed.
  // note: this '$current' is the one from the JET event listener, and NOT the one from forEachAction.
  const EXCLUDED_CHAIN_SCOPE_VARS = [
    Constants.ContextName.EVENT,
    Constants.ContextName.BINDING_CONTEXT,
    '$current', // not using constant, so it is not mistaken for the 'forEachAction' one
    Constants.ContextName.PREVIOUS,
  ];

  class ActionMonitorOptions extends MonitorOptions {
    constructor(actionId, actionDef, action) {
      const message = `${actionDef.module} ${action.logLabel}`;
      super('action', message);
      this.addTags(() => ({
        actionId,
        actionType: actionDef.module,
      }));
    }
  }

  class ActionChainMonitorOptions extends MonitorOptions {
    constructor(chainId, rootAction, logLabel) {
      const message = `action chain ${logLabel}`;
      super('actionChain', message);
      this.addTags(() => ({
        chainId,
        rootAction,
      }));
    }
  }

  /**
   * An ActionChain is a graph of one or more Actions that are executed using the supplied
   * context.
   */
  class ActionChain extends Action {
    /**
     * Builds a new action chain instance with page model metadata. This will create the proper context
     * for an action chain to run. An action chain should not be created manually, it should be created
     * by the static helper method ('start()').
     *
     * @private
     * @param id The ID for the action chain
     * @param metadata The action chain metadata as defined in the page model
     */
    constructor(id, metadata) {
      super(id);

      this.log = logger;
      this.id = id;

      this.constantDefs = metadata.constants || {};

      // variables will be created and initialized when we start the action chain
      this.variableDefs = metadata.variables;

      // store the list of actions
      this.actionDefs = metadata.actions;

      // the starting action
      this.rootAction = metadata.root;

      // optional return type
      this.returnType = metadata.returnType;

      // optional array of outcomes
      this.outcomes = metadata.outcomes;

      // The unique option create a store with a unique name.
      // The silent option is used when value change listener are not needed.
      this.scope = Scope.createScope(`chain_${id}`, null, { unique: true, silent: true });

      // storage for action results; results are stored in the 'variables' namespace
      this.scope.results = this.scope.createVariable(Constants.RESULTS_VARIABLE_KEY,
        Constants.VariableNamespace.VARIABLES, 'object', {});

      // storage for action parameters; action parameters are stored in the 'variables' namespace
      this.scope.createVariable(PARAMETERS_VARIABLE_KEY, Constants.VariableNamespace.VARIABLES, 'object', {});

      this.variables = this.scope.variableNamespaces[Constants.VariableNamespace.VARIABLES];

      // this is the $chain variable, and contains variables, metadata, and results.
      // injectContext will add additional properties from the calling context.
      this.expressionContext = new ActionChainContext(this);

      // the set of all Contexts and shortcuts, available for expressions:
      // $chain = expressionContext, $variables = $chain.variables, $metadata = $chain.metadata
      this.availableContexts = ActionChainContext.getAvailableContexts(this);

      // functions to call at the end, unconditionally; only actions that have access to the chain can add these
      // contains { actionId, name, fnc }
      this.finallyCallbacks = [];

      // stream used to communicate with the VB debugger
      this.debugStream = new ActionChainDebugStream(this);
    }

    /**
     * Execute the action chain beginning with the root action.
     *
     * @param params the params that this action chain is called with
     * @param callingContexts the contexts of the caller which can be another action chain
     * @return {Promise<Object>}  resolves with an outcome object when the action chain has completed.
     */
    start(params, callingContexts) {
      const parameters = params || {};
      return Promise.resolve().then(() => {
        // evaluate the parameter using the calling contexts
        const inputParamValues = {};
        Object.keys(parameters).forEach((key) => {
          const param = parameters[key];
          const value = StateUtils.getValueOrExpression(param, callingContexts);

          // immediately resolve the initial value if it's an expression since we don't want the expression
          // to stick around in the chain variable since we prevent overwriting an expression
          inputParamValues[key] = Utils.resolveIfObservable(value);
        });

        // initialize constants and variables to the input parameters or their default values
        this.initializeConstants(inputParamValues);
        return this.initializeVariables(inputParamValues, callingContexts)
          .then(() => {
            StoreManager.addScopeToStore(this.scope);
            this.activateVariables();
            this.log.startChain('Starting action chain', this.logLabel, 'with parameters:',
              inputParamValues);
            const mo = new ActionChainMonitorOptions(this.id, this.rootAction, this.logLabel);
            return this.log.monitor(mo, (totalTime) => {
              this.totalTime = totalTime;

              // debug the start of the action chain
              return this.debugStream.start().then(() => this.runActionStep(this.rootAction));
            });
          });
      })
        .then((result) => {
          this.end();
          return result;
        })
        .catch((error) => {
          this.end(error);
          throw error;
        });
    }

    /**
     * Initialize constants from declaration.
     * TODO: when ActionChain subclasses Container this code must be removed
     */
    initializeConstants(inputParamValues = {}) {
      const scopeResolver = this.context && this.context.container && this.context.container.scopeResolver;

      // go through the constants and create each one.
      // this assumes the constants definition has been filtered by initDefault()
      Object.keys(this.constantDefs).forEach((constantName) => {
        const constantDef = this.constantDefs[constantName];

        // get the initial value if any
        const inputParamValue = inputParamValues && inputParamValues[constantName];

        if (Utils.isExtendedType(inputParamValue)) {
          throw new Error(`Constant '${constantName}' cannot be an extended type.`);
        } else if (Utils.isInstanceType(constantDef.type)) {
          throw new Error(`Constant '${constantName}' cannot be a built-in type.`);
        } else {
          this.createConstant(constantName, constantDef, scopeResolver, inputParamValue);
        }
      });
    }

    /**
     * Create a constant and store it in the scope
     * @param  {String}    constantName    the name of the constant
     * @param  {Object}    constantDef     the definition of the constant
     * @param  {Container} scopeResolver   the scope resolver object (application, page, ...)
     * @param  {*}         inputParamValue the fromCaller or fromUrl value
     */
    createConstant(constantName, constantDef, scopeResolver, inputParamValue) {
      // determine the default value for this constant then create the constant
      const defaultValue = StateUtils.createNonInstanceTypeDefaultValue(constantName, constantDef,
        scopeResolver, this.availableContexts);

      this.scope.createConstant(constantName, Constants.VariableNamespace.CONSTANTS,
        constantDef.type, defaultValue, inputParamValue);
    }

    /**
     * Create the chain variables and initialize them to their default values or initial values (input parameters).
     * TODO: when ActionChain subclasses Container Container this code must be removed
     *
     * @param parameters parameters to the action chain
     * @param callingContexts the contexts of the caller which can be another action chain
     * @param namespace
     * @returns {Promise}
     */
    initializeVariables(initialValues = {}, callingContexts, namespace = Constants.VariableNamespace.VARIABLES) {
      if (!this.variableDefs) {
        return Promise.resolve();
      }

      const promises = [];
      const scopeResolver = this.context && this.context.container && this.context.container.scopeResolver;

      Object.keys(this.variableDefs).forEach((variableName) => {
        const variableDef = this.variableDefs[variableName];

        // A variable and constant cannot have the same name
        if (this.constantDefs[variableName]) {
          promises.push(Promise
            .reject(new Error(`Variable '${variableName}' cannot have the same name as a constant.`)));
        } else {
          let refVariablesAdded;
          // get the initial value if any
          const initialValue = initialValues && initialValues[variableName];

          // if the value is an instance of an extended class, e.g., ServiceDataProvider, we need to look up the
          // actual referenced variable and the instance properties variable and create reference variables
          // for them in order to support pass-by-reference.
          if (Utils.isExtendedType(initialValue)) {
            refVariablesAdded = this.addReferenceVariables(initialValue, variableName, namespace, callingContexts);
          }

          if (!refVariablesAdded) {
            const promise = StateUtils.createVariableDefaultValue(variableName, variableDef, scopeResolver,
              this.scope, this.availableContexts, namespace).then((defaultValue) => {
              const type = StateUtils.getType(variableName, variableDef, scopeResolver);
              this.scope.createVariable(variableName, namespace, type, defaultValue, initialValue);
            });

            promises.push(promise);
          }
        }
      });

      return Promise.all(promises);
    }

    /**
     * Activate all variables for the current container  (chain scope).
     * TODO: when ActionChain subclasses Container this code must be removed
     *
     * @private
     */
    activateVariables() {
      this.scope.activateVariables();
      // currently we do not allow variables defined in actions chains to be extended, for that matter we
      // don't allow extending chains, so activateVariables doesn't have to be called on extensions
    }

    /**
     * Given an instance of an extended class, e.g., ServiceDataProvider, look up its instance variable and
     * instance properties variable and create and add reference variables pointing to them to the chain scope.
     *
     * @param instance an instance of an extended class, e.g., ServiceDataProvider
     * @param referenceVariableName the name for the reference variable
     * @param namespace namespace for the reference variable
     * @param callingContexts the contexts of the caller used to looked up the referenced variables
     * @returns {Boolean}
     */
    addReferenceVariables(instance, referenceVariableName, namespace, callingContexts) {
      const { id } = instance;
      let added;

      if (id) {
        added = ['$chain', '$page', '$flow', '$application', '$global'].some((contextName) => {
          const context = callingContexts[contextName];

          if (context) {
            const variable = context.getVariable(id, Constants.VariableNamespace.VARIABLES);
            if (variable && variable.getValue() === instance) {
              // look up the instance properties variable whose name ends with _value
              const propVariable = context.getVariable(`${id}${Constants.BuiltinVariableName.VALUE}`,
                Constants.VariableNamespace.VARIABLES);

              if (propVariable) {
                // create a reference variable that points to the instance variable
                this.scope.createReferenceVariable(referenceVariableName, namespace, variable);

                // create a reference variable that points to the instance properties variable
                this.scope.createReferenceVariable(`${referenceVariableName}${Constants.BuiltinVariableName.VALUE}`,
                  namespace, propVariable);

                return true;
              }
            }
          }

          return false;
        });
      }

      if (!added) {
        throw new Error(`Failed to add reference variables for ${referenceVariableName}.`);
      }

      return true;
    }

    end(error) {
      if (this.totalTime) {
        this.log.endChain('Ending action chain', this.logLabel, 'successfully', this.totalTime(error));
      }

      // close the debug stream
      this.debugStream.end();

      this.dispose();
    }

    /**
     * Extract the chain id from the given id which can be prefixed, e.g., application:fooAction or flow:fooAction.
     *
     * @param id the id, e.g., fooChain, application:fooChain or flow:fooChain
     * @returns {*|string}
     */
    static extractChainId(id) {
      const idParts = id.split(':');
      return idParts.length === 1 ? idParts[0] : idParts[1];
    }

    /**
     * Starts an action chain and returns the promise that will resolve to the outcome of the last
     * executed action of the chain.
     *
     * @param actionId The chain id
     * @param params Set of parameters to match the variables of the action chain
     * @param scopes Scopes in context for the chain
     * @param context Internal objects like actions, application, and flow needed by a builtin action
     * @returns {Promise.<*>}
     */
    static startChain(actionId, params, scopes, context) {
      return Promise.resolve().then(() => {
        if (!actionId) {
          throw new Error('Required \'id\' parameter missing for starting action chain.');
        }

        // handle application, flow or page level action chains
        // Resolve the scope (application, flow, page) from the actionId if one exist
        // and get the action metadata.
        return StateUtils.resolveChain(actionId, context.container.scopeResolver)
          .then((actionMetadata) => {
            if (!actionMetadata) {
              throw new Error(`Action chain ${actionId} does not exist.`);
            }

            // setup the action chain and execute and make sure we extract the proper chain id
            const actionChain = new ActionChain(ActionChain.extractChainId(actionId), actionMetadata);

            actionChain.injectContext(scopes, context, actionId);

            let chainError;

            // add a busy state to JET's busy context which is used by webdriverjs tests to wait for
            // activities in the page to quiet down
            const busyContext = ojContext.getPageContext()
              .getBusyContext();
            const busyStateResolver = busyContext.addBusyState({ description: actionId });

            return actionChain.start(params, scopes)
              .catch((e) => {
                chainError = e;
              })
              .then((result) => {
                // run any registered 'finally' cleanup tasks
                actionChain.runFinallyCallbacks();

                // resolve the busy state
                busyStateResolver();

                // if we caught an error above, throw that
                if (chainError) {
                  throw chainError;
                }
                // otherwise, return the original chain's result, not the event handler's chain
                return result;
              });
          });
      });
    }

    /**
     * Also adds any scoped implicit objects for this action chain context. The format should be a
     * map from key (name of the implciit object) to it's value.
     *
     * @param scopes The set of scopes applicable for this action chain
     * @param context The current actions, application, and flow
     * @param chainId used to look up the container using the scopeResolver
     */
    injectContext(scopes, context, chainId) {
      // add 'internal' for any availableContexts that the action may expect, but we don't expose in EL.
      this.internalContext = {};

      // cannot use Object.assign, need to copy getters; some values might not have been created yet (page.pageScope)
      Object.getOwnPropertyNames(scopes).forEach((key) => {
        // skip ones that are specific to the eventListener declaration, and should not be available in the chain
        const descriptor = Object.getOwnPropertyDescriptor(scopes, key);

        if (EXCLUDED_CHAIN_SCOPE_VARS.indexOf(key) === -1) {
          // guard against scope contexts that attempt to override our chain variables
          if (!Object.prototype.hasOwnProperty.call(this.availableContexts, key)) {
            Object.defineProperty(this.availableContexts, key, descriptor);
          }
        } else {
          //  don't expose, but make it available to actions that need it
          Object.defineProperty(this.internalContext, key, descriptor);
        }
      });

      this.context = context;

      // only execute this code when the debugger is installed
      if (this.debugStream.isDebuggerInstalled) {
        // use the scopeResolver to look up the container in which the chain is defined
        const container = StateUtils.resolveContainer(chainId, context.container.scopeResolver);

        // set up the locator for looking up the chain on the debugger side
        this.debugStream.setChainLocator(container.getResourcePath());
      }
    }

    /**
     * Create a scope to store the action parameters and provide isolation between action steps
     * and returns an array of values/getters.
     * Each parameter is defined as a constant so that the value is not stored in redux store.
     * @param  {String} id                a unique id
     * @param  {Object} actionParams      the parameter definition, object where each property is a parameter
     * @param  {Object} availableContexts the contexts for expression evaluation
     * @return {Array}                    an array with the parameter values
     */
    static createActionParameters(id, actionParams = {}, availableContexts) {
      const keys = Object.keys(actionParams);

      // Only create the scope if there is at least one parameter
      if (keys.length === 0) {
        return [];
      }

      const scope = Scope.createScope(id, null, { silent: true });

      // Traverse the action parameters definition and create a constant in the action scope for each parameter
      keys.forEach((key) => {
        const type = 'any';
        const defaultValue = StateUtils.buildVariableDefaults(key, availableContexts, type, actionParams[key]);
        // Constant is created using freeze: false so that their value are allowed to mutate
        scope.createConstant(key, Constants.VariableNamespace.CONSTANTS, type, defaultValue, undefined, null,
          { freeze: false });
      });

      return scope.variableNamespaces[Constants.VariableNamespace.CONSTANTS];
    }

    /**
     * Load the action module specified by actionModuleId. This method will return the cached module if it
     * has already been loaded.
     *
     * @param actionModuleId the id for the action module to load
     */
    static loadActionModule(actionModuleId) {
      this.actionModules = this.actionModules || {};

      return Promise.resolve().then(() => {
        const module = this.actionModules[actionModuleId];

        if (!module) {
          return Utils.getResource(actionModuleId).then((actionModule) => {
            // TODO: turn off caching for now to unblock preflight
            // this.actionModules[actionModuleId] = actionModule;

            return actionModule;
          });
        }

        return module;
      });
    }

    /**
     * Runs a particular action with the given ID. The ID must be part of the action chain that this class is
     * associated with.
     *
     * @param aid The ID of the action to accept
     * @param scopeContextOverride optional. if undefined, this.scopeContext is used.
     * @returns {Promise<Object>} a promise that resolves with an outcome object
     */
    runActionStep(aid, availableContexts = this.availableContexts) {
      // used to measure setup time for the action
      const setupTime = this.log.monitor();

      let actionId = aid;
      // this method takes in the action ID, however we had previously supported the outcomes explicitly providing
      // the action definition. this bit of code provides backwards compatiblity. When we want to stop supporting
      // the old syntax, we will always lookup the actionDef from the actionId
      let actionDef;
      if (typeof actionId === 'string') {
        // this is forward looking code
        actionDef = this.actionDefs[actionId];
        if (!actionDef) {
          throw new Error(`The action chain '${this.id}' does not contain action with ID '${actionId}', aborting.`);
        }
      } else {
        // this is backwards compatible code
        actionDef = actionId;
        actionId = actionDef.id;
      }

      return ActionChain.loadActionModule(actionDef.module).then((NewAction) => {
        const actionOutcomes = actionDef.outcomes;

        // create and configure the action
        const actionConfig = this.getActionConfig(actionId, actionDef);
        const action = new NewAction(actionId, actionDef.label, actionConfig);
        this.addHelpersToAction(action);
        this.addContextToAction(actionDef.module, actionDef, action, availableContexts);

        // Use the unique id of the action to build the unique id of the scope
        const actionParamInstances = ActionChain.createActionParameters(`parameters_${action.id}`,
          actionDef.parameters, availableContexts);

        // need to create a separate parameter instance for the debugger/action chain tester since
        // it needs to serialize the parameters which causes expressions in the parameter instance to
        // be evaluated too early because they now behave like constants
        // RESOLVE: We can't move this code into the debugStream because it causes circular require dependency
        const debugActionParamInstances = this.debugStream.isEnabled
          ? ActionChain.createActionParameters(`parameters_${action.id}`,
            actionDef.parameters, availableContexts) : null;
        this.log.info('Chain', this.logLabel, 'starting action step', action.logLabel,
          'with parameters:', actionParamInstances, ' setup:', setupTime());

        const mo = new ActionMonitorOptions(actionId, actionDef, action);
        // eslint-disable-next-line arrow-body-style
        return this.log.monitor(mo, (totalStepTime) => {
          // execute action and process the next action based on the outcome
          return this.debugStream.actionStart(action, debugActionParamInstances)
            .then(() => action.start(actionParamInstances)
              .then((outcome) => {
                const ao = this.handleActionOutcome(actionId, actionOutcomes, outcome);
                this.log.info('Chain', this.logLabel, 'ending action step', action.logLabel,
                  'with outcome', outcome, totalStepTime());

                // debug the end of an action
                this.debugStream.actionEnd(action, outcome);

                if (ao.nextAction) {
                  return this.runActionStep(ao.nextAction, availableContexts);
                }
                return ao.outcome;
              })
              .catch((e) => {
                this.log.error('Chain', this.logLabel, 'action step', action.logLabel, 'failed.', e,
                  totalStepTime(e));
                throw e; // FIXME messaging
              }));
        });
      });
    }

    /**
     * Returns the final outcome if there is no nextAction to execute; Or returns the
     * id of the nextAction.
     * @param {String} actionId
     * @param {Array[String]} actionOutcomes
     * @param {Object} outcomeParam
     * @returns {*} object with one of 2 properties: outcome, a string or nextAction.
     */
    handleActionOutcome(actionId, actionOutcomes, outcomeParam) {
      const outcome = outcomeParam;
      const nextAction = actionOutcomes ? actionOutcomes[outcome.name] : null;

      if (!nextAction) {
        if (this.outcomes && this.outcomes.indexOf(outcome.name) === -1) {
          throw new Error(`The ${outcome.name} outcome does not match one of the chain's possible outcomes.`);
        }

        // automap the outcome result using the return type
        if (this.returnType) {
          const scopeResolver = this.context && this.context.container && this.context.container.scopeResolver;
          const resolvedType = StateUtils.getType(null, { type: this.returnType }, scopeResolver);

          // coerce the result to the return type
          outcome.result = AssignmentHelper.coerceType(outcome.result, resolvedType);
        }

        this.addActionResult(actionId, outcome);
        return { outcome };
      }

      this.addActionResult(actionId, outcome);

      // at this point we need to call the next step in the action
      return { nextAction };
    }

    /**
     * Store the result of the action in results so that we can access it later
     */
    addActionResult(actionId, outcome) {
      if (actionId) {
        this.scope.results.getValue()[actionId] = outcome.result;
      }
    }

    /**
     * Certain actions need more context. Instead of exposing an interface API, we instead look
     * for specific actions and inject the context.
     *
     * The advantage of this approach is that user-specified actions cannot configure their
     * actions to gain access to the context - something we want to avoid in general.
     *
     * @private
     * @param actionType The module name of the action
     * @param actionDef The definition of the action in metadata
     * @param action The newly created action
     */
    addContextToAction(actionType, actionDef, action, availableContexts = this.availableContexts) {
      if (actionType.startsWith('vb/action/builtin/')) {
        switch (actionType.substr(18)) {
          case 'assignVariablesAction':
          case 'callVariableMethodAction':
          case 'resetVariablesAction': {
            action.setAvailableContext(availableContexts);
            break;
          }

          case 'callChainAction': {
            // need to clear action chain specific context before passing it along
            const availableContextsClone = this.availableContexts.clone();
            // leave the $chain on the availableContexts so it can be used to look up extended-typed variables
            // such as ServiceDataProvider
            delete availableContextsClone.$variables;

            action.setContext(availableContextsClone, this.context);
            break;
          }

          case 'forkAction': {
            // backward compatibility, 'originally used overloaded 'outcomes' map
            // now uses a 'parameters.actions' map; keep 'outcomes' for a little while
            // we won't need to do this once we remove backward-compat
            const outcomes = !actionDef.outcomes ? {} : Utils.cloneObject(actionDef.outcomes);
            if (outcomes.join) {
              delete outcomes.join;
            }
            action.setContext(this, outcomes);
            break;
          }
          case 'downloadExtensionsAction':
          case 'forEachAction': {
            action.setContext(this, availableContexts);
            break;
          }

          case 'restAction':
          case 'callModuleFunctionAction':
          case 'fireNotificationEventAction':
          case 'loginAction':
          case 'logoutAction':
          case 'editorUrlAction':
          case 'checkForExtensionsAction':
          case 'restartApplicationAction': {
            action.setContext(this.context);
            break;
          }

          case 'takePhotoAction': {
            action.setAvailableContexts(availableContexts);
            break;
          }

          case 'fireCustomEventAction': {
            action.setInternalContext(this.context, this.internalContext);
            break;
          }


          default:
            break;
        }
      }

      // inject the container lifecycle state without making the container available to the action
      if (this.context && this.context.container) {
        Object.defineProperty(action, 'containerLifecycleState', {
          get: () => this.context.container.lifecycleState,
        });
      }
    }


    /**
     * get the configuration object to pass to the constructor
     * currently only contains the 'registrar', to allow action to register callbacks
     *
     * @param actionId
     * @returns {{registrar: {setFinallyCallback: (function(*=, *=))}}}
     */
    getActionConfig(actionId /* , actionDef */) {
      // provide a narrow interface for the action to call
      return {
        registrar: {
          setFinallyCallback: (name, callback) => {
            this.setFinallyCallback(actionId, name, callback);
          },
        },
      };
    }


    /**
     * actions that are granted access to the actionChain can register a callback called when the chain is complete.
     * use an array; callbacks will be called in registration order
     * the callback can optionally return a Promise.
     *
     * @param actionInstanceId
     * @param name
     * @param callback
     */
    setFinallyCallback(actionInstanceId, name, callbackFnc) {
      const existing = this.finallyCallbacks.find(callback => callback.id === actionInstanceId);
      // replace the existing one - each action only gets one
      if (existing) {
        existing.name = name;
        existing.fnc = callbackFnc;
      } else {
        this.finallyCallbacks.push({ id: actionInstanceId, name, fnc: callbackFnc });
      }
    }

    /**
     * run any registered callbacks
     *
     * If a callback returns a Promise, it must resolve/reject before the next callback is called.
     * A rejected Promise does not stop callback processing.
     */
    runFinallyCallbacks() {
      let promise = Promise.resolve(); // initial promise
      this.finallyCallbacks.forEach((callback) => {
        promise = promise.then(() => {
          if (typeof callback.fnc === 'function') {
            try {
              this.log.info('calling', callback.name, 'callback for action', callback.id);
              // allow callback fnc to optionally return a Promise
              return Promise.resolve(callback.fnc());
            } catch (e) {
              this.log.error('error in Action callback for', callback.id, ':', callback.name, e);
            }
          }
          return null; // not used;
        });
      });
    }


    /**
     * Add to an action the helper functions that require the context . The helper
     * functions are needed to implement user-specified actions.
     *
     * @param {Action} the newly created action
     */
    addHelpersToAction(action) {
      // inject the helpers on the action
      Object.defineProperty(action, 'helpers', {
        value: new ActionHelpers(this.context),
      });
    }

    /**
     *
     */
    dispose() {
      delete this.variables;
      this.scope.dispose();
      StoreManager.removeScopeFromStore(this.scope);
    }
  }

  return ActionChain;
});

