'use strict';

define('vb/private/monitoring/fetchRequestMonitorOptions',[
  'vbc/private/monitorOptions',
], (MonitorOptions) => {
  /**
   * Monitor options for tracing rest requests
   */
  class FetchRequestMonitorOptions extends MonitorOptions {
    constructor(operationName, message, request) {
      super(operationName, message);
      this.addStartFields(() => ({
        request,
      }));
    }
  }


  return FetchRequestMonitorOptions;
});

