'use strict';

define('vb/private/stateManagement/page',[
  'knockout', 'vb/private/stateManagement/container',
  'vb/private/stateManagement/router',
  'vb/private/stateManagement/stateUtils',
  'vb/private/utils', 'vb/private/log',
  'vb/private/constants', 'vb/private/stateManagement/stateMonitor',
  'vb/private/stateManagement/context/pageContext',
  'vb/private/stateManagement/pageExtension',
  'vb/private/history', 'vb/errors/httpError',
  'vbc/private/performance/performance',
  'vb/private/monitoring/loadMonitorOptions',
], (ko, Container, Router, StateUtils, Utils, Log, Constants, StateMonitor, PageContext,
  PageExtension, History, HttpError, Performance, LoadMonitorOptions) => {
  const logger = Log.getLogger('/vb/stateManagement/page', [
    // Register a custom logger
    {
      name: 'greenInfo',
      severity: 'info',
      style: 'green',
    },
  ]);

  /**
   * Page class
   */
  class Page extends Container {
    constructor(id, parent, path = parent.path, className = 'Page') {
      super(id, parent, className);

      // from this point on the path value cannot be modified.
      Object.defineProperties(this, {
        path: {
          value: `${path}pages/`,
          enumerable: true,
        },
      });

      /**
       * The instances of the loaded flows used by this page
       * @type {Flow}
       */
      this.flows = {};

      this.moduleConfig = ko.observable(Constants.blankModuleConfig);

      this.loadPagePromise = null;
      this.loadAndStartPromise = null;
      this.initializePromise = null;
      this.enterPromise = null;
      this.viewModelPromise = null;

      this.inBeforeEvent = false;
      this.deactivated = false;

      this.log = logger;
    }

    static get extensionClass() {
      return PageExtension;
    }

    /**
     * The folder where the the dynamic layouts are defined
     * For pages, it's "dynamicLayouts/"
     * This location is different for packagePage
     * @return {String}
     */
    static get layoutRoot() {
      return Constants.DefaultPaths.LAYOUTS;
    }

    /**
     * @returns {string}
     */
    get fullName() {
      return `${this.id}-page`;
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor
     *
     * @return {String} the descriptor loader function name
     */
    // eslint-disable-next-line class-methods-use-this
    get descriptorLoaderName() {
      return 'getPageDescriptor';
    }

    /**
     * The name of the runtime environment function to be used to load the module functions
     *
     * @return {String} the module loader function name
     */
    // eslint-disable-next-line class-methods-use-this
    get functionsLoaderName() {
      return 'getPageFunctions';
    }

    /**
     * The name of the runtime environment function to be used to load the html
     *
     * @return {String} the template loader function name
     */
    // eslint-disable-next-line class-methods-use-this
    get templateLoaderName() {
      return 'getPageTemplate';
    }

    /**
     * The name of the chain folder is the page name with '-page-chains' appended.
     * @returns {string}
     */
    get chainsFolderName() {
      return `${this.fullName}-chains`;
    }

    /**
     * Return the first flow up in the parent hierarchy.
     * For flow, it's this.parent.parent, for page it's this.parent for
     * application it's null.
     *
     * @return {Flow} the first flow in the parent hierarchy
     */
    getParentFlow() {
      return this.parent;
    }

    isDefault() {
      return this.parent.definition.defaultPage === this.id;
    }

    /**
     * returns the Flow's Services, if any
     * @returns {Services}
     */
    getServices() {
      return this.parent.getServices();
    }

    /**
     * Retrieve a flow instance. If it doesn't exist, create and load it but never returns
     * undefined.
     *
     * @param  {String} id the flow id
     * @param  {NavigationContext} navContext the context of the current navigation chain
     * @return {Promise} a promise which resolve with the flow instance
     */
    loadFlow(id, navContext) {
      return Promise.resolve().then(() => {
        let flow = this.flows[id];

        if (flow) {
          return flow;
        }

        flow = this.parent.createFlow(id, this);

        return flow.load()
          .then(() => flow.processDefaultPage())
          .then(() => {
            if (!navContext) {
              // A child Router was just created in the flow so if the load was triggerred by the
              // router (which is the case navContext is not defined) then we need to call sync on
              // the JET router to synchronize the state of the routers with the URL.
              // This can happen in 2 cases:
              //   1) when the page is refreshed
              //   2) when going back or forward in the browser history.
              Router.sync();
            }

            this.flows[id] = flow;

            return flow;
          });
      });
    }

    /**
     * Load the nested flow given its id
     *
     * @param  {String} id the id of the flow
     * @param  {NavigationContext} navContext the context of the current navigation chain
     * @return {Promise} a promise that resolve to a Flow instance
     */
    loadContainer(id, navContext) {
      if (navContext && navContext.isCancelled()) {
        return Promise.resolve();
      }

      return this.loadFlow(id, navContext);
    }

    /**
     * Retrieve the cached instance of the nested container.
     * For page, the return value is a flow instance.
     * @param  {String} id the id of the page to retrieve
     * @return {Container} the flow instance
     */
    getContainer(id) {
      return this.flows[id];
    }

    /**
     * Load a nested container using the id
     *
     * When a page is loading the first segment of a path, it is assumed it is
     * the id of a page.
     *
     * @param  {String} id
     * @param  {NavigationContext} navContext
     * @return {Promise}
     */
    loadFirstPathSegment(id, navContext) {
      const { operation } = navContext.options;

      // When using the old navigateToPage, the behavior is to attempt to load a flow
      // first, then if it fails, load a sibling page
      if (operation === 'oldNavigateToPage') {
        return this.loadContainer(id, navContext).catch((error) => {
          // If the flow doesn't exist, try to load a sibling page.
          if (HttpError.isFileNotFound(error)) {
            return this.parent.loadContainer(id, navContext)
              .then((result) => {
                this.log.warn(`Invalid navigation to page "${id}".`,
                  'Using navigateToPage action is deprecated.',
                  'Use the navigate action with the \'page\' parameter.');
                return result;
              });
          }

          throw error;
        });
      }

      // Load a sibling page by asking the parent flow to load the page
      return this.parent.loadContainer(id, navContext);
    }

    /**
     * Retuns the ojModule configuration for the nested flow
     * @param  {{default: string}} options A set of options including default for the default flow
     * @return {Object}  the flow moduleConfig
     */
    [Constants.flowModuleConfigFunctionName](options) {
      // if (!options || !options.default) {

      // }
      const moduleConfig = this.moduleConfig;
      // The moduleConfig is initialized lazyly the first time the ojModule binding is
      // resolved. This is because the page might not have any ojModule, so no point
      // creating one until it's needed.
      if (moduleConfig().viewModel === null) {
        this.loadFlow(options.default).then((flow) => {
          // "Bind" the nested flow moduleConfig to ojModule. This is done by
          // replacing the nested flow moduleConfig observable with the observable
          // used in the ojModule binding. The result is when the flow module config
          // mutates on enter, it will refresh ojModule.
          flow.pagesModuleConfig = moduleConfig;
        });
      }

      return moduleConfig;
    }


    /**
     * Invoke a before event, (either beforeEnter or beforeExit) and return
     * a promise the resolve to true or false depending on the action chain results.
     * @param  {String} eventName the type of event, either Constants.BEFORE_ENTER_EVENT
     * or Constants.BEFORE_EXIT_EVENT.
     * @return {Promise}  a promise that resolve to a boolean true if not cancelled
     */
    invokeBeforeEvent(eventName) {
      // Return the promise so that the outcome can be used to cancel navigation
      return this.invokeEvent(eventName).then((results) => {
        // Traverse the array of result from the execution of all the event
        // promises and look for cancelled result.
        // Check if the type is an array because sometime it returns Constants.NO_EVENT_LISTENER_RESPONSE
        if (Array.isArray(results)) {
          for (let i = 0; i < results.length; i += 1) {
            const { result } = results[i];
            if (result && result.cancelled === true) {
              this.log.info('Navigation to page', this.fullPath, 'was cancelled by', eventName);
              // Because on back/forward button, the browser changes the URL immediately, make sure
              // to restore the previous state when the navigation is cancelled.
              return History.restoreStateBeforeHistoryPop().then(() => false);
            }
          }
        }

        return true;
      });
    }

    /**
     * Traverse a set of definitions (variables or constants) and build new parameters
     * If the input parameter is already in the map, overwrite the existing definition
     * @param  {String} propertyName Either 'variables' or 'constants'
     * @param  {Object} allParameters the map of all the parameters
     */
    buildParameters(defName, allParameters) {
      const parameters = allParameters;
      const defs = this.definition[defName];
      Object.keys(defs).forEach((name) => {
        if (parameters[name]) {
          this.log.warn(`Input parameter ${name} is already defined. Using the definition in ${defName}.`);
        }
        const def = defs[name];
        const inputParameterValue = this.getInputParameterValue(name, def);
        parameters[name] = inputParameterValue;
        this.log.info(
          `Input parameter ${name}[input='${def.input}'] value:`, inputParameterValue);
      });
    }

    /**
     * Build a map of all possible input parameters
     * @return {Object} a map of parameters
     */
    buildAllParameters() {
      // Add variables input parameters
      const parameters = {};
      this.buildParameters('variables', parameters);
      // Add constants input parameters
      // If an input parameter is already defined as a variable, the constant is used.
      this.buildParameters('constants', parameters);
      return parameters;
    }

    /**
     * Build an error page from the list of loading error
     * @param {Error} error an object
     * @return {String} the markup for the error page
     */
    buildErrorPage(error) {
      // If it's a HTTP error return by requirejs, format the status and display it with failing page id
      // sanitize fullPath by not using innerHtml, instead use innerText or textContent. This is
      // the right way to re-mediate DOM based XSS vulnerabilities.
      const divDom = document.createElement('div');
      const headingDom = document.createElement('h1');
      headingDom.textContent = Utils.formatLoadError(error);
      divDom.appendChild(headingDom);
      const textDom = document.createElement('p');
      textDom.textContent = `while loading page "${this.fullPath}".`;
      divDom.appendChild(textDom);
      return divDom.outerHTML; // we have sanitized the content of <div> so ok to use outerHTML
      // return `<div><h1>${Utils.formatLoadError(error)}</h1><p>while loading page
      // "${this.fullPath}".</p></div>`;
    }

    /**
     * Load both the descriptor and the markup and deals with loading errors from both
     * resource.
     * When any of the descriptor of the markup fail loading, a dummy page showing the
     * reason of the failure is displayed.
     * @param  {NavigationContext} navContext the context of the current navigation chain
     * @return {Promise} a promise resolving with an array where the first element is the
     * markup and the second element is the page definition.
     */
    loadPage(navContext) {
      // Keep a reference of the loading promise so that multiple function can wait
      // on the same promise to be resolved.
      this.loadPagePromise = this.loadPagePromise || Promise.all([this.loadTemplate(), this.loadDescriptor()])
        .catch((error) => {
          // If the security provider handles the error, it will throw
          this.callSecurityProvider(error);

          // If the security provider doesn't handles the error, display the error
          // using an error page and descriptor.
          if (!this.application.started
            || (navContext && navContext.options && navContext.options.operation === 'oldNavigateToPage')) {
            // Initialize the context object, for expressions ($page OR $flow OR $chain, etc)
            this.expressionContext = new (this.constructor.ContextType)(this);

            this.initDefault(Constants.errorPageDescriptor);

            return [this.buildErrorPage(error), this.definition];
          }
          throw error;
        });

      return this.loadPagePromise;
    }

    /**
     * Return true if this page should be hidden from the Url
     * @return {boolean} true if it should be hidden from the Url
     */
    hideFromUrl() {
      return this.parent.staticPageId === this.id;
    }

    /**
     * Load this page
     * @param  {NavigationContext} navContext the context of the current navigation chain
     * @return {Promise} a promise that resolves in the loaded page metadata
     */
    load(navContext) {
      return this.loadPage(navContext)
        .then(() => {
          const pageDef = this.definition;

          // Only create the router when the parent defaultPage is a not flow since in that
          // case we need hide the page from the URL.
          if (!this.hideFromUrl()) {
            this.initRouter();
            this.router.defaultStateId = pageDef.routerFlow;

            // A child Router was just created in the flow so if the load was triggerred by the
            // router (which is the case navContext is not defined) then we need to call sync on
            // the JET router to synchronize the state of the routers with the URL.
            // This can happen in 2 cases:
            //   1) when the page is refreshed
            //   2) when going back or forward in the browser history.
            if (!navContext) {
              Router.sync();
            }
          }

          // create the facadeContext early, even before the facade; this requires getters
          this.getAvailableContexts();

          // Setup the component event listeners
          this.initializeEvents();

          // initialize action chains
          this.initializeActionChains();

          return pageDef;
        })
        // make sure that the functions are loaded so that they can be used in 'vbBeforeEnter' event
        .then((pageDef) => this.loadFunctionModule().then(() => pageDef));
    }

    /**
     * Load the page and start it by calling the beforeEnter event.
     * @param  {NavigationContext} navContext the context of the current navigation chain
     * @return {Promise} a promise that resolve to a page instance or undefined if the navigation was cancelled
     */
    loadAndStart(navContext) {
      // Prevent recursion when navigating to same page from inside the beforeEnterEvent
      if (this.inBeforeEvent === true) {
        this.log.warn('Recursive navigation to page', this.id, 'detected.');
        return Promise.resolve(this);
      }

      this.loadAndStartPromise = this.loadAndStartPromise || Promise.resolve().then(() => {
        // Start the page load timer
        const mo = new LoadMonitorOptions('pageLoad', `page load ${this.id}`, this);
        return this.log.monitor(mo, (pageLoadTimer) => this.load(navContext)
          .then(() => {
            if (navContext && navContext.isCancelled()) {
              return undefined;
            }

            this.inBeforeEvent = true;

            return this.invokeBeforeEvent(Constants.BEFORE_ENTER_EVENT)
              .then((result) => {
                let message = 'loaded.';
                let returnValue = this;

                // result is false when the beforeEnter event cancelled the navigation
                if (result === false || (navContext && navContext.isCancelled())) {
                  message = 'CANCELLED.';
                  returnValue = undefined;
                }

                this.log.greenInfo(this.getResourcePath(), message, pageLoadTimer());
                return returnValue;
              })
              .finally(() => {
                this.inBeforeEvent = false;
              });
          })
          .catch((error) => {
            pageLoadTimer(error);
            this.dispose();

            throw error;
          })
          .then((result) => {
            // Make sure to clean up the page and scope if navigation was cancelled or because of an error
            if (!result) {
              this.dispose();
            }
            return result;
          }));
      });

      return this.loadAndStartPromise;
    }

    /**
     * returns the PageContext constructor used to create the '$' expression context
     * @return {PageContext.constructor}
     * @override
     */
    static get ContextType() {
      return PageContext;
    }

    /**
     * Initializes the variables defined in the page model into the page scope, then sets up the context for the
     * page.
     *
     * @returns {Promise} A promise that resolves when complete
     */
    initializePageScopeAndContextVariables() {
      // Create the page variables using the page metadata
      return this.loadPage()
        .then(() => this.initAllVariableNamespace());
    }

    /**
     * The place to initialize builtins variables.
     */
    initializeBuiltins() {
      super.initializeBuiltins();

      // Create the built-in selectedFlow variable
      this.scope.createVariable(Constants.CURRENT_FLOW_VARIABLE, Constants.VariableNamespace.BUILTIN,
        'string', null, undefined, { writable: false });

      // Create a constant for the "info" builtins. For pages, the info object has 2 properties,
      // title and description.
      this.createConstant(Constants.INFO_CONTEXT, {
        type: 'object',
        defaultValue: {
          title: this.definition.title,
          description: this.definition.description,
        },
      },
      Constants.VariableNamespace.BUILTIN);
    }

    defineInfoBuiltinVariable() {
      return {
        title: this.definition.title,
        description: this.definition.description,
      };
    }

    /**
     * called once from getViewModel(), may be overridden by subclasses
     * utility for adding the necessary methods to the view model. This include createView and
     * the lifecycle callback for ojModule.
     * assumes that the availableContexts object has already been created for the page
     * @returns {Object} the viewModel
     */
    createViewModelFromContext() {
      const viewModel = {};
      const availableContexts = this.getAvailableContexts();
      // cannot use Object.assign, need to copy getter; some values might not have been created yet, like page.pageScope
      Object.getOwnPropertyNames(availableContexts).forEach((key) => {
        const descriptor = Object.getOwnPropertyDescriptor(availableContexts, key);
        Object.defineProperty(viewModel, key, descriptor);
      });

      // because we are also using this for the ViewModel as well,
      // and any (possible) lifecycle methods the mode may implement.
      const viewModelFncNames = ['connected', 'disconnected'];

      viewModelFncNames.forEach((name) => {
        if (this[name]) {
          viewModel[name] = this[name].bind(this);
        }
      });

      // Copy function to retrieve the moduleConfig for a flow on the viewModel.
      // This allows to do [[flowModuleConfig({ default: 'main' })]] in the html.
      viewModel[Constants.flowModuleConfigFunctionName] = this[Constants.flowModuleConfigFunctionName].bind(this);

      // Copy the moduleConfig to the viewModel.
      // This allows to do [[vbRouterFlow]] in the html.
      viewModel[Constants.routerModuleConfig] = this.moduleConfig;

      return viewModel;
    }

    getInitializePromise() {
      this.initializePromise = this.initializePromise || this.loadFunctionModule()
        // Need to be first to populate the context
        .then(() => this.initializePageScopeAndContextVariables());

      return this.initializePromise;
    }

    // Router state callback (see Container.js, getRouterConfigureCallBack)
    enter() {
      // As soon as we are done with canEnter, we mark the application started
      this.application.started = true;

      this.getInitializePromise();

      let newModuleConfig;

      if (this.parent.pagesModuleConfig().params !== this.fullPath) {
        newModuleConfig = this.createModuleConfig();
        // Mutate the observable for ojModule to display the new page
        this.parent.pagesModuleConfig(newModuleConfig);
      }

      if (this.parent.parent && this.parent.parent.moduleConfig().params !== this.fullPath) {
        newModuleConfig = newModuleConfig || this.createModuleConfig();
        this.parent.parent.moduleConfig(newModuleConfig);
      }

      this.lifecycleState = Constants.ContainerState.ENTERED;

      // return this.initializePromise;
    }

    /**
     * Invoke the beforeExit event on the page. This function is called by the router
     * and if it returns a promise that resolve to false, the navigation is cancelled.
     * @return {Promise} a promise that resolve to a boolean.
     */
    canExit() {
      return this.invokeBeforeEvent(Constants.BEFORE_EXIT_EVENT)
        // Only clear the busy state when the navigation is cancelled or in case of error.
        // When not cancelled the busy state will be cleared on the run() of the leaf page.
        .then((result) => {
          if (!result) {
            Router.clearBusyState();
          }
          return result;
        })
        .catch((error) => {
          Router.clearBusyState();
          throw error;
        });
    }

    // Router state callback (see Router.js)
    exit() {
      return this.invokeEvent(Constants.EXIT_EVENT).then(() => {
        this.lifecycleState = Constants.ContainerState.EXITED;

        // Update the previous page path value
        this.application.previousPagePath = this.getNavPath();
      });
    }

    // oj-module lifecycle callback
    connected() {
      // this.run();
    }

    // oj-module lifecycle callback
    disconnected() {
      // record a page deactivated state change
      StateMonitor.recordStateChange(StateMonitor.RuntimeState.PAGE_DEACTIVATED);
      StateMonitor.recordStateChange(StateMonitor.RuntimeState.CONTAINER_DEACTIVATED, this);
      this.deactivated = true;
      this.dispose();
    }

    /**
     * Run the page and return a promise that resolve when the page is done.
     * This consist of the following steps:
     *   initialize the scope and variables
     *   update the router state
     *   invoke the enter event
     * Depending on the resolvesAfterEnter argument, the promise returned
     * resolves when the enter event is done.
     *
     * @param  {boolean} resolvesAfterEnter if true, the promise returned does not
     * resolve until the ENTER event is done.
     * @return {Promise} a promise that resolve when the page is done running.
     */
    run(resolvesAfterEnter) {
      return this.getInitializePromise().then(() => {
        const owningRouter = this.parent.router;
        const skipUpdateWithRouter = owningRouter && owningRouter.historyUpdate === 'skip';
        // Now that the URL is the one for this page, save the "fromUrl" variables
        // on the URL to make the page bookmarkable and store the input parameters
        // on the browser history
        Router.updateState(this.fullPath, skipUpdateWithRouter);

        // delete the property so that it does not stick around for further navigation
        if (skipUpdateWithRouter) {
          delete owningRouter.historyUpdate;
        }
        // Assign the $flow.currentPage variable
        // Uses the variable setValueInternal because it's a readonly variable and the regular
        // assignment will fail.
        this.parent.updateCurrentPageVariable(this.id);

        let promise;

        // Only replace the history state once we are on the leaf page
        const isLeafPage = this.isLeafPage();
        if (isLeafPage) {
          promise = History.sync();
        } else {
          promise = Promise.resolve();
        }

        this.application.currentPageParams = Utils.cloneObject(History.getInputParameters());

        // If needed, update the browser state before executing vb_enter so that the URL is correct
        this.enterPromise = promise
          .then(() => this.invokeEvent(Constants.ENTER_EVENT))
          .then(() => this.invokePwaEvents())
          .then(() => {
            if (Utils.isMobile()) {
              this.invokePauseResumeEvent(this.application.pauseResumeEvent);
            }
          })
          .then(() => {
            // record a container activated state change
            StateMonitor.recordStateChange(StateMonitor.RuntimeState.CONTAINER_ACTIVATED, this);

            // // A page is active as soon as its enter and navigated event is done executing.
            // StateMonitor.recordStateChange(StateMonitor.RuntimeState.PAGE_ACTIVATED, this);
          })
          .then(() => {
            if (isLeafPage) {
              // clear any outstanding busy state on the router
              Router.clearBusyState();

              const perf = window.vb.perf; // eslint-disable-line prefer-destructuring
              if (perf) {
                // Log VB specific entries that have been added so far
                perf.logVB();
                // TODO: eventually, force analytics trace before marks are cleared
                Performance.clear();
              }
            }
          });
        return resolvesAfterEnter ? this.enterPromise : promise;
      });
    }


    /**
     * @returns {boolean} true, if this page is a leaf page (as opposed to shell page, for example)
     */
    isLeafPage() {
      const currentPage = Router.getCurrentPage();
      return (currentPage && currentPage.fullPath === this.fullPath);
    }

    /**
     * 'vbBeforeAppInstallPrompt' event is fired as a response to browser BeforeInstallPromptEvent event,
     * before a user is prompted to "install" a PWA application to a home screen.
     * As such, this event will only be fired when VB application has been configured to run as a PWA,
     * and it is running on a browser that supports BeforeInstallPromptEvent event.
     * For testing purposes, the event can be fired from Chrome Dev Tools.
     * Event payload contains one function, getInstallPromptEvent(), that returns
     * BeforeInstallPromptEvent object. To show the native "add to home screen" prompt,
     * BeforeInstallPromptEvent.prompt() must be called (once) as a response to user gesture.
     * Calling BeforeInstallPromptEvent.prompt() on the same event will result in a DOMException.
     *
     * @see {@link https://developers.google.com/web/fundamentals/app-install-banners/}
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent}
     * @param e
     * @returns {Promise<void>} a promise to fire vbInstallPrompt event, or an empty promise if BeforeInstallPromptEvent
     * was not fired for this application.
     */
    invokeAppInstallPromptEvent(e) {
      if (e) {
        return Promise.resolve()
          .then(() => {
            const payload = {};
            // Wrap native event object inside a function, so that it does not get cloned.
            // Executing BeforeInstallPromptEvent.prompt() on a proxy object causes TypeError: Illegal invocation
            payload.getInstallPromptEvent = () => e;
            return this.invokeEventWithBubbling(Constants.INSTALL_PROMPT_EVENT, payload);
          })
          .then(() => {
            // delete stored event
            delete this.application.beforeInstallPromptEvent;
            return null;
          });
      }
      return Promise.resolve();
    }

    invokeNewContentAvailable(e) {
      if (e) {
        // delete stored event
        delete this.application.newContentAvailableEvent;
        return this.invokeEventWithBubbling(Constants.NEW_CONTENT_AVAILABLE, e);
      }
      return Promise.resolve();
    }

    invokePwaEvents() {
      // TODO: which event should be delivered first? Should they even be delivered together?
      return this.invokeAppInstallPromptEvent(this.application.beforeInstallPromptEvent)
        .then(() => this.invokeNewContentAvailable(Utils.cloneObject(this.application.newContentAvailableEvent)));
    }

    /**
     * 'vbPause' or 'vbResume' events will be fired as a response to cordova's pause or resume events.
     * The pause event fires when the native platform puts the application into the background, typically
     * when the user switches to a different application.
     * The resume event fires when the native platform pulls the application out from the background.
     *
     * @see {@link https://cordova.apache.org/docs/en/latest/cordova/events/events.html#pause}
     * @see {@link https://cordova.apache.org/docs/en/latest/cordova/events/events.html#resume}
     * @param e
     * @returns {Promise<void>} a promise to fire vbPause or vbResume event, or an empty promise if neither
     * of these events were fired for this application
     */
    invokePauseResumeEvent(e) {
      if (e) {
        let eventName;
        switch (e.type) {
          case 'resume':
            eventName = Constants.RESUME_EVENT;
            break;
          case 'pause':
            eventName = Constants.PAUSE_EVENT;
            break;
          default:
            // currently only pause and resume events are supported
            return Promise.resolve();
        }

        return this.invokeEventWithBubbling(eventName, e)
          .then(() => {
            // delete the stored event
            delete this.application.pauseResumeEvent;
          });
      }

      return Promise.resolve();
    }

    /**
     * Build the title that will be used for this page.
     * Walk up the flow hierarchy
     *
     * @param {String} title the current title being constructed
     * @return {String} the title
     */
    buildTitle(title) {
      let newTitle = this.expressionContext[Constants.INFO_CONTEXT].title;

      if (newTitle) {
        if (title) {
          newTitle = `${title} - ${newTitle}`;
        }
      } else {
        newTitle = title;
      }

      return this.parent.buildTitle(newTitle);
    }

    /**
     * Returns a scope resolver map where keys are scope name ("page", "flow" or "application")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @private
     * @return {Object} an object which properties are scope
     */
    getScopeResolverMap() {
      return Object.assign({ [Constants.PAGE_PREFIX]: this }, this.parent.getScopeResolverMap());
    }

    createModuleConfig() {
      return {
        // initialize the variables before returning the viewModel
        viewModel: this.getViewModel(),
        // params is only used to know which page is represented
        params: this.fullPath,
        view: this.getView(),
      };
    }

    resetParentModuleConfig() {
      // This is to support refreshPage where dispose is called on an active page.
      // In case of navigation, dispose is called by ojModule after the navigation is
      // done, that's why we need to check if the moduleConfig is matching the fullPath
      if (this.parent.pagesModuleConfig().params === this.fullPath) {
        this.parent.pagesModuleConfig().params = null;
      }

      if (this.parent.parent && this.parent.parent.moduleConfig().params === this.fullPath) {
        this.parent.parent.moduleConfig().params = null;
      }
    }


    /**
     * creates the viewModel. (may be called externally for dynamic container).
     * only creates the model once (unless runtimeManager clears the promise).
     * @see Page.createModuleConfig
     * @see ConfigurableMetadataProviderHelper
     * @returns {Promise}
     */
    getViewModel() {
      if (!this.viewModelPromise) {
        // Initialize the variables before returning the viewModel
        this.viewModelPromise = this.run()
          .then(() => this.createViewModelFromContext());
      }
      return this.viewModelPromise;
    }

    /**
     * creates the view
     * @see Page.createModuleConfig
     * @returns {Promise<unknown>}
     */
    getView() {
      return this.loadPage().then((results) => results[0]);
    }


    dispose() {
      // do not dispose the page if it's being refreshed
      if (this.lifecycleState === Constants.ContainerState.REFRESHING) {
        this.lifecycleState = Constants.ContainerState.ENTERED;
        return;
      }

      // record a page deactivated state change
      // When the parent is being disposed, the child doesn't receive the deactivate,
      // only the dispose.
      if (!this.deactivated) {
        StateMonitor.recordStateChange(StateMonitor.RuntimeState.PAGE_DEACTIVATED);
      }

      // Mutates ojModule in order to release inner ko bindings
      this.moduleConfig(Constants.blankModuleConfig);

      // reset the parent module config
      this.resetParentModuleConfig();

      Object.keys(this.flows).forEach((flowId) => {
        const flow = this.flows[flowId];
        flow.dispose();
      });

      if (this.router) {
        this.router.dispose();
      }

      this.parent.deletePage(this.id);

      if (this.hideFromUrl()) {
        delete this.parent.staticPageId;
      }

      delete this.definition;

      this.initializePromise = null;

      this.enterPromise = null;
      this.viewModelPromise = null;

      super.dispose();
    }
  }

  return Page;
});

