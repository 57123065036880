/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/events/eventRegistry',[
  'vb/private/constants',
  'vb/private/log',
  'vb/private/utils',
  'vb/private/stateManagement/stateUtils',
  'vb/private/events/eventBehaviorFactory',
  'vb/private/events/baseEventModel',
  'vb/private/events/eventModel',
  'vb/private/events/undeclaredEventBehavior',
], (Constants, Log, Utils, StateUtils, EventBehaviorFactory, BaseEventModel, EventModel, UndeclaredEventBehavior) => {
  const logger = Log.getLogger('/vb/private/events/eventRegistry');

  /**
   * events are stored in different maps, based on behavior
   * @param behavior
   * @returns {string}
   */
  function getSection(behavior) {
    return (behavior === Constants.EventBehaviors.DYNAMIC_COMP) ? behavior : 'vb';
  }

  /**
   * return which class should we use for the model
   * @param behavior
   * @returns {*}
   */
  function getRegistrationClass(behavior) {
    return (behavior === Constants.EventBehaviors.DYNAMIC_COMP) ? BaseEventModel : EventModel;
  }

  /**
   * no default class when using the 'template' behavior; otherwise, default is the 'undeclared' model
   * @param behavior
   * @returns {function}
   */
  function getFallbackClass(behavior) {
    return (behavior === Constants.EventBehaviors.DYNAMIC_COMP) ? null : EventModel;
  }


  /**
   * All declared Events *must* be registered with this singleton, by the container in which the Event is declared.
   *
   * Components will use the registry to get an EventModel/EventBehavior for all events.
   * The EventRegistry always returns an EventModel for any fired event, whether one has been registered or not;
   * the model abstracts whether an event was declared, or not.
   *
   * the mapping is: {
   *   [container:fullPath + event simple name] : EventModel
   * }
   *
   */
  class EventRegistry {
    constructor() {
      // this will be a two-dimensional map of [section][name]
      this.registeredMap = {};
    }

    /**
     *
     * @param container {Container}
     * @param eventName {string} MUST be the unqualified name; the 'simple' name, with no namespace or prefix.
     * @param declaration {object} from JSON declaration
     * @param isInterface {boolean} should be true if this is defined in the "interface" section.
     * @returns {null|*}
     */
    register(container, eventName, declaration, isInterface = false) {
      if (eventName) {
        const decl = declaration || {};

        if (decl.payloadType) {
          if (Utils.isInstanceType(decl.payloadType)) {
            // don't allow 'vb/ServiceProvider, etc.
            logger
              .error(`Declared events do not allow builtin types, skipping: ${eventName}, type: ${decl.payloadType}`);
            return null;
          }
          try {
            // eventName in this call is not used, except in the Exception message
            StateUtils.getType(eventName, { type: decl.payloadType });
          } catch (e) {
            logger.error(`Declared events error, skipping: ${e}`);
            return null;
          }
        }
        // qualify the name with the (extensionId, if any, and) container path
        const extId = container.extensionId || ''; // @todo: does this need to be hierarchical for ext-of-ext?
        const key = EventRegistry.key(extId, container.fullPath, eventName);

        const section = getSection(declaration.behavior);
        this.registeredMap[section] = this.registeredMap[section] || {};

        const EventClazz = getRegistrationClass(declaration.behavior);

        this.registeredMap[section][key] = new EventClazz(eventName, this, decl, container, isInterface);
        return this.registeredMap[section][key];
      }

      return null;
    }

    /**
     * Called during eventpropagation, returns an EventModel for the requested event.
     * Either find a registered event model, or create an event model for an undeclared event.
     *
     * if the 'name' is passed unqualified, qualify it using the firing container's namespace.
     *
     * if there is a declared event with the qualified name
     *  - returns the model if it is in requesting container's scope (flow cannot access page events, for example)
     *  - returns null otherwise
     *
     * otherwise, when there is no declaration found:
     * - if the name is unqualified, return a model for an 'undeclared' event
     * - return null if the name is qualified
     *
     * The (qualified) event name tells us with container 'type' must have registered the event.
     * If the registration is found, the registrar container's scopeResolver tells us which container have access.
     *
     * note that a return of null alone is not enough to determine if an event can be acted on by a container;
     * the event processing must also call listenableBy().
     *
     * examples:
     *  Flow declares "foo", Page fires "foo:foo", Flow calls get for the event:
     *    EventRegistry.get returns the registered EventModel
     *    Event is propagated from leaf page.
     *
     *  Page declares "foo", Flow fires "page:foo"
     *    EventRegistry.get returns null, because event though it is declared, it is inaccessible to Flow.
     *    No event propagated.
     *
     * Page declares "foo", Flow fires "foo"
     *    EventRegistry.get returns EventModel with an UndeclaredEventBehavior, because FLow has not used a qualified
     *    name, so we must assume its an 'undeclared' event.
     *    Event is propagated from firing Flow (undeclared behavior) - only listeners for 'foo' are called.
     *
     * @todo: more work could be done to simplify differences between inaccessible declared and undeclared events.
     *
     * @param container the container asking for the Event definition, for handling
     * @param containerThatFired
     * @param name optionally namespaced, according to Event namespacing rules (ex. "page:foo", "base/flow:bar").
     * @param behavior
     * @returns {null|EventModel} null means the event is not accessible by the requesting container.
     */
    get(container, containerThatFired, name, behavior) {
      // split the name in to <ext|'base'>/<container>:<name>, providing defaults where needed
      const nameParts = EventModel.parseName(containerThatFired, name);

      // @todo: this may need work when we support extensions-of-extensions
      const containerForPrefix = (nameParts.ext === Constants.ExtensionNamespaces.BASE)
        ? EventModel.baseContainer(containerThatFired) : containerThatFired;

      const scopeResolver = containerForPrefix.scopeResolver || {}; // shouldn't need the fallback

      if (scopeResolver && scopeResolver[nameParts.prefix]) {
        const key = EventRegistry.key(nameParts.ext, scopeResolver[nameParts.prefix].fullPath, nameParts.name);

        const section = getSection(behavior);

        const decl = this.registeredMap[section] && this.registeredMap[section][key];
        // accessibleBy checks if the Page or Flow that is asking for it is the Page or Flow that
        // is in the scope of the container that declared the event
        if (decl) {
          return decl.accessibleBy(container, name) ? decl : null;
        }

        // we didn't find a declared event, so return an 'undeclared' if its a plain name (no prefix, etc),
        // or null if its qualified, as if referencing a declared event ('page: somefoo')
        // return (UndeclaredEventBehavior.isNamespaced(name)) ? null : new EventModel(name, this);
        if (UndeclaredEventBehavior.isNamespaced(name)) {
          return null;
        }
        const FallbackClazz = getFallbackClass(behavior);
        return FallbackClazz ? new FallbackClazz(name, this) : null;
      }

      return null;
    }


    /**
     * Convenience method, to match the name with the listener declaration in the container.
     *
     * @param container
     * @param eventName
     * @returns {*}
     */
    // eslint-disable-next-line class-methods-use-this
    findListener(container, eventName) {
      const listenerDefs = (container.definition && container.definition.eventListeners) || {};
      if (!listenerDefs) {
        return null;
      }

      // if the whole name is there, return it
      if (listenerDefs[eventName]) {
        return listenerDefs[eventName];
      }

      // split the name; if the prefix matches our container class, look for the 'simple' name, without the prefix
      const parts = EventModel.parseName(container, eventName);
      if (parts.ext === Constants.ExtensionNamespaces.BASE) {
        // check for abbreviated name, without the 'base' namespace (ex: /flow:foo)
        // only add the leading slash if we are an extension container
        const shortName = `${container.isExtension() ? '/' : ''}${parts.prefix}:${parts.name}`;
        if (listenerDefs[shortName]) {
          return listenerDefs[shortName];
        }
      }

      // if we are in the same container scope, match the simple name
      if (parts.prefix === EventModel.basePrefix(container) && listenerDefs[parts.name]) {
        return listenerDefs[parts.name];
      }

      // just return the raw name, if it exists
      return listenerDefs[eventName];
    }

    /**
     * note: different than the event qualified name;
     * uses the full path of the container, instead of the container prefix ('application:', 'flow:', etc.)
     * @param extId
     * @param containerPath
     * @param name
     * @returns {string}
    */
    static key(extId, containerPath, name) {
      const path = containerPath || ''; // only used for generating a unique key
      return `${extId || Constants.ExtensionNamespaces.BASE}/${path.toLowerCase()}:${name}`;
    }
  }


  return new EventRegistry(); // singleton
});

