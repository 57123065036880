// eslint-disable-next-line max-classes-per-file
/* eslint max-classes-per-file: ["error", 2] */

'use strict';

/* eslint function-paren-newline: ["error", "never"] */
define('vb/private/types/dataProviders/serviceDataProvider2',[
  'knockout',
  'vb/private/constants',
  'vb/private/log',
  'vbc/private/logConfig',
  'vb/private/utils',
  'vb/types/typeUtils',
  'vb/helpers/mixin',
  'vb/types/eventTargetMixin',
  'vb/private/types/dataProviderConstants',
  'vb/private/types/dataProviders/serviceDataProviderUtils',
  'vb/private/types/capabilities/noOpFetchFirst',
  'vb/private/types/capabilities/fetchContext',
  'vb/private/types/capabilities/fetchByKeys',
  'vb/private/types/capabilities/fetchByKeysIteration',
  'vb/private/types/capabilities/fetchBySingleKey',
  'vb/private/types/capabilities/fetchFirst',
  'vb/private/types/utils/serviceDataProviderRestHelperFactory',
], (ko, Constants, Log, LogConfig, Utils, TypeUtils, Mixin, EventTargetMixin, DPConstants, SDPUtils,
  NoOpFetchFirst, FetchContext, FetchByKeys, FetchByKeysIteration, FetchBySingleKey, FetchFirst,
  SDPRestHelperFactory) => {
  const SDP_PREFIX = 'sdp-';
  const NOOP_FUNC = () => {};
  const NOOP_SIGNAL = {
    add: NOOP_FUNC,
    addOnce: NOOP_FUNC,
    dispatch: NOOP_FUNC,
    dispose: NOOP_FUNC,
    forget: NOOP_FUNC,
    getNumListeners: NOOP_FUNC,
    halt: NOOP_FUNC,
    has: NOOP_FUNC,
    remove: NOOP_FUNC,
    removeAll: NOOP_FUNC,
    toString: NOOP_FUNC,
  };
  const CRITERION_TYPE = {
    op: 'string',
    attribute: 'string',
    value: 'any',
  };
  CRITERION_TYPE.criteria = [CRITERION_TYPE];
  /**
   * default type definition for ServiceDataProvider
   * @type {{type: {headers: string, uriParameters: string, capabilities: {filter: {operators: string},
   * fetchByKeys: {implementation: string, multiKeyLookup: string}, sort: {attributes: string},
   * fetchFirst: {implementation: string}, fetchByOffset: {implementation: string}},
   * filterCriterion: {op: string, attribute: string, value: string}, keyAttributes: string,
   * transforms: {request: {filter: string, select: string, query: string, paginate: string, sort: string, body:
   *   string}, response: {paginate: string, body: string}}, pagingCriteria: {iterationLimit: string, offset: string,
   *   size: string, maxSize: string}, body: string, itemsPath: string, transformsContext: string, endpoint: string,
   *   responseType: string, totalSize: string, sortCriteria: [{attribute: string, direction: string}],
   *   mergeTransformOptions: string}}}
   */
  const TYPEDEF = {
    body: 'any',
    capabilities: {
      sort: {
        attributes: 'string',
      },
      filter: {
        operators: 'string[]',
      },
      fetchByKeys: {
        implementation: 'string',
        multiKeyLookup: 'string',
      },
      fetchByOffset: {
        implementation: 'string',
      },
      fetchFirst: {
        implementation: 'string',
      },
    },
    endpoint: 'string',
    fetchChainId: 'string',
    headers: 'object',
    itemsPath: 'string',
    keyAttributes: 'any',
    mergeTransformOptions: 'string',
    pagingCriteria: {
      offset: 'number',
      size: 'number',
      maxSize: 'number',
      iterationLimit: 'number',
    },
    responseType: 'any',
    totalSize: 'number',
    transforms: {
      request: {
        paginate: 'string',
        query: 'string',
        filter: 'string',
        sort: 'string',
        select: 'string',
        body: 'string',
      },
      response: {
        paginate: 'string',
        body: 'string',
      },
    },
    transformsContext: 'object',
    uriParameters: 'object',
  };

  const LOGGER = Log.getLogger('/vb/dataProviders/ServiceDataProvider2', [
    // Register custom loggers
    {
      name: 'startFetch',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.serviceDataProviderStart,
    },
    {
      name: 'endFetch',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.serviceDataProviderEnd,
    },
  ]);

  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["getDefinitionValue","getIdAttributeProperty",
  "getLifecycleStageChangedSignal","callActionChain","isDisconnected","getTotalSize"]}] */
  class ServiceDataProvider2 extends Mixin().with(EventTargetMixin) {
    /**
     * @constructor
     * creates a standalone ServiceDataProvider instance that is initialized with the state that the caller provides
     * and returns the instance. The instance is a standalone and is not backed by a variable that manages its
     * state. The instance merely fetches the data from Rest and returns the result.
     *
     * @param dataProviderOptions options used to instantiate the data provider instance
     * @param serviceOptions service options used to initialize RestHelper with
     */
    constructor(dataProviderOptions, serviceOptions) {
      super();
      this.id = SDP_PREFIX + Utils.generateUniqueId();
      this.log = LOGGER;
      // whitelist options passed in
      const dpOptions = dataProviderOptions || {};
      /**
       * represents the state of the DySDP when it was created
       * @type {{}}
       * @private
       */
      this.dataProviderOptions = Object.keys(TYPEDEF).reduce((obj, key) => {
        const o = obj;
        const value = dpOptions[key];
        if (value) {
          o[key] = value;
        }
        return o;
      }, {});
      this.state = this.dataProviderOptions;

      // totalSize is not writable, but it reads its value from underlying endpoint cache
      Object.defineProperty(this, 'totalSize', {
        get: () => ko.pureComputed({
          read: () => {
            // establish a dependency on the endpoint totalSize observable
            const restHelper = this.createRestHelper();
            const name = `${this.variableBridge.scope.name}:${this.dataProviderOptions.endpoint}`;
            const totalSize = restHelper.retrieveState(name, DPConstants.TOTAL_SIZE);
            return totalSize();
          },
        }),
        set: (ts) => {
          const restHelper = this.createRestHelper();
          const name = `${this.variableBridge.scope.name}:${this.dataProviderOptions.endpoint}`;
          restHelper.storeState(name, DPConstants.TOTAL_SIZE, ts);
        },
      });

      this.serviceOptions = serviceOptions;
      this.createRestHelper = () => SDPRestHelperFactory
        .get(serviceOptions || dpOptions.endpoint || '', this.variableBridge.scope.container);
      /**
       * @private
       * @type {undefined}
       */
      this.resolvedResponseType = undefined;
      /**
       * @private
       * @type {undefined}
       */
      this.mergedCaps = undefined;
      /**
       * @private
       * @type {undefined}
       */
      this.fetchMethodsSetup = undefined;
    }

    setVariableBridge(variableBridge) {
      this.variableBridge = variableBridge;
    }

    /**
     * Called by the VariableBridge when a variable of this type is activated for the current scope. This method is
     * used to perform any async tasks such as loading capabilities.
     * @return {Promise<unknown>}
     */
    activateAsync() {
      // TODO: test for entire default value being a constant
      const sdpValue = this.dataProviderOptions;
      const uriParameters = Utils.cloneObject(sdpValue.uriParameters);
      const initConf = {};

      // if the SDP has a createRestHelper() function, use it to create a fresh one, using the same endpoint.
      // (we need a new one when fetching multiple keys, each one needs to be unique)
      // TODO 1: for externalized fetches unless we have a way to fetch endpoint from RestAction figuring out the
      //  capabilities is a problem. Maybe for SDP2 we can always have DT specify the endpoint property, even if it
      //  uses an externalized fetch.
      // TODO 2: when user changes the endpoint later (say via an action), activateAsync needs to be called (when
      //  the new instance is created) in case the service is not already loaded. This is so we can determine the
      //  capabilities! But async updates to variable value is not something the framework is setup to do.
      const restHelper = this.createRestHelper();
      const name = `${this.variableBridge.scope.name}:${sdpValue.endpoint}`;
      const endpointCaps = restHelper.retrieveState(name, DPConstants.CAPABILITIES_KEY);
      if (!endpointCaps) {
        restHelper
          .parameters(uriParameters)
          .initConfiguration(initConf);

        return restHelper.getAndStoreCapabilities(name);
      }
      return Promise.resolve(endpointCaps);


      // .then((epCaps) => {
      //   const capabilitiesDef = (this.dataProviderOptions
      //     && this.dataProviderOptions[DPConstants.CAPABILITIES_KEY]) || {};
      //   this.mergedCaps = Object.assign({}, epCaps, capabilitiesDef);
      //
      //   // a simple merge is good. We expect transforms authors to provide no capabilities or full values per feature
      //   const fetchCapsDef = SDPUtils.getConfiguredFetchCapabilities(this.mergedCaps) || {};
      //
      //   // define fetchByKeys and fetchByOffset implementations on SDP based on configured capabilities.
      //   const fetchCaps = SDPUtils.getResolvedFetchCapabilities(fetchCapsDef);
      //   SDPUtils.initFetchByKeysMethods(this, fetchCaps);
      //   SDPUtils.initFetchByOffsetMethods(this, fetchCaps);
      // });
    }

    callActionChain(chainId, chainParams) {
      return this.variableBridge.callActionChain(chainId, chainParams);
    }

    /**
     * Fetches data by iteration, always starting from the first block of data. If a valid endpoint was not provided
     * at instance creation time this method returns a noop fetchFirst implementation.
     *
     * @param {oj.FetchListParameters|*} params fetch parameters
     * @return {FetchListAsyncIterable} an AsyncIterable
     * @method
     * @name fetchFirst
     */
    fetchFirst(params) {
      if (this.dataProviderOptions.endpoint) {
        const fetchFirst = new FetchFirst(this, params);
        this.log.finer('iterator', fetchFirst.id, 'created for fetchFirst() on SDP:', this.getId());
        return fetchFirst.fetch();
      }
      this.log.info('a valid endpoint was not provided for the ServiceDataProvider', this.id, '. Using a noop'
        + ' implementation');
      return (new NoOpFetchFirst(params)).fetchFirst();
    }

    /**
     * For a feature this method returns the capability supported. This is called by components
     * and consumers of DP implementation.
     *
     * @param {String} feature the capability name - includes 'sort', 'filter', 'fetchByKeys',
     * 'fetchByOffset' and 'fetchFirst'. fetchFirst is defined by VB and by default returns
     * { implementation: 'iteration'} if not set. This must be set if SDP supports other fetch
     * capabilities on the same endpoint as the fetchFirst.
     * @see oj.DataProvider
     * @return {Object} or null if feature is not recognized
     */
    getCapability(feature) {
      if (!this.fetchMethodsSetup) {
        // setup fetch methods if not setup already
        // retrieve fetch capabilities provided by the endpoint and merge with those on the SDP.
        const restHelper = this.createRestHelper();
        const name = `${this.variableBridge.scope.name}:${this.dataProviderOptions.endpoint}`;
        // by the time getCapability is called the endpoint should have loaded and cached its capabilities
        const endpointCaps = restHelper.retrieveState(name, DPConstants.CAPABILITIES_KEY);
        const sdpCapsDef = (this.dataProviderOptions && this.dataProviderOptions[DPConstants.CAPABILITIES_KEY]) || {};
        const mergedCaps = Object.assign({}, endpointCaps, sdpCapsDef);

        const fetchCapsDef = SDPUtils.getConfiguredFetchCapabilities(mergedCaps) || {};
        const finalFetchCaps = SDPUtils.getResolvedFetchCapabilities(fetchCapsDef);
        this.mergedCaps = Object.assign(mergedCaps, finalFetchCaps);

        SDPUtils.initFetchByKeysMethods(this, finalFetchCaps);
        SDPUtils.initFetchByOffsetMethods(this, finalFetchCaps);
        this.fetchMethodsSetup = true;
      }

      const featureCapability = this.mergedCaps[feature];
      return Utils.cloneObject(SDPUtils.getCapabilityByFeature(feature, featureCapability));
    }

    /**
     * Get the variable id as defined in the page model.
     *
     * @final
     * @@return {string} The id for this variable
     */
    // eslint-disable-next-line no-unused-vars
    getId() {
      return this.id;
    }

    /**
     * keyAttributes is the only supported idAttribute property.
     * @return {string}
     */
    getIdAttributeProperty() {
      return DPConstants.DataProviderIdAttributeProperty.KEY_ATTRIBUTES;
    }

    /**
     * Returns the state as its value.
     *
     * @final
     * @returns {*}
     */
    getValue() {
      return this.dataProviderOptions;
    }

    /**
     * Returns the state as the value in the definition.
     * Note: When dealing with SDP variables the defaultValue is its configured value, different from its value when
     * fully evaluated.
     */
    getDefinitionValue() {
      return this.dataProviderOptions;
    }

    /**
     * returns the response type of the instance
     * @returns {*}
     */
    getType(type, description) {
      return this.variableBridge.getType(description, type);
    }

    /**
     * Returns a noop signal.
     * Note: Callers usually register their listeners to be notified of variable lifecycle stage changes, but since
     * this is a standalone object this is a no op.
     * @returns {Object} dummy Signal
     */
    getLifecycleStageChangedSignal() {
      return NOOP_SIGNAL;
    }

    /**
     * Return the total size of data available, including server side if not local. If a positive number is not set by
     * the fetch call -1 is returned.
     * Note: this is part of the DataProvider API
     *
     * @returns {Promise.<number>} total size of data
     */
    getTotalSize() {
      return Promise.resolve(SDPUtils.getTotalSize(this.totalSize()));
    }

    /**
     * False always because an SDP object unlike a SDP variable has no variable lifecycle.
     * @return {boolean}
     */
    isDisconnected() {
      return false;
    }

    /**
     * Invoke an event on the container that this instance variable belongs to. A standalone object is not part of
     * any VB container and so this method is a noop
     * @param name of the event
     * @param payload payload for the event
     * @param withBubbling whether event needs to bubble
     * @returns {*|Promise}
     */
    // eslint-disable-next-line no-unused-vars
    invokeEvent(name, payload, withBubbling = true) {
      return this.variableBridge.invokeEvent(name, payload, withBubbling);
    }

    /**
     * Sets the total size on the SDP variable instance. This mutates the actual variable
     * value, which is fine, because there can be only one canonical totalSize per SDP instance.
     *
     * @param {number} ts total size of data
     * @instance
     * @private
     */
    setTotalSize(ts) {
      this.getValue().totalSize = ts;
    }
  }

  return ServiceDataProvider2;
});

