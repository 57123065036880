'use strict';

define('vb/private/vx/v2/extension',[
  'vb/private/vx/baseExtension',
  'vb/private/constants',
], (BaseExtension, Constants) => {
  // a regex to find string starting with ui/base or dynamicLayouts/base or translations/base
  const baseRegex = new RegExp(`^${Constants.DefaultPaths.UI}base/|^${Constants.DefaultPaths.LAYOUTS}base/|^${Constants.DefaultPaths.TRANSLATIONS}base/`);

  class Extension extends BaseExtension {
    constructor(def, registry) {
      super(def, def.baseUrl, registry);

      // When the bundle is declared in the digest, we only need to register
      // the requirejs mapping for it
      const bundleIds = def.bundleIds;
      if (Array.isArray(bundleIds) && bundleIds.length > 0) {
        const mapping = {};
        this.log.info('Extension', this.id, 'version:', this.version, 'is using', bundleIds.length, 'bundle');
        bundleIds.forEach((bundleId) => {
          let baseUrl = this.baseUrl;
          if (baseUrl.slice(-1) === '/') {
            baseUrl = baseUrl.substring(0, baseUrl.length - 1);
          }
          const url = bundleId.replace(baseUrl, this.baseUrlDef);
          mapping[bundleId] = url;
        });
        this.registry.addRequireMapping(mapping);
        // Remember that this extension is using requirejs bundles so that forcing
        // loading the bundle during init() is not necessary
        this.useBundles = true;
      } else {
        this.useBundles = false;
      }

      this.dependencies = def.dependencies || {};
    }

    getAbsoluteUrl() {
      // in v2, 'ui/' is in the basePath
      // <baseUrl>/ui/self
      return `${this.baseUrlDef}/${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}/`;
    }


    /**
     * Initialize the extension bundle given the bundle information in the manifest coming from
     * the requirejs-info digest and recurse in dependent extensions.
     * If no bundle information is given, call super to use the v1 method of forcing the bundle loading.
     *
     * @return {Promise} a promise that resolve when the bundle and its dependent is initalized
     */
    initBundles() {
      return Promise.resolve()
        .then(() => {
          // Recurse initializing extensions that extends this extension
          const promises = this.getDependentExtensions().map((ext) => ext.init());

          // If no bundle info is given, force the loading using the v1 mechanism defined in super
          if (!this.useBundles) {
            promises.push(super.initBundles());
          }

          return Promise.all(promises);
        });
    }

    /**
     * Retrieve all the extensions that this extension depends on.
     * @return {Array<Extension>}
     */
    getRequiredExtensions() {
      return Object.keys(this.dependencies).map((extId) => this.registry.extensions[extId]);
    }

    /**
     * Return all the extensions extending this extension
     * @return {Array<Extension>}
     * @private
     */
    getDependentExtensions() {
      const result = [];
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.registry.extensions).forEach(([extId, ext]) => {
        const version = ext.dependencies[this.id];
        // Also need to check version
        if (version) {
          result.push(ext);
        }
      });

      return result;
    }

    /**
     * return true when at least one of the file extends a base object
     * @return {Boolean}
     */
    extendsBaseArtifact() {
      return this.files.some((file) => baseRegex.test(file));
    }
  }

  return Extension;
});

