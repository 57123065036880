/* eslint-disable class-methods-use-this */

'use strict';

define('vb/private/pwa/pwaServiceWorkerManagerClass',[
  'vb/private/utils',
  'vb/private/log',
  'vb/versions',
  'vbsw/private/serviceWorkerManagerClass',
], (Utils, Log, Versions, ServiceWorkerManagerClass) => {
  const logger = Log.getLogger('/vb/private/pwa/serviceLWorkerManager');
  // eslint-disable-next-line no-confusing-arrow
  const log = () => window.vbInitConfig.DEBUG ? logger.warn : logger.info;

  /**
   * An implementation of ServiceWorkerManagerClass to support web app PWA's. This type of manager should only be
   * created when window.vbInitConfig.PWA_CONFIG is specified
   *
   * @see PwaUtils.isWebPwaConfig
   */
  class PwaServiceWorkerManagerClass extends ServiceWorkerManagerClass {
    constructor() {
      super();
      this.config = {};

      if (!('serviceWorker' in navigator)) {
        // This could also happen when in private browsing mode on FF,
        // or, when 'Delete cookies when and site data when Firefox is closed' is set
        throw new Error(`Service worker is not supported/enabled in the browser: ${navigator.userAgent}`);
      }

      // disable workbox logging unless we are in debug mode:
      // https://developers.google.com/web/tools/workbox/guides/configure-workbox#disable_logging
      // eslint-disable-next-line no-underscore-dangle
      window.__WB_DISABLE_DEV_LOGS = true;
    }

    getServiceWorkerVersion() {
      return this.wb.messageSW({ type: 'GET_VERSION' })
        .then((version) => {
          logger.info(`Version from the service worker: ${JSON.stringify(version, null, 2)}`);
          return version;
        })
        .catch((error) => {
          logger.warn('Failed to detect service worker version:', error);
          return undefined;
        });
    }

    /**
     * See https://github.com/w3c/ServiceWorker/issues/799, specifically Jake's example:
     * https://github.com/w3c/ServiceWorker/issues/799#issuecomment-165499718
     * to explain why we can't use navigator.serviceWorker.ready
     * The ready read-only property of the ServiceWorkerContainer interface provides a way of delaying code
     * execution until a service worker is active. It returns a Promise that will never reject, and which waits
     * indefinitely until the ServiceWorkerRegistration associated with the current page has an active worker.
     * Once that condition is met, it resolves with the ServiceWorkerRegistration.
     * Active service worker is not the same thing as the service worker controlling the page
     *
     * @param scriptUrl context root relative service worker script url, for example: sw.js
     * @returns {Promise} a promise that resolves once a service worker with a given scriptUrl is controlling the page
     */
    serviceWorkerReady(scriptUrl) {
      return new Promise((resolve) => {
        const sw = navigator.serviceWorker.controller;
        if (sw && sw.scriptURL.endsWith(scriptUrl)) {
          log()(`Activated service worker found: ${sw.scriptURL}`);
          resolve();
        } else {
          this.controllerChangeHandler = (e) => {
            log()(`navigator.serviceWorker.controller change for ${e.target.controller.scriptURL}`);
            resolve();
          };
          navigator.serviceWorker.addEventListener('controllerchange', this.controllerChangeHandler);
        }
      });
    }

    installServiceWorkers(modulePath, externalConfig, Configuration) {
      const serviceWorkerWrappers = [];
      return this.getServiceWorkerConfig(modulePath, externalConfig, Configuration)
        .then((config) => this.installServiceWorker(config))
        .then((serviceWorkerWrapper) => {
          this.configureLoggingLevel(this.config.logConfig);
          serviceWorkerWrappers.push(serviceWorkerWrapper);
          return serviceWorkerWrappers;
        })
        .catch((error) => {
          logger.warn('Failed to install service worker:', error);
          return undefined;
        });
    }

    /**
     * @param {*} vbInitConfig config
     * @returns a path to a specific version of workbox on CDN. For applications with a default configuration,
     * this will be an internal CDN, for example:
     * https://static.oracle.com/cdn/vb/workbox/releases/5.1.4/
     * But if vbInitConfig.WORKBOX_CDN_PATH is specified, it will be used instead (workbox version remains dictated
     * by VB), for example:
     * https://storage.googleapis.com/workbox-cdn/releases/6.0.0-alpha.3/
     * This is useful on Android, workbox needs to be installed from an internal CDN.
     */
    getWorkboxCdnPath(vbInitConfig = window.vbInitConfig) {
      return `${vbInitConfig.WORKBOX_CDN_PATH || Versions.workbox.cdnPath}${Versions.workbox.version}/`;
    }

    /**
    * Caching is enabled unless vbInitConfig.PWA_CONFIG.disableCaching is set to true. In all other cases, it is enabled
    * @param {*} vbInitConfig vbInitConfig
    */
    isCachingEnabled(vbInitConfig = window.vbInitConfig) {
      if (vbInitConfig.PWA_CONFIG.disableCaching === true) {
        return false;
      }
      return true;
    }

    /**
    * Register service worker for PWA's. Despite the name, Service Worker install event won't necessarily happen,
    * because Service Worker might be registered already
    * @param {*} config SERVICE_WORKER_CONFIG section of vbInitConfig
    */
    installServiceWorker(config) {
      this.config = Object.assign(this.config, config);
      const scriptUrlFromConfig = window.vbInitConfig.PWA_CONFIG.scriptUrl;
      this.scriptUrl = scriptUrlFromConfig || `${config.applicationUrl}sw.js`;
      logger.info(`Service worker url: ${this.scriptUrl}`);

      // applicationUrl always ends with a trailing slash. This can mean, in some cases, that the service worker
      // is registered in the scope that does not control the initial request. For example, if initial request is:
      // https://fuscdrmsmc280-fa-ext.us.oracle.com/fscmUI/starterapp, applicationUrl will be:
      // https://fuscdrmsmc280-fa-ext.us.oracle.com/fscmUI/starterapp/ and the service worker will be registered in the
      // scope that does not control the initial request. This means that navigator.serviceWorker.controller will
      // be null, and serviceWorkerReady promise will never resolve, resulting in a stuck application.
      // Service worker is not supposed to have a scope broader than its own location
      // (see https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register)
      // and exceptions require special 'Service-Worker-Allowed' response headers on the service worker script:
      // (see https://developers.google.com/web/ilt/pwa/introduction-to-service-worker#registration_and_scope)
      // Currently, VB does not support this special case and having a mismatch between server configuration and
      // expected service worker scope will result in an error
      // See slack discussion: https://corparch-core-srv.slack.com/archives/G01B7V95FU0/p1603378926081800
      if (!window.location.href.startsWith(config.applicationUrl)) {
        // eslint-disable-next-line max-len
        const scopeError = new Error(`Service worker scope: ${config.applicationUrl} does not match initial request: ${window.location.href}`);
        throw scopeError;
      }
      let sw;

      // When updateViaCache is set to 'imports', sw won't be updated when an imported script changes (on Chrome)
      // This is not necessary, since when imported scripts change (jet, vb, workbox), vbInitConfig injected to sw.js
      // also changes
      // See: https://developers.google.com/web/updates/2019/09/fresher-sw
      const registrationOptions = { updateViaCache: 'imports' };
      const workboxCdn = this.getWorkboxCdnPath(window.vbInitConfig);
      const wbPath = `${workboxCdn}workbox-window.${window.vbInitConfig.DEBUG ? 'dev' : 'prod'}.umd.js`;
      return Utils.getResource(wbPath)
        .then((workbox) => {
          // See https://developers.google.com/web/tools/workbox/modules/workbox-window
          this.wb = new workbox.Workbox(this.scriptUrl, registrationOptions);
          // keep a reference to event handler so it can be removed
          this.workboxEventHandler = this.handleEvent.bind(this);
          this.wb.addEventListener('installed', this.workboxEventHandler);
          this.wb.addEventListener('redundant', this.workboxEventHandler);
          this.wb.addEventListener('activated', this.workboxEventHandler);
          this.wb.addEventListener('waiting', this.workboxEventHandler);
          return this.wb.register({ immediate: true });
        })
        .then((r) => {
          if (r) sw = r.installing || r.waiting || r.active;
          if (sw) logger.info(`workbox register returned ${sw.state} service worker`);
          logger.info(`emulating service worker functionality with scope: ${config.scopes[0]}`);
          // Emulated service worker is registered with the scope of what it would be for the old service worker,
          // vbServiceWorker.js, not sw.js. So we can't use config.applicationUrl here, as this will break
          // csrfTokenHandlerPlugin, for one. See https://jira.oraclecorp.com/jira/browse/VBS-11509
          return this.emulateServiceWorker(config.scopes[0]);
        })
        .catch((error) => {
          logger.error('Service worker registration failed with ', error);
          throw error;
        });
    }

    /**
     * Remove all event listeners added to handle this service worker events
     */
    removeEventListeners() {
      if (this.wb && this.workboxEventHandler) {
        this.wb.removeEventListener('installed', this.workboxEventHandler);
        this.wb.removeEventListener('redundant', this.workboxEventHandler);
        this.wb.removeEventListener('activated', this.workboxEventHandler);
        this.wb.removeEventListener('waiting', this.workboxEventHandler);
      }
      if (this.controllerChangeHandler) {
        navigator.serviceWorker.removeEventListener('controllerchange', this.controllerChangeHandler);
      }
    }

    configureLoggingLevel(logConfig) {
      if (logConfig && logConfig.level) {
        return this.wb.messageSW({
          type: 'SET_LOGGING_LEVEL',
          payload: { level: logConfig.level },
        });
      }
      return Promise.resolve;
    }

    getApplication() {
      this.applicationPromise = this.applicationPromise || Utils.getResource('vb/private/stateManagement/application');
      return this.applicationPromise;
    }

    informUser(message) {
      log()(`Application needs to be reloaded. ${message}`);
      return this.getApplication()
        .then((application) => application.onNewContentAvailable({ message }))
        .catch((error) => {
          logger.error(error);
        });
    }

    handleEvent(event) {
      const state = event.type;
      log()(`Event handler invoked for service worker '${state}' event`);
      switch (state) {
        case 'installed':
          logger.info(`Service worker installed ${event.isUpdate ? 'after update' : 'for the first time'}`);
          break;
        case 'activated':
        {
          if (event.isUpdate) {
            logger.info('Service worker activated after update');
          }
          if (event.isExternal) {
            this.informUser('New version of the service worker has been activated');
          }
          break;
        }
        case 'redundant':
        {
          logger.info('Service worker is now redundant');
          break;
        }
        case 'waiting':
        {
          const msg = `A new service worker, ${event.sw.scriptURL} has installed, but it can't activate until all tabs
    running current version have fully unloaded.`;
          logger.info(msg);
          // debugger;
          logger.warn(event.sw);
          this.wb.messageSkipWaiting();
          break;
        }
        default:
          logger.warn(`Event: ${state} was not handled`);
      }
    }
  }

  return PwaServiceWorkerManagerClass;
});

