'use strict';

define('vb/action/builtin/callComponentMethodAction',['vb/action/action', 'vb/private/log', 'ojs/ojcomponentcore', 'vb/private/utils'], (Action, Log, ojCC, Utils) => {
  const logger = Log.getLogger('/vb/private/stateManagement/callComponentMethodAction');

  class CallElementMethod extends Action {
    /**
     * @param parameters {component, method, params}
     * - component {Element}
     * - method {string}
     * - params {Array}
     *
     * @returns {Outcome)
     */
    perform(parameters) {
      const element = parameters.component;
      const methodName = parameters.method;
      const params = parameters.params || [];

      if (element && methodName) {
        // make sure the parameters are plain objects instead of proxies
        const clonedParams = [];
        if (params) {
          Utils.cloneObject(params, clonedParams);
        }

        let returnValue;
        try {
          returnValue = ojCC.callComponentMethod(element, methodName, ...clonedParams);
        } catch (e) {
          return Action.createFailureOutcome('Error during CallElementMethod', e);
        }

        // handle both sync and async methods
        return Promise.resolve(returnValue)
          .then((result) => Action.createSuccessOutcome(result))
          .catch((e) => Action.createFailureOutcome('Error during CallElementMethod', e));
      }

      const msg = `Missing parameter, element='${element}', methodName='${methodName}'`;
      logger.error(msg);

      return Action.createFailureOutcome('Error during CallElementMethod', new Error(msg)); // default
    }
  }

  return CallElementMethod;
});

