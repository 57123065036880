'use strict';

define('vb/bootstrap',[
  'knockout',
  'vb/private/stateManagement/application',
  'vb/private/configuration',
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/log',
  'vb/private/logCustomWriter',
  // -------------
  'vbIconFontCss', // this is used to preload the icon font in /vb/private/resource
  'ojdnd',
  'text',
  'ojs/ojcomponentcore',
  'ojs/ojcomposite',
  'ojs/ojknockout',
], (ko, Application, Configuration, Constants, Utils, Log) => () => {
  const log = Log.getLogger('/vb/bootstrap');
  let { pathname } = window.location;

  // Remove index.html from the current URL in case it was entered by hand.
  const urlMarkerIndex = pathname.indexOf(`/${Configuration.urlMarker}`);
  if (urlMarkerIndex < 0) {
    // Assume the starter page is index.html
    const index = 'index.html';
    const indexPath = pathname.indexOf(index);

    if (indexPath >= 0) {
      // If index.html is in the path, the baseUrl is what's before index.html
      pathname = pathname.substring(0, indexPath);
      Utils.replaceUrlPathname(pathname);
    }
  } else if (Configuration.appName) {
    // Remove vp from the current URL to be backward compatible with older FA bookmark
    pathname = pathname.substring(0, urlMarkerIndex +1 ) + pathname.substring(urlMarkerIndex + 4);
    Utils.replaceUrlPathname(pathname);
  }

  const whenDomReady = () => {
    try {
      const viewModel = {
        [Constants.appModuleConfig]: Application.pagesModuleConfig,
      };
      ko.applyBindings(viewModel, document.body);
    } catch (err) {
      log.error('Error while applying bindings to the application.', err);
    }
  };

  // load the entire application (so it can be used synchronously in the future)
  Application.load().then(() => {
    if (document.readyState !== 'loading') {
      whenDomReady();
    } else {
      document.addEventListener('DOMContentLoaded', whenDomReady);
    }
  });
});

