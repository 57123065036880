'use strict';

define('vbsw/helpers/serviceWorkerHelpers',['vbsw/private/serviceWorkerManager'], (ServiceWorkerManager) => {
  /**
   * This is a helper class that provides apis for interacting with the service worker registered with the
   * application. Note that these methods are designed to be called from the main UI thread. Regardless of whether
   * the application is running the browser service worker or emulated service worker, these methods will
   * be executed in the correct context to communicate with the service worker.
   *
   * For examples of how to use this helper, please refer to the following wiki:
   * {@link https://confluence.oraclecorp.com/confluence/display/ABCS/Offline+Persistence+Toolkit+Integration}
   */
  class ServiceWorkerHelpers {
    /**
     * Return a promise that resolves to true if the service worker is online, false otherwise.
     *
     * @return {Promise.<Boolean>}
     */
    static isOnline() {
      return ServiceWorkerManager.getInstance().isOnline();
    }

    /**
     * If the flag is true, force the PersistenceManager offline and vice versa.
     *
     * @param flag if true, force the PersistenceManager offline and vice versa
     */
    static forceOffline(flag) {
      return ServiceWorkerManager.getInstance().forceOffline(flag);
    }

    /**
     * Set options on the offline handler.
     *
     * This requires the offline handler to implement a setOptions method.
     *
     * @param options options to be set on the offline handler
     * @returns {Promise}
     */
    static setOfflineHandlerOptions(options) {
      return ServiceWorkerManager.getInstance().setOfflineHandlerOptions(options);
    }

    /**
     * Synchronize the log with the server. By default sync will first send an OPTIONS request before
     * each request URL to determine if the server is reachable. This OPTIONS request will be timed out
     * after 60s and the sync will fail with a HTTP response 504 error. If the OPTIONS request does not
     * time out then sync will progress.
     *
     * See @link https://oracle.github.io/offline-persistence-toolkit/PersistenceSyncManager.html#sync
     *
     * @param options refer to the JSDoc for PersistenceSyncManager for more detail
     * @returns {Promise}
     */
    static syncOfflineData(options) {
      return ServiceWorkerManager.getInstance().syncOfflineData(options);
    }

    /**
     * Returns a Promise which resolves to all the Requests in the Sync Log returned as an Array sorted by the
     * created date of the Request.
     *
     * See @link https://oracle.github.io/offline-persistence-toolkit/PersistenceSyncManager.html#getSyncLog
     *
     * @returns {*}
     */
    static getOfflineSyncLog() {
      return ServiceWorkerManager.getInstance().getOfflineSyncLog();
    }

    /**
     * Insert a Request into the Sync Log. The position in the Sync Log the Request will be inserted at is
     * determined by the Request created date.
     *
     * See @link https://oracle.github.io/offline-persistence-toolkit/PersistenceSyncManager.html#insertRequest
     *
     * @param request the request to insert
     * @param options refer to the JSDoc for PersistenceSyncManager for more detail
     * @returns {*}
     */
    static insertOfflineSyncRequest(request, options) {
      return ServiceWorkerManager.getInstance().insertOfflineSyncRequest(request, options);
    }

    /**
     * Delete a Request from the Sync Log.
     *
     * See @link https://oracle.github.io/offline-persistence-toolkit/PersistenceSyncManager.html#removeRequest
     *
     * @param requestId the id for the request to be deleted
     * @returns {*}
     */
    static removeOfflineSyncRequest(requestId) {
      return ServiceWorkerManager.getInstance().removeOfflineSyncRequest(requestId);
    }

    /**
     * Update a Request from the Sync Log. This function effectively replaces the Request in the sync log
     * with the provided Request.
     *
     * See @link https://oracle.github.io/offline-persistence-toolkit/PersistenceSyncManager.html#updateRequest
     *
     * @param requestId the id for the request to be updated
     * @param request updated request
     * @returns {*}
     */
    static updateOfflineSyncRequest(requestId, request) {
      return ServiceWorkerManager.getInstance().updateOfflineSyncRequest(requestId, request);
    }
  }

  return ServiceWorkerHelpers;
});

