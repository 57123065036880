'use strict';

define('vb/private/services/swaggerUtils',['vb/private/log', 'vb/private/services/serviceConstants'], (Log, ServiceConstants) => {
  const SWAGGER_OPERATIONID = 'operationId';
  const swaggerOperationWithBody = ['put', 'post', 'patch'];

  const logger = Log.getLogger('/vb/private/services/swaggerUtils');

  class SwaggerUtils {
    /**
     * find suitable endpoint ID if there is no operation ID
     * @param pathKey
     * @param pathObject
     * @param operationKey
     * @returns {*}
     */

    static getEndpointId(pathKey, pathObject, operationKey, operationObject) {
      let endpointId = operationObject[SWAGGER_OPERATIONID];
      if (!endpointId) {
        const pathNoSlash = pathKey.replace(/\//g, '');
        endpointId = `${operationKey}${pathNoSlash}`;
      }
      return endpointId;
    }


    static hasRequestBody(operation) {
      return swaggerOperationWithBody.indexOf((operation || '').toLowerCase()) !== -1;
    }


    /**
     * recursely resolve all references in the object, where 'resolve': replace $ref props with the referenced objects
     * @param swaggerObject
     * @param filterFn optional, fn(node, resolutionContext) return false if node should be skipped
     * @param node
     * @param resolutionContext used internally, caller should not pass a value
     * @returns {{}}
     */
    static resolveReferences(swaggerObject, filterFn,
      node = swaggerObject, resolutionContext = { names: [], map: {} }) {
      // check if we should abort
      if (filterFn && !filterFn(node, resolutionContext)) {
        return node;
      }

      function resolveArray(arr) {
        return arr.map((arrItem) => {
          if (Array.isArray(arrItem)) {
            return resolveArray(arrItem);
          }
          if (arrItem && typeof arrItem === 'object') {
            return SwaggerUtils.resolveReferences(swaggerObject, filterFn, arrItem, resolutionContext);
          }
          return arrItem;
        });
      }

      const newNode = {};

      const result = Object.assign({}, SwaggerUtils.resolveReference(swaggerObject, node, resolutionContext));
      // if resolution left the $ref intact, just return it, and use as the node
      if (result.$ref) {
        return result;
      }

      const dereferencedNode = result.reference;

      if (result.inMap) {
        return dereferencedNode;
      }

      Object.keys(dereferencedNode).forEach((nodeName) => {
        const childNode = dereferencedNode[nodeName];

        resolutionContext.names.push(nodeName);

        if (Array.isArray(childNode)) {
          newNode[nodeName] = resolveArray(childNode);
        } else if (childNode && typeof childNode === 'object') {
          newNode[nodeName] = SwaggerUtils.resolveReferences(swaggerObject, filterFn, childNode, resolutionContext);
        } else {
          newNode[nodeName] = childNode;
        }
        resolutionContext.names.pop();
      });

      return newNode;
    }


    /**
     * recursively follow references, expanding. does not follow combinations (allOf, etc).
     * @param swaggerObject
     * @param node
     * @param resolveContext should not be passed by the caller, used internally to track recursion
     * @returns { inMap: true/false, reference: the referenced object, or the original if not found }
     */
    static resolveReference(swaggerObject, node = {}, resolutionContext = {}) {
      const contextMap = resolutionContext.map;

      const contextPath = resolutionContext.names.join('/');
      if (!contextMap[contextPath]) {
        contextMap[contextPath] = node;
      }

      if (node && node.$ref) {
        // todo assume simple same-file reference. otherwise, ignore
        let refPath = node.$ref;
        // if its not a string, its either a valid name which happens to be "$ref", or its some wonky syntax.
        // either way, just return it
        if (typeof refPath !== 'string') {
          return node;
        }

        // skip external references
        // todo: we will possibly need to resolve these, at some point
        if (!refPath || refPath.startsWith('http')) {
          // bufp-36865 don't log, this gets noisy with new BO openapi3
          return {
            inMap: false,
            reference: node,
          };
        }

        if (refPath.startsWith('#/')) {
          refPath = refPath.substring(2);
        }

        // first, see if we've already expanded it
        // note: this is a very simple recursion check, only to prevent loops.
        // it does not define the loop in an 'optimal' way, it only stops the loop.
        // for example: the two recursive references, loop1 and loop2, where each has a ref to the other,
        // the expansion will not create objects that are recursive; the references will
        // terminate with an unexpanded object at some level.
        // loops really only happen in schemas, and runtime does not use the schema information.
        if (contextMap[refPath]) {
          return {
            inMap: true,
            reference: contextMap[refPath],
          };
        }

        let foundNode = swaggerObject;

        refPath.split('/').some((part) => {
          foundNode = foundNode && foundNode[part];
          return !foundNode;
        });

        if (foundNode) {
          const result = SwaggerUtils.resolveReference(swaggerObject, foundNode, resolutionContext);
          contextMap[refPath] = result.reference; // new map entry for path, and resolved object
          contextMap[contextPath] = result.reference; // replace the path/object with the resolved one
          return {
            inMap: false,
            reference: result.reference,
          };
        }

        // this doesn't happen; this would mean the local reference doesn't exist
        logger.info('unable to resolve reference:', refPath);
      }

      return {
        inMap: false,
        reference: node,
      };
    }


    /**
     * follow 'allOf' combinations, recursively, and return an array of all schemas
     * this requires that all $ref references have already be resolved.
     * @param schema
     * @returns {Array} array of expanded schemas, including allOf references
     */
    static expandSchema(schema) {
      const schemas = [];

      if (schema) {
        if (schema.allOf && Array.isArray(schema.allOf)) {
          schema.allOf.forEach((allOfSchema) => {
            // recurse, each 'allOf' could in turn include an 'allOf'
            const allOfSchemas = SwaggerUtils.expandSchema(allOfSchema);
            schemas.push(...allOfSchemas);
          });
        } else {
          schemas.push(schema);
        }
      }
      return schemas;
    }


    /**
     * this requires that the parameter defs have been processed by SwaggerUtils.separateParameters
     * @param paramDef
     * @returns {string}
     */
    static getParameterDefault(paramDef = {}) {
      let defaultValue = paramDef[ServiceConstants.VB_EXTENSIONS]
        && paramDef[ServiceConstants.VB_EXTENSIONS][SwaggerUtils.VB_DEFAULT_VALUE];

      if (defaultValue === undefined) {
        defaultValue = paramDef[SwaggerUtils.VB_DEPRECATED_DEFAULT_VALUE];
      }
      if (defaultValue === undefined) {
        defaultValue = paramDef.schema && paramDef.schema.default;
      }

      return defaultValue;
    }


    /**
     * @param mergedParameters
     * @returns {{path: {}, query: {}, header: {}, body: {}, etc}}
     */
    static separateParameters(mergedParameters) {
      const parameters = {};

      (mergedParameters || []).forEach((paramDef) => {
        parameters[paramDef.in] = parameters[paramDef.in] || {};
        // look for (VB-specific) param default value, and copy it to defaultValue
        const newParamDef = Object.assign({
          defaultValue: SwaggerUtils.getParameterDefault(paramDef),
        }, paramDef);
        parameters[paramDef.in][paramDef.name] = newParamDef;
      });

      return parameters;
    }

    /**
     * for the map of Parameter definitions, return the parameter definitions of the required query and path parameters.
     * Only looks at parameters for the URL ('path', 'query') and ignores 'body' and 'header' parameters (swagger 2.0).
     * @param parameterDefs mapped first by type (query, path, etc.), then by name.
     *   {
     *     path: { foo: {...} },
     *     query: {limit: {...} }, ...
     *   }
     * @returns {Array}
     */
    static getRequiredParameters(parameterDefs) {
      const requiredDefs = [];
      // these are the only param types used on the URL
      SwaggerUtils.URL_PARAM_TYPES.forEach((type) => {
        const parameterDefsForType = parameterDefs[type];
        Object.keys(parameterDefsForType || {}).forEach((key) => {
          const parameterDef = parameterDefsForType[key];
          // note, 'path' should have 'required: true', but check for 'path' just  in case
          if (type === 'path' || parameterDef.required === true) {
            requiredDefs.push(Object.assign({ type }, parameterDef));
          }
        });
      });
      return requiredDefs;
    }

    /**
     * Parses any text meant to be used as a JSON object by services.
     *
     * Replaces the {@link SwaggerUtils#collectGlobalVariableTokens services global variables} if necessary
     *
     * @param {string} text
     * @param {object} [variableHolder] - the object holding the variables. Defaults to
     *                                    <i>ConfigLoader.configurationDeclaration</i> and is only consumed once
     *                                    (after the initial consumption, this method caches the variables and
     *                                    values)
     * @return {*} a JSON object or array parsed from the specified text after replacing the global variables.
     */
    static parseServiceText(text) {
      const tokenAndValuePairs = SwaggerUtils.servicesGlobalVariableTokensSupplier
        && SwaggerUtils.servicesGlobalVariableTokensSupplier();
      if (Array.isArray(tokenAndValuePairs)
        && tokenAndValuePairs.length > 0
        && typeof text === 'string'
        && text.includes('{@')) {
        let replacement = text;
        tokenAndValuePairs.forEach(([variableToken, value]) => {
          replacement = replacement.replaceAll(variableToken, value);
        });
        return JSON.parse(replacement);
      }

      return JSON.parse(text);
    }
  }

  SwaggerUtils.VB_DEPRECATED_DEFAULT_VALUE = `${ServiceConstants.VB_EXTENSIONS}-defaultValue`;

  SwaggerUtils.VB_DEFAULT_VALUE = 'defaultValue'; // this appears in a 'x-vb'

  // the openapi3/swagger parameter types that need replacement/appending in the url
  SwaggerUtils.URL_PARAM_TYPES = ['path', 'query'];

  // Must be set to allow SwaggerUtils.parseServiceText to work.
  // This needs to be set externally because this module cannot depend on ConfigLoader in order to
  // avoid circular RequireJS dependencies.
  SwaggerUtils.servicesGlobalVariableTokensSupplier = null;

  return SwaggerUtils;
});

