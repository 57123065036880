'use strict';

define('vb/types/personalizationProvider',['vb/private/log', 'vb/private/stateManagement/application'], (Log, Application) => {
  const logger = Log.getLogger('/vb/types/personalizationProvider');

  // The key used to store into/retrieve from the window.localStorage.
  // This storage is scoped to the Document origin (including protocol, ie.
  // https://host.domain:port ), so we need to add some app-specific portion
  // and something to avoid conflicts with other framework or app usage
  const PZI_LOCAL_STORAGE_KEY = `oracle/vb/pzi/${Application.definition.id}`;

  /**
   * Base class for managing personalization storage/retrieval
   */
  class PersonalizationProvider {
    /* eslint-disable no-unused-vars, class-methods-use-this */

    // Going forward, this class should contain basic remote retrieval/persistence functionality
    // and expose methods to transform the requests/response that can be overriden.
    // TODO: add methods to transform the response/request for remote persistence

    /**
     * Retrieve the map of user personalization data.
     * @returns {Promise} a promise that resolves to the user state map
     */
    fetchUserStateMap() {
      // TODO: move basic remote fetch logic from SamplePersonalizationProvider here
      return Promise.resolve(this._localUserStateMap);
    }

    /**
     * Persist personalization data with the specified id.
     * @param {String} uniqueId the unique id of the personalization unit
     * @param {Object} userState the user state data
     * @param {Object} userStateMap the current complete map of unique ids to user states
     * @returns {Promise}
     */
    persistUserState(uniqueId, userState, userStateMap) {
      window.localStorage.setItem(PZI_LOCAL_STORAGE_KEY, JSON.stringify(userStateMap));
      // TODO: move basic remote persistence logic from SamplePersonalizationProvider here
      return Promise.resolve();
    }

    /**
     * Initialize the personalization provider with the specified configuration.
     * @param {Object} config the config object (if defined)
     * @returns {Promise}
     */
    initialize(config) {
      this._localUserStateMap = this._getLocalUserStateMap();
      this._userStateMapPromise = this._getUserStateMapPromise();

      // note: app init does not wait on the user state retrieval promise
      return Promise.resolve();
    }

    _getLocalUserStateMap() {
      let localStorageMap;
      try {
        localStorageMap = JSON.parse(window.localStorage.getItem(PZI_LOCAL_STORAGE_KEY));
      } catch (e) {
        logger.warn('Unable to load personalization information from local storage.', e);
      }
      return localStorageMap || {};
    }

    _getUserStateMapPromise() {
      return this.fetchUserStateMap().then((userStateMap) => {
        this._userStateMap = userStateMap || {};
        logger.info('Initialized personalization provider');
        return this._userStateMap;
      }).catch((e) => {
        logger.warn('Unable to load personalization information.', e);
      });
    }

    /**
     * Get the user personalization data for the specified id.
     * @param {String} uniqueId the unique id of the personalization unit
     * @returns {Promise}
     */
    getUserState(uniqueId) {
      logger.finer('Getting personalization unit for:', uniqueId);
      return this._userStateMapPromise.then(userStateMap => (userStateMap ? userStateMap[uniqueId] : null));
    }

    /**
     * Persist changes to the personalization variable with the specified id.
     * @param {String} uniqueId the unique id of the personalization unit
     * @param {Object} userStateChangeInfo the change information (the user state)
     * @returns {Promise}
     */
    handleUserStateChange(uniqueId, userStateChangeInfo) {
      // note: not logging value out of FUD that there could be sensistive info there
      logger.finer('Setting personalization unit for:', uniqueId);
      return this._userStateMapPromise.then((userStateMap) => {
        userStateMap[uniqueId] = userStateChangeInfo; // eslint-disable-line no-param-reassign
        // it is up to the persistUserState implemention to regulate how often calls are made to the remote service
        // TODO: consider making the ability to regulate the calls configurable on this base class
        return this.persistUserState(uniqueId, userStateMap[uniqueId], userStateMap);
      }).catch((e) => {
        logger.error('Unable to store personalization information. Cause:', e);
      });
    }

    /**
     * Get the local user state (personalization data) for the specified id.
     * @param {String} uniqueId the unique id of the personalization unit
     * @returns {Object} the local user state for the specified id.
     */
    getLocalUserState(uniqueId) {
      logger.finer('Getting local user state (personalization data) for:', uniqueId);
      return this._localUserStateMap[uniqueId] || null;
    }
  }

  return PersonalizationProvider;
});

