'use strict';

define('vb/private/vx/baseExtensionAdapter',[
  'vb/private/log',
  'vb/private/configuration',
  'vb/private/utils',
  'urijs/URI',
], (Log, Configuration, Utils, URI) => {
  const logger = Log.getLogger('/vb/stateManagement/vx/extensionAdapter', [
    // Register a custom logger
    {
      name: 'greenInfo',
      severity: 'info',
      style: 'green',
    },
  ]);

  /**
   * The query param name used by DT to pass the extension version to use in the preview
   * @type {String}
   */
  const DT_EXT_VERSION_QUERY_PARAM = 'vbdt:preferExtensionVersion';

  class BaseExtensionAdapter {
    /**
     * @param  {String} registryUrl  the url of the extension manager
     */
    constructor(registryUrl) {
      this.log = logger;
      this.registryUrl = registryUrl;
    }

    /**
     * Calculate the preferred version to use when loading extension
     *
     * @return {String} the version
     */
    static getExtensionVersion() {
      // Precedence is given to dev version that is specified using _preferExtensionVersion query parameter.
      // If query param is not specified, use the FA sandbox id.
      const queryParams = new URI(window.location.href).search(true);
      let version = queryParams[DT_EXT_VERSION_QUERY_PARAM];

      if (version) {
        // Since the param is removed from the URL, store the version in the session storage so that on refresh,
        // we can still access it.
        window.sessionStorage.setItem(DT_EXT_VERSION_QUERY_PARAM, version);
      } else {
        // If the version is on the sessionStorage, this has precedence over the sandbox id.
        version = window.sessionStorage.getItem(DT_EXT_VERSION_QUERY_PARAM);
        if (!version) {
          version = Configuration.getSandboxId();
          if (version) {
            // Add the $ in front of the FA sandbox id and encode it
            version = `$${encodeURIComponent(version)}`;
          }
        }
      }

      return version;
    }

    /**
     * Fix the registry URL for older FA application
     * Older FA application set REGISTRY_URL in vbInitConfig to: https://<fa-server>/fscmUI/vxmanifest/v1/applications/
     * In order to work with extension manager v2, the URL should only be the base: https://<fa-server>/fscmUI/
     * @param  {String} registryUrl
     * @return {String}
     */
    static fixRegistryUrl(registryUrl) {
      let adjustedRegistryUrl = registryUrl;

      // Older applications have the registry URL as https://<fa-server>/fscmUI/vxmanifest/v1/applications/
      // and we want https://<fa-server>/fscmUI/
      const manifestIndex = registryUrl.indexOf('vxmanifest');
      if (manifestIndex > 0) {
        adjustedRegistryUrl = registryUrl.substring(0, manifestIndex);
      }

      // Make sure to terminate with a '/'
      adjustedRegistryUrl = Utils.addTrailingSlash(adjustedRegistryUrl);

      return adjustedRegistryUrl;
    }
  }

  return BaseExtensionAdapter;
});

