'use strict';

define('vb/private/stateManagement/applicationExtension',[
  'vb/helpers/mixin',
  'vb/private/constants',
  'vb/private/stateManagement/containerRjsInitMixin',
  'vb/private/stateManagement/flowExtension',
], (
  Mixin,
  Constants,
  ContainerRjsInitMixin,
  FlowExtension) => {
  class ApplicationExtension extends Mixin(FlowExtension).with(ContainerRjsInitMixin) {
    /**
     * @param extension
     * @param path
     * @param base
     * @param className
     */
    constructor(extension, path, base, className = 'ApplicationExtension') {
      super(extension, path, base, className);
    }

    // The same class is used to extend an application extension
    static get extensionClass() {
      return ApplicationExtension;
    }

    /**
     * Return an array of all Services objects; currently only one.
     *
     * @returns {Array<Services>}
     */
    getAllServices() {
      return [this.services];
    }

    /**
     * This is called by Application, during its createServices.
     * Cannot be called in load(), because load() is only called when the app-flow-x file exists.
     *
     * @returns {Promise<unknown>}
     * @override
     */
    createServices() {
      return Promise.resolve()
        .then(() => {
          const relativePath = this.getResourceFolder();

          const serviceFileMap = this.definition.services || {};

          const options = {
            namespace: this.extensionId,
            relativePath,
            expressionContext: this.getAvailableContexts(),
            protocolRegistry: this.application.protocolRegistry,
            serviceFileMap,
          };

          // creates an ExtensionService
          return this.application.extensionRegistry.loadServicesModel(this.extensionId, options);
        })
        .then((servicesModel) => {
          this.services = servicesModel;
        });
    }

    /**
     * Returns a scope resolver map where keys are scope name ("page", "flow" or "application")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @private
     * @return {Object} an object which properties are scope
     */
    getScopeResolverMap() {
      return {
        [Constants.GLOBAL_PREFIX]: this,
        [Constants.APPLICATION_PREFIX]: this,
      };
    }
  }

  return ApplicationExtension;
});

