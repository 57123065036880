'use strict';

define('vb/action/builtin/navigateToPageAction',['vb/action/action'], (Action) => {
  /**
   * Navigate to a page of the application with given input parameters.
   * This action is deprecated in favor of the navigate action.
   * See https://confluence.oraclecorp.com/confluence/display/ABCS/VB+navigate+action
   *
   * @param {String}            page    The destination page path relative to the current flow (required)
   * @param {Array<key, value>} params  A map of URL parameters (optional)
   * @param {String}            history Effect on the browser history. Allowed value are 'replace', 'skip' or 'push'.
   *                                    If the value is 'replace', the current browser history entry is replace,
   *                                    meaning that back button will not go back to it.
   *                                    If the value is 'skip', the URL is left untouched.
   *                                    (optional and default is push)
   * Returns the outcome 'success' if there was no error during navigation. If navigation
   * completed, returns the payload { navigated: true }.
   * Returns the outcome 'failure' with the error in the payload.
   */
  class NavigateToPageAction extends Action {
    perform(parameters) {
      return this.helpers.navigateToPage(parameters)
        .then((result) => Action.createSuccessOutcome(result.navigated === true))
        .catch((e) => Action.createFailureOutcome('Error during NavigateToPageAction', e));
    }
  }

  return NavigateToPageAction;
});

