'use strict';

define('vb/helpers/bootstrapRest',['vb/private/utils', 'vb/helpers/rest', 'vb/private/configLoader'], (Utils, Rest, ConfigLoader) => {
  /**
   * serves the same purpose as Rest helper, but is intended for use in contexts before Application is created.
   * We would document specifically where this should be used: security providers
   *
   * The differences are:
   *  - this will only work for services that do not use any $dollar-vars (except $initParams) in expressions
   *    (which really shouldn't be happening, because it wouldn't make sense, but we don't know)
   * - this only works for the "services" in application; it would not look in other flows for "services"
   *
   * The existing Rest helper is not re-used for this, to be explicit about the different contexts,
   * since it is possible (for better or worse) to use $application.variables after Application has been created.
   *
   * Use of anything other than $initParams in path expressions should be 'deprecated',
   * but it is unclear if that will be possible.
   */
  class BootstrapRest extends Rest {
    /**
     * factory method, same as Rest.get
     * @param id
     * @returns {BootstrapRest}
     */
    static get(id) {
      return new BootstrapRest(id);
    }

    /**
     * get the endpoint directly from the services declared in the app-flow.json, but loaded BEFORE
     * Application has been created.  This means only $initParams will be available for path expressions.
     *
     * @returns {Promise<R>|Promise<void>|Promise.<T>}
     * @package
     * @override
     */
    // eslint-disable-next-line class-methods-use-this
    _getEndpointProvider() {
      return Promise.resolve({
        getEndpoint: (ref) => ConfigLoader.getServices().then((services) => services.getEndpoint(ref)),
      });
    }
  }

  return BootstrapRest;
});

