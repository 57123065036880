'use strict';

define('vb/private/services/readers/openApiUtils',[
  'vb/private/services/serviceConstants',
],
(ServiceConstants) => {
  /**
   * return the profiles array for the server object, if there is one
   * @param item
   * @returns {*|arg is Array<any>|[string]|[string, string]|Array}
   */
  function profiles(item) {
    return (item && item[ServiceConstants.VB_EXTENSIONS]
      && Array.isArray(item[ServiceConstants.VB_EXTENSIONS].profiles)
      && item[ServiceConstants.VB_EXTENSIONS].profiles) || [];
  }

  /**
   *
   */
  class OpenApiUtils {
    /**
     * find the server in an array for the chosen profile
     * @param servers
     * @param profile
     * @returns {*|{url: string}}
     */
    static findServerForProfile(servers = [], profile) {
      let server;

      // find the first match
      if (profile) {
        server = servers.find((item) => profiles(item) && profiles(item).indexOf(profile) !== -1);
      }
      // if nothing found, use the first one
      server = server || servers[0] || { url: '' };

      return server;
    }
  }

  return OpenApiUtils;
});

