'use strict';

define('vb/private/stateManagement/context/layoutContext',[
  'vb/private/constants',
  'vb/private/stateManagement/context/containerContext',
  'vb/private/stateManagement/context/layoutBaseContext',
],
(Constants, ContainerContext, LayoutBaseContext) => {
  /**
   * set of properties to expose in $layout
   */
  class LayoutContext extends ContainerContext {
    static get BaseContextType() {
      return LayoutBaseContext;
    }

    /**
     * @param layout
     *
     * this method is static because we need to be able to create the available contexts BEFORE
     * the individual XXXContext objects are created, because we base the viewModel on the availableContexts object.
     *
     * these static methods still use class hierarchy for inheriting property accessors.
     *
     * @returns {{ $variables, $constants }}
     */
    static getAvailableContexts(layout) {
      const availableContexts = super.getAvailableContexts(layout);

      // Remove properties added by container context that should not be part of the
      // layout context.
      delete availableContexts.$global;
      delete availableContexts.$application;
      delete availableContexts.$metadata;

      Object.defineProperties(availableContexts, {
        // $layout.variables is an alias of $variables
        $layout: {
          enumerable: true,
          configurable: true,
          get: () => layout.expressionContext,
        },
      });

      return availableContexts;
    }
  }


  return LayoutContext;
});

