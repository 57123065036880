/* eslint-disable no-underscore-dangle */

'use strict';

define('vb/private/services/protocolRegistry',['vb/private/constants',
  'vb/private/utils',
  'vb/private/log',
  'vb/private/services/serviceConstants',
  'vb/private/services/catalogRegistry',
  'urijs/URI',
  'signals',
],
(Constants, Utils, Log, ServiceConstants, CatalogRegistry, URI, signals) => {
  //
  const logger = Log.getLogger('/vb/private/services/protocolRegistry');

  const HANDLERS = [
    'vb/private/services/catalogHandler',
    'vb/extensions/protocol/vbExtensionHandler',
  ];

  class ProtocolRegistry {
    /**
     *
     * @param config
     * @param activeProfile - derived from the config, but broken out for re-use elsewhere
     * @param tenantConfig {object}
     * @param catalogRegistry {CatalogRegistry}
     */
    constructor(config, activeProfile, tenantConfig, catalogRegistry) {
      this._registry = {};
      this._config = config;
      this._activeProfile = activeProfile;
      this._tenantConfig = tenantConfig;
      this._initPromise = null;

      // allow listeners to see what gets referenced
      this._listeners = [];

      this.opened = new signals.Signal();

      this.catalogRegistry = catalogRegistry || new CatalogRegistry();
    }


    /**
     * creates and initializes all handlers; successful ones are put into the protocol map
     * @returns {Promise}
     */
    init() {
      if (!this._initPromise) {
        this._initPromise = Promise.resolve()
          .then(() => Utils.getResources(HANDLERS))
          .then((handlers) => {
            handlers.forEach((HandlerClass) => {
              if (HandlerClass.shouldInstall(this._config)) {
                // each handler has the option to interpret multiple protocols
                HandlerClass.PROTOCOLS.forEach((protocol) => {
                  this._registry[protocol] = new HandlerClass(this._config,
                    this._activeProfile, this._tenantConfig, this.catalogRegistry);
                });
              }
            });
            return this;
          });
      }
      return this._initPromise;
    }

    /**
     *
     * @returns {*}
     */
    get activeProfile() {
      return this._activeProfile;
    }


    /**
     * returns all possible paths through the catalog,
     * by starting with every object (both 'services' and 'backends'),
     * and resolving the URLs.
     *
     * @returns {Promise<{ services: [], backends: [] }>}
     *
     * Example:
     * If ONLY "extB" defines a catalog, and has one "services" object named "simplecatalog",
     * which references a backend also named "simplecatalog":
     *
     * [{
     *   "name": "simplecatalog",
     *   "namespace": "extB"
     *   "url": "http://localhost:9002/simple-files/services",  <- resolved URL
     *       "extensions": {
     *         "services": { (x-vb's merged - headers, etc) },
     *         "backends": { (x-vb's merged - headers, etc) },
     *       },
     *       "metadata": {   <- this is only used when fetching metadata (/describe)
     *         "services": {
     *           "openapi": "3.0",
     *           "path": "/sample-data-service.json",
     *           "query": "",
     *           "extensions": { (additional x-vb, used for fetching the /describe ; headers, etc),}
     *       },
     *       "chain": [
     *         { "type": "services", "name": "simplecatalog" },
     *         { "type": "backends", "name": "simplecatalog" }
     *       ],
     *  }
     ]
     *
     * @see UrlMapper.getUrlMapping
     */
    getTree() {
      if (!this.treePromise) {
        this.treePromise = this.init()
          .then(() => {
            const promises = [];
            Object.keys(this._registry).forEach((protocol) => {
              if (this._registry[protocol].getNames) {
                const p = this._registry[protocol].getNames()
                  .then((catalogs) => Object.assign({ protocol }, { catalogs }));
                promises.push(p);
              }
            });
            return Promise.all(promises);
          })
          .then((protocolInfos) => {
            /**
             * at this point, we have an array of protocol-specific objects whose handler supports getNames().
             * Today, that is only "vb-catalog", so this array has one object.
             * Within that object, there is an array of namespaces objects; one for 'base' and optionally one for
             * each extension. These contain the backends and services for each.
             *
             * [{
             *    "protocol": "vb-catalog",
             *    "catalogs": [
             *      {
             *        "namespace": "base",
             *        "backends": [ "crm", "demo" ],
             *        "services": [ "crmBusinessObjects", "demo-service-two" ]
             *      },
             *      {
             *        "namespace": "extB",
             *        "backends": [ "simplecatalog" ],
             *        "services": [ "simplecatalog" ]
             *      }
             *    ]
             *  }]
             */
            const promises = [];

            protocolInfos.forEach((protocolInfo) => {
              /**
               * we only need the "services" for URL-mapping (we don't map directly to backend URLs).
               */
              protocolInfo.catalogs.forEach((catalog) => {
                // this used to process both BACKENDS and SERVICES, but we only need to resolve SERVICES,
                // because we now only look at "services" for mappings.
                // see UrlMapping.getUrlMapping
                const objType = ServiceConstants.ExtensionTypes.SERVICES;
                const names = catalog[objType] || [];
                names.forEach((name) => {
                  // construct an (artificial) reference to each named service, and have the handler resolve the URL.
                  // This passes the namespace ('base' or ext id) to make sure the correct registered catalog is used.
                  // ex: this.getResolvedInfo('vb-protocol://services/someService', 'extA')

                  const ref = `${protocolInfo.protocol}://${objType}/${name}`;
                  const p = this.getResolvedInfo(ref, catalog.namespace)
                    .catch((e) => {
                      // just log errors when building the mapping tree, since it may contain
                      // unused, broken references. see https://jira.oraclecorp.com/jira/browse/BUFP-41687
                      logger.warn(`Ignoring missing catalog reference for mapping: ${ref}`, e);
                    });
                  promises.push(p);
                });
              });
            });
            return Promise.all(promises)
              .then((catalogObjects) => catalogObjects.filter((o) => !!o)); // remove undefines
          });
      }
      return this.treePromise;
    }


    /**
     * calls getResolvedObject, and returns a default object, if null
     * @param url
     * @param namespace {string}
     * @returns {Object} object containing the resolved url, and combined service and backend extension info,
     *  or a 'default' object with the original url and empty extension information
     * {
     *   url: {string}
      *  extensions: {
      *    services: {}, // headers, etc.
      *    backends: {}, // headers, transforms, etc.
      *  }
     */
    getResolvedInfoOrDefault(url, namespace) {
      return this.getResolvedInfo(url, namespace)
        .then((resolved) => resolved || ProtocolRegistry.createDefault(url))
        .then((resolved) => {
          this.opened.dispatch(url, resolved, namespace);

          return resolved;
        });
    }

    /**
     * for internal use only (for now).
     *
     * Init the registry on-demand, and then find the correct handlers, and delegate
     *
     * Also merges the backends and services extensions, EXCEPT for the services headers.
     * (The CatalogHandler keeps the backends and services extension separate)
     *
     * @param url
     * @param namespace {string}
     *
     * @returns {Object} object containing the resolved url, and combined service and backend extension info, or null
     * {
     *   url: {string}
     *  extensions: {
     *    services: {}, // headers, etc.
     *    backends: {}, // headers, transforms, etc.
     *  },
     *  metadata: {
     *    services: {}, // optional openapi3 Operation Object" stub for fetching the openapi3.
     *    // not currently legal or meaningful for backends to have a 'metadata' object
     *  }
     * }
     */
    getResolvedInfo(url, namespace) {
      // let resolvedInfo;
      let found;
      let protocolHandler;

      return Promise.resolve()
        .then(() => this.init())
        .then(() => {
          const urlInfo = URI.parse(url);
          if (urlInfo.protocol && this._registry[urlInfo.protocol]) {
            protocolHandler = this._registry[urlInfo.protocol];
            return protocolHandler.getResolvedObject(url, namespace, urlInfo)
              .then((resolved) => {
                if (resolved) {
                  // keep a list of visited objects, since we may need the names for the proxy/token relay urls
                  const chain = [];
                  chain.push({
                    type: resolved.type,
                    name: resolved.name,
                    namespace: resolved.namespace,
                    extensionAccess: resolved.extensionAccess,
                  });
                  return Object.assign(resolved, { chain });
                }
                return null;
              });
          }
          return null;
        })
        .then((f) => {
          found = f;
          // check if we still need to resolve the URL (recursively), using the found object's namespace
          return found ? this.getResolvedInfo(found.url, f.namespace) : null;
        })
        .then((next) => {
          if (next) {
            // use the next level's url instead of ours, and merge its extensions into ours
            const extensions = {};

            // merge the 'services' extensions
            extensions[ServiceConstants.ExtensionTypes.SERVICES] =
              protocolHandler.mergeExtensions(next.extensions[ServiceConstants.ExtensionTypes.SERVICES] || {},
                found.extensions[ServiceConstants.ExtensionTypes.SERVICES] || {});

            // merge the backend extension, which should also include all the service extensions.
            //
            // EXCEPT, when using the deprecated "services" syntax, EXCLUDE the following from the "services" object:
            //    - headers, proxyUrls, tokenRelayUrls.
            // (we leave it to the catalogHandler to filter the proxyUrls/tokenRelayUrls).
            // We used to use the "services" headers for the metadata, and not mix them with the "backend" data headers.
            // With the new syntax, we have a separate section for metadata headers,
            // so we merge services and backend headers.
            //
            // note: 'closest' is most-precedent for conflicting extensions.
            // for example, service1 -> backend1 -> backend2, the transforms in service1 win.

            const servicesExtWithoutHeaders =
              protocolHandler.getPropertiesForCrossObjectMerge(
                found,
                extensions[ServiceConstants.ExtensionTypes.SERVICES],
                ServiceConstants.ExtensionTypes.SERVICES,
                ServiceConstants.ExtensionTypes.BACKENDS);

            extensions[ServiceConstants.ExtensionTypes.BACKENDS] =
              protocolHandler.mergeExtensions(next.extensions[ServiceConstants.ExtensionTypes.BACKENDS] || {},
                found.extensions[ServiceConstants.ExtensionTypes.BACKENDS] || {},
                servicesExtWithoutHeaders);

            // 'metadata' is used in the new syntax for specifying how to get the service def.
            // currently, metadata is services-only, but we don't need to distinguish here.
            const chain = found.chain.slice().concat(next.chain); // add the next chain to ours.

            found = {
              url: next.url,
              extensions,
              metadata: found.metadata,
              chain,
              name: found.name,
              namespace: found.namespace,
            };
          } else if (found && found.extensions) {
            // bufp-31105; if the "services" never referenced a "backends", just use "services" for both
            if (found.extensions.services && !found.extensions.backends) {
              found.extensions.backends = Object.assign({}, found.extensions.services); // shallow copy, just in case
            }
          }

          return found;
        });
    }

    /**
     * for each registered protocol handler, get  list of the names of the 'services' and 'backends'
     * Really, only the 'catalog' supports this, so the top-level array always has one item.
     *
     * resolves with an array: [{ protocol: string, namespaces: { backends: Array<string>, services: <Array<string> } }]
     * [{
     *  "protocol": "vb-catalog",
     *  "namespaces": [{
     *     "namespace": "base",
     *     "backends": ['foo', 'fa' ],
     *     "services": ['puppyservice']
     *  },
     *  ... <additional 'namespaces' items for extensions>
     *  ]
     * }]
  }
     ]"
     *
     * @returns {Promise<Array>}
     */
    getNames() {
      if (!this.namesPromise) {
        this.namesPromise = this.init()
          .then(() => {
            const promises = [];
            Object.keys(this._registry).forEach((protocol) => {
              if (this._registry[protocol].getNames) {
                const p = this._registry[protocol].getNames()
                  .then((namespaces) => Object.assign({ protocol }, { namespaces }));
                promises.push(p);
              }
            });

            return Promise.all(promises);
          })
          .catch((e) => {
            logger.info('no catalog.json, continuing', e.toString());
            return [];
          });
      }
      return this.namesPromise;
    }


    /**
     * disposes the catalog, so it can be refreshed
     * @param protocol
     */
    disposeHandler(protocol) {
      // allow handlers to optionally define the 'dispose' function
      if (this._registry[protocol].dispose) {
        this._registry[protocol].dispose();
      }
    }

    /**
     * convenience method
     *
     * The catalog.json will be re-read on-demand, as well as any other protocol-specific resources.
     */
    disposeCatalog() {
      Object.keys(this._registry).forEach((protocol) => {
        this.disposeHandler(protocol);
      });

      if (this.catalogRegistry) {
        this.catalogRegistry.dispose();
        this.catalogRegistry = null;
      }
    }


    /**
     * create a minimal, usable, default
     * @param url
     * @returns {Promise<{url: *, extensions: {services:{}, backends: {}}}>}
     */
    static createDefault(url) {
      return Promise.resolve({
        url,
        extensions: {
          [ServiceConstants.ExtensionTypes.SERVICES]: {},
          [ServiceConstants.ExtensionTypes.BACKENDS]: {},
        },
        chain: [], // nothing visited
      });
    }
  }

  return ProtocolRegistry;
});

