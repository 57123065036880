'use strict';

define('vb/private/stateManagement/context/flowExtensionContext',[
  'vb/private/stateManagement/context/extensionContext',
  'vb/private/stateManagement/context/flowBaseContext',
], (ExtensionContext, FlowBaseContext) => {
  /**
   * Set of properties to expose in flow extension container
   */
  class FlowExtensionContext extends ExtensionContext {
    /**
     * BaseContextType property: The type of the baseContext object used for $base in expression.
     * This is used by super class ContainerContext to construct the baseContext object.
     */
    static get BaseContextType() {
      return FlowBaseContext;
    }

    /**
     * see ContainerContext
     *
     * @param extension
     * @returns {{$variables, $base.variables, ...}}
     */
    static getAvailableContexts(extension) {
      // This part populate $variables, $constants, etc...
      const availableContexts = super.getAvailableContexts(extension);

      // Remove whatever part of the context that we don't want to expose here
      // delete availableContexts.<object>

      Object.defineProperties(availableContexts, {
        $flow: {
          enumerable: true,
          configurable: true,
          get: () => extension.expressionContext,
        },
      });

      return availableContexts;
    }
  }

  return FlowExtensionContext;
});

