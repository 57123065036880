'use strict';

define('vb/helpers/mixin',[], () => {
  const NOOP_ROOT_CLASS = class {};
  /**
   * Helper class that allows the ability to apply mixins. Use is as follows:
   *
   * <code>
   *  class MyClass extends mix(MyBaseClass).with(Mixin1, Mixin2)
   * </code>
   *
   * where 'mix' is the result of importing this module. Every Mixin in the chain (with clause) is a function
   * that takes a superclass to create a new subclass, which can be defined like below
   * const Mixin1 = (superclass) => class extends superclass {}
   *
   * It's similar to saying
   * class MyClass extends Mixin1(Mixin2(MyBaseClass))
   *
   * note: If MyClass does not have a MyBaseClass then a NOOP_CLASS is assumed -
   * const NOOP_CLASS = class {}; // do not use Object, use "class {}", babel issue:
   * https://github.com/babel/babel/issues/4480
   *
   * Example: class MyClass extends mix(NOOP_CLASS).with(Mixin1, Mixin2) {}
   *
   */
  class Mixin {
    constructor(superclass = NOOP_ROOT_CLASS) {
      this.superclass = superclass;
    }

    with(...mixins) {
      return mixins.reduce((c, mixin) => mixin(c), this.superclass);
    }
  }

  return (superclass) => new Mixin(superclass);
});

