'use strict';

define('vb/private/stateManagement/packageFlow',[
  'vb/helpers/mixin',
  'vb/private/stateManagement/flow',
  'vb/private/stateManagement/packageContainerMixin',
  'vb/private/stateManagement/packageAndExtensionContainerMixin',
  'vb/private/stateManagement/packagePage',
  'vb/private/constants',
], (Mixin, Flow, PackageContainerMixin, PackageAndExtensionContainerMixin, PackagePage, Constants) => {
  /**
   * PackageFlow class
   * A class for flow object defined in an App UI
   */
  class PackageFlow extends Mixin(Flow).with(PackageContainerMixin, PackageAndExtensionContainerMixin) {
    constructor(id, parent, path, className = 'PackageFlow') {
      super(id, parent, path, className);

      // AppPackage extends PackageFlow but their package property is defined later
      const appPackage = parent.package || this;

      Object.defineProperties(this, {
        package: {
          value: appPackage,
          enumerable: true,
        },
        extension: {
          get() {
            return this.package.extension;
          },
          enumerable: true,
          configurable: true, // Redefined by AppPackage
        },
        resourceLoc: {
          value: `${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}/${this.path}`,
          enumerable: true,
        },
        // The default event prefix is the lowercase class name (see container.js) but for
        // packageFlow we want to use the same event prefix as flow
        eventPrefix: {
          value: 'flow',
          enumerable: true,
        },
      });
    }

    static get PageClass() {
      return PackagePage;
    }

    static get FlowClass() {
      return PackageFlow;
    }

    descriptorLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        // App UIs are using extension with a manifest containering a list of files,
        // so we can check if the file exist before doing a fetch that may fail.
        this.package.checkFileExist(`${this.resourceLoc}${this.fullName}.json`);
        return super.descriptorLoader(resourceLocator);
      });
    }

    functionsLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        this.package.checkFileExist(`${this.resourceLoc}${this.fullName}.js`);
        return super.functionsLoader(resourceLocator);
      });
    }

    /**
     * Return true if this page can be navigated to from the outside
     * @return {Boolean}
     */
    isNavigable() {
      const { navigation } = this.definition;
      if (navigation && navigation.fromExternal === 'enabled') {
        return true;
      }
      if (this.isDefault() && this.parent.isNavigable()) {
        return true;
      }

      return false;
    }

    /**
     * Check if there is any restriction to navigate to this page
     * @throws an error id the page is not navigable
     */
    checkNavigable() {
      if (!this.isNavigable()) {
        throw new Error(`Navigation to page ${this.getNavPath()} is not enabled.`);
      }
    }
  }

  return PackageFlow;
});

