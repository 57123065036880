/* eslint-disable class-methods-use-this,max-classes-per-file */

'use strict';

define('vb/private/events/eventBehavior',[
  'vb/private/constants',
  'vb/private/log',
  'vb/action/action',
  'vb/private/action/assignmentHelper',
  'vb/private/stateManagement/router',
  'vb/private/stateManagement/stateUtils',
  'vb/private/utils',
], (Constants, Log, Action, AssignmentHelper, Router, StateUtils, Utils) => {
  const logger = Log.getLogger('/vb/private/events/eventBehavior');

  /**
   * EventBehavior
   *
   * two main sets of functionality:
   *  A) "behavior" attribute - how the listeners are invoked, and results are returned.
   *  B) propagation  - order of containers and extensions.
   *
   *
   * A) Behaviors
   * implements for the "behavior" of declared ('custom') events, via subclasses.
   * Where "behavior" can be:
   * notify
   * - standard behavior, all container's listener chains called immediately, no waiting.
   *
   * notifyAnWait
   * - each container's listener chains are called serially
   *
   * checkForCancel
   * - each container's listener chains are called serially; propagation stops when a chain returns a "success"true.
   *    with a payload of { stopPropagation: true }
   *
   * transform
   * - the listener has an additional "$previous" variable which can be passed to the chain
   * - the declaration has an optional additional "returnType"
   * - each container's listener chains are called serially, and the result of each listener chain
   *   is the $previous of the next listener context
   *
   * The container is responsible for finding the event in the registry, and calling EventBehavior.execute.
   *
   *
   * B) Propagation
   * This class now controls the order of container and and extensions event processing.
   * Propagation is 'lateral' within a container and extensions, and 'vertical', up through container parents.
   *
   */
  class EventBehavior {
    /**
     * @param name {string}
     * @param eventModel {object}
     * @param registry {EventRegistry}
     */
    constructor(name, eventModel, registry) {
      if (eventModel) {
        this.name = name;
        this.behavior = eventModel.behavior;
        this.payloadType = eventModel.payloadType;
        this.returnType = eventModel.returnType;
        this.mode = eventModel.mode;
        this.eventModel = eventModel;
      }

      this.registry = registry; // prevent circular requireJS dependency

      this.behavior = this.behavior || Constants.EventBehaviors.NOTIFY;
    }


    /**
     * called by Container, to start event propagation.
     *
     * @param firingContainer
     * @param eventName we need the nae that the caller used, to make sure the namespace is right
     * @param eventPayload
     * @returns {Promise<never>|*}
     */
    start(firingContainer, eventName, eventPayload, expressionContexts) {
      const startingContainer = this.startingContainer(firingContainer);

      // if the event is declared, or using a namespace, it is a 'new' style of event,
      // and always starts bubbling from the (lowest) current Page (or lowest valid container).
      if (startingContainer) {
        const eventModel = this.findEventDefinition(startingContainer, eventName, firingContainer);

        if (eventModel) {
          const coercedPayload = (eventModel && eventModel.payloadType)
            ? AssignmentHelper.coerceType(eventPayload, eventModel.payloadType)
            : eventPayload;

          // convenience object, for internal methods
          const eventWrapper = {
            callerName: eventName,
            name: this.eventModel.fullName,
            origin: firingContainer, // the one who fired
            payload: eventPayload,
            coercedPayload,
            eventModel,
          };

          // now get the listener functions
          const functionWrappers = this
            .recurseContainmentAndCreateFunctions(startingContainer, eventWrapper, expressionContexts);

          return this.eventModel.behavior.execute(functionWrappers);
        }

        return Promise.reject(new Error(`unable to fire event ${eventName}: no event model found `
          + `for ${startingContainer.className}, fired from ${firingContainer.className}.`));
      }
      return Promise.reject(new Error(`unable to fire event ${eventName}: no event source found, `
      + ` fired from ${firingContainer.className}.`));
    }


    /**
     * default behavior; start from the current 'leaf' container (not necessarily the 'event firer')
     * @returns {Page|*}
     */
    startingContainer(container) {
      if (container) {
        return container.getLeafContainer();
      }
      // fallback; shouldn't need this though
      return Router.getCurrentPage();
    }


    /**
     * default (declared) implementation
     * returns true if the "mode" is "listenable" or "triggerable" (all triggerable events are listenable).
     * @param container
     * @returns {boolean}
     */
    listenableBy(container) {
      return (!container.isExtension()
        || this.mode === Constants.EventMode.TRIGGERABLE
        || this.mode === Constants.EventMode.LISTENABLE);
    }


    /**
     * default (declared) implementation
     * @param container
     * @returns {boolean}
     */
    triggerableBy(container) {
      return (!container.isExtension() || this.mode === Constants.EventMode.TRIGGERABLE);
    }

    /**
     * look for the event by name in the global registry
     * @param eventName
     * @param containerThatFired
     * @private
     */
    findEventDefinition(container, eventName, containerThatFired) {
      let eventDef = this.registry.get(container, containerThatFired, eventName);

      if (!eventDef) {
        const nextContainer = container.parent;
        if (nextContainer) {
          eventDef = this.findEventDefinition(nextContainer, eventName, containerThatFired);
        }
      }
      return eventDef;
    }


    /**
     * call all the chains defined for the event listener.
     * this delegates to callChainFunctionsInternal, and wraps the results
     *
     * @param container
     * @param chainFunctionWrappers Array<{chainId: string, fnc: function}
     * @param expressionContexts
     * @param previousResult (*)
     * @returns {Promise<T|void>}
     */
    // eslint-disable-next-line no-unused-vars
    callChainFunctions(container, chainFunctionWrappers, expressionContexts, previousResult) {
      return this.callChainFunctionsInternal(container, chainFunctionWrappers, expressionContexts, previousResult);
    }

    /**
     * call all the chains defined for the event listener, called by callChainFunctions
     * this is the 'default' behavior, other behaviors may override
     *
     * @param container
     * @param chainFunctionWrappers Array<{chainId: string, fnc: function}
     * @param expressionContexts
     * @param previousResult (*)
     * @returns {Promise<T|void>}
     * @private
     */
    // eslint-disable-next-line no-unused-vars
    callChainFunctionsInternal(container, chainFunctionWrappers, expressionContexts, previousResult) {
      return container.callListenerChainFunctionsInParallel(chainFunctionWrappers, expressionContexts)
        // wrap each result with an object
        .then((results) => results && results
          .map((result, index) => ({ chainId: chainFunctionWrappers[index].chainId, result })));
    }

    /**
     * Call the invokeEvent functions for a given container in the propagation 'chain'.
     * The functions are the set of invokeEvent() calls for the container tree,
     * created by Container.recurseContainmentAndCreateFunctions.
     *
     * The function signature should be: fnc(eventBehavior, previousValue).
     * Container curries the invokeEvent function (bind) to provide the name and payload.
     *
     * This delegates to executeInternal, but resolves with undefined.
     * Subclasses should only override if they need to return a result;
     * otherwise, they should override executeInternal.
     *
     * @param functionWrappers Array<{container: Container, fnc: function}>
     * @returns {Promise<undefined>}
     */
    execute(functionWrappers) {
      logger.info('Triggering declared event, name:', this.name, 'behavior:',
        this.behavior, 'payloadType:', this.payloadType);
      return this.executeInternal(functionWrappers)
        .then(() => undefined);
    }


    /**
     * called by execute, can be overridden by subclasses
     *
     * For this base implementation, these are called in parallel.
     * This is the default behavior; subclasses may override.
     *
     * This is invoked by execute().
     *
     * @param functionWrappers
     * @returns {Promise}
     * @private
     */
    executeInternal(functionWrappers) {
      return Promise.all(functionWrappers.map((wrapper) => wrapper.fnc(this)));
    }

    /**
     * simple utility to call AssignmentHelper.coerceType conditionally
     * @param value
     * @param type
     * @returns {*|string|boolean|number}
     */
    static shape(value, type) {
      return type ? AssignmentHelper.coerceType(value, type) : value;
    }


    /**
     * creates an array of function wrappers that represent a curried version of container.invokeEvent.
     * the curried function represents: container.invokeEvent(name, coercedPayload).
     *
     * the caller (EventBehavior) provides the rest of the args (behavior, previous) at invocation.
     *
     *
     * @param container
     * @param eventModel
     * @param eventWrapper
     * @param expressionContexts
     * @returns {Array<{ container: Container, fnc: function }>}
     */
    curryListeners(container, eventModel, eventWrapper, expressionContexts) {
      const functionWrappers = [];
      const fnc = container.invokeEvent.bind(container, eventWrapper.name, eventWrapper.coercedPayload);
      functionWrappers.push({
        container,
        fnc,
      });

      if (eventModel.isInterface) {
        const fncWrappers = this.recurseExtensionsAndCreateFunctions(container, eventWrapper,
          eventModel, expressionContexts);

        Array.prototype.push.apply(functionWrappers, fncWrappers);
      }

      return functionWrappers;
    }


    /**
     * for event bubbling,
     * from the current container, build an array of (bound) asynchronous functions, that return a Promise;
     * Each functions will be a (curried) call to this.invokeEvent.
     * The caller of this function is responsible for calling these functions.
     * By building an array, the caller can chose to serialize, or call in parallel.
     *
     * Each wrapped function must be called by the EventBehavior.execute, initiated by calling EventBehavior.start.
     *
     * The 'eventWrapper' is:
     *  name: event name, optionally qualified with container class prefix
     *  origin: the Container FIRING the event
     *  eventModel: the EventModel
     *  payload: optional, event-specific
     *
     * @param container
     * @param eventWrapper
     * @param eventWrapper.name {string}
     * @param eventWrapper.origin: {Container}
     * @param eventWrapper.payload: {*}
     * @param eventWrapper.coercedPayload: {*}
     * @param eventWrapper.eventModel: {EventModel}
     * @param expressionContexts
     * @returns {Array<{ container: Container, fnc: function }>}
     */
    recurseContainmentAndCreateFunctions(container, eventWrapper, expressionContexts) {
      const functionWrappers = [];
      let nextContainer;

      // if there is a definition anywhere in the chain,
      // we only invoke the event if we can see the event definition from the current container.
      const eventModel = this.registry.get(container, eventWrapper.origin, eventWrapper.name);

      if (eventModel) {
        // do not curry the invokeEvent if we are not allowed to listen to it
        if (this.listenableBy(container)) {
          // get the curried function for the event listeners for the (base) container, and siblings (extensions).
          // this may be overridden by the behavior to change the order, or to change if extensions are processed.
          const fncWrappers = this.curryListeners(container, eventModel, eventWrapper, expressionContexts);
          Array.prototype.push.apply(functionWrappers, fncWrappers);
        }
      }

      // go to the next container...
      const terminalContainer = eventWrapper.eventModel.container;
      // do not bubble to containers above the declaration of the event
      if (container !== terminalContainer) {
        nextContainer = this.getNextContainerForBubbling(container, eventWrapper.name, expressionContexts);
      }

      if (nextContainer) {
        const fncWrappers = this
          .recurseContainmentAndCreateFunctions(nextContainer, eventWrapper, expressionContexts);
        Array.prototype.push.apply(functionWrappers, fncWrappers);
      }

      // we are out of parents
      return functionWrappers;
    }


    /**
     * iterate any extensions, and return (curried) invokeEvent functions.
     *
     * analogous to Container.recurseContainmentAndCreateFunctions, but for extensions.
     * @param container
     * @param eventWrapper
     * @param eventModel
     * @param expressionContexts
     * @returns {Array<{ container: Container, fnc: function }>}
     */
    recurseExtensionsAndCreateFunctions(container, eventWrapper, eventModel, expressionContexts) {
      const functionWrappers = [];
      if (container.extensionsArray) {
        container.extensionsArray
          .forEach((extension) => {
            const fncWrappers = this.recurseContainmentAndCreateFunctions(extension, eventWrapper, expressionContexts);
            Array.prototype.push.apply(functionWrappers, fncWrappers);
          });
      }

      return functionWrappers;
    }

    /**
     * used in recurseContainmentAndCreateFunctions
     * @param container
     * @param eventName
     * @param expressionContexts
     * @returns {Container}
     * @private
     */
    getNextContainerForBubbling(container, eventName, expressionContexts) {
      if (!this.checkStopPropagationExpression(container, eventName, expressionContexts)) {
        return container.parent;
      }
      return null;
    }


    /**
     * looks at the listener definition for 'stopPropagation'
     *
     * if the listener def has 'stopPropagation': true, we do not bubble
     * todo: need to reconcile this with vbBeforeEnter, and result.cancelled: true
     * @param container
     * @param eventName
     * @param expressionContexts
     * @returns {boolean}
     */
    checkStopPropagationExpression(container, eventName, expressionContexts) {
      // support this property only on 'eventListeners', not 'events'
      const eventListener = container.findEventListener(eventName);

      // evaluate listener stopPropagation values
      const stopped = (() => {
        if (eventListener && eventListener.stopPropagation) {
          const expr = StateUtils.getValueOrExpression(eventListener.stopPropagation, expressionContexts);
          return Utils.resolveIfObservable(expr);
        }
        return false;
      })();

      // log an 'info here
      if (stopped) {
        const msg = 'Event listener \'stopPropagation\' returned true';
        logger.info('Event bubbling stopped for event', eventName, ':', msg);
      }
      return stopped;
    }
  }

  // used for constructing the results
  EventBehavior.ResultProperties = {
    RETURN: 'return',
  };

  return EventBehavior;
});

