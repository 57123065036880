'use strict';

/**
 * Loads the app-flow.json early, so it can configure requireJS before other things, like JET, is loaded.
 * Specifically, translations bundle overrides need to be configured before ojcore is loaded.
 *
 * This uses the runtime environment to get the resource.
 *
 * clients should just call init() on the singleton.
 */
define('vb/private/configLoader',['vb/private/utils',
  'vb/binding/expression',
  'vb/private/log',
  'vb/private/constants',
  'vb/private/pathHandler',
  'vb/private/services/protocolRegistry',
  'vb/private/services/catalogRegistry',
  'vbc/private/trace/tracer',
  'urijs/URI',
],
(Utils, Expression, Log, Constants, PathHandler, ProtocolRegistry, CatalogRegistry, Tracer, URI) => {
  const logger = Log.getLogger('/vb/private/configLoader');

  // Agreed with DT
  const SERVICES_GLOBAL_VARIABLE_TOKEN_REGEX = /^{@([A-Za-z][.\w-]*)}$/;

  /**
   *
   * @param path
   * @returns {boolean | *}
   */
  const isAbsolute = (path = '') => new URI(path).is('absolute');

  /**
   * Checks if the nested property of the given object is not equal to the given value.
   *
   * @param {Object} object
   * @param {[String]} nestedProperties
   * @param {*} value
   * @returns {boolean}
   */
  const checkOverwrites = (object, nestedProperties = [], value) => {
    if (object && nestedProperties.length) {
      let currentObject = object;
      nestedProperties.forEach((property) => {
        currentObject = currentObject && currentObject[property];
      });
      return currentObject && currentObject !== value;
    }
    return false;
  };

  let protocolRegistry; // private, accessed via getter in singleton
  let urlMapper; // private, accessed via getter in singleton
  let catRegistry;

  // if requireJS ever changes their internal implementation, this is a guard.
  // almost certainly won't happen; its easy to find many references to this in other libraries.
  let usePrivateRequirejsApi = true;


  class ConfigLoader {
    constructor() {
      this.requirePath = ''; // shared with the application

      this.userConfig = null; // value is set in initializeUserConfig
      this.activeProfile = null; // value set in the init()
      this.initParams = null; // value set in the init()
      this.configurationDeclaration = null; // value set in the init()
      this.servicesMap = null; // value set in the init()
      this.catalogConfiguration = null; // value set in the init()
      this.dtConfig = null; // value set in the init()
      this.runtimeEnvironment = null; // set in getRuntimeEnvironment()

      // used to attempt to keep track of the requirejs.config calls we know about.
      // assumes successive calls override previous ones.
      this.currentRequireConfig = {};

      this._servicesPromise = null; // set in getServices()
      this.loadSecurityProviderPromise = null; // value is set in loadSecurityProvider()

      // An array of pairs [variableToken, value]
      // See ConfigLoader._collectServicesGlobalVariableTokens
      this.servicesGlobalVariableTokens = null;
    }

    /**
     * loads the app-flow.json and reads configuration-specific declaration.
     * Evaluates any expressions,
     * though the only dollar-variable available are the ones exposed by createInitParams (currently none).
     * Currently, the declarations it looks for are:
     *
     * "localization": { // new!!
     *     "locale" - a string
     *     "merge" - a map, matches the ojL10n "merge" config syntax
     * }
     *
     * In translation bundle definitions, we look for one new property:
     * "translations": { // exists, not used here
     *    "myBundle": { // exists, not used here
     *      "path: ... // exists, not used here
     *      "merge" - a new property used to also construct an entry for the merge map.
     *    }
     *  }
     *
     * "requirejs": a new object, that is passed to requirejs.config, except where values from the
     *   previous two declarations conflict:
     *   - "locale" above will override config.ojL10N.locale
     *   - "merge" will be a merge of the localization.merge, the "merge" bundle properties, and config.ojL10N.merge
     *
     * @return {Promise}
     */
    init() {
      let evaluatedConfig = {};
      let config;

      return ConfigLoader.initTracer(window.vbInitConfig)
        .then(() => this.loadConfiguration())
        .then((cfg) => {
          config = cfg;

          let locale;

          this.initParams = ConfigLoader.createInitParams(config);
          this.configurationDeclaration = ConfigLoader.createConfigurationDeclaration(config);
          this.servicesMap = (config && config.services) || {};

          // eslint-disable-next-line no-underscore-dangle
          this.servicesGlobalVariableTokens = ConfigLoader._getServiceGlobalVariableTokens(config);

          // configuration for the ProtocolRegistry and CatalogHandler
          // combine the declared "configuration" and window.vbInitConfig, and add the relativePath
          this.catalogConfiguration = Object.assign({
            relativePath: this.requirePath,
            initConfig: window.vbInitConfig || {},
          }, this.configurationDeclaration);

          // Application Profile
          this.activeProfile = (this.catalogConfiguration.profile) || '';

          // configure logging here, instead of application.js, so it is as early as possible
          ConfigLoader.configureLogging(config);

          // look for the "localization" section
          if (config.localization) {
            // first, check for the top-level "locale"
            locale = this.getEvaluatedSafe(config.localization.locale);
          }

          // now look for "merge" properties in the translation bundle declarations
          const bundleMergePropMap = {};
          if (config.translations) {
            Object.keys(config.translations)
              .forEach((bundleName) => {
                const bundleDecl = config.translations[bundleName];
                const bundleMerge = this.getEvaluatedSafe(bundleDecl.merge);

                if (bundleMerge && typeof bundleMerge === 'string' && bundleDecl.path) {
                  const overriddenBundlePath = bundleMerge;
                  // need to 'normalize' the path for JET
                  const resolvedPathExpr = this.getEvaluatedSafe(bundleDecl.path);
                  if (resolvedPathExpr) {
                    // 'root' path, unrestricted: true
                    const pathHandler = new PathHandler(resolvedPathExpr, '', { allowParent: true });
                    bundleMergePropMap[overriddenBundlePath] = pathHandler.getResolvedPath();
                  }
                }
              });
          }

          // if there is a "requirejs" declaration, just take it verbatim, after evaluating expressions,
          // and pass it to requirejs
          if (config.requirejs && typeof config.requirejs === 'object') {
            evaluatedConfig = this.getEvaluatedSafe(config.requirejs);
          }

          // top-level 'locale' takes priority
          if (locale) {
            evaluatedConfig.config = evaluatedConfig.config || {};
            evaluatedConfig.config.ojL10n = evaluatedConfig.config.ojL10n || {};
            evaluatedConfig.config.ojL10n.locale = locale || evaluatedConfig.config.ojL10n;
          }

          // "merge" properties take priority, and are merged with explicit ones
          if (Object.keys(bundleMergePropMap).length) {
            evaluatedConfig.config = evaluatedConfig.config || {};
            evaluatedConfig.config.ojL10n = evaluatedConfig.config.ojL10n || {};
            // 'merge' maps are combined with conflicts resolved by:
            //   "Localization": "merge" takes precedence
            //   then the 'merge" property in the app bundle declarations
            //   finally, and "merge" properties in the "requirejs"
            const allMerges = Object.assign({}, evaluatedConfig.config.ojL10n.merge, bundleMergePropMap);
            evaluatedConfig.config.ojL10n.merge = allMerges;
          }

          // handle userConfig here, so we can configure URL Mapping to skip it
          this.initializeUserConfig(config);

          // 'dtConfig' was introduces on 2104 and allows DT to pass information that is not available
          // on other sources, like index.html for example.
          // At the moment, this object is used to convey the IDCS host information so that 'vb-extension://tenant/idcs'
          // can be resolved when the  RT application is running in the preview mode, i.e., running inside a DT frame
          // (see DT's PreviewResourceContentRewriter.js).
          this.dtConfig = config.dtConfig || {};

          // workaround circular dependency  UrlMapper->ServicesUtils->ConfigLoader->UrlMapper
          return Utils.getResource('vb/private/urlMapper')
            .then((UrlMapper) => {
              const serviceWorkerConfig = window.vbInitConfig && window.vbInitConfig.SERVICE_WORKER_CONFIG;
              urlMapper = new UrlMapper(this.protocolRegistry, this.activeProfile, serviceWorkerConfig);
            });
        })
        .then(() => this.getRuntimeEnvironment())
        .then((re) => re.getServiceWorkerRequireConfig())
        .then((swRequireConfig) => {
          // merge in the service worker require config from the runtime environment if any
          if (swRequireConfig) {
            evaluatedConfig = Utils.cloneObject(swRequireConfig, evaluatedConfig);
          }

          this.setConfiguration(evaluatedConfig);

          // return a config object containing require config, external plugins, and logConfig
          // eslint-disable-next-line no-underscore-dangle
          return this._getExternalPlugins()
            .then((plugins) => {
              // this is passed to the 'service worker' initialization as externalConfig
              const result = {
                requireConfig: evaluatedConfig,
                logConfig: config ? config.logConfig : undefined,
                userConfig: this.userConfig,
              };
              // @todo: used to return null here, not sure why
              if ((plugins && plugins.length > 0) || Object.keys(evaluatedConfig).length > 0) {
                result.plugins = plugins;
              }
              return result;
            });
        });
    }


    /**
     * @param vbInitConfig window.vbInitConfig
     * @returns {*} a promise to an initialized tracer. If the runtime environment allows it, and tracer configuration
     * specified in vbInitConfig is valid, this will be a client trace Tracer. Otherwise this will be a noop tracer.
     * Must be called prior to Service Worker registration.
     * @see https://confluence.oraclecorp.com/confluence/display/MDO/Trace-Client+API
     */
    static initTracer(vbInitConfig) {
      return Utils.getRuntimeEnvironment()
        .then((re) => {
          if (typeof re.isOpenTraceEnabled === 'function' && re.isOpenTraceEnabled()) {
            const traceOptions = Utils.getTraceOptions(vbInitConfig);
            return Tracer.init(traceOptions);
          }
          // set explicit disabled flag in trace config, because SW thread cannot access runtime environment
          // so it can't run the above check
          if (vbInitConfig && vbInitConfig.TRACE_CONFIG) {
            // eslint-disable-next-line no-param-reassign
            vbInitConfig.TRACE_CONFIG.disabled = true;
          }
          return Promise.resolve();
        });
    }

    /**
     * loads app-flow.json
     *
     * @returns {Promise<Object>}
     */
    loadConfiguration() {
      return this.getRuntimeEnvironment()
        .then((re) => re.getApplicationDescriptor(`${this.requirePath}app`));
    }

    /**
     * separate to allow mocking
     */
    getRuntimeEnvironment() {
      // allow runtimeEnvironment to be mocked for unit tests
      return (this.runtimeEnvironment)
        ? Promise.resolve(this.runtimeEnvironment)
        : Utils.getRuntimeEnvironment()
          .then((re) => {
            this.runtimeEnvironment = re;
            return re;
          });
    }

    /**
     * initialize the 'initParams' values from either the window variable, or the app-flow declaration
     *
     * (this will also exposed in $application.initParams object, see application.js)
     *
     * Expressions are supported for "initParams" JSON,
     * but may NOT reference any VB constructs; only 'window' variables are usable.
     *
     *
     * as a window variable:
     *   window.vbInitParams = {
     *     ...
     *   }
     *
     * as a declaration:
     *   "configuration": {
     *      "initParams": {
     *        ...
     *      }
     *   }
     *
     *
     *
     *
     * @returns {{}}
     */
    static createInitParams(config) {
      const windowValues = (window && window.vbInitParams) || {};
      const declaredValues = Expression
        .getEvaluatedSafe((config.configuration && config.configuration.initParams) || {}, {});

      return Object.assign({}, windowValues, declaredValues);
    }

    /**
     *
     * @returns {*}
     */
    static createConfigurationDeclaration(config) {
      // first. look for window.vbInitParams.catalog
      // @todo: vbInitParams is temporary - remove, once consumers are able to declare in app-flow.json
      //
      // note: this is different than initParams
      // 'initParams' is    window.vbInitParams {...}  and "configuration": { "initParams": {...} }
      // This instead uses  window.vbInitParams {...}  and "configuration": {...} <- uses configuration object!
      // The use of the window variables will be removed eventually

      const windowValues = (window && window.vbInitParams) || {};
      const declaredValues = (config.configuration) || {};

      // declared values override windows values
      return Object.assign(windowValues, declaredValues);
    }


    /**
     * wrapper for Expression.getEvaluatedSafe.
     *
     * this is used for evaluating expressions in the "translations", "localization", and "requirejs",
     * which are all evaluated before $application exists.
     * These values can be referenced by "$initParams.<property name>
     *
     * @param expression
     * @private
     * @returns {*}
     */
    getEvaluatedSafe(expression) {
      return Expression.getEvaluatedSafe(expression, this.getExpressionContext());
    }

    /**
     * @private
     * @returns {{ $initParams: {Object} }}
     */
    getExpressionContext() {
      return { [Constants.ContextName.INIT_PARAMS]: this.initParams };
    }


    /**
     * do the actual requireJS call
     * @param configuration
     * @private
     */
    setConfiguration(configuration) {
      if (configuration) {
        // eslint-disable-next-line no-underscore-dangle
        this.currentRequireConfig = ConfigLoader._mergeRequirejsConfig(this.currentRequireConfig, configuration);
        // requirejs.config(this.currentRequireConfig);
        requirejs.config(configuration);
      }
    }

    /**
     * used for "requirejs" declarations in app-flow-x extensions and app.json.
     * @param container
     */
    addRequirejsConfigFromContainer(container) {
      const config = container.definition && container.definition.requirejs;
      if (config) {
        const evaluatedConfig = this.getEvaluatedSafe(config);
        if (evaluatedConfig) {
          this.addRequirejsPathsFromContainer(container, evaluatedConfig);
          this.addRequirejsBundlesFromContainer(container, evaluatedConfig);
        } else {
          logger.warn(`Evaluation of requirejs configuration provided by "${container.extensionId}" extension failed.`);
        }
      }
    }

    /**
     *
     * @param {*} container
     * @param config RequireJS configuration
     */
    addRequirejsBundlesFromContainer(container, config = container.definition && container.definition.requirejs) {
      if (config && config.bundles) {
        const bundles = Object.assign({}, config.bundles);
        // Do not call requirejs.config directly as it will overwrite some of the config values,
        // instead merge extension config with the current config before setting it on RequireJS
        this.setConfiguration({ bundles });
      }
    }

    /**
     * used for "requirejs" paths declarations in app-flow-x extensions and app.json. Allows:
     * - "paths" to be used from the extension
     * - only paths that are not already set can be set; we check the requireJS config for existing paths.
     * @param container
     * @param config RequireJS configuration
     */
    addRequirejsPathsFromContainer(container, config = container.definition && container.definition.requirejs) {
      if (config && config.paths) {
        const paths = Object.assign({}, config.paths);
        const map = {};

        const currentConfig = this._getExistingRequirejsConfig();
        const existingMaps = currentConfig.map;

        Object.keys(paths).forEach((path) => {
          let mappedPath = paths[path];

          const isLocal = !isAbsolute(mappedPath);
          if (isLocal) {
            // Local modules need to be resolveable when referenced from other modules within the extension/appUi
            // as well as from the RT when we load component/module imports
            delete paths[path];

            // "vx/<ext-id>/"
            let baseUrl = container.baseUrl;
            // "vx/<ext-id>/" if container is an app extension, or
            let basePath = baseUrl;
            // check if resource is relative to the container
            if (container.path && container.path.length && mappedPath[0] !== '/') {
              // "vx/<ext-id>/ui/self/applications/<app-id>/" if container is an App UI page
              // "vx/<ext-id>/ui/<ext-id1>/applications/<app-id>/" if container is an App UI extension
              baseUrl += container.resourceLoc;
              // "vx/<ext-id>/applications/<app-id>/" if container is an App UI page
              basePath += container.path;
            }

            // key of the context aware requirejs map
            // "vx/<ext-id>" or "vx/<ext-id>/ui/self/applications/<app-id>"
            // remove trailing '/' otherise requirejs map does not work
            // eslint-disable-next-line max-len
            const contextPath = (baseUrl[baseUrl.length - 1] === '/') ? baseUrl.substring(0, baseUrl.length - 1) : baseUrl;

            // key used in the requirejs global map
            // "vx/<ext-id>/<path>" or "vx/<ext-id>/applications/<app-id>/<path>"
            const globalResourcePath = basePath + path;

            // remove starting '/'
            if (mappedPath[0] === '/') {
              mappedPath = mappedPath.substring(1);
            }

            // "vx/<ext-id>/<resolved-resource-path>"
            // resolved-resource-path examples:
            //  * build/components/myCCA          (V1 from component exchange)
            //  * self/resources/components/myCCA (V1 local to extension)
            //  * ui/self/resources/components/myCCA (V2 local to extension)
            //  * ui/applications/app1/resources/components/myCCA (V2 local to App UI)
            const resolvedModuleId = baseUrl + mappedPath;

            // don't allow existing ones to change
            // if (checkExistingMaps(existingMaps, contextPath, globalResourcePath, path, resolvedModuleId)) {
            if (!checkOverwrites(existingMaps, ['*', globalResourcePath], resolvedModuleId)
                && !checkOverwrites(existingMaps, [contextPath, path], resolvedModuleId)) {
              // when modules inside the extension depend on the <path> we use extension context map
              const extensionUrlMap = map[contextPath] || (map[contextPath] = {});
              // "<path>" => "vx/<ext-id>/<resolved-resource-path>"
              extensionUrlMap[path] = resolvedModuleId;

              // when RT imports components/modules for the <path> while loading the extension
              const starMap = map['*'] || (map['*'] = {});
              // "vx/<ext-id>/<path>" => "vx/<ext-id>/<resolved-resource-path>", or
              // "vx/<ext-id>/applications/<app-id>/<path>" => "vx/<ext-id>/<resolved-resource-path>"
              starMap[globalResourcePath] = resolvedModuleId;

              // For CCAs we must map <path> globally as one CCA can refrence another and oj-dynamic
              // also needs to be able to load them by ID.
              // TODO: check if this is a CCA path (if possible)
              const isCCAPath = resolvedModuleId.includes('/components/');
              // "<path>" => "vx/<ext-id>/<resource-path>"
              if (isCCAPath) {
                if (!checkOverwrites(existingMaps, ['*', path], resolvedModuleId)) {
                  starMap[path] = resolvedModuleId;
                } else {
                  // don't allow existing ones to change
                  logger.warn('An extension has tried to replace an existing CCA requireJS "paths" entry, ignoring: '
                    + `"${path}": "${paths[path]}"`);
                }
              }
            } else {
              // don't allow existing ones to change
              logger.warn('An extension has tried to replace an existing requireJS "paths" entry, ignoring: '
                + `"${path}": "${paths[path]}"`);
            }
          } else if (checkOverwrites(currentConfig, ['paths', path], paths[path])) {
            // don't allow existing ones to change
            logger.warn('An extension has tried to replace an existing requireJS "paths" entry, ignoring: '
              + `"${path}": "${paths[path]}"`);
            delete paths[path];
          }
        });

        // Do not call requirejs.config directly as it will overwrite some of the config values,
        // instead merge extension config with the current config before setting it on RequireJS
        this.setConfiguration({ paths, map });
      }
    }

    /**
     * return the current requireJS config
     *
     * separate function, to allow mocking in tests
     * @private
     */
    _getExistingRequirejsConfig() {
      // always try the internal requirejs config, first.
      if (usePrivateRequirejsApi) {
        try {
          return requirejs.s.contexts._.config;
        } catch (err) {
          // only fail once; use our own from that point on.
          logger.warn('Unable to get requirejs configuration, using internal configuration and continuing.');
          usePrivateRequirejsApi = false;
        }
      }
      // only used if the above reference causes an exception
      return this.currentRequireConfig;
    }

    /**
     * does a merge of 'paths' and 'maps', with 'current' taking precedence.
     * @param previous
     * @param current
     * @private
     */
    static _mergeRequirejsConfig(previous, current) {
      const merged = {};

      if (previous.paths || current.paths) {
        merged.paths = Object.assign({}, previous.paths, current.paths);
      }

      if (previous.map) {
        merged.map = {};
        // merge each sub-map object
        Object.keys(previous.map).forEach((mapProp) => {
          merged.map[mapProp] = Object
            .assign({}, previous.map[mapProp], current.map && current.map[mapProp]);
        });
      }
      // make sure we get all new map objects
      if (current.map) {
        merged.map = Object.assign({}, current.map, merged.map);
      }

      return merged;
    }

    /**
     * Return external plugins specified by the security provider.
     *
     * @returns {*}
     * @private
     */
    _getExternalPlugins() {
      return this.loadSecurityProvider()
        .then((securityProvider) => {
          if (!securityProvider) {
            return undefined;
          }

          // need to dynamically load DefaultSecurityProvider here instead of in define so we don't
          // pull in JET too early and break the translation bundle
          return Utils.getResource('vb/private/types/defaultSecurityProvider')
            .then((DefaultSecurityProvider) => {
              // if securityProvider is an instance of DefaultSecurityProvider, only return the plugins
              // specified in userConfig.configuration.plugins
              if (securityProvider instanceof DefaultSecurityProvider) {
                return this.userConfig.configuration ? this.userConfig.configuration.plugins : undefined;
              }

              // otherwise, call getServiceWorkerPlugins to get the external plugins
              return securityProvider.getServiceWorkerPlugins(this.userConfig.configuration);
            })
            // need to also get the plugins provided by RuntimeEnvironment.getServiceWorkerPlugins
            .then((externalPlugins) => Utils.getRuntimeEnvironment()
              .then((re) => re.getServiceWorkerPlugins()
                .then((pluginsOverride) => {
                  if (!pluginsOverride) {
                    return externalPlugins;
                  }

                  if (!externalPlugins) {
                    return pluginsOverride.length > 0 ? pluginsOverride : undefined;
                  }

                  return externalPlugins.concat(pluginsOverride);
                })));
        })
        .catch((e) => {
          // log and ignore the error
          logger.error('Failed to load security provider', this.userConfig.type, e);
        })
        .finally(() => {
          // this is a workaround for the regression described in VBS-11362
          // re-setting the promise so that the security provider is re-initialized
          // so that the message handler can be properly registered
          // TODO: we should fix this properly in 22.01
          this.loadSecurityProviderPromise = null;
        });
    }

    /**
     * use "logConfig" in application to configure logging;
     * for now, only "level" is supported.
     *
     * ex.
     * "logConfig": {
     *    "level: "info"
     * }
     *
     * @param config the contents of app-flow.json
     * @returns {boolean}
     */
    static configureLogging(config) {
      if (config && config.logConfig && config.logConfig.level) {
        return Log.setMinimumLevel(config.logConfig.level);
      }
      return false;
    }


    /**
     * these services should NOT be used by the Application! These are here for things that need
     * to load services before Application is created, but...
     *
     * Application service paths may refer to Application variables.
     * But...
     * ApplicationConfiguration is creating its own service map BEFORE Application exists,
     * for things that may need access to service defs before the Application is created.
     *
     * So, if the vb app requires services before Application (for example, if its configured for Extensions,
     * or the security provider is using a service def), the application CANNOT use
     * any dollar-variables in the service map expressions EXCEPT $initParams.
     *
     *
     * @param definition
     * @returns {Promise<Services>}
     */
    getServices() {
      if (!this._servicesPromise) {
        this._servicesPromise = Utils.getResource('vb/private/services/services')
          .then((Services) => {
            const options = {
              relativePath: this.requirePath,
              serviceFileMap: this.servicesMap,
              expressionContext: this.getExpressionContext(),
              isUnrestrictedRelative: true,
              protocolRegistry: this.protocolRegistry,
            };
            return new Services(options);
          });
      }
      return this._servicesPromise;
    }

    /**
     * setup this.userConfig, and evaluate the userConfig.configuration.path as an expression, if needed.
     * @params config contents of app-flow.json
     * @returns {Object} original userConfig, or a shallow clone with the configuration.url evaluated, if applicable.
     */
    initializeUserConfig(appConfig) {
      this.userConfig = appConfig.userConfig;

      const config = (this.userConfig && this.userConfig.configuration);
      if (config && config.url) {
        const context = { [Constants.ContextName.INIT_PARAMS]: this.initParams };
        this.userConfig.configuration = Object
          .assign({}, config, { url: Expression.getEvaluatedSafe(config.url, context) });
      }
      return this.userConfig;
    }

    /**
     * Load and instanciate the security provider class defined in userConfig from the app-flow.json
     * @return {Promise} a promise that resolve with the security provider instance
     */
    loadSecurityProvider() {
      if (!this.userConfig) {
        return Promise.resolve();
      }

      this.loadSecurityProviderPromise = this.loadSecurityProviderPromise || Promise.resolve()
        .then(() => {
          const { type } = this.userConfig;
          if (!type) {
            throw new Error('Missing type in userConfig');
          }

          return Utils.getResource(type).then((SecurityProviderClass) => new SecurityProviderClass());
        });

      return this.loadSecurityProviderPromise;
    }

    /**
     * create a shared ProtocolRegistry, used to re-reference additional, app-flow.json-defined, service metadata
     *
     * as a window variable:
     *   window.vbInitParams.config = {
     *      catalog: path to catalog JSON file
     *      services: {
     *         catalog: {
     *            .... backend and services token values
     *         }
     *      }
     *   }
     *
     * as a declaration:
     *   "configuration": {
     *      "initParams": {
     *        ...
     *      }
     *   }
     *
     * @param declaration
     * @returns {ProtocolRegistry}
     */
    get protocolRegistry() {
      if (!protocolRegistry) {
        const tenantConfig = {};
        const idcsInfo = (this.userConfig && this.userConfig.configuration && this.userConfig.configuration.idcsInfo)
          || this.dtConfig.idcsInfo;
        if (idcsInfo) {
          tenantConfig.idcsInfo = idcsInfo;
        }

        protocolRegistry = new ProtocolRegistry(this.catalogConfiguration,
          this.activeProfile, tenantConfig, this.catalogRegistry);
      }
      return protocolRegistry;
    }

    /**
     * accessor
     * catalogRegistry to register the base catalog.json, or any extension catalog.json
     * @returns {CatalogRegistry}
     */
    // eslint-disable-next-line class-methods-use-this
    get catalogRegistry() {
      if (!catRegistry) {
        catRegistry = new CatalogRegistry();
      }
      return catRegistry;
    }

    /**
     *
     * @returns {UrlMapper}
     */
    // eslint-disable-next-line class-methods-use-this
    get urlMapper() {
      if (!urlMapper) {
        logger.error('No UrlMapper available; ConfigLoader.init must be called first');
      }
      return urlMapper;
    }

    /**
     * @param p {ProtocolRegistry}
     * @private only for tests!!
     */
    // eslint-disable-next-line class-methods-use-this
    set protocolRegistry(p) {
      protocolRegistry = p;
    }

    /**
     * Collects all service global variables on the specified variableHolder, returning an array with
     * the corresponding variable token and value.
     *
     * The service global variables is a variable like 'services.global.foo', the variable token is a string
     * like '{@foo}', and the value is, at the moment and by design decision, a string.
     *
     * The variable tokens in the returned array are ordered based on the return of <i>Object.keys(variableHolder)</i>.
     *
     *  @param variableHolder
     * @return {[[string, string]]} an array in which each element is an array for the format
     *         [globalVariableToken, value] (like ['{@foo}', '123']), where both the token and the value are
     *         guaranteed to be valid.
     */
    static _collectServicesGlobalVariableTokens(variableHolder) {
      if (variableHolder) {
        const variables = Object.keys(variableHolder);
        if (variables.length > 0) {
          return Object.keys(variableHolder)
            .filter((variable) => typeof variable === 'string'
              && variable.length > 'services.global.'.length
              && variable.startsWith('services.global.'))
            .map((variable) => {
              const variableToken = `{@${variable.substring('services.global.'.length)}}`;
              if (SERVICES_GLOBAL_VARIABLE_TOKEN_REGEX.test(variableToken)) {
                // Examples of valid tokens: {@port} {@Port} {@Port-1} {@My_Port}
                // Examples of invalid tokens: {@1port} {@@Port} {@$Port-1} {@_Port} {@port @port}

                const value = variableHolder[variable];
                if (typeof value === 'string') {
                  return [variableToken, value];
                }
                logger.warn(`Ignoring services global variable '${variable}' because its value is not a string.`);
              }

              logger.warn(`Ignoring services global variable '${variable}' because its name is not valid.`);
              return undefined;
            })
            .filter((v) => v);
        }
      }
      return [];
    }

    /**
     * Gets the services global variables using the window.vbInitParams and config.configuration.initParams.
     *
     * The values are NOT evaluated as expressions.
     *
     * @param config
     * @return {Array<[string, string]>} see ConfigLoader._collectServicesGlobalVariableTokens
     * @private
     */
    static _getServiceGlobalVariableTokens(config) {
      const windowValues = (window && window.vbInitParams) || {};
      const declaredValues = config.configuration && config.configuration.initParams;
      const variableHolder = Object.assign({}, windowValues, declaredValues);
      return this._collectServicesGlobalVariableTokens(variableHolder);
    }
  }

  return new ConfigLoader(); // singleton
});

