'use strict';

define('vb/private/types/arrayDataProvider2',[
  'knockout',
  'ojs/ojdataprovider',
  'ojs/ojarraydataprovider',
  'vb/helpers/mixin',
  'vb/private/types/builtinExtendedTypeMixin',
  'vb/private/log',
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/types/utils/dataProviderUtils',
  'vb/private/types/dataProviderConstants',
  'vb/private/types/utils/jsonDiffer',
  'vb/private/stateManagement/stateUtils',
  'vb/private/types/arrayDataManager',
],
(ko, ojDataProvider, ojArrayDataProvider, Mix, BuiltinExtendedTypeMixin, Log, Constants, Utils, DataUtils, DPConstants,
  JsonDiffer, StateUtils, ArrayDataManager) => {
  const LOGGER = Log.getLogger('/vb/types/ArrayDataProvider2');

  const OJ_REFRESH_EVENT = new ojDataProvider.DataProviderRefreshEvent();

  const checkArrayOfArrays = (a) => a.every((x) => Array.isArray(x));

  /**
   * A builtin type that holds the data (array) locally. This implementation wraps the JET
   * ArrayDataProvider, in order to make use of features like sort, filter etc.
   * This class co-ordinates changes between VB and JET, iow, changes to data property of a
   * variable are diff-ed and propagated to the JET observable array (through direct mutation).
   *
   * There are several ways of configuring the VB ArrayDataProvider2 based on the nature of the
   * data:
   *
   * 1. data must be stable (once initialized with all the data it rarely changes in its entirety
   * except for mutations - adds/updates/removes).
   *
   * Stable data is often static or local that is known right away and can be set on the ADP
   * configuration in the following ways:
   *   - inlined in the def via defaultValue
   *   - referenced in the def via an expression pointing to a constant, variable or module function
   *   - populated later in an actionChain called from vbEnter. Generally all data is fetched
   *   once and populated at init time (from an application / flow cache).
   *
   * *** Mutations on Local Data: ***
   *  - it's recommended that authors mutate the variable data property directly rather than use
   *  the fireDataProviderEvent (add/update/remove). This ensures that the data property and the
   *  component can be kept in sync.
   *
   *  - data property can be mutated directly using assignVariables action but might require JS
   *  code if multiple # of rows need to be inserted / removed at specific locations.
   *
   * *** Refreshing Static data post-init: ***
   *
   *  - generally if data is stable there would be little need for this but if needed new data
   *  can be set on the data property directly.
   *  NOTE: It's important to understand that reseting data is an expensive operation as it
   *  forces the component to re-render itself with the new data, and so must be avoided.
   *
   * 2. if data is volatile (no guarantees can be made on the stability of data. Often such data
   * is fetched from Rest endpoint and using an SDP is better suited for these cases). But if
   * ADP is still preferred VB ADP can be configured in the following ways:
   *
   *   - data is an expression that points to a variable that is populated with finite chunk of
   *   data at init time, say data fetched from a REST endpoint.
   *   - NOTE: in the future we will support composing data providers that will make this simpler
   *
   *  *** Mutations on Dynamic Data ***
   *  - it's recommended that authors mutate the variable data property directly
   *  rather than use the fireDataProviderEvent (add/update/remove). This ensures that the data
   *  property and the component can be kept in sync.
   *  - data property can be mutated directly but with dynamic data this can be tedious
   *  operation to insert at specific index etc. keys are better option because they are
   *  meant to be unique
   *
   * *** Refreshing Dynamic data post-init: ***
   * - data property can be mutated directly and will automatically notify the component.
   *
   */
  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["getWritableOptions",
   "getIdAttributeProperty"] }] */
  class ArrayDataProvider2 extends Mix(ojArrayDataProvider).with(BuiltinExtendedTypeMixin) {
    constructor() {
      const keyAttributes = undefined;
      const sortComparators = undefined;
      const options = { implicitSort: [], keyAttributes, sortComparators };
      const observableData = ko.observableArray([]);
      super(observableData, options);

      this._dataObservable = observableData;
      this.adm = undefined;
      this.jsonDiffer = undefined;
      this.log = LOGGER;
      this.variableLifecycleStage = Constants.VariableLifecycleStage.INIT;
    }

    /**
     * Initialize super with final initial values for properties.
     */
    activate() {
      this.variableLifecycleStage = Constants.VariableLifecycleStage.ACTIVE;

      // TODO: test for entire default value being a constant. value could end up being undefined
      const value = this.getValue() || {};
      const keyAttrName = DPConstants.DataProviderIdAttributeProperty.KEY_ATTRIBUTES;
      if (value[keyAttrName]) {
        const keyAttrsVal = DataUtils.unwrapData(value[keyAttrName]);
        this.options[keyAttrName] = keyAttrsVal;
      }
      this.jsonDiffer = new JsonDiffer(this.options[keyAttrName]);

      const { implicitSort } = value;
      if (implicitSort) {
        this.options.implicitSort = DataUtils.unwrapData(implicitSort) || [];
      }

      const { textFilterAttributes } = value;
      if (textFilterAttributes) {
        this.options.textFilterAttributes = DataUtils.unwrapData(textFilterAttributes) || [];
      }

      // initialize sortComparators - account for expressions used with top-level sortComparators and comparators
      // property.
      const { sortComparators } = value;
      if (sortComparators) {
        const sc = DataUtils.unwrapData(sortComparators);
        // have at least a comparators property with a value or it too is an expression
        if (sc && sc.comparators) {
          const comparators = DataUtils.unwrapData(sc.comparators);
          let mapSC;
          // comparators could be an Array of arrays or a Map
          if (Array.isArray(comparators) && checkArrayOfArrays(comparators)) {
            const unwrappedComparators = [];
            comparators.forEach((c, i) => {
              // todo what if function callback itself is an expression that can't be evaluated at init time. this
              //  would need to done in activate().
              unwrappedComparators[i] = [c[0], DataUtils.unwrapData(c[1])];
            });
            mapSC = new Map(unwrappedComparators);
          } else if (comparators instanceof Map) {
            mapSC = comparators;
          } else if (comparators) {
            this.log.info('ArrayDataProvider2', this.getId(), 'is configured with sortComparators that'
              + 'cannot be evaluated at  this time!', sortComparators);
          }
          this.options.sortComparators = mapSC ? { comparators: mapSC } : undefined;
        }
      }

      const { data } = value;
      if (data) {
        const dataEval = DataUtils.unwrapData(data);
        if (dataEval && Array.isArray(dataEval) && dataEval.length > 0) {
          const tmpObsData = ko.utils.unwrapObservable(this._dataObservable);
          ko.utils.arrayPushAll(tmpObsData, dataEval);
          // even when we start with initial data, the ADP instance is created with [] data,
          // so it's important to call valueHasMutated
          this._dataObservable.valueHasMutated();
        }
      }
    }

    /**
     * keyAttributes is the only supported idAttribute property.
     * @return {string}
     */
    getIdAttributeProperty() {
      return DPConstants.DataProviderIdAttributeProperty.KEY_ATTRIBUTES;
    }

    /**
     * Sets up and returns custom property definition for 'data' property. This is to ensure that
     * the data observable and the 'data' property are kept in sync. JET ArrayDataProviders
     * require a stable reference and it needs to be a ko observable. BUFP-30940
     *
     * the variable 'data' property is always the source of truth and is used to keep the data
     * observable in sync. The data property mutates under the following situations:
     *
     * 1. by assignVariablesAction
     *  - normally variable onvalueChanged will get called with the diff, but under certain
     *  situations as explained below under Issues, calls to getValue() come in earlier than
     *  this event, requiring that the data observable be kept in sync sooner.
     *
     * 2. by fireDataProviderEventAction
     *  - as part of BUFP-35110, only ArrayDataProvider2 allows authors to use
     *  fireDataProviderEventAction by itself to mutate LADP data. Internally this mutates its
     *  value, which then is similar to (1) above
     *
     * 3. by component
     *   - component writes are possible today, but not really allowed. Component writes are
     *   similar to the behavior of assignVariablesAction (1)
     *
     * 4. at end of init
     *   - when a variable value is fully known, the first call to getValue() at this stage
     *   yields the right value. Same as (1)
     *
     * Some issues encountered in the above scenarios:
     * -----------------------------------------------
     * There could be cases where the call to getValue() might happen prematurely requiring
     * checks to keep the observable in sync.
     *
     * A. during VB initialization when the property is updated with its initial value, today
     * there is no event notifying us that the value has changed. BUFP-30444. Until that is
     * fixed we need to ensure that the data observable and the data property values are kept in
     * sync through this method.
     *
     * B. after init() when an action like assignVars mutates the ADP, ko subscribers are
     * notified in synchronous fashion, before the builtin type's listener gets notified of the
     * change (onValueChanged event is delivered async). The subscribers can be any of these
     *
     * i. component bound to ADP var
     * ii. expression used by a page variable, or the current action chain variable uses an
     *  expression with the mutating variable
     *
     * iii. another action chain that spawned the current chain references the mutating variable.
     *
     * If i...iii does not happen then the handlePropertyVariableChangeEvent listener is called,
     * which handles any deltas
     *
     * @param propKey
     * @param currScope
     * @param namespace
     * @param variable
     * @returns {*}
     */
    getVariablePropertyDefinition(propKey, currScope, namespace, variable) {
      const currentScope = currScope;
      if (propKey === 'data') {
        return {
          get: () => {
            // when data property is requested always return the dataObservable. We also need to
            // ensure that the dataObservable and the 'data' property are kept in sync.
            const value = this.getValue();
            const propValue = value.data;
            // there is a possibility for the getter to be called before variable has been activated, so ensure
            // value is eval-ed only on/or after ADP has been activated.
            if (propValue && (this.variableLifecycleStage !== Constants.VariableLifecycleStage.INIT)) {
              const data = DataUtils.unwrapData(propValue);
              const obsData = ko.utils.unwrapObservable(this._dataObservable);
              if (Array.isArray(propValue)) {
                const eventPayload = this.jsonDiffer.processDeltas(obsData, data);
                if (eventPayload.detail.add || eventPayload.detail.update || eventPayload.detail.remove) {
                  this.dispatchMutationEvent(eventPayload);
                }
              }
            }

            return propValue;
          },
          set: (newValue) => {
            // setter on data is never called directly by component rather, author always writes
            // to the property first, which then events, but for BUFP-35110 we now write to data
            // directly (set the entire value)
            const currentVal = currentScope.variableNamespaces[namespace][variable.name];
            const updatedVal = Object.assign({}, currentVal, { data: newValue });
            currentScope.variableNamespaces[namespace][variable.name] = updatedVal;
          },
          enumerable: true,
          configurable: true,
        };
      }
      return super.getVariablePropertyDefinition(propKey, currScope, namespace, variable);
    }

    buildKeys(items) {
      let keyArray;
      const keyAttrs = this.options[DPConstants.DataProviderIdAttributeProperty.KEY_ATTRIBUTES];
      if (keyAttrs) {
        const idHelper = DataUtils.getIdAttributeHelper(keyAttrs);
        keyArray = items && idHelper.getKeys(items);
      }

      return keyArray;
    }


    /**
     * Provide a "definition" of the type including the array items, which applies to the "data"
     * property.
     *
     * @param variableDef actual declaration of the ArrayDataProvider2.
     * @param {Object} scopeResolver
     * @return {{type: {data: *, keyAttributes: string|string[], itemType: string, implicitSort: [*],
     * sortComparators: object}, resolved: boolean}}
     */
    // eslint-disable-next-line class-methods-use-this
    getTypeDefinition(variableDef, scopeResolver) {
      let arrayTypeDef;
      if (variableDef.defaultValue && variableDef.defaultValue.itemType) {
        // itemType is specified in the defaultValue
        const { itemType } = variableDef.defaultValue;

        if (typeof itemType === 'string') {
          arrayTypeDef = `${itemType}[]`;
        } else {
          arrayTypeDef = [itemType];
        }
      } else {
        arrayTypeDef = 'any[]';
      }

      return {
        type: {
          data: StateUtils.getType('data', { type: arrayTypeDef }, scopeResolver),
          keyAttributes: 'any',
          itemType: 'any',
          implicitSort: [{
            attribute: 'string',
            direction: 'string',
          }],
          sortComparators: {
            comparators: 'any',
          },
          textFilterAttributes: 'string[]',
        },
        resolved: true,
      };
    }

    /**
     * Called whenever a DataProvider event needs to be raised so listeners can be notified.
     *
     * @param event
     * @param {boolean} external true when called by external callers (example
     * fireDataProviderEventAction). default is false.
     */
    dispatchEvent(event, external = false) {
      if (external) {
        if (event.detail && event.detail.refresh
          && (event.detail.add || event.detail.update || event.detail.remove)) {
          this.log.error('unable to dispatch both refresh and mutation events at the same time.',
            event.detail);
          return false;
        }

        if (event.type === DPConstants.DataProviderEvent.REFRESH
          || (event.detail && event.detail.refresh)) {
          return this.dispatchRefreshEvent(event);
        }
        return this.processExternalMutationEvent(event);
      }

      return super.dispatchEvent(event);
    }

    /**
     * Returns 'none' for writableProperties indicating none of the properties are
     * writable, implying no properties within the variable are writable. The entire
     * variable needs to be updated each time, which is the behavior of assignVariables action
     * anyway. This also limits the ability for components to write values directly to properties.
     * @return {object}
     */
    getWritableOptions() {
      return { propertiesWritable: Constants.VariableWritablePropertyOptions.NONE };
    }

    /**
     * Generally we expect authors to mutate LADP variable property 'data' directly using
     * assignVariablesAction. But as part of BUFP-35110 we want to allow authors to use
     * fireDataProviderEventAction by itself to mutate LADP data.
     *
     * In order to determine whether the mutation payload needs to be applied on the LADP data,
     * we take a snapshot of the ADP data and apply mutations on it first, and then compare this
     * with the current value in store.
     * - if they are same the LADP data property is not updated
     * - if different the new value is set on the LADP variable. This automatically notifies
     * listeners to re-fetch new data (via ko) and/or valueChange listener will automatically
     * apply the changes to the data observable.
     *
     * @param event
     * @throws error when event payload did not cause any mutation of the underlying data
     * @private
     */
    processExternalMutationEvent(event) {
      const adpValue = this.getValue();
      const dataValue = adpValue.data;
      const cloneDataValue = Utils.cloneObject(dataValue);
      const adm = new ArrayDataManager(this, cloneDataValue);

      const dataMutated = adm.applyDataMutations(event);
      if (!dataMutated && event.detail) {
        const errMsg = `Unable to dispatch the mutation event due to inadequate or inaccurate information to perform 
        the operation, ${JSON.stringify(event.detail, Utils.setToJSONReplacer, 2)}`;
        this.log.error(errMsg);
        throw errMsg; // so external callers can handle error appropriately. Example action raises failure outcome
      }

      // check if there were valid mutations
      const dataDiff = this.jsonDiffer.processDeltas(dataValue, cloneDataValue);
      const dataChanged = (dataDiff.detail.add || dataDiff.detail.update || dataDiff.detail.remove);

      // if there are then set the cloneDataValue as the new value of the LADP. This should
      // automatically fire the ko notifications and the valueChange listeners, which cause the
      // observables to be updated
      if (dataChanged) {
        this.data = cloneDataValue;
      } else {
        this.log.info('The mutation event did not affect the value of variable', this.name,
          'because its value before and after mutation is the same', cloneDataValue);
        return false;
      }
      return true;
    }

    /**
     * Processes a mutation event payload by updating the (JET ADP) data observable ** directly,
     * which automatically notifies subscribers (UI) of data changes. We don't dispatch an event to
     * super class because JET ADP subscribes to changes on data observable and this is how
     * changes are notified.
     *
     * ** Note: the reason being vb/LADP extends from JET ADP. In the future when we stop
     * extending from JET this method will raise the event instead.
     *
     * @param event
     * @private
     */
    dispatchMutationEvent(event) {
      const dataObs = ko.utils.unwrapObservable(this._dataObservable);
      this.adm = (this.adm && this.adm.reInit(dataObs)) || new ArrayDataManager(this, dataObs);
      const dataMutated = this.adm.applyDataMutations(event);

      if (!dataMutated) {
        this.log.error('Unable to dispatch the mutation event due to inadequate information',
          event);
        return false;
      }
      this._dataObservable.valueHasMutated();

      return dataMutated;
    }

    /**
     * Processes a refresh event.
     * @private
     */
    dispatchRefreshEvent(event) {
      this.log.info('Dispatching a REFRESH event, after data mutation');
      return super.dispatchEvent(event);
    }


    /**
     * called when the variable property is changed either directly or its expression
     * re-evaluates.
     *
     * @param e
     */
    handlePropertyVariableChangeEvent(e) {
      if (e.name.endsWith('value')) {
        if (e.diff) {
          // treat data changes differently from other properties because data changes fire just a mutation event
          if (e.diff.data) {
            this.detectDataChanged(e);
          } else {
            // we don't expect users to change itemType but of other props change by themselves then raise a refresh
            // event.
            const dispatchRefresh = ['detectSortComparatorsChanged', 'detectKeyAttributesChanged',
              'detectImplicitSortChanged', 'detectTextFilterAttributesChanged']
              .map((fn) => this[fn](e)).reduce((a, b) => a || b);
            if (dispatchRefresh) {
              this.log.info('Dispatching a REFRESH event because the ADP configuration', this.getId(),
                'changed', e);
              this.dispatchEvent(OJ_REFRESH_EVENT);
            }
          }
        }
      }
    }

    /**
     * Whether the sortComparators property value changed.
     * @param e onValueChanged event
     * @returns {boolean}
     * @private
     */
    detectSortComparatorsChanged(e) {
      const { value } = e;
      // generally when sortComparators change, ADP super may want to re-sort data in the UI.
      // TODO: it appears that function callbacks are not stored in redux and so when a comparator value changes
      //  from one callback to another there is not way of knowing that this changed because we get undefined as its
      //  value!
      if (e.diff && e.diff.sortComparators) {
        const oldSC = Utils.resolveIfObservable(this.options.sortComparators);
        const oldSCArrayValue = oldSC && Array.from(oldSC.comparators.entries());
        let newSCArrayValue;
        if (value.sortComparators && value.sortComparators.comparators) {
          const comps = value.sortComparators.comparators;
          newSCArrayValue = comps instanceof Map ? Array.from(comps.entries()) : comps;
          if (!checkArrayOfArrays(newSCArrayValue)) {
            this.log.error('value set for sortComparators property of ADP variable', this.id,
              'is invalid!', value.sortComparators);
          }
        }
        const diff = this.jsonDiffer.diffWithNoObjectHash(newSCArrayValue, oldSCArrayValue);
        if (diff) {
          this.options.sortComparators = { comparators: new Map(newSCArrayValue) };
          this.log.info('sortComparators property for ADP variable', this.id,
            'has changed! old value:', oldSC, 'new data:', value.sortComparators);
          return true;
        }
      }

      return false;
    }

    /**
     * Whether the implicitSort property value changed.
     * @param e onValueChanged event
     * @returns {boolean}
     * @private
     */
    detectImplicitSortChanged(e) {
      return this.detectArrayPropertyChanged(e, 'implicitSort');
    }

    /**
     * Whether the textFilterAttributes property value changed.
     * @param e onValueChanged event
     * @returns {boolean}
     * @private
     */
    detectTextFilterAttributesChanged(e) {
      return this.detectArrayPropertyChanged(e, 'textFilterAttributes');
    }

    detectArrayPropertyChanged(e, prop) {
      const { value } = e;

      if (value[prop] && value[prop].length > 0) {
        const oldPropValue = Utils.resolveIfObservable(this.options[prop]);
        const diff = this.jsonDiffer.diffWithNoObjectHash(value[prop], oldPropValue);
        if (diff) {
          this.options[prop] = value[prop];
          this.log.info(prop, 'property for ADP variable', this.id,
            'has changed! old value:', oldPropValue, 'new value:', value[prop]);
          return true;
        }
      }
      return false;
    }

    /**
     * Whether the keyAttributes property value changed.
     * @param e onValueChanged event
     * @returns {boolean}
     * @private
     */
    detectKeyAttributesChanged(e) {
      const { value } = e;
      const keyAttrsPropName = this.getIdAttributeProperty();
      const oldKeyAttrs = Utils.resolveIfObservable(this.options[keyAttrsPropName]);
      const newKeyAttrs = value[keyAttrsPropName];

      if (Utils.diff(oldKeyAttrs, newKeyAttrs)) {
        this.options[keyAttrsPropName] = newKeyAttrs;

        // reset the jsonDiffer
        this.jsonDiffer = new JsonDiffer(this.options[keyAttrsPropName]);
        this.log.info('Key attribute property for ADP variable', this.id,
          'has changed! old value:', oldKeyAttrs, 'new data:', newKeyAttrs);
        return true;
      }

      return false;
    }

    /**
     * This method is called when the data variable property mutates requiring the data observable to be updated as
     * well. Here we also check to see implicitSort, keyAttributes and sortComparators textFilterAttributes were
     * changed as well. We treat changes made to other properties along with data as a 'mutation'.
     * TODO: Though raising a 'mutation' event makes sense for 'implicitSort' and 'sortComparators', for
     * keyAttributes changing we may need to raise a refresh event, because keyAttributes changing invalidates the
     * data stored on ojADP!
     *
     * @param e
     * @private
     */
    detectDataChanged(e) {
      const { value } = e;
      const obsData = ko.utils.unwrapObservable(this._dataObservable);
      const data = (value && value.data) || [];
      let dispatchMutationEvent = false;

      // generally oldValue and the data observable are in sync when this listener is called (see
      // getVariablePropertyDefinition) but it's quite possible for them to get out-of-sync if there is no consumer
      // of the ADP (i.e., its value is never evaluated for them to be kept in sync).
      const eventPayload = this.jsonDiffer.processDeltas(obsData, data);
      const dataMutationRequired = (eventPayload.detail.add
      || eventPayload.detail.update || eventPayload.detail.remove);

      // Check if along with the data other props - keyAttributes, implicitSort, sortComparators, textFilterAttributes
      // have changed so that super properties can be updated before raising mutation event.
      if (dataMutationRequired) {
        this.detectSortComparatorsChanged(e);
        this.detectImplicitSortChanged(e);
        this.detectKeyAttributesChanged(e);
        this.detectTextFilterAttributesChanged(e);

        this.log.info('data property for ADP variable', this.id,
          'has changed! old data:', e.oldValue, 'new data:', data, '. The diff is', eventPayload);
        dispatchMutationEvent = true;
      }

      if (dispatchMutationEvent) {
        this.dispatchMutationEvent(eventPayload);
      }
    }
  }

  return ArrayDataProvider2;
});

