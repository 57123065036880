'use strict';

define('vb/extensions/dynamic/private/types/jsonMetadataProviderDescriptor',[
  'vb/extensions/dynamic/private/types/abstractMetadataProviderDescriptor',
  'vb/extensions/dynamic/private/types/bridges/jsonMetadataProviderFactoryBridge',
],
(AbstractMetadataProviderDescriptor, JsonMetadataProviderFactoryBridge) => {
  /**
   * A "metadata" variable for providing the metadata provider to JET dynamic forms/tables.
   * Provides ONLY the basic shaping metadata, based on JSON.
   *
   * Does NOT provide any dynamic layout functionality.
   */
  class JsonMetadataProviderDescriptor extends AbstractMetadataProviderDescriptor {
    /**
     * from the (resolved) declaration, create an 'options' structure that the FactoryMetadataProviderDescriptor
     * understands.
     * @param value
     * @returns {Promise<{factory: string, options: *}>|Promise<void>|Promise.<{factory: string, options: *}>}
     */
    createOptions(value) {
      return super.createOptions(value)
        .then((options) => {
          // the 'data'd defaults to an empty object, so remove it if we have a path and its empty
          if (options.path && options.data
            && Object.keys(options.data).length === 0
            && options.data.constructor === Object) {
            // remove it, JET providers don't like an empty object

            delete options.data; // eslint-disable-line no-param-reassign
          }
          return options;
        });
    }


    /**
     * @override
     * @returns {MetadataProviderFactoryBridge}
     */
    createFactoryBridge() {
      return new JsonMetadataProviderFactoryBridge();
    }


    /**
     * @override
     * @returns {{type: {path: string, data: string}}}
     */
    getTypeDefinition() {
      return {
        type: {
          path: 'string',
          data: 'object',
        },
      };
    }
  }

  return JsonMetadataProviderDescriptor;
});

