'use strict';

define('vb/private/stateManagement/packageContainerMixin',[], () => {
  /**
   * Mixin that should be used by all package containers.
   *
   * It adds custom resolution of modules imported by package containers.
   */
  const PackageContainerMixin = (superclass) => class extends superclass {
    /**
     * Utility method to load a module from package container, and log errors
     *
     * @param {Object} components
     * @param {Boolean} storeImport true when the module should be stored in this.imports
     * @return {Promise|undefined} a promise to load module if there are module to import
     * @private
     */
    importModule(components, storeImport) {
      // vx/ext-id/
      const baseUrl = this.baseUrl || '';
      // applications/app-id/
      const appUrl = (this.package && this.package.path) || '';
      if (baseUrl.length && appUrl.length) {
        const baseUrlPath = require.toUrl(baseUrl);
        const processedComponents = {};

        Object.keys(components).forEach((key) => {
          const loaderDef = components[key];
          const path = loaderDef && loaderDef.path && loaderDef.path.trim();
          if (path) {
            // need to convert local path to the app based path

            const localPath = `${baseUrl}${appUrl}${path}`;
            const localUrl = require.toUrl(localPath);
            // check if local path resolves into location within the extension
            // if localPath resolves into the same URL as resolved base + path it means no custom path mapping was found
            if (localUrl !== (baseUrlPath + appUrl + path)) {
              processedComponents[key] = { path: localPath };
            } else {
              // leave it as is
              processedComponents[key] = loaderDef;
            }
          }
        });
        return super.importModule(processedComponents, storeImport);
      }
      return super.importModule(components, storeImport);
    }
  };

  return PackageContainerMixin;
});

