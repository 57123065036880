'use strict';

define('vb/private/types/utils/serviceDataProviderRestHelper',[
  'knockout',
  'vb/private/constants',
  'vb/private/log',
  'vbc/private/logConfig',
  'vb/helpers/rest',
  'vb/private/services/transformsUtils',
  'vb/private/types/dataProviderConstants',
], (ko, Constants, Log, LogConfig, RestHelper, TransformsUtils, DPConstants) => {
  /**
   * Stores Endpoint runtime state. Stores any state for an endpoint identified by its scope:endpointId and that can
   * be shared across multiple usages of this endpoint loaded in a given scope. Currently there are 2 properties
   * that can be stored here -
   * - capabilities : capabilities by feature as define by JET DataProvider.
   * - totalSize: canonical totalSize
   * @type {{}}
   */
  const ENDPOINT_RT_STATE = {};
  const TOTALSIZE_KEY = 'totalSize';
  /**
   * Helper specifically used with SDP so it can control restHelper behavior.
   *
   * @see {RestHelper}
   */
  class ServiceDataProviderRestHelper extends RestHelper {
    static storeInCache(name, key, value) {
      ENDPOINT_RT_STATE[name] = ENDPOINT_RT_STATE[name] || {};
      const keyValue = ENDPOINT_RT_STATE[name][key];
      if (ko.isObservable(keyValue)) {
        keyValue(value);
      } else {
        ENDPOINT_RT_STATE[name][key] = value;
      }
    }

    static retrieveFromCache(name, key) {
      return (ENDPOINT_RT_STATE[name] && ENDPOINT_RT_STATE[name][key]);
    }

    /**
     * depending on capabilities not all transforms need to be run
     * @returns {*}
     */
    getRequestTransformsToRun(configuration) {
      if (configuration.fetchConfiguration) {
        const fetchCall = configuration.fetchConfiguration.capability;
        const fetchCallParams = configuration.fetchConfiguration.fetchParameters;
        if (fetchCall === 'fetchByKeys' && fetchCallParams.keys.size > 0
          && this.transformRequestFuncMap.fetchByKeys) {
          // if the transforms author defines a fetchByKeys function use it
          return ['fetchByKeys'];
        }
      }
      const transformFuncKeys = Object.keys(this.transformRequestFuncMap);
      // call select transform first and body last
      return transformFuncKeys
        .filter(TransformsUtils.excludeFetchByKeys)
        .sort(TransformsUtils.selectTransformFirst)
        .sort(TransformsUtils.bodyTransformLast); // to allow other transforms to update body
    }

    // eslint-disable-next-line class-methods-use-this
    storeState(name, key, value) {
      ServiceDataProviderRestHelper.storeInCache(name, key, value);
    }

    // eslint-disable-next-line class-methods-use-this
    retrieveState(name, key) {
      return ServiceDataProviderRestHelper.retrieveFromCache(name, key);
    }

    /**
     * Gets and stores the capabilities for the endpoint. This method should be called once per scope#endpoint
     * @param name
     * @return {Promise<*>} resolves with the capabilities or undefined
     */
    getAndStoreCapabilities(name) {
      let caps = {};
      let capsPromise = this.retrieveState(name, DPConstants.CAPABILITIES_KEY);
      if (!capsPromise) {
        capsPromise = new Promise((resolve) => {
          this._getEndpoint().then((endpoint) => {
            if (endpoint) {
              endpoint.getConfig(this.params, { ignoreMissingParams: true }).then((config) => {
                const endpointMetadata = endpoint.getMetadata();
                const configuration = {
                  url: config.url,
                  readOnlyParameters: this.parameters, // todo: parameters is a method, this doesn't look right,
                  endpointDefinition: endpointMetadata,
                  initConfig: {},
                };
                Object.assign(configuration.initConfig, this.initRequestMap);
                const transformsFuncs = endpoint.getMetadataTransforms();
                if (transformsFuncs) {
                  const func = transformsFuncs.capabilities;
                  if (func) {
                    caps = func.call(null, configuration);
                    ServiceDataProviderRestHelper.storeInCache(name, DPConstants.CAPABILITIES_KEY, caps);
                  }
                }
                const totalSize = ko.observable(-1);
                ServiceDataProviderRestHelper.storeInCache(name, TOTALSIZE_KEY, totalSize);

                resolve(caps);
              });
            } else {
              this.log.error('AbstractRestHelper', this.id, 'error fetching endpoint details',
                JSON.stringify(this.endpointReference));
              resolve(caps);
            }
          }).catch((e) => {
            this.log.error('AbstractRestHelper', this.id, 'error fetching full URL for endpoint',
              JSON.stringify(this.endpointReference), e);
            resolve(caps);
          });
        });
        ServiceDataProviderRestHelper.storeInCache(name, DPConstants.CAPABILITIES_KEY, capsPromise);
      }
      return capsPromise;
    }
  }

  return ServiceDataProviderRestHelper;
});

