'use strict';

define('vb/private/services/catalogRegistry',[
  'vb/private/log',
  'vb/private/utils',
  'vb/private/constants',
  'vb/private/services/serviceConstants',
  'vb/private/services/swaggerUtils',
  'urijs/URI',
], (Log, Utils, Constants, ServiceConstants, SwaggerUtils, URI) => {
  const logger = Log.getLogger('/vb/private/services/catalogRegistry');

  // if the transforms is defined on the catalog, it's better to resolve it's path now to avoid errors later.
  // What is happening here:
  // - the catalog path is typically something like 'services/catalog.json' and the transforms path something like
  //   './serviceName/transforms.js'
  // - The code is meant to resolve the relative transforms path against the catalog path, normalizing it.
  const adjustTransforms = (catalogPath, transformsObject) => {
    if (transformsObject) {
      if (transformsObject.path && transformsObject.path[0] === '.') {
        // eslint-disable-next-line no-param-reassign
        transformsObject.path = new URI(transformsObject.path, catalogPath).path();
      }

      if (transformsObject.originalPath && transformsObject.originalPath[0] === '.') {
        // eslint-disable-next-line no-param-reassign
        transformsObject.originalPath = new URI(transformsObject.originalPath, catalogPath).path();
      }
    }
  };

  // See CatalogRegistry.isVisibleExtension
  const isVisibleExtension = (extension, candidateId) => extension.id === candidateId
    || extension.getRequiredExtensions().some((ext) => isVisibleExtension(ext, candidateId));

  /**
   * A class to support multiple catalog.json (and potentially catalog-x.jsons in the future).
   *
   * All Catalogs will be registered with this class, and loaded by this class.
   * Catalog's are associated with their 'namespace', which is either 'base', or the extension ID.
   * There is always a 'base' catalog.json.
   *
   * Clients (CatalogHandler) will ask this for names of the services and backends for a given catalog,
   * or for all catalogs, in priority order.
   */
  class CatalogRegistry {
    constructor() {
      this.loadPromise = Promise.resolve();
      this.pending = [];
      this.catalogs = {};

      // Set by the application, enables the catalog to access extensions.
      this.extensionRegistry = null;
    }

    /**
     * Add a catalog.json path to the list of catalogs to be loaded, associated by namespace.
     *
     * At the next access to any of the public APIs below, any newly registered catalogs will be loaded.
     * @param path
     * @param name
     */
    register(name, path) {
      this.pending.push({ path, name });
    }

    /**
     * @todo: not initially implemented; we do not currently have a concept of a catalog 'extension'.
     */
    // registerExtension(path, name) {
    //   this.pending.push({ path, name });
    // }


    /**
     * Get a list of names, so we can programmatically iterate resolutions.
     * This is used by ExtensionServices to get names specific to its namespace (extension ID)
     *
     * @returns {Promise<{services: *, backends: *}>}
     *
     * @see ExtensionServices
     */
    getNames(namespace = CatalogRegistry.ROOT_CATALOG) {
      return this.get(namespace)
        .then((catalog) => ({
          backends: Object.keys(catalog.backends || {}),
          services: Object.keys(catalog.services || {}),
        }));
    }


    /**
     * Returns an array of objects describing the backend and service names for each catalog.
     *
     * @see getNames
     * example:
     * [{
     *   "namespace": "base",
     *   "backends": ["fa"],
     *   "services": ["crmRestApi"]
     * },
     * {
     *   "namespace": "extB",
     *   "backends": ["simplecatalog"],
     *   "services": ["simplecatalog"]
     * }]

     * @returns {Promise<{ namespace: {string}, backends: {Object[]}, services: {Object[]} }[] >}
     */
    getAllNames() {
      return this.getNamespaces()
        .then((names) => names.map((namespace) => ({
          namespace,
          backends: Object.keys(this.catalogs[namespace].backends || {}),
          services: Object.keys(this.catalogs[namespace].services || {}),
        })));
    }


    /**
     * used by getAllNames, returns all keys for the base, and any extension, catalogs.
     * Always includes 'base', may contain <extension Id> keys.
     *
     * @returns {Promise<string[]>}
     * @private
     */
    getNamespaces() {
      return this.loadPending()
        .then(() => Object.keys(this.catalogs));
    }


    /**
     * Used internally by getNames()
     * @param name
     * @returns {Promise<Object>}
     * @private
     */
    get(name = CatalogRegistry.ROOT_CATALOG) {
      return this.loadPending()
        .then(() => this.catalogs[name]);
    }


    /**
     * Loads the registered catalogs, that have not been loaded since thelast time this was called.
     * Allows catalogs to be added after the vb-protocol has been initialized / used.
     *
     * @returns {Promise<void>}
     */
    loadPending() {
      if (this.pending.length === 0) {
        return this.loadPromise;
      }
      // clear the pending list
      const pending = this.pending;
      this.pending = [];
      // ... and load
      this.loadPromise = Promise.resolve()
        .then(() => {
          const promises = pending.map(({ path, name }) => {
            if (this.catalogs[name]) {
              logger.warn('catalog already loaded, skipping', name, path);
              return null;
            }

            return this.load(path)
              .then((catText) => {
                try {
                  const catalog = SwaggerUtils.parseServiceText(catText);

                  if (catalog.backends) {
                    Object.keys(catalog.backends).forEach((backendId) => {
                      const backend = catalog.backends[backendId];
                      adjustTransforms(path, backend.transforms);
                    });
                  }

                  if (catalog.services) {
                    Object.keys(catalog.services).forEach((serviceId) => {
                      const service = catalog.services[serviceId];
                      const transforms = service.info && service.info['x-vb'] && service.info['x-vb'].transforms;
                      adjustTransforms(path, transforms);
                    });
                  }

                  this.catalogs[name] = catalog;
                } catch (e) {
                  // log it, but continue loading other catalogs
                  logger.error('Invalid JSON for catalog', name, path, e);
                }
              });
          });
          return Promise.all(promises);
        })
        .then(() => this.catalogs);

      return this.loadPromise;
    }

    /**
     *
     * @param path
     * @returns {Promise}
     */
    // eslint-disable-next-line class-methods-use-this
    load(path) {
      return Utils.getRuntimeEnvironment()
        .then((rtEnv) => rtEnv.getServiceExtensionCatalog(path));
    }

    /**
     * @param {string} extensionId
     * @param {string} candidateId
     * @return {boolean} true if 'extensionId' is the id of an existing extension and if 'candidateId' refers to either
     *                   base or to an extension that can be reached from the extension with 'extensionId' (i.e., if
     *                   this extension requires the one identified by 'candidateId').
     */
    isVisibleExtension(extensionId, candidateId) {
      if (this.extensionRegistry) {
        const extensionObject = this.extensionRegistry.extensions;
        if (extensionObject) {
          const extension = extensionObject[extensionId];
          if (extension) {
            if (candidateId === Constants.ExtensionNamespaces.BASE) {
              return true;
            }

            if (extensionId !== Constants.ExtensionNamespaces.BASE) {
              // The 'isVisibleExtension' invoked here is recursive. Also it doesn't need to obtain the extension
              // from the extensionId as done above.
              return isVisibleExtension(extension, candidateId);
            }
          }
        }
      }
      return false;
    }

    /**
     * @return {boolean} true if the 'extensionAccess' flag doesn't need to be consulted when deciding whether or not
     *                   a catalog object is visible in another extension.
     */
    ignoreExtensionAccessFlag(objectType) {
      return objectType === ServiceConstants.ExtensionTypes.BACKENDS
        && this.extensionRegistry
        && this.extensionRegistry.constructor.extensionManagerVersion === 'v1';
    }

    /**
     * @param {string} extensionId
     * @return {Array<Extension>} an array with the ids of the extensions whose catalog can be used by the extension
     *                            identified by the specified id.
     */
    getRequiredExtensionIds(extensionId) {
      if (this.extensionRegistry) {
        const extensionObject = this.extensionRegistry.extensions;
        if (extensionObject) {
          const extension = extensionObject[extensionId];
          if (extension) {
            return extension.getRequiredExtensions().map((ext) => ext.id);
          }
        }
      }
      return [];
    }

    /**
     *
     */
    // eslint-disable-next-line class-methods-use-this
    dispose() {
      this.extensionRegistry = null;
      this.loadPromise = null;
      this.catalogs = {};
    }
  }

  // an internal name for the base/root catalog
  CatalogRegistry.ROOT_CATALOG = Constants.CatalogNamespaces.BASE;


  return CatalogRegistry;
});

