'use strict';

define('vb/action/builtin/callChainAction',[
  'vb/action/action',
  'vb/private/action/actionChain',
  'vb/private/utils',
  'vb/private/log',
],
(Action, ActionChain, Utils, Log) => {
  /**
   * // TODO doc this, write tests
   */
  class CallChainAction extends Action {
    constructor(id, label) {
      super(id, label);
      this.log = Log.getLogger('/vb/action/action/CallChainAction');
      this.scopes = null;
      this.context = null;
    }

    perform(parameters) {
      return new Promise((resolve, reject) => {
        const actionId = parameters.id;
        const params = parameters.params;

        if (!actionId) {
          this.log.error('Required \'id\' parameter missing.');
          reject();
        }

        this.log.info('Calling action chain', actionId, 'with parameters:', params);
        ActionChain.startChain(actionId, params, this.scopes, this.context)
          .catch(reject)
          .then(resolve);
      });
    }

    setContext(scopes, context) {
      this.scopes = scopes;
      this.context = context;
    }
  }

  return CallChainAction;
});

