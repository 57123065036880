'use strict';

define('vb/private/vx/v1/localExtensionAdapter',[
  'vb/private/vx/baseExtensionAdapter',
  'vb/private/utils',
], (BaseExtensionAdapter, Utils) => {
  /**
   * The extension adapter used to load extension from local file
   */
  class LocalExtensionAdapter extends BaseExtensionAdapter {
    /**
     * Return a promise to load the manifest given the extensionId.
     * @param  {String} applicationId the id of the application for which we load an extension
     * @return {Promise<Object>} a promise that resolves to the manifest
     */
    loadManifest(applicationId) {
      return Promise.resolve().then(() => {
        if (!applicationId) {
          throw new Error('Cannot load extension, application id is not defined');
        }

        this.log.info('Loading extensions from local file', this.registryUrl,
          'using extension manager v1.');

        return Utils.loadAndParse(this.registryUrl).then((registry) => {
          const reg = registry.extensions || [];
          const ref = {};
          const promises = [];

          // Reference the ui part and build the promises
          reg.forEach((ext) => {
            ref[ext.id] = ext.ui;
            promises.push(Utils.loadAndParse(`${ext.ui.baseUrl}/manifest.json`));
          });

          // Load all the vb-extension.json and insert the missing ui.baseUrl
          return Promise.all(promises).then((extensions) => {
            extensions.forEach((ext) => {
              // Insert the ui.baseUrl part in the extension
              ext.ui = {
                baseUrl: ref[ext.id].baseUrl,
              };
            });

            return { extensions };
          });
        });
      });
    }
  }

  return LocalExtensionAdapter;
});

