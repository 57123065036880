'use strict';

define('vb/private/pwa/jetCache',[
  'ojs/ojcontext',
  'ojs/ojthemeutils',
  'vb/private/utils',
  'vbc/private/pwa/pwaUtils',
  'vbc/private/log',
  'vbc/private/monitorOptions',
], (ojContext, ThemeUtils, Utils, PwaUtils, Log, MonitorOptions) => {
  const logger = Log.getLogger('/vb/private/pwa/jetCache');

  const JET_THEME_REDWOOD = 'redwood';
  const WEB_TARGET_PLATFORM = 'web';
  const ALL_TARGET_PLATFORM = 'all';
  const JET_THEMES = [
    'alta-web',
    'alta-ios',
    'alta-android',
    'alta-windows',
    `${JET_THEME_REDWOOD}-${ALL_TARGET_PLATFORM}`];
  const JET_RESOURCES_PATH = 'metadata/components/jetResources.json';
  const JET_BUNDLES_CONFIG = 'default/js/bundles-config';

  //
  // Cache bundles specified in https://static.oracle.com/cdn/jet/v7.2.0/default/js/bundles-config-debug.js
  // that would not get cached as a result of component imports, because they are required by every VB
  // application. These get loaded early during main.init() before SW is registered, so these requests
  // cannot be intercepted by pwaCacheStrategy at that time. But by the time jetCache is called,
  // pwaCacheStrategy will intercept these requests and add them to the cache.
  //
  const JET_MIN = [
    'default/js/min/oj3rdpartybundle.js', // https://jira.oraclecorp.com/jira/browse/BUFP-30744
    'default/js/min/ojcorebundle.js', // https://jira.oraclecorp.com/jira/browse/BUFP-30744
    '3rdparty/require/require.js',
  ];

  //
  // Even though OPT (Offline Persistence Toolkit) is mainly used by the Service Worker thread, and it is loaded
  // by the Service Worker, it needs to be cached because it is required by app-flow.js, where OfflineHandler
  // is defined. And app-flow.js is also loaded by the main thread. So when the page is reloaded offline,
  // it won't load unless everything required by app-flow.js is in the cache.
  // In addition, OPT cannot benefit from cache on fetch because app-flow.js is loaded before SW is registered.
  // More general, everything that app references via require should be cached. Even though RT supports it
  // via appShellCache.json, there is no support, currently, to edit this file in DT.
  //
  const OPT_MIN = [
    '3rdparty/persist/min/offline-persistence-toolkit-arraystore-',
    '3rdparty/persist/min/offline-persistence-toolkit-core-',
    '3rdparty/persist/min/offline-persistence-toolkit-pouchdbstore-',
    '3rdparty/persist/min/offline-persistence-toolkit-localstore-',
    '3rdparty/persist/min/offline-persistence-toolkit-filesystemstore-',
    '3rdparty/persist/min/offline-persistence-toolkit-responseproxy-',
  ];

  //
  // OPT version is expected to be in a format of numbers separated by dots, for example: `1.2.9`
  // The require url could have other numbers in it, such as jet version. For example:
  // https://static.oracle.com/cdn/jet/v7.1.0/3rdparty/persist/min/offline-persistence-toolkit-core-1.2.9
  //
  // In the regular expression \d+(\.\d+)+$:
  // \d+ matches a digit (equal to [0-9])
  // + Quantifier — Matches between one and unlimited times, as many times as possible, giving back as needed (greedy)
  // 1st Capturing Group (\.\d+)+
  // + Quantifier — Matches between one and unlimited times, as many times as possible, giving back as needed (greedy)
  // $ asserts position at the end of the string, or before the line terminator right at the end of the string (if any)
  //
  const OPT_VERSION_REGEX = /\d+(\.\d+)+$/;
  const JS_MIN_REGEX = /js\/min/i;
  const OPT_MIN_REGEX = /persist\/min/i;
  const JS_DEBUG = 'js/debug';
  const OPT_DEBUG = 'persist/debug';
  const CACHE_JET_MONITOR = 'cacheJet';
  const JET_CACHING_MESSAGE = 'Jet caching';

  /**
   * This class is responsible for preloading all the JET resources required by the application. The actual caching
   * is done by the Service Worker, which is configured to recognize JET requests and adding them to the cache
   * as part of fetch processing.
   */
  class JetCache {
    /**
     * After the page loads, fetch all the jet resources required by an application.
     * @returns {Promise} a Promise to cache JET resources
     */
    static cacheJetWhenPageLoads() {
      const busyContext = ojContext.getPageContext().getBusyContext();
      let totalTime;
      return busyContext.whenReady()
        .then(() => {
          const mo = new MonitorOptions(CACHE_JET_MONITOR, JET_CACHING_MESSAGE);
          return logger.monitor(mo, (finish) => {
            totalTime = finish;
            const vbConfig = window.vbInitConfig;
            if (vbConfig) {
              const shellPath = vbConfig.SERVICE_WORKER_CONFIG && vbConfig.SERVICE_WORKER_CONFIG.shellPath;
              const jetPath = `${vbConfig.JET_CDN_PATH}${vbConfig.JET_CDN_VERSION}/`;
              return JetCache.cacheJet(shellPath, jetPath, vbConfig.DEBUG)
                .then((jetCachePromiseResult) => {
                  logger.info('PWA: cacheJetWhenPageLoads() succeeded', totalTime());
                  return jetCachePromiseResult;
                });
            }
            logger.info('PWA: cacheJetWhenPageLoads() no config found', totalTime());
            return undefined;
          });
        })
        .catch((error) => {
          // If JET caching fails, there is not much we can do
          logger.error(error);
          logger.info('PWA: cacheJetWhenPageLoads() failed', totalTime(error));
        });
    }

    /**
     * Performs a fetch for specified JET resources in order to force caching.
     * @param resources an array JET resources to fetch
     * @param jetPath JET cdn location, for example: <i>https://static.oracle.com/cdn/jet/v7.0.0/</i>
     * @param debug flag indicates whether minified JET scripts should be cached
     * @returns {Promise<>} a promise to fetch JET resources
     */
    static fetchJetResources(resources = [], jetPath = '', debug) {
      return Promise.resolve()
        .then(() => {
          const files = [];
          const jetCdn = PwaUtils.postSlash(jetPath);
          let customJet = resources;
          if (debug) {
            customJet = resources.map((f) => f.replace(JS_MIN_REGEX, JS_DEBUG)
              .replace(OPT_MIN_REGEX, OPT_DEBUG));
          }
          files.push(...customJet.map((f) => `${jetCdn}${f}`));
          const fetchPromises = files.map((f) => fetch(f)
            .then((response) => {
              // opaque responses result in status = 0;
              if (!(response.ok || response.type === 'opaque')) {
                logger.error(`Fetch error for ${f.url}`);
              }
              return response;
            }));
          return Promise.all(fetchPromises);
        });
    }

    /**
     * If an application is using one of the supported JET themes, theme name will be returned. If an application
     * is using a custom theme, returns undefined.
     * @returns {undefined|*} JET theme or undefined
     */
    static getTheme() {
      const themeName = ThemeUtils.getThemeName();
      const themeTargetPlatform = ThemeUtils.getThemeTargetPlatform();
      const jetTheme = `${themeName}-${themeTargetPlatform}`;
      if (JET_THEMES.includes(jetTheme)) {
        // for a web platform or redwood, theme name should be alta (or redwood), not alta-web
        return (themeTargetPlatform === ALL_TARGET_PLATFORM || themeTargetPlatform === WEB_TARGET_PLATFORM)
          ? themeName
          : jetTheme;
      }
      // for custom themes, return undefined since these themes won't be available on JET CDN
      return undefined;
    }

    /**
     * @param {String} jetPath the location of the jet resources
     * @returns {Array} an array of theme based resources required by an application, or an empty array if an
     * application is using a custom theme
     */
    static getThemeBasedResources(jetPath) {
      return fetch(`${jetPath}${JET_RESOURCES_PATH}`)
        .then((response) => response.json())
        .then((jsonContent) => jsonContent.jetResources[JetCache.getTheme()])
        .catch(() => {
          logger.warn(`PWA: getThemeBasedResources() fetch failed for JET meta-data - ${jetPath}${JET_RESOURCES_PATH}`);
          return [];
        });
    }

    static getOptModulesList() {
      return OPT_MIN;
    }

    /**
     * @returns {undefined|*} version of offline persistence toolkit (OPT) from require path mapping.
     */
    static getOptVersion() {
      //
      // require.toUrl('persist/persistenceManager') should resolve to the path for core OPT module specified
      // in JET's bundles-config. For example,
      // https://static.oracle.com/cdn/jet/v7.1.0/3rdparty/persist/min/offline-persistence-toolkit-core-1.2.9
      //
      // If, however, a custom OPT version is required, require.toUrl cannot be used.
      //
      const optCoreBundlePath = require.toUrl('persist/persistenceManager');
      const optVersionMatch = optCoreBundlePath.match(OPT_VERSION_REGEX);
      if (optVersionMatch) {
        return optVersionMatch[0];
      }
      logger.warn(`PWA: getOptResources() no matching OPT version found in ${optCoreBundlePath}`);
      return undefined;
    }

    static getOptResources() {
      let opt = [];
      const optVersion = JetCache.getOptVersion();
      if (optVersion) {
        opt = JetCache.getOptModulesList().map((f) => `${f}${optVersion}.js`);
      }
      return opt;
    }

    /**
     *  Cache JET bundles and files required by a VB application. This includes:
     * - require.js
     * - bundles-config.js (or bundles-config-debug.js)
     * - JET modules specified in appShellCache.json
     * - JET resources and theme based resources specified in appShellCache.json
     *
     * @param shellPath a location of appShellCache.json file, relative to SW scope. For example:
     * <i>./mobileApps/testApp/version_1552430187000/appShellCache.json</i>
     * @param jetPath JET cdn location, for example: <i>https://static.oracle.com/cdn/jet/v7.0.0/</i>
     * @param debug flag indicates whether minified JET scripts should be cached
     * @returns {Promise<any>} a promise to cache JET resources required by an application or an empty promise if
     *  application is offline or shellPath is not specified.
     */
    static cacheJet(shellPath, jetPath, debug = false) {
      if (!PwaUtils.isOnline() || !PwaUtils.isPwaShellPath(shellPath)) {
        return Promise.resolve();
      }
      logger.info('PWA: cacheJet()');
      return Promise.resolve()
      //
      // As specified in vbInitConfig, shellPath is relative to SW scope, for example:
      // https://vbmasterdev-vbcsqatest.uscom-central-1.c9dev1.oc9qadev.com/ic/builder/rt/xyz/1.0/
      // But when fetching from the UI thread, requests are relative to web app:
      // https://vbmasterdev-vbcsqatest.uscom-central-1.c9dev1.oc9qadev.com/ic/builder/rt/xyz/1.0/mobileApps/abc/
      //
      // So this file needs to be fetched relative to SW scope.
      //
        .then(() => PwaUtils.getServiceWorkerScope())
        .then((scope) => {
          // empty scope is fine
          if (scope || scope === '') {
            const path = `${scope}${shellPath}`;
            logger.info(`PWA: cacheJet() loading shell cache from ${path}`);
            return PwaUtils.loadAppShellFile(path, fetch);
          }
          logger.warn('PWA: cacheJet() no service worker');
          return undefined;
        })
        .then((appShell) => {
          if (appShell) {
            return JetCache.getThemeBasedResources(jetPath).then((themeResources) => {
              // JET modules & their dependencies will be added to the cache as a result of calling require.
              // Any fetch requests resulting from require calls will be intercepted by SW and cached.
              // However, since these modules were potentially loaded already, and require won't issue a fetch request
              // once a module has been loaded, they might not result in cache updates
              //
              // Calling requirejs.undef does not solve the problem because dependencies don't get undefed.
              // For example, undefining ojs/ojvalidation-datetime
              // does not nuke https://static.oracle.com/cdn/jet/v8.1.0/default/js/min/ojvalidator-daterestriction.js
              // and https://static.oracle.com/cdn/jet/v8.1.0/default/js/min/ojconverter-datetime.js
              const jetCachePromises = [];
              jetCachePromises.push(Utils.getResources(appShell.jetModules));
              const jetResources = [];
              jetResources.push(...JET_MIN);
              jetResources.push(...JetCache.getOptResources());
              jetResources.push(`${JET_BUNDLES_CONFIG}${debug ? '-debug' : ''}.js`);

              if (Array.isArray(themeResources)) {
                jetResources.push(...themeResources);
              }
              jetCachePromises.push(JetCache.fetchJetResources(jetResources, jetPath, debug));
              return Promise.all(jetCachePromises);
            });
          }
          return undefined;
        });
    }
  }

  return JetCache;
});

