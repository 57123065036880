'use strict';

define('vb/extensions/dynamic/private/types/abstractMetadataProviderDescriptor',[
  'vb/private/types/metadataDescriptor',
  'vb/private/types/factoryMetadataProviderDescriptor',
],
(MetadataDescriptor, FactoryMetadataProviderDescriptor) => {
  /**
   *
   */
  class AbstractMetadataProviderDescriptor extends FactoryMetadataProviderDescriptor {
    /**
     *
     * @param id
     * @param variableDef
     * @param value
     * @param container
     * @return{Promise}
     */
    init(id, variableDef, value, container) {
      return Promise.resolve()
        .then(() => this.createOptions(value, container))
        .then((options) => {
          // make sure our bridge is created
          this.getFactoryBridge();

          const factoryDeclaration = {
            options,
          };
          return super.init(id, variableDef, factoryDeclaration, container);
        });
    }

    /**
     * from the (resolved) declaration, create an 'options' structure that the FactoryMetadataProviderDescriptor
     * understands.
     * @param value
     * @returns {Promise<{factory: string, options: *}>|Promise<void>|Promise.<{factory: string, options: *}>}
     */
    // eslint-disable-next-line class-methods-use-this
    createOptions(value) {
      const options = Object.assign({}, MetadataDescriptor.resolveExpressions(value));

      return Promise.resolve(options);
    }


    /**
     * no need to override, sets 'this.bridge'. subclasses should override createFactoryBridge()
     * @returns {MetadataProviderFactoryBridge}
     */
    getFactoryBridge() {
      if (!this.bridge) {
        this.bridge = this.createFactoryBridge();
      }
      return this.bridge;
    }

    /**
     * return the VB factory implementation that will act as a bridge to the JET factory
     * subclasses must override
     */
    // eslint-disable-next-line class-methods-use-this
    createFactoryBridge() {
      throw new Error('do not use directly, createFactoryBridge() must be implemented');
    }

    /**
     * return the VB type definition
     * subclasses must override
     */
    // eslint-disable-next-line class-methods-use-this
    getTypeDefinition() {
      throw new Error('do not use directly, getTypeDefinition() must be implemented');
    }

    /**
     * @override from FactoryMetadataProviderDescriptor, normally uses the 'factory' property.
     * @returns {Promise}
     */
    // eslint-disable-next-line no-unused-vars
    getProviderFactory(factoryPath) {
      return Promise.resolve(this.getFactoryBridge());
    }

    /**
     *
     * duck-types createMetadataProvider from JET factory interface.
     * this is invoked when the Variable type is being used as the "factory" parameter
     * for a FactoryMetadataProviderDescriptor.
     *
     * The FMPD uses this class as a factory, and this method in turns creates a 'bridge' factory if it hasn't been
     * created already, and then delegates creation of the MetadataProvider.
     *
     * The bridge always translated VB-specific options to JET ones.
     *
     * @param options
     * @return {Promise}
     */
    createMetadataProvider(options) {
      // make sure our bridge is created
      return this.getFactoryBridge().createMetadataProvider(options);
    }

    dispose() {
      super.dispose();
      if (this.bridge) {
        this.bridge.dispose();
      }
    }
  }

  return AbstractMetadataProviderDescriptor;
});

