'use strict';

define('vb/private/stateManagement/context/variableBridge',['vb/private/stateManagement/stateUtils'], (StateUtils) => {
  /**
   * set of properties to expose in $base for page extension
   */
  class VariableBridge {
    constructor(scope, instance) {
      this.instanceFactoryTypeInstance = instance;
      this.scope = scope;
      this.scopeResolver = scope.container.scopeResolver;
    }

    /**
     * Returns the type definition exploded.
     */
    getType(description, type) {
      return StateUtils.getType(description, { type }, this.scopeResolver);
    }

    invokeEvent(eventName, eventPayload /* withBubbling = true */) {
      return this.scope.container.invokeEventWithBubbling(eventName, eventPayload);
    }

    callActionChain(id, params) {
      return this.scope.container.callActionChain(id, params);
    }

    activateAsync() {
      if (this.instanceFactoryTypeInstance && typeof this.instanceFactoryTypeInstance.activateAsync === 'function') {
        return this.instanceFactoryTypeInstance.activateAsync();
      }
      return Promise.resolve();
    }
  }

  return VariableBridge;
});

