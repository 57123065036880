'use strict';

define('vb/private/types/capabilities/fetchByOffsetIteration',[
  'vb/private/log',
  'vb/private/constants',
  'vb/private/types/dataProviderConstants',
  'vb/private/utils',
  'vb/private/types/capabilities/fetchContext',
  'vb/private/types/capabilities/fetchByOffsetUtils',
  'vb/private/types/capabilities/fetchFirst',
  'vbc/private/monitorOptions'],
(Log, Constants, DPConstants, Utils, FetchContext, FetchByOffsetUtils, FetchFirst, MonitorOptions) => {
  const FETCH_BY_OFFSET_CAPABILITY = 'fetchByOffset';

  // for list of supported fetch list parameters - oj.FetchListParameters
  // we intentionally skip offset because this uses the fetchFirst capability that always
  // starts at 0.
  const FETCH_BY_OFFSET_ITERATION_PARAMS = ['size', 'sortCriteria', 'filterCriterion'];

  /**
   * fetchByOffset data provider capability types supported
   */
  const FetchByOffsetCapability = {
    FETCH_BY_OFFSET_ITERATION: 'iteration',
  };

  /**
   * Implements the iteration based FetchByOffset implementation. This uses the fetchFirst iteration
   * based implementation but where the SDP is optimized for 'fast' lookups, by fetching
   * more rows for every iteration, so key lookups are faster.
   */
  class FetchByOffsetIteration extends FetchFirst {
    /**
     * constructor
     *
     * @param sdp
     * @param params {Object} see oj.FetchByOffsetParameters.
     */

    /**
     * List of fetch parameters provided in the fetch call.
     * @returns {[string,string,string]}
     */
    getFetchParams() {
      return FETCH_BY_OFFSET_ITERATION_PARAMS;
    }

    /**
     * The fetch capability this class implements by default. Subclasses must override this
     * method.
     * @returns {string}
     */
    getFetchCapability() {
      return DPConstants.CapabilityType.FETCH_BY_OFFSET;
    }

    /**
     * overridden so multiple iterations can be made to locate the keys items and and the
     * response packed in a form FetchByOffset contract expects.
     * @returns {Promise}
     */
    fetch() {
      const uniqueId = `${this.sdp.id} [${this.id}]`;
      const cap = this.sdp.getCapability(FETCH_BY_OFFSET_CAPABILITY);

      const isImplementationIteration = function () {
        return cap && cap.implementation === FetchByOffsetCapability.FETCH_BY_OFFSET_ITERATION;
      };

      if (isImplementationIteration()) {
        return Promise.resolve().then(() => {
          const { sdp } = this;
          const { fetchOptions } = this;

          sdp.log.startFetch('ServiceDataProvider', uniqueId,
            'fetchByOffset using iteration, with options', JSON.stringify(fetchOptions),
            'and state:', this.sdpState);
          const mo = new MonitorOptions('sdp.fetchByOffsetIteration', uniqueId);
          return sdp.log.monitor(mo, (fetchTime) => {
            const fetchAsyncIterator = super.fetch()[Symbol.asyncIterator]();
            return this.optimizedFetch(fetchAsyncIterator).then((result) => {
              const fetchByOffsetResult = {
                fetchParameters: this.originalFetchOptions,
                results: result.results,
                done: result.done,
              };

              sdp.log.endFetch('ServiceDataProvider', uniqueId,
                'fetchByOffset using iteration succeeded with result:', fetchByOffsetResult, fetchTime());

              return (fetchByOffsetResult);
            }).catch((err) => {
              // super class would have fired a notification event already. no need to repeat here.
              sdp.log.endFetch('ServiceDataProvider', uniqueId,
                'fetchByOffset using iteration failed with error:', err, fetchTime(err));

              throw (err);
            });
          });
        });
      }

      // we should never get here because SDP will never call this class for iteration based
      // lookups
      const err = `ServiceDataProvider ${uniqueId}: FetchByOffset iteration based implementation ` +
        `cannot be used for ${cap.implementation} based implementation! Check your configuration!`;
      this.log.error(err);
      return Promise.reject(err);
    }

    /**
     * Calls next() until all the requested keys are all found, or when we are done iterating.
     */
    optimizedFetch(asyncIterator, rm, fetched) {
      const resultArray = rm || [];
      let rowsFetched = fetched || 0;
      let limit;

      return asyncIterator.next().then((iteratorResult) => {
        const { done } = iteratorResult;
        limit = limit || this.getIterationLimit();
        const { value } = iteratorResult;
        const { data } = value;
        const dataLen = data.length || 0;
        const { metadata } = value;
        const offset = (this.originalFetchOptions && this.originalFetchOptions.offset);
        const { size } = this.fetchOptions;

        if (offset < (rowsFetched + dataLen)) {
          const start = (offset <= rowsFetched) ? 0 : (offset - rowsFetched);
          for (let index = start; index < dataLen; index++) {
            if (resultArray.length === size) {
              break;
            }
            resultArray.push({ metadata: metadata[index], data: data[index] });
          }
        }
        rowsFetched += dataLen;
        if (resultArray.length < size && !done) {
          if (limit !== -1 && rowsFetched >= limit) {
            // we have reached the limit, just return the results
            return { done, results: resultArray };
          }
          return this.optimizedFetch(asyncIterator, resultArray, rowsFetched);
        }
        return { done, results: resultArray };
      });
    }
  }

  return FetchByOffsetIteration;
});

