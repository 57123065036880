'use strict';

define('vb/private/debug/actionChainDebugStream',['vb/private/debug/debugStream', 'vb/private/utils', 'vb/private/debug/constants',
  'vb/private/constants', 'vb/binding/expression'],
(DebugStream, Utils, DebugConstants, Constants, Expression) => {
  /**
   * Debug stream for an action chain.
   */
  class ActionChainDebugStream extends DebugStream {
    constructor(actionChain) {
      super(DebugConstants.DebuggeeType.ACTION_CHAIN);

      // the action being debugged
      this.actionChain = actionChain;

      // the accumulated content of the stream
      this.content = {
        type: 'actionChainExecution',
        chainId: actionChain.id,
        execId: this.id,
        $chain: {
          actions: [],
        },
      };
    }

    /**
     * Set the locator used to look up the chain diagram on the debugger side. The container locator
     * is the url used by requirejs to load the container descriptor file.
     *
     * @param containerLocator the locator which is the url used to load the container descriptor
     */
    setChainLocator(containerLocator) {
      // append the action chain id to the the container locator
      this.chainLocator = `/${containerLocator}/${this.actionChain.id}/`;
    }

    /**
     * Clone the current variable state in $application, $package, $flow, $page and $chain.
     */
    cloneState() {
      const scopes = this.actionChain.availableContexts;
      const state = ActionChainDebugStream.cloneScopes(scopes);

      const { $base } = scopes;
      if ($base) {
        state.$base = ActionChainDebugStream.cloneScopes($base, true);
      }

      return state;
    }

    static cloneScopes(scopes, strip$) {
      const state = {};

      ['$chain', '$layout', '$page', '$flow', '$application', '$global'].forEach((name) => {
        const scopeName = strip$ ? name.substring(1) : name;
        const scope = scopes[scopeName];

        if (scope) {
          state[scopeName] = {
            variables: DebugStream.cloneObject(scope.variables),
            constants: DebugStream.cloneObject(scope.constants),
          };
        }
      });

      return state;
    }

    /**
     * Suspend the action if we have reached a breakpoint and met the breakpoint condition if set.
     *
     * @param action the action to suspend
     * @returns {*}
     */
    suspendIfBreakpointReached(action) {
      if (this.isDebuggerInstalled) {
        const breakpointReached = Object.keys(this.breakpoints)
          .some((key) => {
            const bp = this.breakpoints[key] || {};

            // return true if there is a breakpoint set for this action and meets the condition if set
            return action.id.startsWith(`${key}_`) && bp.isSet && this.evalCondition(bp.condition);
          });

        if (!this.runUntilFinish && (this.stopAtNextAction || breakpointReached)) {
          this.stopAtNextAction = false;

          const actionState = this.content.$chain.actions.find(element => element.id === action.id);

          // inform the debugger that a breakpoint is reached
          this.fireStateChanged(DebugConstants.DebugState.ACTION_SUSPENDED, actionState);

          // suspend the action
          return this.suspend();
        }
      }

      return Promise.resolve();
    }

    /**
     * Evaluation the expression for the breakpoint condition.
     *
     * @param condition expression for the breakpoint condition
     * @returns {boolean}
     */
    evalCondition(condition) {
      if (condition) {
        try {
          return condition ? Expression.createFromString(condition, this.actionChain.availableContexts)() : true;
        } catch (err) {
          return false;
        }
      }

      return true;
    }

    /**
     * Log the starting state of the action chain.
     */
    start() {
      if (this.isEnabled) {
        // log the context before the chain is executed
        this.content.$chain.beforeContext = this.cloneState();

        return this.registerDebuggee()
          .then(() => this.fireStateChanged(DebugConstants.DebugState.CHAIN_STARTING));
      }

      return Promise.resolve();
    }

    /**
     * Log the starting state of the given action.
     *
     * @param action the action to log
     * @param parameters the parameters for the action
     */
    actionStart(action, parameters) {
      if (this.isEnabled) {
        const actionState = {
          id: action.id,
          parameters: DebugStream.cloneObject(parameters),
          beforeContext: this.cloneState(),
        };

        this.content.$chain.actions.push(actionState);

        return this.fireStateChanged(DebugConstants.DebugState.ACTION_STARTING, actionState)
          .then(() => this.suspendIfBreakpointReached(action))
          .then(() => {
            actionState.startTime = new Date().getTime();
          })
          .then(() => this.fireStateChanged(DebugConstants.DebugState.ACTION_RUNNING, actionState));
      }

      return Promise.resolve();
    }

    /**
     * Log the ending state of the given action.
     *
     * @param action the action to log
     * @param outcome the outcome of the action
     */
    actionEnd(action, outcome) {
      if (this.isEnabled) {
        const endTime = new Date().getTime();
        const actionState = this.content.$chain.actions.find(element => element.id === action.id);

        if (actionState) {
          actionState.outcome = DebugStream.cloneObject(outcome);
          actionState.afterContext = this.cloneState();
          actionState.completionTime = `${endTime - actionState.startTime} ms`;
        }

        this.fireStateChanged(DebugConstants.DebugState.ACTION_FINISHED, actionState);
      }
    }

    /**
     * Log the ending state of the action chain.
     */
    end() {
      if (this.isEnabled) {
        this.content.$chain.afterContext = this.cloneState();

        // wrap the results in $chain so it can be used for evaluating expect statements
        this.content.$chain.results =
          this.actionChain[Constants.VariableNamespace.VARIABLES][Constants.RESULTS_VARIABLE_KEY];

        this.fireStateChanged(DebugConstants.DebugState.CHAIN_FINISHED);
      }
    }


    /**
     * Suspend the debug stream until resume is called.
     *
     * @returns {Promise|null}
     */
    suspend() {
      if (this.isDebuggerInstalled) {
        if (!this.suspendPromise) {
          this.suspendPromise = new Promise((resolve) => {
            this.resumeResolver = resolve;
          });
        }

        return this.suspendPromise;
      }

      return Promise.resolve();
    }

    /**
     * Resume the debug stream.
     */
    resume() {
      if (this.resumeResolver) {
        this.resumeResolver();

        this.suspendPromise = null;
      }
    }

    // the following methods make calls to the debugger

    /**
     * Register this debug stream as a debuggee with the debugger.
     *
     * @returns {*}
     */
    registerDebuggee() {
      if (this.isDebuggerInstalled) {
        return super.registerDebuggee({ chainLocator: this.chainLocator });
      }

      return Promise.resolve();
    }

    // the following methods are called by the debugger

    /**
     * Called by the debugger to update the breakpoints.
     *
     * @param breakpoints updated breakpoints
     */
    updateBreakpoints(breakpoints) {
      this.breakpoints = breakpoints || {};
    }

    /**
     * Called by the debugger to continue execution until the next breakpoint is reached.
     */
    continue() {
      this.resume();
    }

    /**
     * Called by the debugger to continue execution and break at the next action.
     */
    step() {
      if (this.suspendPromise) {
        this.stopAtNextAction = true;
        this.resume();
      }
    }

    /**
     * Called by the debugger to finish execution of the chain without breaking.
     */
    finish() {
      if (this.suspendPromise) {
        this.runUntilFinish = true;
        this.resume();
      }
    }

    /**
     * Resume suspended action when the debugger is uninstalled.
     */
    debuggerUninstalled() {
      this.resume();
      super.debuggerUninstalled();
    }
  }

  return ActionChainDebugStream;
});

