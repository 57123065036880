'use strict';

define('vb/private/stateManagement/containerRjsInitMixin',[
  'vb/private/configLoader',
], (ConfigLoader) => {
  /**
   * Mixin that should be used by all containers that allow requirejs segment in their definition.
   *
   * It loads requirejs configuration after the container is loaded.
   */
  const ContainerRjsMixin = (superclass) => class extends superclass {
    /**
     * Override, to support "requirejs" declaration.
     *
     * @returns {*}
     * @override
     */
    load() {
      return super.load()
        .then(() => this.initializeRequirejsMappings());
    }

    /**
     * For "requirejs" declaration, allow adding new "paths" to requireJS.
     */
    initializeRequirejsMappings() {
      const requirejsDecl = this.definition && this.definition.requirejs;
      if (requirejsDecl) {
        ConfigLoader.addRequirejsConfigFromContainer(this);
      }
    }
  };

  return ContainerRjsMixin;
});

