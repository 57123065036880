// eslint-disable-next-line max-classes-per-file
/* eslint max-classes-per-file: ["error", 4] */

'use strict';

define('vb/private/types/capabilities/fetchByOffsetUtils',[
  'vb/private/log',
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/types/dataProviderConstants',
  'vb/private/types/utils/dataProviderUtils'],
(Log, Constants, Utils, DPConstants, DataProviderUtils) => {
  /**
   * Duck types ItemMetadata.
   */
  class FetchByOffsetResultItemMetadata {
    constructor(key) {
      this.key = key;
    }
  }

  /**
   * A class that duck types oj.Item that is set on the FetchByOffsetItemResult which
   * essentially is of the form
   * {
   *   fetchParameters: oj.FetchByParameters,
   *   results: [
   *     data: {},
   *     metadata: {
   *       key: any
   *     }
   *   ]
   * }
   */
  class FetchByOffsetItemResult {
    constructor(data, metadata) {
      this.data = data;
      this.metadata = metadata;
    }
  }

  class FetchByOffsetResult {
    constructor(done, params, results) {
      this.done = done;
      this.fetchParameters = params;
      this.results = results;
    }
  }

  /**
   * Object that implements the FetchByOffset contract, both making a fetch call and building
   * results expected by callers of fetchByOffset. See JET oj.DataProvider for details.
   */
  class FetchByOffsetUtils {
    /**
     * Build the final result expected - oj.FetchByOffsetResult
     * @param sdpState has value and defaultValue properties
     * @param options fetchOptions
     * @param result
     * @returns {*}
     */
    static buildFetchResult(fetchContext, result) {
      const jsonData = result.body;
      const { itemsPath } = fetchContext.sdpState.value;
      const options = fetchContext.fetchOptions;
      const resultsArray = [];

      const buildResultsMap = function (itemData) {
        if (itemData) {
          const itemMetadata = FetchByOffsetUtils.getItemMetadata(fetchContext, itemData);
          const itemResult = new FetchByOffsetItemResult(itemData, itemMetadata);
          resultsArray.push(itemResult);
        }
      };

      const itemsData = DataProviderUtils.getObjectByPath(jsonData, itemsPath);
      if (itemsData && Array.isArray(itemsData)) {
        itemsData.forEach((itemData) => {
          buildResultsMap(itemData);
        });
      } else {
        buildResultsMap(itemsData);
      }

      const hasMore = fetchContext.hasMore();
      let done = false;
      if (typeof hasMore === 'undefined' || hasMore === false) {
        fetchContext.log.finer('iterator', this.id, 'returning last result set from fetch call');
        done = true;
      } else {
        done = false;
      }
      return new FetchByOffsetResult(done, options, resultsArray);
    }

    /**
     * Returns an Item object.
     * @param items
     * @private
     */
    static getItemMetadata(fetchContext, item) {
      const { sdpState } = fetchContext;
      const { sdp } = fetchContext;
      const idAttr = sdpState.value[sdp.getIdAttributeProperty()];

      // this will return a helper that returns object attributes OR indices as the key
      const idHelper = DataProviderUtils.getIdAttributeHelper(idAttr);
      const key = idHelper.getKey(item);
      if (key) {
        return new FetchByOffsetResultItemMetadata(key);
      }

      sdp.log.error('idAttribute is not set. using the key provided via fetch options');
      return {};
    }
  }

  return FetchByOffsetUtils;
});

