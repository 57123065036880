'use strict';

define('vb/action/builtin/editorUrlAction',[
  'vb/action/action',
  'vb/private/log',
  'vb/private/configuration',
  'vb/private/utils',
  'vb/private/stateManagement/router',
  'urijs/URI',
],
(Action, Log, Configuration, Utils, Router, URI) => {
  const logger = Log.getLogger('/vb/action/builtin/editorUrlAction');
  const PARAMS = {
    APP_NAME: 'app',
    APP_URL: 'appUrl',
    EXT_ID: 'extId',
    EXT_MNGR: 'extMngr',
    PAGE_URL: 'pageUrl',
    DOMAIN: 'domain',
    BASE: 'base',
    SANDBOX: 'sandbox',
    STRIPE: 'stripe',
    PAGE_CONT: 'pageContainer',
    PAGE_PATH: 'pagePath',
    COMPONENT_ID: 'componentId',
    DYNAMIC_LAYOUT: 'dynamicLayout',
    RULESET: 'ruleset',
  };

  // the value of the _h parameter
  const SAAS = 'saas';

  /**
   * This action is used to build the URL of the VB editor from an application at runtime. It gathers
   * multiple pieces of information and returns a URL with request parameters representing various
   * contextual info needed by the VB editor. This action should not be used for mobile application.
   * The base URL pointing to the editor location is either passed as an argument to the action or
   * has to be defined in the EDITOR_URL property of the vbInitConfig global object. If this value
   * is not available, the action will abort with an error.
   * Depending if the dynamicLayout request parameter is defined, the editor will either edit the
   * current page or the ruleset of a specific dynamic component.
   * Here is the list of all request parameters on the generated URL to the editor:
   * <br>
   * app: Web application name
   * appUrl: URL of the application. This will not be on the URL if it is the same as "base"
   * extId: Id of the extension in context, could be more than one value
   * extMngr: Version of the extension manager, v2 or undefined
   * domain: Domain part of the URL of the application
   * base: The URL of the application resources (possibly a CDN path)
   * sandbox: Sandbox ID (if there is one active)
   * stripe: the FA stripe, for example "fscm"
   * pageContainer: Containership path of the current page. This is the page/flow/page path
   * that represent which flow contains the page
   * pagePath: Resource path of the current page. This is a path is relative to the application
   * path (see base param above)
   * componentId: DOM id of the dynamic layout component to edit
   * dynamicLayout: A way to identify the dynamic layout of the component to edit. This is the
   * endpoint property of the DynamicLayoutMetadataProviderDescriptor bound to the layout component
   * to edit for example "leads/get_leads"
   * ruleset: Value of "layout" property of the dynamic layout component to edit
   * <br>
   * @param componentId {String} the id of the component the DOM. The component has to
   * be a dynamic component.
   */
  class EditorUrlAction extends Action {
    constructor(id, label) {
      super(id, label);
      this.log = logger;
    }

    perform(parameters) {
      const initConfig = window.vbInitConfig || {};
      const faConfig = window.faConfig || {};
      const application = this.scopes.application;

      let { editorUrl } = parameters;
      if (!editorUrl) {
        editorUrl = initConfig.EDITOR_URL;
        if (!editorUrl) {
          throw new Error('The property EDITOR_URL is missing from vbInitConfig.');
        }
      }

      // Construct the URL starting with the value from vbInitConfig
      const uri = new URI(editorUrl);

      // Strip existing request parameters
      uri.search('');

      // Make sure the path ends with '/'
      const path = Utils.addTrailingSlash(uri.path());
      uri.path(path);

      // Search always starts with ?_h=saas
      const search = { _h: SAAS };

      // Set the "app" parameter
      let appId = initConfig.APP_ID;
      if (!appId) {
        appId = application.definition.id;
      }
      search[PARAMS.APP_NAME] = appId;

      // Set the "domain" parameter
      search[PARAMS.DOMAIN] = window.location.host;

      // Set the "base" parameter
      const base = initConfig.BASE_URL || require.toUrl('');
      search[PARAMS.BASE] = base;
      let appUrl = base;

      // Set the "appUrl" parameter
      if (Configuration.applicationUrl !== base) {
        appUrl = Configuration.applicationUrl;
        search[PARAMS.APP_URL] = appUrl;
      }

      // Set the "pageUrl" parameter
      let pageUrl = window.location.href;
      // Shorten the page URL when it starts with the appUrl
      if (pageUrl.startsWith(appUrl)) {
        pageUrl = pageUrl.substring(appUrl.length);
      }
      search[PARAMS.PAGE_URL] = pageUrl;

      // Set the "sandbox" parameter
      const sandbox = Configuration.getSandboxId();
      if (sandbox) {
        search[PARAMS.SANDBOX] = sandbox;
      }

      // set the "stripe" parameter
      const stripe = faConfig.APP_STRIPE;
      if (stripe) {
        search[PARAMS.STRIPE] = stripe;
      }

      // Set the extension manager version only if in v2. In v1, this param did not exist
      // and should still not be expected.
      const extMngrVersion = application.extensionRegistry.constructor.extensionManagerVersion;
      if (extMngrVersion === 'v2') {
        search[PARAMS.EXT_MNGR] = extMngrVersion;
      }

      // Set the "pageContainer" parameter
      const currentPage = Router.getCurrentPage();
      if (currentPage) {
        // Use getNavPath() instead of fullPath for the value of the container path because
        // getNavPath() knows how to skip the hidden page of the fndShell.
        const currentPageContainer = currentPage.getNavPath();
        search[PARAMS.PAGE_CONT] = currentPageContainer;

        // Set the "pagePath" parameter
        search[PARAMS.PAGE_PATH] = `${currentPage.path}${currentPage.id}`;

        if (currentPage.package) {
          // Set the extension id to the id of extension defining the App UI page
          search[PARAMS.EXT_ID] = currentPage.extensionId;
        }
      }

      // Set the componentId the ruleset parameters
      const { componentId } = parameters;
      if (componentId) {
        // TODO: The search for the component should only be done in the view for
        // this page, not the entire document.
        const element = document.getElementById(componentId);
        if (element) {
          search[PARAMS.COMPONENT_ID] = componentId;
          search[PARAMS.RULESET] = element.getAttribute('layout');
          const binding = element.getAttribute('metadata');
          if (binding) {
            const dynamicLayout = this.getDynamicLayout(binding);
            if (dynamicLayout) {
              search[PARAMS.DYNAMIC_LAYOUT] = dynamicLayout;
            } else {
              this.log.warn('Component', componentId,
                'is missing its DynamicLayoutMetadataProviderDescriptor definition');
            }
          }
        } else {
          this.log.warn('Component', componentId, 'is not found in the document.');
        }
      }

      this.log.info('Using VB editor URL:', editorUrl, 'with params:', search);
      uri.setSearch(search);

      // The action result is the URL to open the VB editor
      return Action.createSuccessOutcome(uri.href());
    }

    /**
     * Return the dynamic layout given the component binding
     * @param  {String} binding
     * @return {String}
     */
    getDynamicLayout(binding) {
      let ret;

      const mdpd = EditorUrlAction.getMetadataProviderFromBinding(binding);
      if (mdpd) {
        const { definition } = this.scopes.container;
        const mdpdDef = definition.metadata[mdpd];
        if (mdpdDef) {
          ret = mdpdDef.defaultValue && mdpdDef.defaultValue.endpoint;
        }
      }

      return ret;
    }

    /**
     * Return the DynamicLayoutMetadataProviderDescriptor id given the binding of a component.
     * Assume the binding is of the form '[[$page.metadata.leads.provider]]'
     * @param  {String} binding
     * @return {String}
     */
    static getMetadataProviderFromBinding(binding) {
      // TODO: Find a cleaner way to retrieve the metadata object
      const marker = '.metadata.';
      const mtIndex = binding.indexOf(marker);
      let ret;

      if (mtIndex) {
        const start = mtIndex + marker.length;
        ret = binding.substring(start, binding.indexOf('.', start));
      }

      return ret;
    }

    setContext(scopes) {
      this.scopes = scopes;
    }
  }

  return EditorUrlAction;
});

