'use strict';

// ojdataprovider and ojcore modules are explicitly required to ensure the classes added to oj namespace by
// dataprovider is automatically made available in release mode. Until JET-35243 is fixed so we have to hard code
// JET API constants / enums
define('vb/private/types/dataProviderConstants',[], () => {
  const Constants = {};

  Constants.Common = {
    IMPLEMENTATION: 'implementation',
    ITERATION: 'iteration',
  };
  Constants.DataProviderFetchType = {
    FETCH_BY_KEYS: 'fetchByKeys',
    FETCH_FIRST: 'fetchFirst',
    FETCH_BY_OFFSET: 'fetchByOffset',
  };
  Constants.CAPABILITIES_KEY = 'capabilities';
  Constants.TOTAL_SIZE = 'totalSize';
  Constants.CapabilityType = {
    SORT: 'sort',
    FILTER: 'filter', // not defined by JET oj.DataProvider
    FETCH_BY_KEYS: Constants.DataProviderFetchType.FETCH_BY_KEYS,
    FETCH_BY_OFFSET: Constants.DataProviderFetchType.FETCH_BY_OFFSET,
    FETCH_FIRST: Constants.DataProviderFetchType.FETCH_FIRST,
  };
  Constants.CapabilityKeys = {
    FETCH_BY_KEYS_IMPLEMENTATION: Constants.Common.IMPLEMENTATION,
    FETCH_BY_KEYS_MULTI_KEY_LOOKUP: 'multiKeyLookup',
    FETCH_BY_OFFSET_IMPLEMENTATION: Constants.Common.IMPLEMENTATION,
    FETCH_FIRST_IMPLEMENTATION: Constants.Common.IMPLEMENTATION,
    FETCH_FIRST_ITERATE_AFTER_DONE: 'iterateAfterDone',
    FILTER_OPERATORS: 'operators',
    SORT_ATTRIBUTES: 'attributes',
  };
  Constants.CapabilityValues = {
    FETCH_BY_KEYS_IMPLEMENTATION_LOOKUP: 'lookup',
    FETCH_BY_KEYS_IMPLEMENTATION_ITERATION: Constants.Common.ITERATION,
    FETCH_BY_KEYS_MULTI_KEY_LOOKUP_YES: 'yes',
    FETCH_BY_KEYS_MULTI_KEY_LOOKUP_NO: 'no',
    FETCH_BY_OFFSET_IMPLEMENTATION_RANDOM_ACCESS: 'randomAccess',
    FETCH_BY_OFFSET_IMPLEMENTATION_ITERATION: Constants.Common.ITERATION,
    FETCH_FIRST_IMPLEMENTATION_ITERATION: Constants.Common.ITERATION,
    FETCH_FIRST_ITERATE_AFTER_DONE_NOT_ALLOWED: 'notAllowed',
    FILTER_OPERATORS_OPS: [
      '$co', // oj.AttributeFilterOperator.AttributeOperator.$co,
      '$eq', // oj.AttributeFilterOperator.AttributeOperator.$eq,
      '$ew', // oj.AttributeFilterOperator.AttributeOperator.$ew,
      '$pr', // oj.AttributeFilterOperator.AttributeOperator.$pr,
      '$gt', // oj.AttributeFilterOperator.AttributeOperator.$gt,
      '$ge', // oj.AttributeFilterOperator.AttributeOperator.$ge,
      '$lt', // oj.AttributeFilterOperator.AttributeOperator.$lt,
      '$le', // oj.AttributeFilterOperator.AttributeOperator.$le,
      '$ne', // oj.AttributeFilterOperator.AttributeOperator.$ne,
      // BUFP-31575: for the short term disable regex operators for all services until
      // BUFP-31650 is addressed
      // '$regex', // oj.AttributeFilterOperator.AttributeOperator.$regex,
      '$sw', // oj.AttributeFilterOperator.AttributeOperator.$sw,
      '$and', // oj.CompoundFilterOperator.CompoundOperator.$and,
      '$or', // oj.CompoundFilterOperator.CompoundOperator.$or,
    ],
    SORT_ATTRIBUTES_MULTIPLE: 'multiple',
  };

  /**
   * the name of property on a DataProvider implementation used to set the primary key attribute(s)
   * @type {{ID_ATTRIBUTE: string, KEY_ATTRIBUTES: string}}
   */
  Constants.DataProviderIdAttributeProperty = {
    ID_ATTRIBUTE: 'idAttribute',
    KEY_ATTRIBUTES: 'keyAttributes',
  };

  /**
   * Events raised by DataProvider implementation per JET contract.
   * @type {{REFRESH: string, MUTATE: string}}
   * @see oj.DataProvider oj.DataProviderMutationEvent oj.DataProviderRefreshEvent
   */
  Constants.DataProviderEvent = {
    REFRESH: 'refresh',
    MUTATE: 'mutate',
  };

  /**
   * Types of mutation events
   * @type {{ADD: string, UPDATE: string, REMOVE: string}}
   * @see oj.DataProviderMutationEventDetail
   */
  Constants.DataProviderMutationEvent = {
    ADD: 'add',
    UPDATE: 'update',
    REMOVE: 'remove',
  };

  /**
   * Special idAttribute keywords.
   * @type {{AT_INDEX: string, AT_VALUE: string}}
   */
  Constants.DataProviderIdAttribute = {
    AT_INDEX: '@index',
    AT_VALUE: '@value',
  };

  /**
   * default array type structure
   * @type {string}
   */
  Constants.DEFAULT_ANY_ARRAY_TYPE = 'any[]';
  /**
   * default any type structure
   * @type {string}
   */
  Constants.DEFAULT_ANY_TYPE = 'any';
  /**
   * default object type structure
   * @type {string}
   */
  Constants.DEFAULT_OBJECT_TYPE = 'object';
  /**
   * default object type structure
   * @type {string}
   */
  Constants.DEFAULT_OBJECT_ARRAY_TYPE = 'object[]';
  /**
   * default primitive array type structure
   * @type {Array<string>}
   */
  Constants.DEFAULT_PRIMITIVE_ARRAY_TYPES = ['string[]', 'number[]', 'boolean[]'];

  return Constants;
});

