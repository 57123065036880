/* eslint-disable class-methods-use-this,no-underscore-dangle */

'use strict';

define('vb/private/services/definitionObject',['vb/private/log', 'vb/private/services/serviceProvider',
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/services/serviceUtils',
  'vb/private/services/serviceConstants',
  'urijs/URI'],
(Log, ServiceProvider, Constants, Utils, ServiceUtils, ServiceConstants, URI) => {
  const logger = Log.getLogger('/vb/private/services/services');

  // names for x-vb extensions
  const VB_TRANSFORMS = 'transforms';
  const VB_TRANSFORMS_PATH = 'path';
  const VB_DISABLED = 'disabled';

  const COMMON_TRANSFORM_NAMES = {
    request: ['sort', 'filter', 'query', 'paginate'],
    response: ['paginate'],
    metadata: ['capabilities'],
  };

  /**
   * map of transform functions created from in-line strings.
   * two maps are created, for request and response.
   * These may be overridden by the optional 'transforms.path' extension
   *
   */

  function getTransformMaps(transformsDef = {}) {
    const transforms = {
      request: {},
      response: {},
      metadata: {},
    };

    ['request', 'response', 'metadata'].forEach((key) => {
      if (transformsDef[key]) {
        Object.keys(transformsDef[key]).forEach((xformKey) => {
          if (transformsDef[key][xformKey]) {
            const code = transformsDef[key][xformKey];
            try {
              // eslint-disable-next-line no-new-func
              transforms[key][xformKey] = code ? new Function('configuration', 'options', code) : null;
            } catch (err) {
              logger.error(err);
            }
          }
        });
      }
    });

    return transforms;
  }


  /**
   * a base class for Service and Endpoint, for assigning private properties based on the 'x-vb' extension.
   * provides a load() method for asynchronously loading anything required by the extension
   *
   * about 'parent':
   * Extensions ("x-vb") are really only realized on the Endpoints; the Service has properties for the extensions
   * defined at the service level, but its really only through inheritance in the Endpoint that the extensions
   * are applied.  Meaning, the Endpoint '_extensions' represent the merge of extensions defined both
   * on the Service and Endpoint.
   */
  class DefinitionObject {
    /**
     * @param name
     * @param openApi
     * @param parent for Service, this is null. For Endpoint, this is the Service, to allow extension inheritance.
     * @param namespace {string}
     * @param relativePath container's path
     * @param catalogInfo possible additions/overrides, local to the app
     * @param isUnrestrictedRelative true means, allow using parent folders in paths (only Application)
     */
    constructor(name, openApi, parent, namespace, relativePath, catalogInfo, isUnrestrictedRelative) {
      this.name = name;
      // extensions metadata; the 'x-vb' object from the JSON, merged with anything from the catalog
      // ignore anything in the 'catalogInfo.services'; that's only used for getting the service def, itself
      const backendExtensions = (catalogInfo && catalogInfo.backends && catalogInfo.backends.extensions) || {};

      // combine the 'pure' swagger/openapi3 model extensions with any we layer on from the catalog (or 'other').
      const thisExtensions = openApi.getExtensions();

      // First, flatten (merge) the x-vb info ext
      //
      // merge the catalog.json with the x-vb for this object.
      // If this object is an endpoint, the x-vb has already been flattened with its parent serviceDev.
      this._extensions = DefinitionObject._mergeExtensions(thisExtensions, backendExtensions);

      this._loadExtensionsPromise = null;
      this._relativePath = relativePath || '';

      this._catalogInfo = catalogInfo || { chain: [] };

      this.isUnrestrictedRelative = isUnrestrictedRelative;

      this._parent = parent;
      this._namespace = namespace;

      const transformDefs = this._extensions[VB_TRANSFORMS] || {};

      this._transformsFile = transformDefs[VB_TRANSFORMS_PATH];

      const transforms = getTransformMaps(transformDefs);

      // set the transforms for the in-line string functions now; may be overridden later by module transforms
      // this also merges in any from the parent (for Endpoint, the 'parent' is Service)
      // todo: would be nice if this was more generic, to allow for more than request and response, but leave for now
      this._transforms = {
        request: {},
        response: {},
        metadata: {},
      };

      // don't merge parent transforms in until load() is called
      this._setRequestTransforms(transforms.request);
      this._setResponseTransforms(transforms.response);
      this._setMetadataTransforms(transforms.metadata);

      // merge in the parent ones when we filter
      this._disabledTransforms = transformDefs[VB_DISABLED] || {};
    }

    getRelativePath(filename) {
      // TODO: HAD TO special-case paths that start with this prefix
      if (filename && filename.startsWith('vb/')) {
        return filename;
      }
      return filename ? this._relativePath + filename : '';
    }


    /**
     * first time called, will check for the 'transforms.path' extension, and initialize
     * transformations from the JS module.
     * With or without a transforms.path, the Promise is resolved with 'this'.
     * Subsequent calls return the previous promise.
     * @returns {Promise} resolved with 'this'
     */
    load() {
      // make sure the parent has been loaded already
      return Promise.resolve(this._parent && this._parent.load())
        .then(() => {
          this._loadExtensionsPromise = this._loadExtensionsPromise
            || this._loadTransformsAndMerge(this._transformsFile).then(() => this);

          return this._loadExtensionsPromise;
        });
    }

    _setRequestTransforms(...requestMaps) {
      Object.assign(this._transforms.request, ...requestMaps);
    }

    _setResponseTransforms(...responseMaps) {
      Object.assign(this._transforms.response, ...responseMaps);
    }

    _setMetadataTransforms(...metadataMaps) {
      Object.assign(this._transforms.metadata, ...metadataMaps);
    }

    /**
     * @param pathJs if path is false, this returns an empty resolved promise.
     * Otherwise, calls Utils.getResources.
     *
     * NOTE: this uses requireJS to load the script because they are JavaScript, and require modules.
     * If we use fetch(), we need to execute those somehow.
     *
     * @returns {Promise}
     * @private
     */
    _loadTransformModule(pathJs) {
      return Promise.resolve()
        .then(() => {
          // need to remove the '.js' extension, or requireJS will not do any possible mapping
          let path = pathJs;

          if (pathJs) {
            const useJsExt = ServiceUtils.isAbsolute(pathJs);
            const hasJsExt = pathJs.endsWith('.js');

            if (useJsExt && !hasJsExt) {
              path = `${pathJs}.js`;
            } else if (!useJsExt && hasJsExt) {
              path = pathJs.substring(0, pathJs.length - 3);
            }
          }

          if (path) {
            // normalize the path, e.g., somePath/./someTransform -> somePath/someTransform,
            // so it doesn't throw off the require mapping that maps the path to a js file
            const uri = new URI(path);
            uri.normalizePath();

            return Utils.getRuntimeEnvironment()
              .then(rtEnv => rtEnv.getTransformsSource(uri.toString()));
          }

          return {};
        });
    }


    /**
     * 1) load our JS transforms, if any
     * 2) merge the parents transforms, our existing inline transforms, and any JS transforms
     *
     * Before calling this, make sure parent.load() has been called, so its transforms are ready to beinherited.
     *
     * @param filepath
     * @returns {Promise}
     */
    _loadTransformsAndMerge(filepath) {
      let path = filepath;

      const transforms = {
        request: {},
        response: {},
        metadata: {},
      };

      return Promise.resolve()
        .then(() => {
          // need to figure out if its relative to service, or application
          // two cases that might come form design-time, initially:
          // -- './transform.js' - relative to SERVICE def file
          // -- 'vb/ServiceRampTransforms' - relative to normal requireJS baseUri

          // do not allow Flows to use '..' paths
          if (path && path.indexOf(Constants.PARENT_FOLDER) >= 0 && !this.isUnrestrictedRelative) {
            logger.error('found', Constants.PARENT_FOLDER,
              'in Flow transform path, which is not allowed. skipping:', path);
            return transforms;
          }

          if (path) {
            // if the transform starts with the RELATIVE_FOLDER_PREFIX (a dot),
            // it means we want to interpret th path as relative to the service def.
            // in that case, add the service path prefix to the transform path
            if (path.startsWith(Constants.RELATIVE_FOLDER_PREFIX)) {
              // either or our parent has a filename (parent is a Service), or we do (we are a Service)
              const servicePath = (this._parent && this._parent._catalogInfo && this._parent._catalogInfo.url)
                  || (this._catalogInfo && this._catalogInfo.url);

              // use the service path for the transform path
              if (servicePath) {
                // get the path part of the service def path, without the file name
                const index = servicePath.lastIndexOf(Constants.PATH_SEPARATOR);
                const prefix = (index <= 0) ? '' : (servicePath.substring(0, index + 1));
                // and prepend it to the transform file path (including transform file name)
                path = `${prefix}${path}`;
              }
            } else if (this.isUnrestrictedRelative) {
              // if there is no dot, we want this to be app-relative, not container relative
              path = this.getRelativePath(path);
            }
          }

          return this._loadTransformModule(path);
        })
        .then((module) => {
          try {
            // the module should return an object with this form; there is no restriction on transform name,
            // since restAction, and the Rest helper, simply apply all transforms.
            // but standard names should be used since they have meaning in other contexts, like ServiceDataProvider
            /**
             * {
             *  request: {
             *    sort: fn(),
             *    paginate: fn(),
             *    ...
             *  },
             *  response: {
             *   paginate: fn()
             *   ...
             *  }
             */
            Object.keys(module).forEach((category) => {
              // each property of the module object can either be a constructor or a singleton
              const instance = (typeof module[category] === 'function') ?
                new module[category]() : module[category]; // eslint-disable-line new-cap

              // walk prototype chain, get properties of the instance, and its prototype (not the chain).
              const propertyNames = [];
              propertyNames.push(...Object.getOwnPropertyNames(instance));
              let proto = Object.getPrototypeOf(instance);
              const protoObj = Object.getPrototypeOf({});
              while (proto && proto !== protoObj) {
                propertyNames.push(...Object.getOwnPropertyNames(Object.getPrototypeOf(instance)));
                proto = Object.getPrototypeOf(proto);
              }
              // use default names to make sure we look for the common ones, regardless of module structure
              COMMON_TRANSFORM_NAMES[category].forEach((commonName) => {
                if (propertyNames.indexOf(commonName) === -1) {
                  propertyNames.push(commonName);
                }
              });

              // filter '_' and constructor
              propertyNames.filter(name => name !== 'constructor' && name[0] !== '_').forEach((functionName) => {
                if (instance[functionName] && typeof instance[functionName] === 'function') {
                  transforms[category][functionName] = instance[functionName].bind(instance);
                  // this boolean is our clue that this transform fnc one of ours, and will do its own transforms
                  // so we need to copy the value over, after the bind()
                  if (instance[functionName].doesQueryEncoding) {
                    transforms[category][functionName].doesQueryEncoding = true;
                  }
                }
              });
            });


            // here we finally inherit transforms from our parent.  precedence is, from lowest to highest:
            // - all parent transforms, if any
            // - our own JS module transforms
            // - our own (existing) inline transforms

            // prior to calling the next code, we only have 'inline' transforms

            // eslint-disable-next-line no-underscore-dangle
            const parentTransforms = (this._parent && this._parent._transforms) || {};

            const inlineRequest = Object.assign({}, this._transforms.request);
            this._setRequestTransforms(parentTransforms.request, transforms.request, inlineRequest);

            const inlineResponse = Object.assign({}, this._transforms.response);
            this._setResponseTransforms(parentTransforms.response, transforms.response, inlineResponse);

            const inlineMetadata = Object.assign({}, this._transforms.metadata);
            this._setMetadataTransforms(parentTransforms.metadata, transforms.metadata, inlineMetadata);


            return transforms;
          } catch (e) {
            // just rethrow for now
            throw e;
          }
        });
    }


    /**
     * merge the headers; otherwise, catalog overrides the definition
     * @param extensionsFromOpenApi
     * @param extensionsFromCatalog
     * @returns {*}
     * @private
     */
    static _mergeExtensions(extensionsFromOpenApi, extensionsFromCatalog) {
      const openApiExt = extensionsFromOpenApi || {};
      const catalogExt = extensionsFromCatalog || {};

      // for headers, the catalog will override anything in the servers
      // @todo: should headers in catalog override the service def?
      const headers = Object.assign({}, openApiExt.headers || {}, catalogExt.headers || {});

      // otherwise, the service def takes precedence over catalog (auth block, etc).

      // take the (atomic) auth block from either the openapi3, or the catalog
      const authentication = Object.assign({}, openApiExt.authentication || catalogExt.authentication || {});

      const merged = Object.assign({}, catalogExt, openApiExt, { headers });
      // don't add an empty 'authorization' object, just to keep it clean-ish, and keep the 'clutter' low
      if (Object.keys(authentication).length) {
        merged[ServiceConstants.AUTH_DECL_NAME] = authentication;
      }
      return merged;
    }
  }

  return DefinitionObject;
});

