'use strict';

define('vb/action/builtin/assignVariablesAction',[
  'vb/action/action', 'vb/binding/expression', 'vb/private/utils', 'vb/private/action/assignmentHelper',
  'vb/private/stateManagement/stateUtils', 'vb/private/constants', 'acorn', 'vb/private/log',
  'vb/private/stateManagement/instanceFactoryVariable', 'vb/private/stateManagement/variable',
], (Action, Expression, Utils, AssignmentHelper, StateUtils, Constants, Acorn, Log, InstanceFactoryVariable, Variable) => {
  const logger = Log.getLogger('/vb/action/builtin/assignVariablesAction');

  /**
   * The reset option determines whether or not to reset the target before an assignment. The default is 'toDefault'
   * which would set the target to its default value before assigning values to it. 'none' means override the existing
   * value. 'empty' means empty out the target before assignment.
   *
   * @type {{NONE: string, TO_DEFAULT: string}}
   */
  const RESET_OPTION = {
    NONE: 'none',
    TO_DEFAULT: 'toDefault',
    EMPTY: 'empty',
  };

  /**
   * The auto option controls whether to auto-assign all properties from the source to the corresponding properties of
   * the target. If auto is set to 'ifNoMapping', the default, auto-assignment will only be performed if no
   * mapping is provided. If auto is set to 'always', auto-assignment will always be performed first before any
   * mapping is applied.
   *
   * @type {{ALWAYS: string, IF_NO_MAPPING: string}}
   */
  const AUTO_OPTION = {
    ALWAYS: 'always',
    IF_NO_MAPPING: 'ifNoMapping',
    ASSIGNED: '_assigned', // indicate an object has already been auto-assigned (for internal use only)
  };

  /**
   * Assign Variable Function Syntax:
   * The new syntax simply calls an assign variable function on the page functions module:
   *
   * parameters: {
   *   "$page.variables.target1": { "functionName": "assignVarFunc" }
   * }
   *
   * The assignVarFunc is meant to be generated by the DT using helper functions from AssignVariableHelper.
   * For example:
   *
   * assignVarFunc(helper, targetPrototype) {
   *   const sourceVar = helper.get("$page.variables.source1");
   *   return helper.pick(targetPrototype, sourceVar);
   * }
   *
   * Metadata-driven Syntax:
   * This action is used to assign values to a set of variables. The action takes a map of target expression and
   * assignment metadata pairs. For example:
   *
   * parameters: {
   *   "$page.variables.target1": { "source": $page.variables.source1 },
   *   "$page.variables.target2": { "source": $page.variables.source2 }
   * }
   *
   * The target expression has to resolve to a variable or a variable's property if it's a structure. It has to be
   * prefixed with one of the following: $application.variables, $page.variables, $chain.variables, $variables and
   * followed by a variable name or a path to a variable property. For example:
   *
   * $application.variables.a
   * $page.variables.a.b
   * $variables.a.b.c (which is shorthand for $chain.variables.a.b.c)
   *
   * Furthermore, the expression can be arbitrarily complex as long as it is a valid JavaScript expression and satisfies
   * the above constraints. For example:
   *
   * $page.variables[$page.variables.varName]
   * $page.variables.targetArray[$page.functions.getArrayIndex() + 1][$page.variables.propName]
   *
   * The assignment metadata has the following format:
   * {
   *   "source": "some expression", /
   *   "reset": "none", // default to "toDefault"
   *   "auto": "always", // default to "ifNoMappings"
   *   "mapping": { ... }
   * }
   *
   * The "source" expression can be an arbitrary expression that evaluates to a primitive value, an object or an array.
   *
   * The "reset" option can be either "toDefault" (which is the default), "none" or "empty. The "toDefault" options
   * means that the action will first reset the target to its default value before assignment. The "none" option means
   * to override the existing value. The 'empty' option means empty out the target before assignment.
   *
   * The auto option controls whether to auto-assign all properties from the source to the corresponding properties of
   * the target. If auto is set to 'ifNoMapping', the default, auto-assignment will only be performed if no
   * mapping is provided. If auto is set to 'always', auto-assignment will always be performed first before any
   * mapping is applied.
   *
   * The "mapping" is a piece of metadata used to provide fine-grained control over what gets assigned from the source
   * to the target.
   *
   * If no mapping is provided or the auto option is set to 'always', the assign action will auto-assign the source to
   * the target. If the source is an object, auto-assignment will recursively assign each property in the source object
   * to the corresponding property in the target object. (Note that if the target property is an object and the source
   * property is a primitive or vice versa, no assignment will be made). If the target is an array, the source will be
   * treated as an array if it is not one already. For each row of the source array, a default row will be created and
   * appended to the target array and the source row is auto-assigned to the target row.
   *
   * If the target does not have a wildcard (any or object) type, the assignment mode will be auto. Otherwise, it will
   * be treated as a direct assignment with the exception where if both the target and the source are objects
   * (not array), the source will be merged into the target. If you want to directly assign the source to the target
   * in this case, you'll need to set the reset option to 'empty'.
   *
   * If mapping is provided and the auto option is set to 'ifNoMapping', instead of auto-assigning the source to the
   * target, assignment will be performed according to the mapping. For example:
   *
   * "$page.variables.target": {
   *   "source": "{{ $page.variables.source }}",
   *   "mapping": {
   *     "$target.a": "$source.b",
   *     "$target.b.c": "$source.c.b"
   *   }
   * }
   *
   * $target is an implicit variable that refers to $page.variables.target. Similarly, $source is an implicit variable
   * that refers to $page.variables.source if it's a primitive or an object. In the case where $page.variables.source
   * is an array, $source will refer to a row in the array. Furthermore, just like the "source" expression, the
   * right-hand-side values of the mapping are also expressions. In the above example, $source.b will be auto-assigned
   * to $target.a and $source.c.b to $target.b.c.
   *
   * The mapping can also be nested. For example:
   *
   * "$page.variables.target": {
   *   "source": "{{ $page.variables.source }}",
   *   "mapping": {
   *     "$target.a": "$source.b",
   *     "$target.b": {
   *       "source": "$source.c",
   *       "mapping": {
   *         "$target.c": "$source.b"
   *       }
   *     }
   *   }
   * }
   *
   * In the nested mapping, $target will refer to the closest target expression which is $target.b. Similarly, $source
   * will refer to the closest source which is $source.c.  This example is equivalent to the previous example without
   * the nested mapping. Note that the reset option can also be specified in the nested mapping which can be used
   * to override the reset option for the parent mapping.
   *
   */
  class AssignVariablesAction extends Action {
    constructor(id, label) {
      super(id, label);
      this.availableContexts = null;
      this.log = logger;
    }

    /**
     * @param parameters
     * @returns on success, Outcome {name: "success"}. On failure throws the first caught exception
     */
    perform(parameters) {
      let exception;
      Object.keys(parameters).some((targetExpr) => {
        const md = parameters[targetExpr];

        try {
          if (md.functionName) {
            this.assignUsingFunction(targetExpr, md.module, md.functionName, md.params);
          } else {
            // handle deprecated syntax
            const resetOption = md.reset || RESET_OPTION.TO_DEFAULT;
            const autoOption = md.auto || AUTO_OPTION.IF_NO_MAPPING;
            const targetInfo = this.assignUsingMetadata(targetExpr, md.source,
              md.mapping, {
                resetOption,
                autoOption,
                availableContexts: this.availableContexts,
              });

            // For now, we need to assign the root value to the variable to trigger a write to the store.
            // TODO: remove this code once we support using the assign action as the reducer for the redux store
            if (targetInfo.rootObj && targetInfo.rootPropName) {
              const tRValue = targetInfo.rootValue;
              this.log.info('Action', this.logLabel, 'assigning variable', targetExpr, 'to', tRValue);
              targetInfo.rootObj[targetInfo.rootPropName] = tRValue;
            }
          }
          return false; // keep calling some()
        } catch (e) {
          // let the parent logger get this, so it actually has the action id
          // this.log.error(`Error executing action (${this.actionId})`, e);
          exception = e;
          return true; // terminate the some()
        }
      });

      // this should just throw the error
      // return failed ? Action.createOutcome('failure', exception) : Action.createOutcome('success');
      if (exception) {
        throw exception;
      }
      return Action.createSuccessOutcome();
    }

    /**
     * Inject the available contexts for expression evaluations.
     *
     * @param availableContexts the available contexts such as $application, $page, etc
     */
    setAvailableContext(availableContexts) {
      this.availableContexts = availableContexts;
    }

    /**
     * Perform the assignment using the custom page function specified by functionName.
     *
     * @param targetExpr the target expression string
     * @param functionName the name of the custom page function
     * @param parms
     */
    assignUsingFunction(targetExpr, module = undefined, functionName, parms) {
      const params = parms || [];
      const mod = module || this.availableContexts.$page.functions;
      const assignVarFunc = mod[functionName];
      if (!assignVarFunc) {
        throw new Error(`Custom function ${functionName} does not exist.`);
      }

      const targetInfo = this.analyzeTargetExpr(targetExpr, { availableContexts: this.availableContexts });

      // make the prototype value available to the helper so it can be used for picking arrays
      const helper = new AssignmentHelper(this.availableContexts, targetInfo.type);

      // the prototype value passed into the assign variable function needs to have arrays emptied
      const defaultValue = Utils.emptyArrays(Utils.cloneObject(targetInfo.defaultValue));

      const instanceFactoryVar = targetInfo.typeClassification === Constants.VariableClassification.INSTANCE_FACTORY;
      const $source = assignVarFunc.apply(mod, [helper, defaultValue, ...params]);
      if (instanceFactoryVar) {
        // This workaround is done only for instanceFactory variables to stop from inadvertently breaking existing apps
        // that use function based assignment. The workaround addresses 2 problems -
        //
        // 1. There is a bug when using a function to assign value to existing variable, the unwrapped value
        // minus the computeds is getting written back to the store, wiping out any referenced variable dependencies.
        // Therefore it's preferable to use the metadata assignment so as not to lose the computeds in the unwrapped
        // value. Using the metadata assignment approach using 'auto' assignment always gets the store value and copies
        // over the changes.
        // 2. Another problem with function assignment is that writing back an array item does not work because
        // there are no setter wrappers for the array item itself. Example this does not work
        // "module": "vb/action/builtin/assignVariablesAction",
        //   "parameters": {
        //     "$page.variables.incidentsListLDPV.constructorParams[0]": {
        //     "module": "{{ $page.functions }}",
        //       "functionName": "updateFilterCriterionForIncidents" // this returns an item in the array
        //   },
        //   "reset": "none"
        // }
        const finalTI = this.performAutoAssignment(targetExpr, $source, undefined, targetInfo);
        // Assign the root value to the variable to trigger a write to the store.
        // TODO: remove this code once we support using the assign action as the reducer for the redux store
        if (finalTI.rootObj && finalTI.rootPropName) {
          const tRValue = targetInfo.rootValue;
          this.log.info('Action', this.logLabel, 'assigning variable', targetExpr, 'to', $source);
          finalTI.rootObj[finalTI.rootPropName] = tRValue;
        }
      } else {
        // create an expression to perform the final assignment
        const availableContexts = Object.assign({}, this.availableContexts, { $source });
        const assignExpr = Expression.createFromString(`${targetExpr} = $source`, availableContexts, false);
        this.log.info('Action', this.logLabel, 'assigning variable', targetExpr, 'to', $source);
        assignExpr();
      }
    }

    /**
     * Perform the assignment using metadata.
     *
     * @param targetExpr the target expression string
     * @param source the source value
     * @param mapping mapping metadata
     * @param baseTargetInfo analyzed info regarding the assignment target
     * @returns {*}
     */
    assignUsingMetadata(targetExpr, source, mapping, baseTargetInfo) {
      let targetInfo = this.analyzeTargetExpr(targetExpr, baseTargetInfo);

      // perform auto assignment if there are no mappings or if the auto option is set to always
      if (!mapping || targetInfo.autoOption === AUTO_OPTION.ALWAYS) {
        targetInfo = this.performAutoAssignment(targetExpr, source, mapping, targetInfo);
      }

      if (mapping) {
        const targetType = targetInfo.type;
        let $target = targetInfo.leafObj[targetInfo.leafPropName];
        const $source = source;

        targetInfo.resetOption = RESET_OPTION.NONE;

        if (Utils.isArrayType(targetType)) {
          $target = $target || [];
          const rowType = Utils.getArrayRowType(targetType);
          const sourceArr = Array.isArray($source) ? $source : [$source];

          for (let i = 0; i < sourceArr.length; i += 1) {
            const sourceRow = sourceArr[i];
            let targetRow;

            if (targetInfo.autoOption === AUTO_OPTION.ASSIGNED) {
              const rowIndex = ($target.length - sourceArr.length) + i;

              // if autoOption is assigned, that means we've already auto-assigned the sourceArr to $target
              // so we need to apply the mapping to the assigned rows in $target instead
              targetRow = $target[rowIndex];

              // if target row is null or undefined and the row type is an object type, we need to replace the row
              // with an empty structure so we don't get a NPE when assigning values into the row
              if ((targetRow === null || targetRow === undefined) && Utils.isObjectType(rowType)) {
                targetRow = StateUtils.buildVariableDefaults(null, null, rowType);
                $target[rowIndex] = targetRow;
              }
            } else if (Utils.isArrayType(rowType)) {
              targetRow = [];
            } else if (Utils.isObjectType(rowType)) {
              // create an empty target row using rowType
              targetRow = StateUtils.buildVariableDefaults(null, null, rowType);
            } else if (Utils.isAnyType(rowType)) {
              // for any type, determine the initial value for targetRow based on the sourceRow
              if (Array.isArray(sourceRow)) {
                targetRow = [];
              } else if (Utils.isObject(sourceRow)) {
                targetRow = {};
              }
            }

            const rowTargetInfo = {
              type: rowType,
              availableContexts: targetInfo.availableContexts,
              leafObj: targetRow,
            };

            this.assignUsingMapping(mapping, { $target: targetRow, $source: sourceRow }, rowTargetInfo);

            // don't append the row again if it's an auto-assigned row
            if (targetInfo.autoOption !== AUTO_OPTION.ASSIGNED) {
              $target.push(rowTargetInfo.rootValue);
            }
          }

          targetInfo.leafObj[targetInfo.leafPropName] = $target;
        } else {
          this.assignUsingMapping(mapping, { $target, $source }, targetInfo);
        }
      }

      return targetInfo;
    }

    /**
     * Does auto assignment when there are no mappings or if the auto option is set to always.
     * @param targetExpr
     * @param source
     * @param mapping
     * @param tInfo
     * @private
     */
    performAutoAssignment(targetExpr, source, mapping, tInfo) {
      const targetInfo = tInfo;
      // perform auto assignment if there are no mappings or if the auto option is set to always
      if (!mapping || targetInfo.autoOption === AUTO_OPTION.ALWAYS) {
        const helper = new AssignmentHelper(this.availableContexts, targetInfo.type);
        const leafValue = targetInfo.leafObj[targetInfo.leafPropName];

        if (leafValue && !Utils.isCloneable(source)) {
          if (targetInfo.type === 'object') {
            logger.warn('Assigning ', source, ' to ', targetExpr,
              ' will result in data being copied into an empty or existing object. If you intend to'
                + ' have the variable reference the source instance, change the variable type to any or make the'
                + ' instance directly available on $page.functions instead.');
          } else if (targetInfo.type === 'any') {
            logger.warn('Assigning ', source, ' to ', targetExpr,
              ' will result in data being copied into the existing instance held by the variable.  If you '
                + 'intend to replace the variable with the new source instance, set the reset option to empty.');
          }
        }

        // using pick to perform auto assignment
        targetInfo.leafObj[targetInfo.leafPropName] = helper.pick(leafValue, source);

        // set autoOption to assigned so we don't auto-assigned the properties again when processing the mappings
        if (targetInfo.autoOption === AUTO_OPTION.ALWAYS) {
          targetInfo.autoOption = AUTO_OPTION.ASSIGNED;
        }
      }
      return targetInfo;
    }

    /**
     * Perform the assignment using the given mapping metadata.
     *
     * @param mapping mapping metadata
     * @param scope scope for evaluating the target and source expressions
     * @param baseTargetInfo analyzed info regarding the assignment target
     */
    assignUsingMapping(mapping, scope, baseTargetInfo) {
      const availableContexts = Object.assign({}, baseTargetInfo.availableContexts, scope);
      const targetInfo = Object.assign(baseTargetInfo, { availableContexts });

      Object.keys(mapping).forEach((lhs) => {
        const rhs = mapping[lhs];
        let source;
        let rhsMapping;

        if (typeof rhs === 'string') {
          source = Expression.createFromString(rhs, availableContexts, false)();
          targetInfo.resetOption = baseTargetInfo.resetOption;
        } else if (typeof rhs === 'object') {
          source = Expression.createFromString(rhs.source, availableContexts, false)();
          rhsMapping = rhs.mapping;

          // use the reset and auto options from the mapping if specified
          targetInfo.resetOption = rhs.reset || baseTargetInfo.resetOption;
          targetInfo.autoOption = rhs.auto || baseTargetInfo.autoOption;
        }

        const newTargetInfo = this.assignUsingMetadata(lhs, source, rhsMapping, targetInfo);
        if (targetInfo.leafPropName) {
          targetInfo.leafObj[targetInfo.leafPropName] = newTargetInfo.rootValue;
        } else {
          targetInfo.rootValue = newTargetInfo.rootValue;
        }
      });
    }

    /**
     * Parse the given expression and analyze its abstract syntax tree. The return value is a stack containing
     * name-value pairs of all the evaluated properties. The bottom of the stack should be one of the built-in
     * scope variables. For example, given the expression $page.variables.target, the stack would look at follow:
     *
     * stack:
     * 0: { name: '$page', value: [page context] }
     * 1: { name: 'variables', value: [namespace object] }
     * 2: { name: 'target', value: [value of target] }
     *
     * @param expr the expression string to parse and analyze
     * @param contexts the available contexts used for evaluating scope variables
     * @returns {[*]} the stack containing name-value pairs of all the evaluated properties
     */
    analyzeExprAst(expr, contexts) {
      const ast = Acorn.parse(expr, { ecmaVersion: 'latest' });
      if (ast.body.length !== 1) {
        throw new Error(`The target of an assignment must be a single expression: ${expr}`);
      }

      const stmt = ast.body[0];
      if (stmt.type !== 'ExpressionStatement') {
        throw new Error(`The target of an assignment must be an expression: ${expr}`);
      }

      const stack = [{ name: 'contexts', value: contexts }];
      this.analyzeExprNode(stmt.expression, expr, stack);

      // remove the contexts
      stack.splice(0, 1);

      return stack;
    }

    /**
     * Analyze an expression node.
     *
     * @param exprNode an expression node
     * @param expr the expression string
     * @param stack the stack containing name-value pairs of all the evaluated properties
     */
    analyzeExprNode(exprNode, expr, stack) {
      const type = exprNode.type;

      if (type === 'Identifier') {
        this.evalAndPushPropValue(exprNode.name, stack);
      } else if (type === 'MemberExpression') {
        this.analyzeObjNode(exprNode.object, expr, stack);
        this.analyzePropNode(exprNode.property, expr, stack);
      } else {
        throw new Error(`Invalid expression: ${this.getExprFragment(exprNode, expr)}`);
      }
    }

    /**
     * Analyze an object node.
     *
     * @param objNode and object node
     * @param expr the expression string
     * @param stack the stack containing name-value pairs of all the evaluated properties
     */
    analyzeObjNode(objNode, expr, stack) {
      const type = objNode.type;

      if (type === 'Identifier') {
        this.evalAndPushPropValue(objNode.name, stack);
      } else if (type === 'MemberExpression') {
        this.analyzeExprNode(objNode, expr, stack);
      } else {
        throw new Error(`Invalid object expression: ${this.getExprFragment(objNode, expr)}`);
      }
    }

    /**
     * Analyze a property node.
     *
     * @param propNode a property node
     * @param expr the expression string
     * @param stack the stack containing name-value pairs of all the evaluated properties
     */
    analyzePropNode(propNode, expr, stack) {
      const type = propNode.type;
      let propName;

      if (type === 'Literal') {
        propName = propNode.value;
      } else if (type === 'Identifier') {
        propName = propNode.name;
      } else {
        // if the property node is not a literal or identifier, extract the expression fragment for the property
        // node and directly evaluate it
        const contexts = stack[0].value;
        const propExpr = this.getExprFragment(propNode, expr);
        propName = Expression.createFromString(propExpr, contexts)();
      }

      this.evalAndPushPropValue(propName, stack);
    }

    /**
     * Evaluate the property value for the given propName by peeking at the stack. The result is pushed
     * onto the stack as a name-value pair.
     *
     * @param propName the property name
     * @param stack the stack to peak and push the result name-value pair
     */
    // eslint-disable-next-line class-methods-use-this
    evalAndPushPropValue(propName, stack) {
      const parentObj = stack[stack.length - 1];
      const value = parentObj && parentObj.value ? parentObj.value[propName] : undefined;
      stack.push({ name: propName, value });
    }

    /**
     * Extract an expression fragment from the given expr using the node's start and end indices.
     *
     * @param exprNode an expression node
     * @param expr the expression string from which to extract the fragment
     * @returns {string}
     */
    // eslint-disable-next-line class-methods-use-this
    getExprFragment(exprNode, expr) {
      return expr.substring(exprNode.start, exprNode.end);
    }


    /**
     * only allow assignment to certain namespaces
     * @param namespace
     * @returns {boolean}
     */
    static isValidTargetNamespace(namespace) {
      return namespace === Constants.VariableNamespace.VARIABLES || namespace === Constants.TRANSLATIONS_CONTEXT;
    }

    /**
     * if we are assigning to a variable that is NOT in the 'variables' namespace, assume that its 'builtin' internally
     * @param namespace
     * @returns {string}
     */
    static actualNamespace(namespace) {
      return (namespace === Constants.VariableNamespace.VARIABLES) ? namespace : Constants.VariableNamespace.BUILTIN;
    }

    /**
     * Return an error for missing namespace in the expression
     *
     * @param  {String} targetExpr the target expression
     * @return {Error}             the error object
     */
    static getMissingNamespaceError(targetExpr) {
      return new Error(`No namespace provided for target expression ${targetExpr}.`);
    }

    /**
     * Return an error for an invalid namespace in the expression
     *
     * @param  {String} namespace   the invalid namespace
     * @param  {String} targetExpr  the target expression
     * @return {Error}              the error object
     */
    static getInvalidNamespaceError(namespace, targetExpr) {
      return new Error(`Invalid namespace ${namespace} in target expression ${targetExpr}.`);
    }

    /**
     * Return an error for missing variable name in the expression
     *
     * @param  {String} targetExpr the target expression
     * @return {Error}             the error object
     */
    static getMissingVariableNameError(targetExpr) {
      return new Error(`No variable name provided in target expression ${targetExpr}.`);
    }

    /**
     * Verify that an expression with base is valid. This method is called for expression having
     * $base.x or $extension.base.x
     * @param  {String} targetExpr the target expression
     * @param  {Array} stack       an array of object with name and value for each level of the expression
     * @return {Object}            the next scope to be validated
     */
    static checkBase(targetExpr, stack) {
      let scope = stack[0].value;

      if (stack.length < 3) {
        throw AssignVariablesAction.getMissingNamespaceError(targetExpr);
      }

      const baseName = stack[1].name;
      if (baseName !== Constants.VariableNamespace.VARIABLES) {
        if (baseName !== 'application' && baseName !== 'flow' && baseName !== 'page') {
          throw AssignVariablesAction.getInvalidNamespaceError(baseName, targetExpr);
        }

        // Change the scope to be at $base.application, $base.flow or $base.page
        scope = stack[1].value;

        // and remove page, flow or application from stack
        stack.splice(1, 1);
      }

      return scope;
    }


    /**
     * Analyze the target expression to determine the variable and its type and default value, etc.
     *
     * @param targetExpr the target expression
     * @param baseTargetInfo analyzed info regarding the assignment target
     * @returns {*}
     */
    analyzeTargetExpr(targetExpr, baseTargetInfo) {
      const targetInfo = Object.assign({}, baseTargetInfo);
      let scope;
      let defaultValue;
      let type;
      let leafObj;
      let leafPropName;

      const stack = this.analyzeExprAst(targetExpr, baseTargetInfo.availableContexts);
      if (stack.length < 1) {
        throw new Error('Invalid empty target expression.');
      }

      const scopeName = stack[0].name;
      if (Constants.ALL_SCOPES.indexOf(scopeName) !== -1) {
        if (scopeName !== '$variables') {
          scope = stack[0].value;

          if (stack.length < 2) {
            throw AssignVariablesAction.getMissingNamespaceError(targetExpr);
          }

          // With the introduction of $base, the namespace following up can be page, flow or application
          if (scopeName === '$base') {
            scope = AssignVariablesAction.checkBase(targetExpr, stack);
          } else if (scopeName === '$extension') {
            const baseName = stack[1].name;
            if (baseName !== 'base') {
              throw AssignVariablesAction.getInvalidNamespaceError(baseName, targetExpr);
            }

            // Remove $extension from stack
            stack.splice(0, 1);

            scope = AssignVariablesAction.checkBase(targetExpr, stack);
          }

          targetInfo.rootObj = stack[1].value;
          targetInfo.namespace = stack[1].name;

          if (targetInfo.namespace === Constants.VariableNamespace.CONSTANTS) {
            throw new Error(`Cannot assign a value to a constant in target expression ${targetExpr}.`);
          }

          if (!AssignVariablesAction.isValidTargetNamespace(targetInfo.namespace)) {
            throw AssignVariablesAction.getInvalidNamespaceError(targetInfo.namespace, targetExpr);
          }

          if (stack.length < 3) {
            throw AssignVariablesAction.getMissingVariableNameError(targetExpr);
          }
          targetInfo.rootPropName = stack[2].name;

          stack.splice(0, 3);
        } else {
          // for $variables, the scope is $chain
          scope = targetInfo.availableContexts.$chain;

          targetInfo.namespace = scopeName.substring(1);
          targetInfo.rootObj = stack[0].value;

          if (stack.length < 2) {
            throw AssignVariablesAction.getMissingVariableNameError(targetExpr);
          }
          targetInfo.rootPropName = stack[1].name;

          stack.splice(0, 2);
        }

        const rootValue = targetInfo.rootObj[targetInfo.rootPropName];

        // only an instance of extendedType has an internal variables for its properties, e.g., ServiceDataProvider
        if (Utils.isExtendedType(rootValue)) {
          targetInfo.rootPropName += Constants.BuiltinVariableName.VALUE;
        }

        const variable = scope.getVariable(targetInfo.rootPropName,
          AssignVariablesAction.actualNamespace(targetInfo.namespace));

        if (!variable) {
          throw new Error(`Variable ${targetInfo.rootPropName} does not exist in target expression ${targetExpr}.`);
        }

        targetInfo.typeClassification = variable.typeClassification;

        // TEMPORARY: need to clone the variable's data without any expressions getting evaluated
        targetInfo.rootValue = variable.cloneData();

        defaultValue = variable.createNewValue();
        type = variable.getType();
      } else {
        // $target
        targetInfo.rootObj = targetInfo.leafObj;
        targetInfo.rootPropName = targetInfo.leafPropName;
        targetInfo.rootValue = stack[0].value;
        defaultValue = targetInfo.defaultValue;
        type = targetInfo.type;
        stack.splice(0, 1);
      }

      if (stack.length > 0) {
        leafObj = targetInfo.rootValue;

        for (let i = 0; i < stack.length; i += 1) {
          const item = stack[i];
          const propName = item.name;

          if (i < stack.length - 1) {
            leafObj = leafObj[propName];
          }

          leafPropName = propName;

          if (Utils.isWildcardType(type)) {
            // a dereferenced wildcard type should aways be any
            type = 'any';
          } else if (Utils.isArrayType(type)) {
            type = Utils.getArrayRowType(type);
          } else if (Utils.isObjectType(type)) {
            type = type[propName];
          }

          if (defaultValue) {
            if (Array.isArray(defaultValue)) {
              // can't deference an array default value so build it from the type
              defaultValue = StateUtils.buildVariableDefaults(null, null, type);
            } else {
              if (typeof defaultValue === 'function') {
                // when evaluating the target variable expression if the token de-references into an
                // expression, then the assignment is not a supported usecase. Throw an error to
                // user suggesting an alternate assignment.
                const parentName = stack[(i > 0 ? i - 1 : 0)].name;
                const err = `Detected an unsupported assignment: ${targetExpr}! Either assign `
                  + `a literal value  to '${parentName}' directly, or assign to the variable that `
                  + `${parentName}' references!`;
                throw err;
              }
              defaultValue = defaultValue[propName];
            }
          }
        }
      } else {
        leafObj = targetInfo;
        leafPropName = 'rootValue';
      }

      if (targetInfo.resetOption === RESET_OPTION.TO_DEFAULT) {
        leafObj[leafPropName] = Utils.cloneObject(defaultValue);
      } else if (targetInfo.resetOption === RESET_OPTION.EMPTY) {
        let emptyValue;
        if (Utils.isArrayType(type)) {
          emptyValue = [];
        } else if (Utils.isObjectType(type)) {
          // create an empty object with undefined properties using the type
          emptyValue = StateUtils.buildVariableDefaults(null, null, type);
        } else {
          emptyValue = undefined;
        }
        leafObj[leafPropName] = emptyValue;
      }

      targetInfo.leafObj = leafObj;
      targetInfo.leafPropName = leafPropName;
      targetInfo.defaultValue = defaultValue;
      targetInfo.type = type;

      return targetInfo;
    }

    static get RESET_OPTION() {
      return RESET_OPTION;
    }
  }

  return AssignVariablesAction;
});

