'use strict';

define(
  'vbsw/private/plugins/fallbackInheritedAuthHandlerPlugin',[
    'vbsw/private/plugins/abstractAuthHandlerPlugin',
    'vbc/private/constants',
  ], (AbstractAuthHandlerPlugin, CommonConstants) => {
    /**
     * Fall back if no other authentication method is avaliable
     */
    class fallbackInheritedAuthHandlerPlugin extends AbstractAuthHandlerPlugin {
      constructor(fetchHandler) {
        super(fetchHandler);
      }

      deriveUrlKey(request) {
        return 'authenticated';
      }

      handleRequestHook(request) {
        const inherit = request.headers.get('vb-inherit-authentication');
        if (inherit && inherit === 'not-handled') {

          const fallbackProxy =  request.headers.get('vb-inherit-authentication-fallback');
          if (fallbackProxy) {
            // Overwrite vb-info-extension header
            // in future where I have more time properly refactor this code.
            request.headers.set(CommonConstants.Headers.VB_INFO_EXTENSION, JSON.stringify({
              proxyUrls: {
                authenticated: fallbackProxy
              },
            }));
            // Prevent cross posting
            request.headers.delete(CommonConstants.Headers.PROTOCOL_OVERRIDE_HEADER);

            return super.handleRequestHook(request);
          }
        }

        return Promise.resolve();
      }
    }

    return fallbackInheritedAuthHandlerPlugin;
  });

