'use strict';

define('vb/private/stateManagement/packageAndExtensionContainerMixin',[
  'vb/private/constants',
  'urijs/URI',
], (Constants, URI) => {
  /**
   * Mixin that should be used by all package container and all extensions
   *
   */
  const PackageAndExtensionContainerMixin = (superclass) => class extends superclass {
    /**
     * utility method to load a css file from url, absolute path or container relative path
     *
     * @param {Array} paths the array of paths in the definition
     * @param {Array} resolvedPaths the array of path resolved to the extension
     * @return {Promise}
     * @private
     */
    importCss(paths, resolvedPaths = []) {
      return Promise.resolve()
        .then(() => {
          paths.forEach((path) => {
            let cssPath = path.trim();
            // When starts with self:, it means extension relative so
            // go to top of extension + ui/self
            if (cssPath.startsWith('self:')) {
              // remove self:
              cssPath = cssPath.substring(5);
              if (cssPath[0] === '/') {
                cssPath = cssPath.substring(1);
              }
              cssPath = `${this.baseUrl}${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}/${cssPath}`;
            } else {
              const uri = URI.parse(cssPath);

              // local url not supported in extensions
              if (!uri.protocol) {
                // absolute url means relative to the App UI
                if (cssPath[0] === '/') {
                  cssPath = `${this.package.getResourceFolder()}${cssPath.substring(1)}`;
                } else {
                  this.log.error('Cannot load css', cssPath, 'start the path with self:/');
                  cssPath = null;
                }
              }
            }

            if (cssPath) {
              resolvedPaths.push(`css!${cssPath}`);
            }
          });

          return super.importCss([], resolvedPaths);
        });
    }
  };

  return PackageAndExtensionContainerMixin;
});

