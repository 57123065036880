'use strict';

define('vb/private/stateManagement/packageLayout',[
  'vb/helpers/mixin',
  'vb/private/stateManagement/layout',
  'vb/private/stateManagement/packageContainerMixin',
  'vb/private/stateManagement/packageAndExtensionContainerMixin',
  'vb/errors/httpError',
], (Mixin, Layout, PackageContainerMixin, PackageAndExtensionContainerMixin, HttpError) => {
  /**
   * PackageFlow class
   * A class for flow object defined in an App UI
   */
  class PackageLayout extends Mixin(Layout).with(PackageContainerMixin, PackageAndExtensionContainerMixin) {
    constructor(id, parent, delegator, path, className = 'PackageLayout') {
      super(id, parent, delegator, path, className);

      Object.defineProperties(this, {
        package: {
          // It's possible the parent of this layout is a page extension which has no access
          // to package so we get it from the base instead.
          value: parent.package || parent.base.package,
          enumerable: true,
        },
        extension: {
          value: parent.extension,
          enumerable: true,
        },
        path: {
          // It is possible the layout is created with a path already been set. In that
          // case, make sure it's not a dup of baseUrl
          value: Layout.calculatePath(path, parent.extension.baseUrl),
          enumerable: true,
        },
        // Need a get: because the value of path is defined later
        resourceLoc: {
          get: () => this.path,
          enumerable: true,
        },
        // The default event prefix is the lowercase class name (see container.js) but for
        // packageLayout we want to use the same event prefix as layout
        eventPrefix: {
          value: 'layout',
          enumerable: true,
        },
      });
    }

    get fullName() {
      return this.name;
    }

    /**
     * Return true if error is a FileNotFound error.
     *
     * @param error
     * @returns {Boolean|boolean|*}
     */
    static isFileNotFoundError(error) {
      return error instanceof HttpError && error.isFileNotFound();
    }

    descriptorLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        // App UI are using extension with a manifest containering a list of files,
        // so we can check if the file exist before doing a fetch that may fail.
        this.package.checkFileExist(`${this.resourceLoc}${this.fullName}.json`, this.extensionId);
        return super.descriptorLoader(resourceLocator);
      })
        // Return an empty layout when the base layout doesn't exist to support the use case
        // where an extension needs to extend a layout that doesn't exist in the base extension.
        .catch((error) => {
          if (PackageLayout.isFileNotFoundError(error)) {
            return { layouts: {} };
          }
          throw error;
        });
    }

    functionsLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        this.package.checkFileExist(`${this.resourceLoc}${this.fullName}.js`, this.extensionId);
        return super.functionsLoader(resourceLocator);
      })
        // Return an empty JS module when the base module doesn't exist to support the use case
        // where an extension needs to extend a layout that doesn't exist in the base extension.
        .catch((error) => {
          if (PackageLayout.isFileNotFoundError(error)) {
            return {};
          }
          throw error;
        });
    }

    templateLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        this.package.checkFileExist(`${this.resourceLoc}${this.fullName}.html`, this.extensionId);
        return super.templateLoader(resourceLocator);
      })
        // Return an empty template when the base template doesn't exist to support the use case
        // where an extension needs to extend a layout that doesn't exist in the base extension.
        .catch((error) => {
          if (PackageLayout.isFileNotFoundError(error)) {
            return '';
          }
          throw error;
        });
    }
  }

  return PackageLayout;
});

