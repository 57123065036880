'use strict';

define('vb/action/eventAction',[
  'vb/private/constants',
  'vb/private/log',
  'vb/action/action',
  'vb/private/stateManagement/router',
],
(Constants, Log, Action, Router) => {
  const logger = Log.getLogger('/vb/action/eventAction');

  /**
   * not allowed
   */
  const INVALID_EVENTS = [
    Constants.ENTER_EVENT,
    Constants.EXIT_EVENT,
    Constants.BEFORE_ENTER_EVENT,
    Constants.BEFORE_EXIT_EVENT,
    Constants.INTERACTABLE_EVENT,
    Constants.AFTER_NAVIGATE_EVENT,
    Constants.RESOURCE_CHANGED_EVENT,
  ];

  // --
  class EventAction extends Action {
    // abstract base class
    perform() {
      throw new Error('Cannot instantiate EventAction.');
    }

    setContext(context) {
      this.context = context;
      if (context && context.container && context.container.invokeEventWithBubbling
        && typeof context.container.invokeEventWithBubbling === 'function') {
        this.eventContainer = context.container;
      }
    }

    /**
     * @param target
     * @returns {boolean}
     */
    static isValidTarget(target) {
      return target === undefined || target === null
        || target === Constants.EventTargets.LEAF || target === Constants.EventTargets.CURRENT;
    }

    /**
     * determine which container to use as the start of the event, and fire/invoke the event
     * @param name
     * @param payload
     * @param options all parameters for the action
     * @returns {Promise|null} null means it did not fire the event
     */
    static fireEvent(eventContainer, name, payload, options) {
      let container;
      if (options && options.target === Constants.EventTargets.LEAF) {
        container = Router.getCurrentPage();
        if (!container) {
          logger.warn(`Could not get the current page for event: ${name}`);
        }
      } else {
        container = eventContainer;
      }

      if (container) {
        // returns Promise
        return container.invokeEventWithBubbling(name, payload);
      }
      return null;
    }

    /**
     * check if the event name is allowed
     * @param name
     * @returns {boolean}
     */
    static isValid(name) {
      return name && INVALID_EVENTS.indexOf(name) === -1;
    }
  }

  return EventAction;
});

