'use strict';

define('vb/private/types/defaultSecurityProvider',['vb/types/securityProvider',
  'vb/private/utils',
  'vb/private/stateManagement/router',
  'vb/private/stateManagement/application',
  'vb/private/log',
  'urijs/URI',
  'vbsw/private/serviceWorkerManager',
  'vbsw/private/fetchHandler',
  'vbsw/private/pwa/pwaUtils',
  'vbsw/private/constants',
], (SecurityProvider, Utils, Router, Application, Log, URI,
  ServiceWorkerManager, FetchHandler, PwaUtils, SwConstants) => {
  // the amount of time in milliseconds to wait for the access token from the hidden iframe
  const REFRESH_ACCESS_TOKEN_TIMEOUT = 3000;

  // the id for the hidden iframe for refreshing the access token
  const REFRESH_ACCESS_TOKEN_IFRAME = 'vb-refresh-access-token-iframe';

  /**
   * Builtin implementation of SecurityProvider for VBCS.
   */
  class DefaultSecurityProvider extends SecurityProvider {
    /**
     * Extract idcsInfo and authentication objects from config.
     *
     * @param config configuration object
     * @returns {Promise}
     */
    initialize(config) {
      this.idcsInfo = config.idcsInfo || {};

      // get the default security configuration
      this.defaultAuthentication = config.authentication;

      return super.initialize(config);
    }

    /**
     * Fetch the current user info using the url in the config object. If no url is provided, derive the
     * url from vbInitConfig
     *
     * @param config the config object containing the url for fetching the current user
     */
    fetchCurrentUser(config) {
      // if no url or service endpoint ID is provided, derive url from vbInitConfig
      if (!config.url && !config.endpoint) {
        const vbConfig = window.vbInitConfig;
        // the url is different between staged/live and DT mode
        if (!vbConfig.IS_DT_MODE) {
          // staged/live
          config.url = `${Router.baseUrl}_currentuser`;
        } else {
          // DT mode
          // get the context root from vbInitConfig and make sure it starts and ends with /
          const contextRoot = this.getContextRoot();

          config.url = `${contextRoot}resources/security/user`;
        }
      }

      return super.fetchCurrentUser(config);
    }

    /**
     * This method is overridden to work around an issue with a PWA application running in the mobile
     * Safari browser where the SSO cookie is not accessible from the service worker immediately
     * after logging in. The workaround is to have the main application, which has the SSO cookie,
     * fetch the current user information directly instead of going through the service worker.
     *
     * @param config configuration object containing the url for fetch current user information.
     * @param forceOffline force offline (used by unit tests only)
     * @returns {Promise}
     */
    fetchCurrentUserRaw(config, forceOffline) {
      // only perform the workaround for a PWA application running in the mobile Safari browser
      if (Utils.isMobileSafari() && PwaUtils.isPwaConfig(window.vbInitConfig)) {
        // requests to be skipped by the service worker with and without :443 port
        const requestsToSkip = [
          {
            url: config.url.replace(':443', ''),
            method: 'GET',
          },
          {
            url: config.url,
            method: 'GET',
          },
        ];

        // register the urls to be skipped by the service worker
        return ServiceWorkerManager.getInstance().addRequestsToSkip(requestsToSkip)
          .then(() => {
            // create a fetch handler to handle the current user request
            const fetchHandler = new FetchHandler('/', { useCacheResponseWhenOfflineHeaderEnabled: true });

            // remember fetch and Request before offline toolkit overrides them
            const browserFetch = fetch;
            const browserRequest = Request;

            return fetchHandler.installPlugins(['vbsw/private/plugins/authPostprocessorHandlerPlugin'])
              .then(() => fetchHandler.initializePersistenceManagerWithStore())
              .then((pm) => {
                // force the persistence manager offline (used by unit tests only)
                if (forceOffline) {
                  pm.forceOffline(true);
                }

                const options = {
                  credentials: 'same-origin',
                  headers: {
                    [SwConstants.USE_CACHED_RESPONSE_WHEN_OFFLINE]: true, // use offline toolkit to handle caching
                    'Cache-Control': 'no-cache, no-store', // bypass browser cache
                    Pragma: 'no-cache', // bypass IE browser cache
                  },
                };

                const request = new Request(config.url, options);

                return fetchHandler.handleRequest(request).then((response) => {
                  // BUFP-42180: switch to using OPT api once available for restoring fetch and Request
                  // restore fetch and Request
                  fetch = browserFetch;
                  Request = browserRequest;

                  return ServiceWorkerManager.getInstance().removeRequestsToSkip(requestsToSkip)
                    // make response match the result from the rest helper
                    .then(() => ({ response }));
                });
              });
          });
      }

      return super.fetchCurrentUserRaw(config);
    }

    /**
     * Returns the context root for the application.
     *
     * @returns {*}
     */
    getContextRoot() {
      const vbConfig = window.vbInitConfig;
      let contextRoot = vbConfig.CONTEXT_ROOT ? vbConfig.CONTEXT_ROOT : '/';

      if (!contextRoot.startsWith('/')) {
        contextRoot = `/${contextRoot}`;
      }
      if (!contextRoot.endsWith('/')) {
        contextRoot = `${contextRoot}/`;
      }

      return contextRoot;
    }

    /**
     * Return an array of service worker plugin urls specified in userConfig.configuration.serviceWorkerConfig object
     * in the application model json file.
     *
     * @param config the userConfig.configuration.serviceWorkerConfig object
     * @param isAnonymous true if the application is anonymous
     * @returns {Array}
     */
    getServiceWorkerPlugins(config, isAnonymous = false) {
      // Get the config plugins
      return Utils.getRuntimeEnvironment()
        .then((rte) => Promise.all([super.getServiceWorkerPlugins(config, isAnonymous),
          rte.getServiceWorkerPlugins()])
          .then((pluginsArr) => {
            const plugins = pluginsArr[0];

            const vbConfig = window.vbInitConfig || {};
            const versionId = vbConfig.BASE_URL_TOKEN;
            const orgId = vbConfig.ORGANIZATION_ID;
            const vbServer = vbConfig.VB_SERVER;

            // the default plugins specific to VBCS
            const defaultPlugins = [
              {
                url: 'vbsw/private/plugins/sessionExpirePlugin',
                params: {
                  contextRoot: this.getContextRoot(),
                  idcsHost: this.idcsInfo.hostName,
                },
              },
              {
                url: 'vbsw/private/plugins/authPreprocessorHandlerPlugin',
                params: {
                  isAnonymous,
                  defaultAuthentication: this.defaultAuthentication,
                  passthroughs:
                    (config.configuration && config.configuration.passthroughs) || [],
                },
              },
              {
                url: 'vbsw/private/plugins/sessionTrackingHandlerPlugin',
                params: {
                  userId: this.userInfo.email,
                },
              },
              // if orgId is not undefined, install multiTenantCsrfTokenHandlerPlugin instead
              (orgId === undefined)
                ? {
                  url: 'vbsw/private/plugins/csrfTokenHandlerPlugin',
                  params: {
                    versionId,
                  },
                }
                : {
                  url: 'vbsw/private/plugins/multiTenantCsrfTokenHandlerPlugin',
                  params: {
                    orgId,
                    vbServer,
                  },
                },
              {
                url: 'vbsw/private/plugins/implicitFlowHandlerPlugin',
                params: {
                  allowedScopes: this.idcsInfo.allowedScopes ? this.idcsInfo.allowedScopes : [],
                },
              },
              'vbsw/private/plugins/tokenRelayHandlerPlugin',
              {
                url: 'vbsw/private/plugins/authHeaderHandlerPlugin',
                params: {
                  isAnonymous,
                },
              },
              {
                url: 'vbsw/private/plugins/resourceChangedPlugin',
                params: {
                  contextRoot: this.getContextRoot(),
                  versionId,
                },
              },
            ];

            // add the default plugins
            defaultPlugins.forEach((plugin) => {
              if (plugins.indexOf(plugin) === -1) {
                plugins.push(plugin);
              }
            });

            // add additional plugins provided via runtime environment
            plugins.push(...pluginsArr[1]);

            // Make sure no vb- headers used for intra plugin communication escape
            plugins.push('vbsw/private/plugins/authPostprocessorHandlerPlugin');

            return plugins;
          }));
    }

    /**
     * This method is used to handle the 'vbSessionExpired' message from the service worker.
     */
    vbSessionExpired() {
      const loginUrl = this.getLoginUrl();

      // go through runtimeEnvironment to give DT a chance to handle session expiry
      return Application.runtimeEnvironment.sessionExpired(loginUrl);
    }

    /**
     * This method is used to handle the 'vbRefreshImplicitFlowAccessToken' message from the ImplicitFlowHandlerPlugin
     * for refreshing the access token.
     *
     * @param scope the scope for which to refresh the access token
     * @returns {Promise}
     */
    vbRefreshImplicitFlowAccessToken(scope) {
      return new Promise((resolve, reject) => {
        // get the hidden iframe for refreshing the access token
        const refreshTokenIframe = document.getElementById(REFRESH_ACCESS_TOKEN_IFRAME);

        if (refreshTokenIframe) {
          let timeout;

          // listener for waiting for the access token from the hidden iframe
          const listener = (e) => {
            if (e.origin === window.location.origin && e.data.method === 'vbRefreshAuthToken') {
              const accessTokenHash = e.data.args[0];

              if (accessTokenHash) {
                // clear the timeout and remove the listener
                clearTimeout(timeout);
                window.removeEventListener('message', listener);

                // parse the hash containing the access token
                const fragments = Router.parseFragment(accessTokenHash);

                // extract the new access token from the hash
                const token = `${fragments.token_type} ${fragments.access_token}`;

                resolve(token);
              }
            }
          };
          window.addEventListener('message', listener);

          // redirect the hidden iframe to the authorization url
          const authUrl = this.getOAuthAuthorizationUrl(scope);
          refreshTokenIframe.src = authUrl;

          timeout = setTimeout(() => {
            window.removeEventListener('message', listener);
            reject('Refreshing access token timed out.');

            // either we got redirected to the login page because the user is not logged in,
            // or the consent form if the scope is being access for the first time, simply redirect
            // the application to the authorization url so the user can log in or respond to the
            // consent form
            window.location.href = authUrl;
          }, REFRESH_ACCESS_TOKEN_TIMEOUT);
        }
      });
    }

    /**
     * Construct an authorization url for obtaining an OAuth access token for the given scope.
     *
     * @param scope to scope for which to request an access token
     * @returns {String}
     */
    getOAuthAuthorizationUrl(scope) {
      const uri = new URI(`${this.idcsInfo.hostName}/oauth2/v1/authorize`);

      uri.addSearch('client_id', this.idcsInfo.clientId)
        .addSearch('response_type', 'token')
        .addSearch('scope', scope)
        .addSearch('redirect_uri', this.idcsInfo.oAuthRedirectEndpoint);
        // .addSearch('nonce', '123'); TODO

      return uri.href();
    }
  }

  return DefaultSecurityProvider;
});

