'use strict';

define('vb/private/action/assignmentUtils',[
  'vb/private/constants',
  'vb/binding/expression',
  'vb/private/utils',
  'vb/private/action/assignmentHelper',
  'vb/private/log',
], (Constants, Expression, Utils, AssignmentHelper, Log) => {
  const LOGGER = Log.getLogger('/vb/stateManagement/assignmentUtils');

  /**
   * Builtin assign variable utils class for use in expressions used with assignVariablesAction. This class' methods are
   * primarily used by page authors to do something like -
   * $application.builtinUtils.assignmentUtils.assignValue(value)
   */
  class AssignmentUtils {
    /**
     * returns the params as the new source value to assign to target.
     * @param helper
     * @param defaultValue
     * @param Array params
     * @param params.instance <optional> if not set the constructorParams is used to create a new instance
     * @param params.constructorParams
     */
    static assignValue(helper, defaultValue, params) {
      return params;
    }
  }

  return AssignmentUtils;
});

