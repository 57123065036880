'use strict';

define('vb/private/stateManagement/layoutExtension',[
  'vb/private/stateManagement/containerExtension',
  'vb/private/stateManagement/context/layoutExtensionContext',
  'vb/private/utils',
  'vb/private/constants',
], (ContainerExtension, LayoutExtensionContext, Utils, Constants) => {
  /**
   * LayoutExtension class
   */
  class LayoutExtension extends ContainerExtension {
    constructor(extension, path, base, className = 'LayoutExtension') {
      // layout doesn't have a parent since it's not part of the container hierarchy
      super(extension, null, path, base, className);
    }

    static get extensionClass() {
      return LayoutExtension;
    }

    descriptorLoader(resourceLocator) {
      const resource = `${resourceLocator}-x.json`;
      return this.application.runtimeEnvironment.getExtensionTextResource(resource)
        .then((jsonContent) => Utils.parseJsonResource(jsonContent, resource));
    }

    functionsLoader(resourceLocator) {
      return super.functionsLoader(`${resourceLocator}-x`);
    }

    templateLoader(resourceLocator) {
      return super.templateLoader(`${resourceLocator}-x.html`);
    }

    static get resourceSuffix() {
      return '-x.json';
    }

    /**
     * The name of the runtime environment function to be used to load the module functions.
     *
     * @return {String} the module loader function name
     */
    // eslint-disable-next-line class-methods-use-this
    get functionsLoaderName() {
      return 'getExtensionModuleResource';
    }

    /**
     * The name of the runtime environment function to be used to load the html.
     *
     * @return {String} the template loader function name
     */
    // eslint-disable-next-line class-methods-use-this
    get templateLoaderName() {
      return 'getExtensionTextResource';
    }

    /**
     * For JET V1 translations, VB extensions cannot define new bundles for extensions.
     * (string exts are managed via string servlet customization in FA. to be determined for V2 translations).
     *
     * Just in case someone defines a "translations" section, override this to do nothing.
     * LayoutExtension.loadTranslationBundles() is not called, but stubbing this makes this more obvious,
     * and accidental-change-proof.
     *
     * @returns {Promise<void>}
     * @override
     */
    // eslint-disable-next-line class-methods-use-this
    loadTranslationBundles() {
      return Promise.resolve();
    }

    /**
     * Returns the model object for the layout container. The model contains all the accessible $ properties
     * of the layout container. All properties are initialized and ready to be evaluated in expressions.
     * Extensions have also been applied to the layout.
     * The model is bound to the layout view by the JET dynamic component.
     *
     * {
     *   $variables: {}
     *   $constants: {}
     *   $chains: {}
     *   $functions: {}
     *   $listeners: {}
     *
     *   @todo: $extension.variables (tracked VBS-1793)
     * }
     *
     * @return {Promise<model>} a promise that resolve with the model object.
     */
    getViewModel() {
      return Promise.all([
        this.load(), this.loadFunctionModule(), this.loadTemplate(),
      ])
        .then(() => {
          const viewModel = {};
          const availableContexts = this.getAvailableContexts();

          Object.getOwnPropertyNames(availableContexts).forEach((key) => {
            const descriptor = Object.getOwnPropertyDescriptor(availableContexts, key);
            Object.defineProperty(viewModel, key, descriptor);
          });

          return viewModel;
        });
    }

    /**
     * Returns the ExtensionContext constructor used to create the '$' expression context.
     * @return {ExtensionContext.constructor}
     * @override
     */
    static get ContextType() {
      return LayoutExtensionContext;
    }

    /**
     * Returns a scope resolver map where keys are scope name ("page", "flow" or "application")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @private
     * @return {Object} an object which properties are scope
     */
    getScopeResolverMap() {
      return {
        [Constants.LAYOUT_PREFIX]: this,
      };
    }
  }

  return LayoutExtension;
});

