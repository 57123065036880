'use strict';

define('vb/private/stateManagement/context/baseContext',[
  'vb/private/constants'],
(Constants) => {
  const symbol = Symbol('base-accessor');
  /**
   * Set of properties to expose in $base
   */
  class BaseContext {
    /**
     * @param extension
     */
    constructor(container) {
      Object.defineProperty(this, symbol,
        {
          value: {
            get export() {
              return container.export;
            },
            get applicationBaseContext() {
              // make sure we get the baseContext from the correct container
              const app = container.package || container.application;
              return app.expressionContext.baseContext;
            },
            get scope() {
              return container.scope;
            },
          },
        });
    }

    /**
     * $base.application
     */
    get application() {
      return this[symbol].applicationBaseContext;
    }

    /**
     * $base.variables
     */
    get [Constants.VariableNamespace.VARIABLES]() {
      return this[symbol].export[Constants.VariableNamespace.VARIABLES];
    }

    /**
     * $base.constants
     */
    get [Constants.VariableNamespace.CONSTANTS]() {
      return this[symbol].export[Constants.VariableNamespace.CONSTANTS];
    }

    /**
     * $base.events
     */
    get events() {
      return this[symbol].export.events;
    }

    // The getVariable function is needed for the assignVariable action to work
    getVariable(name, namespace) {
      let variable;
      // only expose variables that are defined in the interface section.
      if (this[namespace]) {
        const properties = Object.keys(this[namespace]);
        if (properties.indexOf(name) >= 0) {
          variable = this[symbol].scope.getVariable(name, namespace);
        }
      }
      return variable;
    }
  }


  return BaseContext;
});

