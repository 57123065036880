/* eslint-disable class-methods-use-this */
/* eslint max-classes-per-file: ["error", 2] */

'use strict';

define('vb/private/types/metadataDescriptor',[
  'vb/private/log',
  'vb/private/constants',
  'vb/private/utils',
  'vb/binding/expression',
  'vb/helpers/mixin',
  'vb/private/types/builtinExtendedTypeMixin',
], (Log, Constants, Utils, Expression, Mixin, BuiltinExtendedTypeMixin) => {

  /**
   * custom variable type for metadata providers
   *
   * note: using "class {}" instead of Object, babel issue: https://github.com/babel/babel/issues/4480
   *
   */
  class MetadataDescriptor extends Mixin().with(BuiltinExtendedTypeMixin) {
    constructor() {
      super();
      this.log = Log.getLogger('/vb/types/metadataDescriptor');
    }

    /**
     * Initializer.
     * @param id
     * @param variableDef the declarative definition of this variable
     * @param value the defaultValue is provided here
     * @param container app, flow, or page. needed to pass context to the provider
     * @return {Promise} the declaration, with resolved expressions
     * @constructor
     */
    init(id, variableDef, value, container) {
      return Promise.resolve()
        .then(() => {
          super.init(id, variableDef, value);
          this.container = container;

          // Other extended variables work because they implement a contract with the component,
          // so they don't need expression resolution until the component makes calls.
          const result = MetadataDescriptor.resolveExpressions(value);

          // make the variable name available on the options which will be used as the id
          // as well as part of the scope name for the layout
          result.options.id = id;

          return result;
        });
    }

    /**
     * utility to call expression function in the evaluated declaration
     * @param obj
     * @returns {*}
     */
    static resolveExpressions(o) {
      // we don't want to call regular functions, we want to pass them along to the (JET) provider
      const obj = Utils.resolveIfObservable(o);

      if (!Utils.isCloneable(obj)) {
        return obj;
      }

      const clone = Array.isArray(obj) ? [] : {};

      Object.keys(obj || {}).forEach((key) => {
        const v = obj[key];

        if (typeof v === 'object' || typeof v === 'function') {
          clone[key] = MetadataDescriptor.resolveExpressions(v);
        } else {
          clone[key] = v;
        }
      });
      return clone;
    }


    /**
     *
     * @returns {*}
     */
    getTypeDefinition() {
      return {
        type: 'object',
      };
    }

    /**
     * Called when the variable is disposed
     * override this method
     */
    // eslint-disable-next-line class-methods-use-this
    dispose() {
      // no-op
    }
  }

  return MetadataDescriptor;
});

