'use strict';

define('vb/private/services/endpointReference',[
  'vb/private/constants',
  'vb/private/utils',
], (Constants, Utils) => {
  /**
   * class EndpointReference
   *
   * An abstraction of the ways a service endpoint (a.k.a. operation) can be references.
   * Normally, an "endpoint" property is used, which is "[<namespace>:]<service ID>/<operation ID>",
   *   where the namespace is optional.
   *
   * When creating an SDP programmatically for some Dynamic UI cases, an tuple with { url, operationRef } may be used.
   *
   * This class is meant to:
   * - represent either in a scalable way (allow more properties in the future if needed, etc).
   * - calculate a namespace when the namespace is not specified, and a container is provided for context.
   *
   */
  class EndpointReference {
    /**
     * Creates an endpoint reference from the specified arguments.
     *
     * A string id is parsed to obtain the 'namespace', 'serviceId', and 'operationId' following this pseudo expression
     * <i>[namespace]:serviceId/[operationId]</i>. If not provided 'namespace' is undefined while 'serviceId' and
     * 'operationId' are both empty strings.
     *
     * An object id is expected to provide the 'url', 'serviceId', and 'operationRef' information.
     *
     * @param {(string|{url: string, serviceId: string, operationRef: string})} id
     * @param {object} [container] - typically the container which defines the scope in which the endpoint is being
     *                                 invoked.
     * @param {string} [container.extensionId] - the container's extension Id (or namespace), which is used to
     *                                           set the endpoint reference 'containerNamespace' property (defaults
     *                                           to 'base' if not informed).
     * @throws {Error} if id is neither a string nor an object
     */
    constructor(id, { extensionId = Constants.ExtensionNamespaces.BASE } = {}) {
      if (!id) {
        throw Error('Invalid ID used for endpoint reference.');
      }

      // the namespace of the extension/container/"scope" in which this endpoint is being used - for example, 'extA' if
      // this endpointReference is being instantiated because of an action chain in extension 'extA'. Never undefined.
      this.containerNamespace = extensionId;

      if (typeof id === 'string') {
        // eslint-disable-next-line max-len
        const { prefix: namespace, main: serviceId = '', suffix: operationId = '' } = Utils.parseQualifiedIdentifier(id);
        this.namespace = namespace;
        this.serviceId = serviceId;
        this.operationId = operationId;
      } else if (id.url) {
        // if its not a string, assume it's one from JET Dyn UI - url, operationRef.
        const { url, operationRef, serviceId } = id;
        this.url = url;
        this.operationRef = operationRef;
        this.serviceId = serviceId;
      } else {
        throw new Error(`Attempt to create an invalid EndpointReference: ${JSON.stringify(id)}`);
      }
    }

    // never undefined: either the namespace or containerNamespace
    // useful when there is a need to obtain "the best, defined" namespace for this endpoint reference
    get derivedNamespace() {
      return this.namespace || this.containerNamespace;
    }

    /**
     * @returns {string}
     */
    toString() {
      return JSON.stringify(this);
    }
  }

  return EndpointReference;
});

