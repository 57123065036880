'use strict';

define('vb/action/builtin/webShareAction',['vb/action/action', 'vb/private/log'], (Action, Log) => {
  const logger = Log.getLogger('/vb/action/builtin/webShareAction');

  /**
   * WebShareAction allows mobile applications to invoke the native sharing capabilities of the host platform and
   * share content with other applications, such as Facebook, Twitter, Slack, SMS, etc.
   * This action should only be invoked following a user gesture (such as a button click).
   * It is a good idea to only enable share UI based of feature detection:
   * <pre>
   *   <oj-button disabled="[[!navigator.share]]">Share</oj-button>
   * </pre>
   * WebShareAction parameters correspond to
   * [Web Share API]{@link https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share} options:
   * <li><i>title</i> the title of the document being shared. May be ignored by the handler/target.
   * <li><i>text</i> an arbitrary text that forms the body of the message being shared.
   * <li><i>url</i> a URL string referring to a resource being shared.
   * <p>
   * All parameters are individually optional, but at least one parameter has to be specified. Any url can be shared,
   * not just urls under website's current scope.
   * Text can be shared with or without a url.
   * <br>
   * An example below illustrates action's parameters one would specify to share current page's title and url:
   * <pre>
   *   "parameters": {
   *   "title": [[ document.querySelector('h1').textContent ]],
   *   "url": "[[ document.querySelector('link[rel=canonical]') &&
   *         document.querySelector('link[rel=canonical]').href || window.location.href ]]",
   *   },
   * </pre>
   * A success outcome is returned once user has completed a share action. A failure outcome is returned
   * when browser does not support Web Share API or a parameter error is detected.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share}
   * @see {@link https://caniuse.com/#search=web%20share}
   * @see {@link https://www.npmjs.com/package/cordova-plugin-web-share}
   */
  class WebShareAction extends Action {
    constructor(id, label) {
      super(id, label);
      this.log = logger;
    }

    /**
     * @param parameters
     * @returns {Promise} Outcome {name: "success"}, once a user has completed a share action or {name: "failure"},
     * when Web Share is not supported, or parameter error is detected.
     */
    // eslint-disable-next-line class-methods-use-this
    perform(parameters) {
      if (!(navigator.share)) {
        const msg = 'Web Share is not supported in the browser';
        return Action.createFailureOutcome(msg, new Error(msg));
      }
      const title = parameters.title; // eslint-disable-line prefer-destructuring
      const text = parameters.text; // eslint-disable-line prefer-destructuring
      const url = parameters.url; // eslint-disable-line prefer-destructuring

      // Make sure that parameters, if specified, are of correct types
      if (!(this.checkParam(title, 'title') && this.checkParam(text, 'text') && this.checkParam(url, 'url'))) {
        return Action.createFailureOutcome(this.errorMessage, new TypeError(this.errorMessage));
      }
      // Make sure that at least one parameter is specified
      if (!(title || text || url)) {
        const msg = 'At least one of the webShareAction\'s parameters must be specified';
        return Action.createFailureOutcome(msg, new Error(msg));
      }
      const data = { };
      if (title) {
        // title of the document being shared. May be ignored by the handler/target
        data.title = title;
      }
      if (text) {
        // an arbitrary text that forms the body of the message being shared
        data.text = text;
      }
      if (url) {
        // a URL string referring to a resource being shared
        data.url = url;
      }
      return navigator.share(data)
        .then(() => Action.createSuccessOutcome())
        .catch((error) => {
          // Web and Cordova navigator.share support differs in terms of what is considered a share promise
          // rejection. For example, on Android, dismissing a native share UI results in a promise rejection for PWA,
          // but a resolved promise for a native application. For this reason, share promise rejection will not be
          // (initially) exposed as webShareAction failure.
          this.log.warn(`(${this.logLabel}): ${error.message}`);
          return Action.createSuccessOutcome();
        });
    }

    /**
     * Checks whether a parameter, if defined, is of a string type. Sets this.errorMessage on type mismatch
     * and logs an error. For example:
     * <i>
     * (shareIncident_r65qq5d23): Invalid WebShareAction parameter 'title' : true
     * </>
     * @param param parameter value to check
     * @param paramName parameter name for error message
     * @returns {boolean} true, if no type errors are found, false otherwise.
     */
    checkParam(param, paramName) {
      if (param && typeof param !== 'string') {
        this.errorMessage = `Invalid WebShareAction parameter '${paramName}' : ${param}`;
        this.log.error(`(${this.logLabel}): ${this.errorMessage}`);
        return false;
      }
      return true;
    }
  }
  return WebShareAction;
});

