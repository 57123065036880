'use strict';

/**
 * class DataDescriptionExtensionModel
 * extends EmptyModel, does NOT include translations
 */
define('vb/extensions/dynamic/private/models/dataDescriptionExtensionModel',[
  'vb/extensions/dynamic/private/models/abstractModel',
  'vb/extensions/dynamic/private/models/emptyModel',
], (AbstractModel, EmptyModel) => AbstractModel(EmptyModel, 'data-description-x.js'));

