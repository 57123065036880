'use strict';

/**
 * Builtin implementation of MobileDefaultUserSecurityProvider for VB.
 */
define('vb/private/types/mobileDefaultSecurityProvider',[
  'vb/types/securityProvider',
  'vb/private/utils',
  'vb/private/mobile/security/security',
  'vb/private/stateManagement/router',
  'vb/private/stateManagement/application',
  'vbsw/private/serviceWorkerManager',
  'vbsw/private/constants',
  'vb/private/log'],
(SecurityProvider, Utils, Security, Router, Application, ServiceWorkerManager, SwConstants, Log) => {
  const logger = Log.getLogger('/vb/private/types/mobileDefaultSecurityProvider');

  class MobileDefaultUserSecurityProvider extends SecurityProvider {
    /**
     * Extract the authentication object from config.
     *
     * @param config configuration object
     * @returns {Promise}
     */
    initialize(config) {
      this.config = config;

      // get the default security configuration
      this.defaultAuthentication = config.authentication;

      // userInfo url
      this.userInfoUrl = config.oauthUrl;

      return super.initialize(config);
    }

    /**
     * Fetch the current user info.
     */
    // eslint-disable-next-line class-methods-use-this
    fetchCurrentUser() {
      return Promise.resolve();
    }

    /**
     * Return a promise that resolves to an array of plugin info.  A plugin info can be either an url string or
     * an object containing url and params properties. The params property will be passed to the constructor
     * of the plugin. For example:
     *
     *  [
     *   'vbsw/plugin1',
     *   {
     *     url: 'vbsw/plugin2',
     *     params:  {
     *       foo: 'bar'
     *     }
     *   }
     * ]
     *
     * @param config the userConfig.configuration.serviceWorkerConfig object
     * @param isAnonymous true if the user is not logged in
     * @returns {Promise<Array>}
     */
    getServiceWorkerPlugins(config, isAnonymous = false) {
      // Get the config plugins
      return super.getServiceWorkerPlugins(config, isAnonymous).then((plugins) => {
        // the default plugins specific to VB
        const defaultPlugins = [
          {
            url: 'vbsw/private/plugins/mobileAuthPreprocessorHandlerPlugin',
            params: {
              isAnonymous,
              defaultAuthentication: this.defaultAuthentication,
            },
          },
          {
            url: 'vbsw/private/plugins/sessionTrackingHandlerPlugin',
            params: {
              userId: this.userInfo.email,
            },
          },
          'vbsw/private/plugins/tokenRelayHandlerPlugin',
          'vbsw/private/plugins/csrfTokenHandlerPlugin',
          {
            url: 'vbsw/private/plugins/mobileAuthHandlerPlugin',
            params: {
              allowedScopes:
                (this.config && this.config.idcsInfo && this.config.idcsInfo.allowedScopes)
                  ? this.config.idcsInfo.allowedScopes : [],
              currentUserUrl: this.userInfoUrl || '',
            },
          },
        ];

        // Only add the plugins that are not already defined in the config
        defaultPlugins.forEach((plugin) => {
          if (plugins.indexOf(plugin) === -1) {
            plugins.push(plugin);
          }
        });

        // Make sure no vb- headers used for intra plugin communication escape
        plugins.push('vbsw/private/plugins/authPostprocessorHandlerPlugin');

        return plugins;
      });
    }

    /**
     * Returns the secure user information.
     *
     * @return promise a user specific information
     */
    getUserInfo() {
      if (this.config.authentication.type === SwConstants.AuthenticationType.BASIC || !this.userInfoUrl) {
        // not sure how we want to retrieve user info in case of basic auth, so for now leave everything blank
        return Promise.resolve({
          userId: '',
          userName: '',
          longId: '',
          fullName: '',
          email: '',
          roles: [],
          permissions: [],
          isAnonymous: false,
        });
      }

      // use offline toolkit to handle caching:
      const requestHeaders = new Headers();
      requestHeaders.set(SwConstants.USE_CACHED_RESPONSE_WHEN_OFFLINE, 'true');

      const request = new Request(this.userInfoUrl,
        {
          credentials: 'same-origin',
          headers: requestHeaders,
        });
      return fetch(request).then((response) => {
        if (response.ok) {
          return response.json().then((userInfo) => {
            const roles = this.processRolesOrPerms(userInfo.roles, undefined);

            return Promise.resolve({
              userId: userInfo.id,
              userName: userInfo.userName,
              longId: userInfo.id,
              fullName: userInfo.fullName,
              email: userInfo.email,
              roles,
              permissions: [],
              isAnonymous: userInfo.isAnonymous,
            });
          });
        }

        throw new Error('Failed to retrieve user information');
      });
    }

    /**
     * Handle the user login process.
     * Redirect to the login page using the login URL given by the security provider configuration.
     * If defined, the returnPath is added to the login URL using the query parameter name defined in the
     * 'returnPathQueryParam' property of the SecurityProvider class.
     *
     * @param  {string} returnPath the path of the page to go to when login is successful
     * @return {boolean} true if the login process is handled
     */
    handleLogin(returnPath) {
      // close any message bars before navigation
      const messageBars = document.getElementsByTagName('oj-vb-message-bar');
      for (let i = 0; i < messageBars.length; i += 1) {
        messageBars[i].closeAll();
      }

      ServiceWorkerManager.getInstance().isOnline()
        // proceed with the security initialization and login if the app is online
        // otherwise just resolve and only try to get the user information
        .then((isOnline) => (isOnline
          ? Security.initialize(this.config).then(() => Security.login()) : Promise.resolve()))
        .then(() => this.getUserInfo())
        .then((securityConfig) => {
          // Update default userInfo with value from security config
          if (securityConfig && securityConfig.isAnonymous !== undefined && !securityConfig.isAnonymous) {
            logger.info('Successfully retrieved user information...');
            Object.assign(this.userInfo, {
              userId: securityConfig.userId,
              username: securityConfig.userName,
              longId: securityConfig.longId,
              fullName: securityConfig.fullName,
              email: securityConfig.email,
              roles: securityConfig.roles,
              permissions: securityConfig.permissions,
              isAuthenticated: !securityConfig.isAnonymous,
            });
          } else {
            logger.info('Running in anonymous mode...');
          }
        })
        .catch((err) => {
          // in case of errors, default to anonymous user but still log the error
          if (err) {
            logger.error(err);
            logger.info('Running in anonymous mode...');
          }
        })
        // ensure we have the correct set of plugins installed
        .then(() => this.getServiceWorkerPlugins(null, !this.userInfo.isAuthenticated)
          .then((plugins) => this.installServiceWorkerPlugins(plugins))
          .then(() => {
            if (Application.router) {
              const options = {
                page: returnPath || '/',
                operation: 'navigateToPage',
              };
              Router.queueNavigate(Application, options);
            } else {
              Application.dispose();
              Utils.getResource('vb/bootstrap').then((bootstrap) => bootstrap());
            }
          }));

      // return true so that the original navigation is cancelled
      return true;
    }

    /**
     * Handle the user logout process.
     * The default implementation navigate to the URL defined by the logoutUrl argument.
     * If the logoutUrl argument is not defined, it uses the logoutUrl of the SecurityProvider
     * configuration.
     *
     * @param  {String} logoutUrl  the home URL of the application
     */
    // eslint-disable-next-line no-unused-vars, class-methods-use-this
    handleLogout(logoutUrl) {
      Security.logout()
        .catch((err) => {
          if (err) {
            logger.error(err);
          }
        })
        .then(() => {
          // regardless of whether the logout failed or not, reset window.location.href
          window.location.href = 'index.html';
        });
    }

    /**
     * Handle the 'vbGetAuthHeader' message to retrieve the authorization header.
     * If the token has expired, the user will be re-directed to login again.
     *
     * @returns {Promise}
     */
    // eslint-disable-next-line class-methods-use-this
    vbGetAuthHeader() {
      return Security.getHeaders();
    }
  }

  return MobileDefaultUserSecurityProvider;
});

