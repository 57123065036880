'use strict';

define('vb/private/services/serviceDefinition',[
  'vb/private/log',
  'vb/private/services/serviceProvider',
  'vb/private/services/swaggerUtils',
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/configLoader',
  'vb/private/services/definitionObject',
  'vb/private/services/endpoint',
  'vb/private/services/serviceConstants',
  'vbsw/private/constants',
  'vb/private/services/serviceUtils',
],
(Log, ServiceProvider, SwaggerUtils, Constants, Utils, ConfigLoader, DefinitionObject,
  Endpoint, ServiceConstants, SWConstants, ServiceUtils) => {
  // const logger = Log.getLogger('/vb/private/services/services');

  /**
   * ServiceDefinition
   */
  class ServiceDefinition extends DefinitionObject {
    /**
     *
     * @param name
     * @param path
     * @param protocolRegistry utility that handles vb-specific protocols
     * @param catalogInfo catalog.json information, if any
     * @param openApi abstract definition model, implementation is either OpenApi 2 or OpenApi 3
     * @param relativePath container path, relative to requirejs root
     * @param requestInit fetch options
     * @param namespace {string}
     * @param isUnrestrictedRelative true for application; flows/pages may not load from parent folders (app may).
     */
    constructor(name,
      path, protocolRegistry, catalogInfo, openApi, relativePath, requestInit, namespace, isUnrestrictedRelative) {
      //
      super(name, openApi, null, namespace, relativePath, catalogInfo, isUnrestrictedRelative);

      this._openApi = openApi;
      this._requestInit = requestInit;

      this._protocolRegistry = protocolRegistry;

      this._namespace = namespace;

      const chosenServer = openApi.getServerForProfile(ConfigLoader.activeProfile);
      this.baseUri = chosenServer.url;

      this.nameForProxy = ServiceUtils.getNameForProxy(name, path, catalogInfo.chain);

      // merge chosen server's "x-vb" info into the global ("info"-level) one
      if (chosenServer[ServiceConstants.VB_EXTENSIONS]) {
        // the server ones will take precedence
        const mergedExtensions = ServiceUtils
          .mergeExtensions(this._extensions, chosenServer[ServiceConstants.VB_EXTENSIONS]);


        this._extensions = ServiceUtils.augmentExtension(this.nameForProxy, catalogInfo.chain, mergedExtensions);
      } else {
        // we need the augmented info for the 'legacy' syntax, also, for http/https promotion via proxy
        this._extensions = ServiceUtils.augmentExtension(this.nameForProxy, catalogInfo.chain, this._extensions);
      }


      this._endpoints = {};

      // hack for BUFP-25774 - for business objects; this replaces regex for 'ic/builder' in the plugins
      // @todo: remove business object hack when 'x-vb:authentication' handling is implemented in the runtime
      if (name === 'businessObjects' || path.endsWith('businessObjects/service.json')) {
        this._extensions.authenticationType = SWConstants.AuthenticationType.ORACLE_CLOUD;
      }

      openApi.getPathObjects().forEach((pathObject) => {
        const pathKey = pathObject.path;

        pathObject.getOperationObjects().forEach((operationObject) => {
          const operationKey = operationObject.name;

          const endpointId = SwaggerUtils.getEndpointId(pathKey, pathObject, operationObject.name, operationObject);

          this._endpoints[endpointId] = new Endpoint({
            name: endpointId,
            service: this,
            protocolRegistry,
            pathKey,
            pathObject,
            operationKey,
            operationObject,
            isUnrestrictedRelative: this.isUnrestrictedRelative,
          });
        });
      });
    }

    isDefined() {
      return !!this.url;
    }

    /**
     * @return {boolean} true if this service definition is visible outside the container that defines it
     *                   (i.e., if an extension can use it).
     */
    isExtensionAccessible() {
      const definitionExtension = this._openApi && this._openApi.getExtensions();
      if ((definitionExtension && definitionExtension.extensionAccess) === true) {
        return true;
      }

      const mergedExtensions = this._catalogInfo && this._catalogInfo.mergedExtensions;
      return (mergedExtensions && mergedExtensions.extensionAccess) === true;
    }

    _getEndpoints() {
      return this._endpoints;
    }

    getRequestTransforms() {
      return this._transforms.request;
    }

    getResponseTransforms() {
      return this._transforms.response;
    }

    getMetadataTransforms() {
      return this._transforms.metadata;
    }
  }

  return ServiceDefinition;
});

