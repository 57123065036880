'use strict';

define('vb/private/stateManagement/context/appPackageContext',[
  'vb/private/constants',
  'vb/private/stateManagement/context/flowContext',
], (Constants, FlowContext) => {
  const symbol = Symbol('app-ui-accessor');

  class AppPackageContext extends FlowContext {
    /**
     *
     * @param package
     */
    constructor(appPackage) {
      super(appPackage);

      Object.defineProperty(this, symbol, {
        value: {
          get global() {
            const extensionApplication = appPackage.application.extensions[appPackage.extensionId];
            return extensionApplication && extensionApplication.expressionContext.application;
          },
          /**
           * Shortcut to retrieve the builtins variable map
           */
          get builtins() {
            return appPackage.scope.variableNamespaces[Constants.VariableNamespace.BUILTIN];
          },
        },
      });
    }

    /**
     * $application.responsive.XXX
     */
    get [Constants.RESPONSIVE_CONTEXT]() {
      return this[symbol].global[Constants.RESPONSIVE_CONTEXT];
    }

    /**
     * $application.user.XXX
     */
    get [Constants.APPLICATION_USER_VARIABLE]() {
      return this[symbol].global[Constants.APPLICATION_USER_VARIABLE];
    }

    /**
     * $application.initParams.XXX
     */
    get [Constants.INIT_PARAM_CONTEXT]() {
      return this[symbol].global[Constants.INIT_PARAM_CONTEXT];
    }

    /**
     * $application.currentPage.XXX
     */
    get [Constants.CURRENT_PAGE_VARIABLE]() {
      return this[symbol].global[Constants.CURRENT_PAGE_VARIABLE];
    }

    /**
     * $application.deployment.XXX
     */
    get [Constants.DEPLOYMENT_CONSTANT]() {
      return this[symbol].global[Constants.DEPLOYMENT_CONSTANT];
    }

    get global() {
      return this[symbol].global;
    }

    static getAvailableContexts(appPackage) {
      const availableContexts = super.getAvailableContexts(appPackage);

      delete availableContexts.$flow;

      Object.defineProperties(availableContexts, {
        // Override the $global defined in ContainerContext to point to the extension application
        $global: {
          enumerable: true,
          configurable: true,
          // Retrieve the application extension with the same extension id
          get() {
            return appPackage.expressionContext.global;
          },
        },
        // Override the $application defined in ContainerContext to point to the App UI context
        $application: {
          enumerable: true,
          configurable: true,
          get() {
            return appPackage.expressionContext;
          },
        },
      });


      return availableContexts;
    }
  }

  return AppPackageContext;
});

