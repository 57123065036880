'use strict';

define('vb/private/stateManagement/packagePage',[
  'vb/helpers/mixin',
  'vb/private/stateManagement/packageContainerMixin',
  'vb/private/stateManagement/packageAndExtensionContainerMixin',
  'vb/private/stateManagement/page',
  'vb/private/constants',
], (Mixin, PackageContainerMixin, PackageAndExtensionContainerMixin, Page, Constants) => {
  /**
   * PackagePage class
   * A class for page object defined in an App UI
   */
  class PackagePage extends Mixin(Page).with(PackageContainerMixin, PackageAndExtensionContainerMixin) {
    constructor(id, parent, path, className = 'PackagePage') {
      super(id, parent, path, className);

      Object.defineProperties(this, {
        package: {
          value: parent.package,
          enumerable: true,
        },
        extension: {
          value: parent.package.extension,
          enumerable: true,
        },
        resourceLoc: {
          // ui/self/{path}
          value: `${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}/${this.path}`,
          enumerable: true,
        },
        // The default event prefix is the lowercase class name (see container.js) but for
        // packagePage we want to use the same event prefix as page
        eventPrefix: {
          value: 'page',
          enumerable: true,
        },
      });
    }

    /**
     * The folder where the dynamic layouts are defined
     * For pages in an App UI, it's "dynamicLayouts/self/"
     * @return {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get layoutRoot() {
      return `${Constants.DefaultPaths.LAYOUTS}${Constants.ExtensionFolders.SELF}/`;
    }

    /**
     * The class to use to create layout mode.
     * @return {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get layoutModelClass() {
      return 'vb/private/stateManagement/packageLayout';
    }

    descriptorLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        // App UIs are using extension with a manifest containering a list of files,
        // so we can check if the file exist before doing a fetch that may fail.
        this.package.checkFileExist(`${this.resourceLoc}${this.fullName}.json`);
        return super.descriptorLoader(resourceLocator);
      });
    }

    functionsLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        this.package.checkFileExist(`${this.resourceLoc}${this.fullName}.js`);
        return super.functionsLoader(resourceLocator);
      });
    }

    templateLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        this.package.checkFileExist(`${this.resourceLoc}${this.fullName}.html`);
        return super.templateLoader(resourceLocator);
      });
    }

    /**
     * Return true if this page can be navigated to from the outside
     * @return {Boolean}
     */
    isNavigable() {
      const { navigation } = this.definition;
      if (navigation && navigation.fromExternal === 'enabled') {
        return true;
      }
      if (this.isDefault() && this.parent.isNavigable()) {
        return true;
      }

      return false;
    }

    /**
     * Check if there is any restriction to navigate to this page
     * @throws an error id the page is not navigable
     */
    checkNavigable() {
      if (!this.isNavigable()) {
        throw new Error(`Navigation to page ${this.getNavPath()} is not enabled.`);
      }
    }
  }

  return PackagePage;
});

