'use strict';

define('vb/private/stateManagement/variableFactory',['vb/private/stateManagement/variable',
  'vb/private/stateManagement/instanceFactoryVariable',
  'vb/private/stateManagement/stateUtils',
  'vb/private/constants',
  'vb/private/utils',
], (Variable, InstanceFactoryVariable, StateUtils, Constants, Utils) => {
  /**
   * A variable factory that creates different kinds of variables based on its 'type'
   *   - primitive (uses variable.js)
   *   - object (- do -)
   *   - extended type class (- do -): 'vb/ServiceDataProvider', 'fa/SearchDataProvider'
   *   - any old type with no 'constructorParams' property: { type: 'oj/someRandomOJClass' }
   *
   *   - all other types (uses instanceFactoryVariable.js) - any type that has a 'constructorParams' property will be
   *    considered this new kind of variable. A fresh instance of the type is created every time its state changes.
   *    It's state is really its 'constructorParams'
   */
  class VariableFactory {
    /**
     * Creates either a regular Variable or a InstanceFactoryVariable.
     * TODO: The current 'ExtendedType' variables are going to need some special casing
     * @param type The type of the variable (see StateUtils.getType() for type details)
     * @param options Object
     * @param options.scope
     * @param options.name The name of the variable
     * @param options.namespace optional, defaults to "variables"
     * @param options.type
     * @param options.defaultValue
     * @param options.initialValue
     * @param options.descriptor optional, example: {writable: false}. overrides other values.
     * For a full list of properties see constructor for Variable and InstanceFactoryVariable.
     * @return {DynamicServiceDataProvider} DynamicServiceDataProvider standalone instance
     */
    static createVariable(type, options) {
      const argsArr = (options && Object.values(options)) || [];
      const Clazz = Utils.isTypeDefInstanceFactory(type) ? InstanceFactoryVariable : Variable;
      return new Clazz(...argsArr);
    }
  }

  return VariableFactory;
});

