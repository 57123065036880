/* eslint-disable class-methods-use-this,max-classes-per-file */

'use strict';

define('vb/private/events/undeclaredEventBehavior',[
  'vb/private/constants',
  'vb/private/log',
  'vb/action/action',
  'vb/private/action/assignmentHelper',
  'vb/private/events/eventBehavior',
], (Constants, Log, Action, AssignmentHelper, EventBehavior) => {

  const NAMESPACES = [
    Constants.APPLICATION_PREFIX,
    Constants.FLOW_PREFIX,
    Constants.PAGE_PREFIX,
  ];


  /**
   * UndeclaredEventBehavior
   *
   * this represents the behavior for events events that are not declared in an "events" section.
   * Those are the original style of events (they were called 'custom' events, but now the new ones are),
   * and the built-in 'vbXXX' events.
   *
   *
   * when using fireCustomEventAction with undeclared events:
   * - start bubbling at the container that calls the action
   * - no behavior - just call all listeners, and don't wait for Promises, and don't return results.
   *
   * call event listeners, don't wait for any responses.
   */
  class UndeclaredEventBehavior extends EventBehavior {
    /**
     * default behavior; start from the current page (not necessarily the 'event firer')
     *
     * @param container
     * @returns {Page|*}
     */
    startingContainer(container) {
      return UndeclaredEventBehavior.isNamespaced(this.name) ? null : container;
    }

    /**
     * see if the name reference has a prefix ('foo:', etc.)
     * @param name
     * @returns {boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    static isNamespaced(name) {
      const parts = (name || '').split(':');
      if (parts.length === 2) {
        const prefixParts = parts[0].split('/'); // get the last part
        return NAMESPACES.indexOf(prefixParts[prefixParts.length - 1]) >= 0;
      }
      return false;
    }

    /**
     * anyone can trigger an undeclared event
     * @param container
     * @returns {boolean}
     * @override
     */
    triggerableBy() {
      return true;
    }


    /**
     * undeclared events are always listenable, but this is double-checking that the name isn't namespaced.
     * @returns {boolean}
     * @override
     */
    listenableBy() {
      return !UndeclaredEventBehavior.isNamespaced(this.name);
    }

    /**
     * don't process extensions, only invoke the event on itself
     * @param container
     * @param eventModel
     * @param eventWrapper
     * @returns {Array<{ container: Container, fnc: function }>}
     * @override
     */
    curryListeners(container, eventModel, eventWrapper) {
      const functionWrappers = [];
      const fnc = container.invokeEvent.bind(container, eventWrapper.name, eventWrapper.coercedPayload);
      functionWrappers.push({
        container,
        fnc,
      });
      return functionWrappers;
    }
  }

  return UndeclaredEventBehavior;
});

