'use strict';

define('vb/private/services/readers/openApi3Object',[
  './openApiObjectCommon',
  './openApiUtils',
  'vb/private/services/serviceUtils',
  'vb/private/services/swaggerUtils',
  'vb/private/services/uriTemplate',
], (OpenApiObjectCommon, OpenApiUtils, ServiceUtils, SwaggerUtils, UriTemplate) => {
  /**
   * local utility, find the very first entry name/value pair.
   * Used as fallback for finding a response.
   * @param obj
   * @returns {*}
   */
  function anyProperty(obj) {
    const entries = Object.entries(obj);
    return (entries.length) ? { name: entries[0][0], value: entries[0][1] } : { name: null, value: {} };
  }
  /**
   *
   */
  class OpenApi3OperationObject extends OpenApiObjectCommon.OperationObject {
    /**
     * abstract and simplify Request Body Object (or $ref) 'content'
     * @returns {Array}
     */
    getResponseContentTypes() {
      // we don't use anything in the definition, we just want a list of media types (for now)
      const responses = this.responses || {};
      let response = responses.default || responses['200'] || anyProperty(responses).value;
      response = SwaggerUtils.resolveReferences(this.openApi.definition, null, response);

      return Object.keys(response.content || {});
    }


    /**
     * abstract and simplify Response Object (or $ref) 'content'
     * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#responseObject
     * @returns {Array}
     */
    getRequestContentTypes() {
      let requestBody = this.requestBody || this.openApi.requestBody || {};

      requestBody = SwaggerUtils.resolveReferences(this.openApi.definition, null, requestBody);
      // we don't use anything in the definition, we just want a list of media types (for now)
      return Object.keys(requestBody.content || {});
    }
  }

  /**
   *
   */
  class OpenApi3Object extends OpenApiObjectCommon {
    constructor(definition, context = {}) {
      super(definition);
      this.initParams = context.initParams || {};
      this.definitionUrl = context.definitionUrl;
    }

      /**
     * abstract OpenAPI 3.0 Server Objects
     * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#serverObject
     * @returns {Array|*}
     */
    getServers() {
      return this.resolveServerTemplates();
    }


    /**
     * return Server Object for the chosen profile
     * @param profile
     * @return {Object} Server Object
     */
    getServerForProfile(profile) {
      return OpenApiUtils.findServerForProfile(this.getServers(), profile);
    }


    /**
     * factory method for v3 Operation Object
     * @param name
     * @param pathObject
     * @param operationObjectKey
     * @returns {OpenApi3OperationObject}
     * @override
     */
    createOperationObjectObject(name, pathObject, operationObjectKey) {
      const operationObjectDefinition = pathObject[operationObjectKey];
      return new OpenApi3OperationObject(name, pathObject, operationObjectDefinition, this);
    }


    /**
     * internal, replace template parameters in server paths
     * @returns {Array|*}
     */
    resolveServerTemplates() {
      if (!this.resolvedServers) {
        const servers = this.definition.servers || [];
        this.resolvedServers = servers.map((server) => {
          const newServer = OpenApi3Object.replaceServerVariables(server, this.initParams);
          if (this.definitionUrl) {
            newServer.url = ServiceUtils.toAbsoluteServerUrl(this.definitionUrl, newServer.url);
          }
          return newServer;
        });
      }
      return this.resolvedServers;
    }

    /**
     * for a Server Object, replace path templates with Server Variable Object 'default's
     * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#serverVariableObject
     * @param serverObj
     * @param initParams optional, declared values in app-flow.json
     * @returns {*}
     */
    static replaceServerVariables(serverObj, initParams) {
      const newServerObj = Object.assign({}, serverObj);

      const parameters = {};

      // first gather the default values as defined on the OpenAPI definition
      const vars = serverObj.variables;
      if (vars) {
        Object.keys(vars).forEach((name) => {
          parameters[name] = vars[name].default;
        });
      }

      // now replace the default values by "defined, not-null" values that may have been passed via initParams
      const pars = initParams;
      if (pars) {
        Object.keys(pars).forEach((name) => {
          const value = pars[name];
          if (value !== undefined && value !== null) {
            parameters[name] = value;
          }
        });
      }

      const uriTemplate = new UriTemplate(serverObj.url || '', {}, true); // don't add extras
      newServerObj.url = uriTemplate.replace(parameters);
      return newServerObj;
    }
  }

  return OpenApi3Object;
});

