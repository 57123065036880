'use strict';

define('vb/private/vx/v2/remoteExtensionAdapter',[
  'vb/private/vx/baseExtensionAdapter',
  'urijs/URI',
  'vbc/private/monitorOptions',
  'vbsw/private/constants',
], (BaseExtensionAdapter, URI, MonitorOptions, SwConstants) => {
  /**
   * The extension adapter used to load extension from the extension manager
   */
  class RemoteExtensionAdapter extends BaseExtensionAdapter {
    constructor(registryUrl) {
      super(BaseExtensionAdapter.fixRegistryUrl(registryUrl));
    }

    /**
     * Return a promise to load the manifest for the unified application
     * @return {Promise<Object>}  a promise that resolves to the manifest
     */
    loadManifest() {
      return Promise.resolve().then(() => {
        this.log.info('Loading extensions from', this.registryUrl,
          'using extension manager v2.');

        const version = this.constructor.getExtensionVersion();
        const request = new Request(this.buildUrl(version));
        // use offline toolkit to handle caching
        request.headers.set(SwConstants.USE_CACHED_RESPONSE_WHEN_OFFLINE, 'true');
        const mo = new MonitorOptions('extensionManifestLoad', `extension manifest load ${request.url}`);
        return this.log.monitor(mo, (registryLoadTimer) => fetch(request)
          .then((response) => {
            if (!response.ok) {
              throw new Error('Response not OK when fetching extension at:', request.url);
            }

            return response.json();
          })
          .then((results) => {
            const digest = (results && results.digest) || {};
            // Extension Manager v2 returns digest.runtime[], so convert to v1 manifest.extensions[]
            const extensions = digest.runtime || [];
            const requirejsInfo = digest['requirejs-info'] || [];

            const manifest = { extensions, requirejsInfo };

            this.log.greenInfo('Found', manifest.extensions.length,
              'extension(s) using digest version', version, registryLoadTimer());

            return manifest;
          }));
      });
    }

    static getExtensionVersion() {
      const version = BaseExtensionAdapter.getExtensionVersion();

      if (!version) {
        // For v2 adds the digest version if defined. Default to 'latest'
        const digestVersion = window.vbInitConfig && window.vbInitConfig.REGISTRY_DIGEST_VERSION;
        return digestVersion ? encodeURIComponent(digestVersion) : 'latest';
      }
      return version;
    }

    /**
     * Build the extension URL based on the baseUrl and the extension id
     * A typical extension URL with a sandbox defined looks like:
     * https://<fa-server>/fscmUI/vxmanifest/v2/digest/[latest|$<sandboxId>]?type=topology
     * @param  {String} version     the preferred extension version
     * @return {String}             the extension URL
     */
    buildUrl(version) {
      const uri = new URI(`${this.registryUrl}vxmanifest/v2/digests/`);

      // The version string has already been encoded, so don't encoded it again in
      // order to preserve the $ character
      uri.segment(version);
      // Ask for runtime and requirejs-info digests
      uri.search('type=runtime,requirejs-info');

      return uri.href();
    }
  }

  return RemoteExtensionAdapter;
});

