/* eslint-disable prefer-destructuring */

'use strict';

define('vbc/private/monitorOptions',[], () => {
  const STARTING = 'starting';
  const ENDING = 'ending';
  class MonitorOptions {
    /**
     * @param operationName the name of the operation to monitor
     * @param message user readable monitor message
     */
    constructor(operationName, message) {
      this.operationName = operationName;
      this.message = message;
    }

    /**
     * @param message monitor message
     * @returns {MonitorOptions}
     */
    addMessage(message) {
      this.message = message;
      return this;
    }

    /**
     * @param message start monitor message
     * @returns {MonitorOptions} monitor options for chaining
     */
    addStartMessage(message) {
      this.startMessage = message;
      return this;
    }

    /**
     * @param message end monitor message
     * @returns {MonitorOptions} monitor options for chaining
     */
    addEndMessage(message) {
      this.endMessage = message;
      return this;
    }

    /**
     * @param tags a function that returns log monitor tags object
     * @returns {MonitorOptions} monitor options for chaining
     */
    addTags(tags) {
      this.tags = tags;
      return this;
    }

    /**
     * @param startFields a function that returns a fields object
     * @returns {MonitorOptions} monitor options for chaining
     */
    addStartFields(startFields) {
      this.startFields = startFields;
      return this;
    }

    /**
     * @param endFields a function that returns a fields object
     * @returns {MonitorOptions} monitor options for chaining
     */
    addEndFields(endFields) {
      this.endFields = endFields;
      return this;
    }

    /**
     * @returns {*|string} a start message for this monitor. If no start message was specified, start message is
     * constructed from the message.
     */
    getStartMessage() {
      let startMessage = this.startMessage;
      if (!startMessage && this.message) {
        startMessage = `${STARTING} ${this.message}`;
      }
      return startMessage;
    }

    /**
     * @returns {*|string} an end message for this monitor. If no end message was specified, end message is
     * constructed from the message.
     */
    getEndMessage() {
      let endMessage = this.endMessage;
      if (!endMessage && this.message) {
        endMessage = `${ENDING} ${this.message}`;
      }
      return endMessage;
    }

    /**
     * @returns {Object | undefined} log monitor tags object
     */
    getTags() {
      let tags;
      if (typeof this.tags === 'function') {
        tags = this.tags();
      }
      return tags;
    }

    /**
     * @returns {Object | undefined} log monitor fields object
     */
    getStartFields() {
      let fields;
      if (typeof this.startFields === 'function') {
        fields = this.startFields();
      }
      return fields || {};
    }

    /**
     * @returns {Object | undefined} log monitor fields object
     */
    getEndFields() {
      let fields;
      if (typeof this.endFields === 'function') {
        fields = this.endFields();
      }
      return fields || {};
    }
  }
  return MonitorOptions;
});

