'use strict';

define('vb/extensions/dynamic/private/models/abstractModel',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/log',
  'vb/private/model/modelUtils',
], (Constants, Utils, Log, ModelUtils) => {
  const logger = Log.getLogger('/vb/extensions/dynamic/private/models/abstractModel');

  /**
   * Represents a basic non-container based 'viewmodel' for dyanmic ui JSON resources.
   * Only supports
   *
   * This is the base class for:
   *    DataDescriptionModel, DataDescriptionExtensionModel
   *    LayoutOverlayModel, LayoutOverlayExtensionModel.
   *
   * This model loads an addition JS file, from the same location ('folder') as the original metadata (JSON).
   *    data-description.json loads data-description.js
   *    data-description-overlay.json loads data-description-overlay.js
   *    data-description-x.json loads data-description-x.js
   *    field-templates-overlay.json loads field-templates-overlay.js
   *    field-templates-x.json loads field-templates-x.js
   *
   * This subclass is parameterized, because for data-description, the extension does NOT include translations,
   * but the base class does.
   * So:
   *    class DataDescriptionModel extends TranslationsModel
   *    class DataDescriptionExtensionsModel extends EmptyModel
   *
   * @returns Function(Clazz: class, filename: string )
   *    Clazz: the class to use as the base class
   *    fileNameOverride: optional, file name to load, will be prepended with the configured path.
   */
  return (Clazz, fileNameOverride) => class extends Clazz {
    /**
     * @param definition the definition/declaration for the whole file.
     * @param folder {string} location of the file.
     * @param ownerName {string} file name; may have extension, will be stripped for name-spacing and JS module name.
     * @param loaders { text: {}function}, module: {function} }
     *   these functions may be used to optionally load addition resouces. in this case,
     *   this model needs to load JS for $functions.
     */
    constructor(definition, folder, ownerName, loaders) {
      super(definition, folder, ownerName);
      // 'folder' is the where the VB JS file lives (data-description.json, data-description-overlay.json, etc)
      // 'ownerName' is the name of the file that this 'viewmodel' (aka 'expression model') represents;
      //   ex: 'data-description.json, or data-description-overlay.json.
      //   But we use it for the name of the JS file; for data-description.json, we load data-description(.js)

      this.moduleLoader = loaders.module;

      this.jsFileName = Utils.removeFileExtension(fileNameOverride || ownerName);
    }

    /**
     * load all bundles.
     * allows use of $initParams in path expressions
     *
     * note: dynamic UI artifacts are not allowed to use self-relative paths; iow, paths that start with "./".
     * the paths must either be absolute (https://..) , or relative to the app root (resources/...)
     *
     * @returns {Promise<object>}
     */
    init() {
      if (!this.loadPromise) {
        // execute loads is parallel
        this.loadPromise = Promise.all([this.load(), super.load()])
          .then(([jsModule]) => {
            this.jsModule = ModelUtils.initializeJsModule(jsModule); // uses Router.getCurrentPage() for container.
            return this;
          });
      }
      return this.loadPromise;
    }

    /**
     * this is the set of properties for expressions
     *
     * { translations: {object} }
     *
     * creates: {
     *   translations: { format: function |  object }
     * }
     * @returns {object}
     */
    getContext() {
      if (!this.context) {
        // note: super.getContext() ALSO sets this.context, but also setting it here so its clear.
        this.context = super.getContext();
        Object.defineProperty(this.context, 'functions', {
          get: () => this.jsModule,
          enumerable: true,
          configurable: false,
        });
      }
      return this.context;
    }


    /**
     * load the JS module
     */
    load() {
      // log a warning; a missing JS will be logged as a requireJS error, but we treat the JS as optional.
      const fullPath = `${this.folder}${this.jsFileName}`;
      return this.moduleLoader(fullPath)
        .catch((e) => {
          logger.warn('Unable to load JS module, ignoring.', this.jsFileName, e);
          return null;
        });
    }
  };
});

