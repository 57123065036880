/* eslint-disable no-underscore-dangle */

'use strict';

define('vb/extensions/dynamic/private/helpers/serviceMetadataProviderHelper',['vb/helpers/rest',
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/log',
  'vb/private/services/endpointReference',
  'vb/private/services/servicesManager',
  'vb/private/services/readers/openApiObjectFactory',
  'vb/private/stateManagement/application',
  'vb/private/model/modelUtils',
  'vb/private/ui/responsiveUtils',
  'vb/private/configLoader',
  'vb/private/stateManagement/layout',
  'vb/private/stateManagement/context/layoutContext',
  'vb/private/stateManagement/context/layoutBaseContext',
  'vb/extensions/dynamic/private/helpers/dataDescriptionMetadataProviderHelper',
],
(RestHelper, Constants, Utils, Log, EndpointReference, ServicesManager, OpenApiObjectFactory, Application, ModelUtils,
  ResponsiveUtils, ConfigLoader, Layout, LayoutContext, LayoutBaseContext,
  DataDescriptionMetadataProviderHelper) => {
  const logger = Log.getLogger('/vb/extensions/dynamic/private/helpers/serviceMetadataProviderHelper');

  // this is duplicated from ConfigurableMetadataProviderHelper, but its only used for a code path that
  // should not be executed any more (path overrides). It 'just in case' (and not worth sharing).
  const DATA_NAME = 'layout.json';

  // DataDescriptionMetadataProviderHelper uses data-description.json for its metadata
  // so we do not load data-description-overlay.json when using that helper.
  // but for openapi3-based metadata, we want to.
  // notice that the extension name is "-x", not "-overlay-x"
  const METADATA_OVERLAY_NAME = `${DataDescriptionMetadataProviderHelper.METADATA_NAME_ROOT}-overlay.json`;

  // as mentioned above, data-description-x.json (also) extends data-description-overlay.json
  const METADATA_OVERLAY_EXT_NAME = DataDescriptionMetadataProviderHelper.METADATA_OVERLAY_EXT_NAME;

  /* This was originally introduced before the OpenApi3 support to allow prototyping,
   * using an "indirect' mode. This is almost certainly not used any more.
   * The concept of 'indirect' was introduced early before we had OpenApi3 support, and catalog.json support,
   * as a way for the app to fetch OpenApi 3.
   *
   * @todo: remove 'indirect' after 20.10.0
   */
  const INDIRECT_MODE = 'indirect';

  /**
   * DataDescriptionMetadataProviderHelper can be used as the "vbHelper" interface that is passed to
   * JET dynamic UI metadata provider for interfacing with VB.
   *
   * This extends ConfigurableMetadataProviderHelper, and implements the openapi3-based version of
   * the helper; the metadata comes from VB service metadata, rather than a local JSON file.
   *
   * This contains one additional function, loadExternalServiceMetadata, which is used by JET, but is deprecated.
   *
   * The very early dynamic UI /VB integration required some workarounds to enable its use; that functionality
   * is all encapsualted in the class, and should eventually be deprecated/removed.
   *
   * @see DataDescriptorMetadataProviderHelper for the closely-related JSON-based version.
   *
   * its 'private', but shared with JET; not for use my application developers
   */
  class ServiceMetadataProviderHelper extends DataDescriptionMetadataProviderHelper {
    /**
     *
     * @param options {object} for the helper and provider.
     * @param options.endpoint {string} required VB endpoint ID
     * @param vbContext the context created by VB, and passed as 'options.context' to the components/providers
     * @param container the VB container where the dynamic component with this layout will be used
     * @returns {ServiceMetadataProviderHelper}
     */
    static get(options, vbContext, container) {
      const endpointId = options.endpoint;
      // get the base configuration
      const helperOptions = ServiceMetadataProviderHelper.getHelperConfiguration();

      // the 'metadata' is unused, because we override the 'fetch' method.
      delete helperOptions.descriptors.metadata;

      // add the endpointId to the existing options
      const opts = Object.assign({
        endpointId,
      }, options, { helper: helperOptions });

      return new ServiceMetadataProviderHelper(opts, vbContext, container).init();
    }

    /**
     *
     * @returns {object}
     */
    static getHelperConfiguration() {
      // get the base configuration
      const helperOptions = DataDescriptionMetadataProviderHelper.getHelperConfiguration();

      // remove the existing one, if any
      helperOptions.descriptors.files = helperOptions.descriptors
        .files.filter((d) => d.property !== 'clientMetadata');

      helperOptions.descriptors.files.push({
        property: 'clientMetadata',
        baseFile: METADATA_OVERLAY_NAME,
        extensionFile: METADATA_OVERLAY_EXT_NAME,
        prefix: 'text!',
        optional: true,
        modelClass: DataDescriptionMetadataProviderHelper.BASE_MODEL,
        extensionModelClass: DataDescriptionMetadataProviderHelper.EXTENSION_MODEL,
      });

      return helperOptions;
    }

    /**
     *
     * @param options
     * @param options.endpointId {string}
     * @param vbContext
     * @param container
     */
    constructor(options, vbContext, container) {
      super(options, vbContext, container);

      this.endpointId = options.endpointId || ''; // empty string should never happen
      this.endpointReference = new EndpointReference(options.endpointId, container);

      // If the endpointReference has a namespace, it means that we need to load the layout from
      // the extension identified by that namespace as well. If namespace is base, we use the
      // application container to load the layout artifacts. We use the base container
      // if the extension id of the base container matches namespace. Otherwise, we throw
      // an error for now until we implement a way to look up a page container given an
      // extension id.
      const { namespace } = this.endpointReference;
      if (namespace) {
        if (namespace === 'base') {
          // remember the original container which will be used to calculate layout scope name
          this.delegatingContainer = this.container;

          this.container = this.container.application;
          this.layoutRoot(Constants.DefaultPaths.LAYOUTS);
        } else if (namespace !== this.container.extensionId) {
          if (namespace === this.container.base.extensionId) {
            // remember the original container which will be used to calculate layout scope name
            this.delegatingContainer = this.container;

            this.container = this.container.base;
          } else {
            // TODO: We need a way to look up a page container given an extension id. It shouold return an empty
            // container with all the path information set up even if that container doesn't exist in that extension.
            throw new Error(`Loading layout from ${namespace} extension is currently not supported.`);
          }
        }
      }

      this.context = vbContext;
    }

    /**
     * used by JET to get a URL for an endpoint in some cases
     * @todo revisit this use-case for JET to call toUrl(), can we find a better way to do this?
     *
     * basically, we're using the VB 'endpoint' id abstraction for the initial service def,
     * but then they need the actual endpoint URL to calculate additional (openapi3) reference URLs for some cases.
     * it seems a little strange to start with the abstraction, but un-abstract later... but maybe its ok.
     * @returns {Promise<never>}
     */
    toUrl() {
      return this.getRestHelper()
        .toUrl();
    }

    /**
     * not sure if this is used by JET, but providing for symmetry
     * @returns {Promise<never>}
     */
    toRelativeUrl() {
      return this.getRestHelper()
        .toRelativeUrl();
    }

    /**
     * only using RestHelper for toUrl()/toRelativeUrl(), used by the metadata provider when constructing URLs.
     * @todo: does the providr still need to construct URLs when then switch to using programmatic VB data providers?
     * @returns {*}
     */
    getRestHelper() {
      if (!this.restHelper) {
        this.restHelper = RestHelper.get(this.endpointId, this.container);
      }
      return this.restHelper;
    }


    /**
     * @param mode  default, (unassigned), or 'indirect'. 'indirect' is deprecated.
     * @returns {ServiceMetadataProviderHelper}
     * @deprecated
     * @private
     */
    mode(mode) {
      this._mode = mode;
      if (mode === INDIRECT_MODE) {
        logger.warn('Metadata variable is using deprecated "indirect" mode. This will be removed in the next release');
      }
      return this;
    }

    /**
     * Override Rest helper, to either return a service loaded from the service declarations,
     * or an openapi3 loaded 'indirectly' via an endpoint in a swagger.
     *
     * default (none): return the service def (openapi3)
     *
     * 'indirect': @deprecated return an openapi3 that is pointed to by an openapi3. I'm sure no one uses that any more,
     *   but leave it for one version just in case.
     *
     * @returns {Promise.<Response>|*}
     * @override
     * @deprecated
     */
    fetch() {
      if (!this._fetchPromise) {
        if (this._mode === INDIRECT_MODE) {
          // this is deprecated, should be removed
          this._fetchPromise = super.fetch();
        } else {
          this._fetchPromise = this._fetchDirect();
        }
      }
      return this._fetchPromise
        .catch((e) => {
          logger.error('error during metadata provider helper fetch', e);
          throw e; // rethrow
        });
    }


    /**
     * API used by JET, to replace fetch()
     * because we override fetch() above, we must also override getMetadata,
     * and return he {data: string} object.
     *
     * @todo: eventually, we may need to support a model here
     *
     * note there is currently no 'dataModel'
     *
     * @see ConfigurableMetadataProviderHelper.getMetadata
     * @override
     */
    getMetadata() {
      return this.fetch()
        .then((metadata) => ({
          data: metadata,
        }));
    }

    /**
     * need to override, to handle a backward-compatibility issue;
     * JET bound the data-description-overlay.json to the layout.js incorrectly,
     * and apps teams used it (20.10), so we need to 'merge' those (for a while).
     *
     * see BUFP-39805
     *
     * We only need to do it here, because we only allow data-description-overlay.json for openapi3-based.
     * data-description.json does not need this.
     *
     * the following are merged, if both exist, and passed as "clientMetadataModel"
     *    for base: data-description-overlay.js & layout.js
     *    for ext: data-description-x.js & layout-x.js
     *
     * @returns {*}
     */
    getLayoutResources() {
      return super.getLayoutResources()
        .then((resourcesList) => {
          resourcesList.forEach((resources) => {
            // replace the data-description-overlay or data-description-x 'expression model'
            // with a weird hybrid, with he layout expression model merged in
            if (resources.clientMetadataModel && resources.dataModel
              && (resources.dataModel.$layout instanceof LayoutContext
                || resources.dataModel.$base instanceof LayoutBaseContext)) {
              // eslint-disable-next-line no-param-reassign
              resources.clientMetadataModel = ModelUtils
                .chooseCorrectFunctionsModel(resources.clientMetadataModel, resources.dataModel,
                  'data-description-overlay.js');
            }
          });
          return resourcesList;
        });
    }


    /**
     *
     * @returns {Promise.<{serviceDef, catalogInfo, requestInit}>}
     * @private
     */
    _fetchServiceInfo() {
      if (!this._fetchServiceInfoPromise) {
        this._fetchServiceInfoPromise = ServicesManager.getDefinitionInfo(this.endpointReference);
      }
      return this._fetchServiceInfoPromise;
    }

    /**
     * 'direct' means, return the service definition already referenced by the application.
     * The service is loaded using the normal service definition loading mechanisms, instead of Rest helper fetch().
     * @returns {Promise.<Response>}
     * @private
     */
    _fetchDirect() {
      return this._fetchServiceInfo()
        .then((info) => {
          if (info && info.serviceDef) {
            const s = JSON.stringify(info.serviceDef);
            return new Response(s);
          }

          throw new Error(`unable to load service definition: ${this.endpointReference.serviceId}`);
        });
    }


    /**
     * Allows override of the default '../../dynamicLayouts/<service>/<path>/layout.json' layout path
     * This also overrides the layoutRoot.
     * @param path
     * @returns {ServiceMetadataProviderHelper}
     */
    layoutPath(path) {
      this.layoutPathOverride = path;
      return this;
    }


    /**
     * Get the path for the given operationId, remove all the parameters, and use that as the path.
     * examples: /foo/{foo_id}/bar/{bar_id} => foo/bar
     *
     * @private
     * @override
     */
    _calcLayoutPath() {
      if (!this._layoutPrefixPromise) {
        if (this.layoutPathOverride) {
          // getLayoutPrefix used to be getLayoutPath; the override isn't ever used, but just in case it is,
          // interpret it as a path prefix, and strip the layout.json
          let prefixOverride = this.layoutPathOverride;
          if (prefixOverride.endsWith(DATA_NAME)) {
            prefixOverride = prefixOverride.substring(0, prefixOverride.length - (DATA_NAME.length));
          }
          this._layoutPrefixPromise = Promise.resolve(prefixOverride);
        } else if (!this.endpointReference.operationId) {
          // fallback, but this will never happen. do something reasonable if it dos, for some weird reason.
          logger.warn('no operationId, using empty path');
          this._layoutPrefixPromise = Promise.resolve(this.normalizePath(''));
        } else {
          this._layoutPrefixPromise = this.fetch()
            .then((response) => {
              if (response.ok) {
                return response.clone().json();
              }
              throw new Error(`unable to get layout prefix for ${this.endpointId}`);
            })
            .then((definition) => {
              let path = null;

              const openApi = OpenApiObjectFactory.get(definition);
              openApi.pathObjects.some((pathObject) => {
                const foundOp = pathObject.operationObjects
                  .find((op) => op.operationId === this.endpointReference.operationId);
                if (foundOp) {
                  path = this.normalizePath(pathObject.path);
                }
                return path;
              });
              if (!path) {
                logger.error('unable to find operationId for layout prefix:', this.operationId);
              }
              return path;
            });
        }
      }
      return this._layoutPrefixPromise;
    }


    /**
     * provide a way to vbHelper clients to follow external OpenaAPI links (operationRef, x-links, etc),
     * using the same headers/proxy/etc that  was used to fetch the original service definition.
     *
     * @param url
     * @returns {Promise<string>}
     *
     * @deprecated JET components should do the fetch directly, and VB will intercept using UrlMapper
     */
    loadExternalServiceMetadata(url) {
      // use the original behavior if mapping is disabled
      logger.warn('ServiceMetadataProviderHelper.loadExternalServiceMetadata is DEPRECATED! ',
        'It will be removed in a future release.');
      if (this.urlMapperDisabled) {
        let requestInit;
        return Promise.resolve()
          .then(() => {
            if (!url) {
              throw new Error('invalid URL passed to loadExternalServiceMetadata.');
            }
            return this._fetchServiceInfo();
          })
          .then((info) => {
            requestInit = info.requestInit;
            return Utils.getRuntimeEnvironment();
          })
          .then((env) => env.getServiceDefinition(url, requestInit))
          .catch((e) => {
            logger.error('failed to load serviceDefinition for metadata url', url, e);
            throw e; // rethrow
          });
      }

      // just do the fetch using the URL
      return fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }

          logger.error('failed to load serviceDefinition for metadata url', url, response.status);
          throw Error(`unable to load service metadata: status: ${response.status}`);
        });
    }


    /**
     * remove parameters from the path, includes ending slash
     *
     * example: dynamicLayouts/insidesales/
     *
     * @param path
     */
    normalizePath(path = '') {
      // Remove parameters in { }
      const parts = path.split('/');
      const newPath = parts.filter((part) => part && part[0] !== '{').join('/');

      const operationPart = Utils.addTrailingSlash(newPath);
      // don't add the service ID, because elastic search and data services may need to share layouts
      // return `${this.layoutRootPrefix}${this.serviceId}${Constants.PATH_SEPARATOR}${operationPart}`;
      return `${this.layoutRootPrefix}${operationPart}`;
    }
  }

  return ServiceMetadataProviderHelper;
});

