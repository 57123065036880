'use strict';

define('vb/private/services/readers/openApiObjectFactory',['./openApi2Object', './openApi3Object'],
(OpenApi2Object, OpenApi3Object) => {
  /**
   *
   */
  class OpenApiObjectFactory {
    /**
     * return a model for the definition
     * @param definition the JSON object
     * @param context abstraction of Application context. optional, used for variables substitution.
     *        One of the used properties for context is 'definitionUrl' whose value should be the
     *        url (or path) from which the definition was obtained.
     * @returns {OpenApiObjectCommon}
     */
    static get(definition, context) {
      if (definition.openapi) {
        return new OpenApi3Object(definition, context);
      }
      return new OpenApi2Object(definition, context);
    }
  }

  return OpenApiObjectFactory;
});

