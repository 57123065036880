'use strict';

define('vb/private/stateManagement/layout',[
  'vb/private/stateManagement/container',
  'vb/private/stateManagement/context/layoutContext',
  'vb/private/stateManagement/layoutExtension',
  'vb/private/utils',
  'vb/private/constants',
  'vb/private/translations/bundlesModel',
], (Container, LayoutContext, LayoutExtension, Utils, Constants, BundlesModel) => {
  /**
   * Layout class: A VB model for dynamic layout
   *
   */
  class Layout extends Container {
    /**
     * Layout constructor
     *
     * @param  {String} id        a string id to identify the layout, for the
     * MetadataProviderHelper case, this will be the endpoint id
     * @param  {Page} page        the page that contain this layout
     * @param  {Page} delegator  the container that delegated to the given page container to load the layout
     * @param path {string} path with file name (no extension)
     * @param  {String} className the class name
     * @return {Layout}           layout object
     */
    constructor(id, page, delegator, path, className = 'Layout') {
      // There might be multiple instance of this layout in a page,
      // so generate a unique id.
      super(id, page, className);

      const contextPage = delegator || page;
      if (contextPage) { // some test don't include the container
        contextPage.registerLayout(this);
      }

      Object.defineProperties(this, {
        // Redefine the value of the name property and this time
        // it's read-only and cannot be redefined again (configurable is false)
        // The name is always layout, layout.json, layout-x.json
        name: {
          value: 'layout',
          enumerable: true,
        },
        path: {
          // It is possible the layout is created with a path already been set. In that
          // case, make sure it's not a dup of baseUrl
          value: Layout.calculatePath(path, this.baseUrl),
          enumerable: true,
          configurable: true, // redefined by packageLayout subclass
        },
      });

      if (delegator) {
        this.delegator = delegator;

        // recalculate the container path using the delegator as the container
        this.fullPath = this.getContainerPath();
      }
    }

    static get extensionClass() {
      return LayoutExtension;
    }

    /**
     * Use to calculate the path of the layout.
     * It is possible the layout is created with a path already been set. In that
     * case, make sure it's not a dup of baseUrl
     * @param  {String} path
     * @param  {String} baseUrl
     * @return {String}
     */
    static calculatePath(path, baseUrl) {
      if (path && baseUrl && path.startsWith(baseUrl)) {
        return path.substring(baseUrl.length);
      }

      return path;
    }

    descriptorLoader(resourceLocator) {
      const resource = `${resourceLocator}.json`;
      return this.application.runtimeEnvironment.getTextResource(resource)
        .then((jsonContent) => Utils.parseJsonResource(jsonContent, resource));
    }

    functionsLoader(resourceLocator) {
      return super.functionsLoader(resourceLocator);
    }

    templateLoader(resourceLocator) {
      return super.templateLoader(`${resourceLocator}.html`);
    }

    /**
     * @override
     * @returns {*}
     */
    loadTemplate() {
      return super.loadTemplate()
        .catch((e) => {
          // swallow error, html isn't required for Layout
          // swallow errors for missing files, log the rest
          if (!e.requireType || e.requireType !== 'scripterror') {
            this.log.error('Error loading module', this.getResourcePath(), e);
          }
          return Promise.resolve();
        });
    }

    /**
     * The name of the runtime environment function to be used to load the module functions
     *
     * @return {String} the module loader function name
     */
    // eslint-disable-next-line class-methods-use-this
    get functionsLoaderName() {
      return 'getModuleResource';
    }

    /**
     * The name of the runtime environment function to be used to load the html
     *
     * @return {String} the template loader function name
     */
    // eslint-disable-next-line class-methods-use-this
    get templateLoaderName() {
      return 'getTextResource';
    }

    /**
     * returns the LayoutContext constructor used to create the '$' expression context
     * @return { LayoutContext.constructor }
     * @override
     */
    static get ContextType() {
      return LayoutContext;
    }

    initDefault(definition) {
      const def = definition;

      // Temporarily provide backward compatibility for the dynamic component metadata. This is
      // for the case where layoutTypes is not yet used by the dynamic component. In that case we
      // can only assume types is for the dynamic component, not the VB model.
      if (def.types && !def.layoutTypes) {
        delete def.types;
      }

      return super.initDefault(def);
    }


    /**
     * Returns a promise that resolves when all resources associated with the layouit container are loaded
     */
    load() {
      this.loadPromise = this.loadPromise || this.loadMetadata()
        .then(() => Promise.all([this.loadExtensions(), this.loadImports(), this.loadTranslationBundles()]))
        .then(() => {
          this.combineExtensions();
          // Setup the component event listeners
          this.initializeEvents();

          // initialize action chains
          this.initializeActionChains();

          return this.initAllVariableNamespace();
        });

      return this.loadPromise;
    }

    /**
     * overrides the base impl, so we can add "allowSelfRelative: false"
     * @returns {null}
     * @overrides
     */
    loadTranslationBundles() {
      if (!this.loadBundlesPromise) {
        // todo : define system vars, for substitutions in path
        this.loadBundlesPromise = BundlesModel.loadBundlesModel(this.application.runtimeEnvironment, this.definition,
          this.getResourceFolder(), { initParams: this.application.initParams, allowSelfRelative: false },
          this.extension)
          .then((bundlesModel) => {
            this.bundles = bundlesModel;
            return this.bundles;
          });
      }
      return this.loadBundlesPromise;
    }


    /**
     * Returns the model object for the layout container. The model contains all the accessible $ properties
     * of the layout container. All properties are initialized and ready to be evaluated in expressions.
     * Extensions have also been applied to the layout.
     * The model is bound to the layout view by the JET dynamic component.
     *
     * {
     *   $variables: {}
     *   $constants: {}
     *   $chains: {}
     *   $functions: {}
     *   $listeners: {}
     *   $layout: {}
     * }
     *
     * @return {Promise<model>} a promise that resolve with the model object.
     */
    getViewModel() {
      return Promise.all([
        this.load(), this.loadFunctionModule(), this.loadTemplate(),
      ])
        .then(() => {
          const viewModel = {};
          const availableContexts = this.getAvailableContexts();

          Object.getOwnPropertyNames(availableContexts).forEach((key) => {
            const descriptor = Object.getOwnPropertyDescriptor(availableContexts, key);
            Object.defineProperty(viewModel, key, descriptor);
          });

          return viewModel;
        });
    }

    /**
     * Used by event processing. For Layout containers, we start (and end) with the Layout itself,
     * unless it is a "dynamicComponent" event, which uses a completely different propagation implementation.
     * (For 'dynamicComponent' behavior, FireCustomEventAction delegates to the JET component.)
     * @see FireCustomEventAction
     *
     * @overrides Container.getLeafContainer
     * @returns {Layout}
     * @override
     */
    // eslint-disable-next-line class-methods-use-this
    getLeafContainer() {
      return this;
    }

    getScopeResolverMap() {
      return {
        [Constants.LAYOUT_PREFIX]: this,
      };
    }

    getContainerPath() {
      const parent = this.delegator || this.parent;
      if (parent) {
        const parentId = parent.getContainerPath();
        return parentId ? `${parentId}${Constants.PATH_SEPARATOR}${this.id}` : this.id;
      }

      return '';
    }

    /**
     * Add the extension id as part of the name to make it unique between
     * multiple extensions.
     * @return {String} a new scope name
     */
    getNewScopeName() {
      // use the delegator's extension id if this layout is delegated from a container
      // from another extension
      const extensionId = this.delegator ? this.delegator.extensionId : this.extensionId;

      return `${this.className}/${extensionId}/${this.fullPath}`;
    }
  }

  return Layout;
});

