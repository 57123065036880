/* eslint-disable max-len */

'use strict';

define('vb/private/services/operationRefEndpoint',[
  'vb/private/log',
  'vb/private/services/servicesManager',
  'vb/private/services/uriTemplate',
  'vb/private/services/endpointReference',
], (Log, ServicesManager, UriTemplate, EndpointReference) => {
  const logger = Log.getLogger('/vb/private/services/operationRefEndpoint');
  /**
   * examples of operationRefs
   *
   * "x-links": { // (or "links")
   *   "SalesProfileTypeLookupLOV": {
   *      "operationRef" : "https://fuscdrmsmc217-fa-ext.us.oracle.com:443/crmRestApi/resources/11.12.0.0/crmBusinessUnits/describe#/paths/~1crmBusinessUnits~1{crmBusinessUnits_Id}~1child~1fndCommonSetIdLookups/get",
   *      "x-lov" : {
   *        "definedOn" : "$this#/RetireReasonCode",
   *        "valueField" : "LookupCode",
   *        "displayFields" : [ "Meaning" ]
   *       },
   *       "parameters" : {
   *         "finder" : "CommonSetIdLOVFinder%3BpLookupType%3DMKL_RETIRE_REASON_CD_SETID%2CpEffectiveDate%3D2020-03-19%2CpEnabledFlag%3DY%2CpReferenceGroupName%3DMKL_RETIRE_REASON_CD_SETID"
   *       }
   *
   * ************
   *
   * "operationRef" : "https://fuscdrmsmc217-fa-ext.us.oracle.com:443/fscmRestApi/resources/11.13.18.05/standardLookupsLOV/describe#/paths/~1standardLookupsLOV/get",
   * "x-lov" : {
   *  "definedOn" : "$this#/RecordSet",
   *    "valueField" : "LookupCode",
   *    "displayFields" : [ "Meaning" ]
   *   },
   *  "parameters" : {
   *     "finder" : "LookupTypeFinder%3BLookupType%3DORA_HZ_RESOURCE_SEARCH_REC_SET"
   *
   * *************
   *
   * "operationRef" : "#/paths/~1resources~1{resources_Id}/get",
   * "parameters" : {
   *   "resources_Id" : "$request.path.resources_Id"
   * }
   */

  /**
   * This class implements the Endpoint interface.
   * BUT Endpoint is a concrete class, and contains a lot of functionality that isn't needed here,
   * so this does not extend Endpoint.
   *
   * @todo: make Endpoint an interface, and extend it for the two concrete classes.
   */
  class OperationRefEndpoint {
    /**
     * The OperationRefEndpoint may or may not have a parent Service, depending on whether the URL could be mapped.
     *
     * @param serviceId {string|null}
     * @param url
     * @param operationRef
     * @param {object} [container] - typically the container which defines the scope in which the endpoint is being
     *                                 invoked.
     * @param {string} [container.extensionId] - the container's extension Id (or namespace), which is used to
     *                                           set the endpoint reference 'containerNamespace' property (defaults
     *                                           to 'base' if not informed).
     */
    constructor(serviceId, url, operationRef, container) {
      this.url = url;

      // deconstructed operationRef
      const parts = (operationRef || '').split('#');
      const pathParts = (parts[1] || '').split('/');

      const pathSegments = pathParts.map((name) => name.replace(/~1/g, '/'));

      if (pathSegments.length !== 4 || !pathSegments[3]) {
        logger.warn('Unable to determine HTTP method from "operationRef", using "GET"', operationRef);
      }
      this.method = pathSegments[3] || 'GET';

      // parameter replacement support
      this.uriTemplate = new UriTemplate(url);

      // @todo: we should be able to create this from the url & operationRef
      this.endpointReference = new EndpointReference({ serviceId, url, operationRef }, container);
    }

    /**
     *
     * @returns {Promise<any>}
     */
    init() {
      if (!this.servicePromise) {
        this.servicePromise = Promise.resolve()
          .then(() => {
            if (this.endpointReference.serviceId) {
              // eslint-disable-next-line no-underscore-dangle
              return ServicesManager._findDefinition(this.endpointReference)
                .then((def) => {
                  this.service = def ? def.service : null;
                });
            }
            this.service = null;
            return null; // for eslint, not used.
          })
          .then(() => this); // return the Endpoint
      }
      return this.servicePromise;
    }

    // {
    //   "url": "http://localhost:1988/webApps/ifixitfaster/api/incidents?technician=hcr",
    //   "method": "GET",
    //   "headers": {
    //     "vb-info-extension": "{\"headers\":{},\"baseUrl\":\"\",\"serviceName\":\"incidents\"}",
    //     "vb-allow-anonymous-access": true
    //   },
    //   "requestContentTypes": [],
    //   "responseContentTypes": []
    // }"
    getConfig(parameters, options) {
      return this.init()
        .then(() => {
          // simple parameter support, for path and query params
          const ignoreMissing = options && options.ignoreMissingParams;
          const url = this.uriTemplate.replace(parameters, ignoreMissing);

          return {
            url,
            method: this.method,
            headers: {},
            requestContentTypes: [],
            responseContentTypes: [],
          };
        });
    }

    // passed to transform as config.endpointDefinition
    // {
    //   "name": "getIncidents",
    //   "serviceId": "incidents",
    //   "method": "GET",
    //   "description": "",
    //   "url": "http://localhost:1988/webApps/ifixitfaster/api/incidents",
    //   "baseUri": "http://localhost:1988/webApps/ifixitfaster/api",
    //   "requestContentTypes": [],
    //   "responseContentTypes": [],
    //   "parameters": {
    //     "query": {
    //       "technician": {
    //         "defaultValue": "hcr",
    //         "name": "technician",
    //         "in": "query",
    //         "x-vb-defaultValue": "hcr"
    //       }
    //     }
    //   },
    //   "headers": {},
    //   "staticQueryParameters": {}
    // }"
    // eslint-disable-next-line class-methods-use-this
    getMetadata() {
      return {};
    }

    getRequestTransforms() {
      return this.service && this.service.getRequestTransforms ? this.service.getRequestTransforms() : {};
    }

    getResponseTransforms() {
      return this.service && this.service.getResponseTransforms ? this.service.getResponseTransforms() : {};
    }

    getMetadataTransforms() {
      return this.service && this.service.getMetadataTransforms ? this.service.getMetadataTransforms() : {};
    }
  }

  return OperationRefEndpoint;
});

