/* eslint-disable no-underscore-dangle */

'use strict';

define('vb/private/services/services',['vb/private/log',
  'vb/private/services/serviceProvider',
  'vb/private/constants',
  'vb/private/services/serviceConstants',
  'vb/private/utils',
  'vb/binding/expression',
  'vb/private/services/serviceDefinition',
  'vb/private/services/readers/openApiObjectFactory',
  'vb/private/configLoader',
  'vb/private/services/servicesLoader',
  'vb/private/services/serviceUtils',
  'vb/private/services/endpointReference',
  'urijs/URI'],
(Log, ServiceProvider, Constants, ServiceConstants, Utils, Expression, ServiceDefinition,
  OpenApiObjectFactory, ConfigLoader, ServicesLoader, ServiceUtils, EndpointReference, URI) => {
  const logger = Log.getLogger('/vb/private/services/services');

  /**
   * Services
   */
  class Services {
    /**
     * @param options, contains the following properties (all required, except where noted):
     *  - relativePath
     *  - serviceFileMap map (plain js obj) of service name to service definition file
     *     { myService: 'my-service.json', anotherService: 'blah.json', <etc>}
     *  - expressionContext (also known as 'scope') the dollar vars available to path expressions
     *  - protocolRegistry {ProtocolRegistry} handing 'vb-catalog', for layering local metadata on service defs
     *  - isUnrestrictedRelative: optional, defaults to false
     */
    constructor({
      relativePath,
      serviceFileMap,
      expressionContext,
      protocolRegistry,
      isUnrestrictedRelative,
      namespace,
    }) {
      this._relativePath = relativePath || '';

      const fileMap = serviceFileMap || {};
      Utils.removeDecorators(fileMap);

      // paths now support expressions
      this._serviceFileMap = fileMap;
      this._expressionContext = expressionContext;

      this._serviceProviderMap = {};
      this._pendingProviders = [];

      this._protocolRegistry = protocolRegistry;

      // means this is declared in the Application, and treats paths differently
      this.isUnrestrictedRelative = !!isUnrestrictedRelative;

      this.namespace = namespace || Constants.ExtensionNamespaces.BASE;
      // ServiceUtils.createNamespaceMap() return  a simple map-like data structure,
      // that takes 'id' and 'namespace' as params for the 'key'.
      this._loadedServicePromises = ServiceUtils.createNamespaceMap(this.namespace);
      this._endpointMap = ServiceUtils.createNamespaceMap(this.namespace);

      // Ordered collection of services to which this Services delegates. In other words,
      // if I a service is not found on this Services, it searches for it on the Services
      // from this array, in the order they show here.
      this._delegates = [];

      // create a warning for deprecated expressions
      Services.checkForDeprecatedPathExpressions(this._serviceFileMap);
    }

    /*
     * At the moment...
     * - A "services" (i.e. an instance of this class) is associated with one extension
     * - A "services" manages the services for the extension
     * - A "services" knows about the "services" for the required extensions.
     *    - The "services" of a required extension is called "delegate"
     *    - this._delegates is an array of Services, each associated with an extension that this
     *      extension requires to work
     *    - The list of delegates is created upfront by the application, right after the instantiation of the Services.
     * - When looking for a service, this services looks into its own managed services, then looks into the managed
     *   services of each delegate. This search is done recursively, stopping as soon as the service is found.
     *
     * The intent for the future is to change this algorithm. The goal then would be for the Application to provide a
     * way for clients (like Services) to traverse the required extensions only when needed. The computation of the
     * dependency graph as well the loading of any artifact and/or state would then be done on demand.
     */

    /**
     * @param {Services} services
     * @return Services this services
     */
    addDelegate(services) {
      // this is OK because we will not add services too frequently (at the moment only during the Application loading)
      if (services !== this && !this._delegates.includes(services)) {
        this._delegates.push(services);
      }

      return this;
    }

    /**
     * Asynchronously traverses the delegators until selector returns a truthy result.
     * @param {function(Services):*} selector
     * @return {Promise<*>}
     */
    searchDelegates(selector) {
      return this._delegates.reduce(
        (promise, delegate) => promise.then((result) => result || selector(delegate)),
        Promise.resolve(),
      );
    }

    /**
     * @param endpointReference {EndpointReference}
     * @returns {Promise<boolean>} true, means the given ID is declared
     */
    containsDeclaration(endpointReference) {
      return this.isNamespaceMatch(endpointReference.namespace)
        ? this.findDeclaration(endpointReference.serviceId, endpointReference).then((declaration) => !!declaration)
        : Promise.resolve(false);
    }

    /**
     * Default impl looks in the map and maybe at the delegate services.  Can be overridden to provide a
     * default declaration, when there isn't one.
     *
     * @param {string} name {string} service name
     * @param {EndpointReference} [endpointReference] optional
     * @returns {Promise<object|null>}
     */
    // eslint-disable-next-line no-unused-vars
    findDeclaration(name, endpointReference) {
      const path = this._serviceFileMap[name] || null;
      if (path || !name) {
        return Promise.resolve(path);
      }

      // if we the serviceName is not in _serviceFileMap and this is a base services, try to find it in any of the
      // delegates. However, specifically because typically the delegates is the fallbackServices,
      // we need to make sure that serviceName exists because, otherwise, we'd be returning "found" for a
      // service that does not exist.
      if (this.namespace === Constants.ExtensionNamespaces.BASE) {
        const promise = this.searchDelegates((delegate) => delegate.load([name], false, endpointReference)
          .then((result) => (result[0] && result[0].name === name
            ? delegate.findDeclaration(name, endpointReference)
            : null)));
        return promise
          .catch((error) => {
            logger.error(`Delegate services failed to load service ${name}: ${error}`, error);
            return null;
          });
      }

      return Promise.resolve(null);
    }

    /**
     * Add an instance of ServiceProvider that needs to be registered to provide the service
     * @param serviceProvider
     */
    addServiceProvider(serviceProvider) {
      if (serviceProvider instanceof ServiceProvider) {
        const serviceName = serviceProvider.getServiceName();
        if (!serviceName) {
          const error = new Error('The serviceProvider does not provide a service name.');
          logger.error(error);
          throw error;
        }
        if (!serviceProvider.getServiceFilePath()) {
          const error = new Error('The serviceProvider does not provide a service file path.');
          logger.error(error);
          throw error;
        }
        if (!serviceProvider.getDefinition()) {
          const error = new Error('The serviceProvider does not provide a service definition.');
          logger.error(error);
          throw error;
        }

        // if the provider or the service def has already been registered or loaded...
        if (this._serviceProviderMap[serviceName]) {
          const error = new Error(`The serviceProvider with name ${serviceName} is already registered.`);
          logger.error(error);
          throw error;
        }

        // use our namespace
        if (this._loadedServicePromises.has(serviceName, this.namespace)) {
          const error = new Error(`A service with name ${serviceName} is already registered.`);
          logger.error(error);
          throw error;
        }

        this._serviceProviderMap[serviceName] = serviceProvider;

        // queue it for load on the next load() call
        this._pendingProviders.push(serviceProvider);
      } else {
        logger.error('Given serviceProvider is not an instance of ServiceProvider');
      }
    }

    /**
     * loads the service specified by the provider
     * @param serviceProvider
     * @returns {Promise}
     */
    loadServiceProvider(serviceProvider) {
      const serviceName = serviceProvider.getServiceName();

      // use a stubbed catalogInfo (chain: []), and use our namespace
      if (!this._loadedServicePromises.has(serviceName, this.namespace)) {
        if (this.isPathAllowed(serviceProvider.getServiceFilePath())) {
          this._loadedServicePromises.set(serviceName, this.namespace, Promise.resolve()
            // We don't need the catalog info and requestInit for service providers right now.
            .then(() => this
              .createServiceDefinition(serviceName, serviceProvider.getServiceFilePath(),
                { chain: [] }, Services.getOpenApi(serviceProvider.getDefinition()),
                null, this.namespace)));
        }
      }

      return this._loadedServicePromises.get(serviceName, this.namespace);
    }

    /**
     * @param namesToLoad optional array of keys/names. if passed, limit loading to only these
     * @param forceReload optional. by default, services are not reloaded if previously loaded
     * @param endpointReference {EndpointReference}
     * @returns {Promise} resolved to an Array of ServiceDefinition objects, for only the 'namesToLoad'
     */
    load(namesToLoad, forceReload, endpointReference) {
      // if new providers have been added, make sure we load those
      return Promise.resolve()
        .then(() => {
          // filtering empty names to avoid unnecessary work.
          const servicesToLoad = (namesToLoad || Object.keys(this._serviceFileMap)).filter((n) => !!n);

          const filteredMap = {};
          const promises = servicesToLoad.map((name) => this.findDeclaration(name, endpointReference)
            .then((declaration) => {
              if (declaration) {
                // convert 'string' declaration to objects
                if (typeof declaration === 'string') {
                  // eslint-disable-next-line no-param-reassign
                  declaration = { path: declaration };
                }

                if (declaration.path) {
                  filteredMap[name] = declaration;
                }
              } else {
                logger.info('service:', name, 'not found in container', this._relativePath, 'continuing');
              }
            }));

          return Promise.all(promises).then(() => filteredMap);
        })
        .then((filteredMap) => {
          // fileInfos will be { path: '...', <headers: {...}> }
          const fileInfos = Object.values(filteredMap);
          const serviceNames = Object.keys(filteredMap);

          // even if they've already been loaded, add the promise to the map. this includes provider promises.
          fileInfos.forEach((fileInfo, index) => {
            // path might be an expression, evaluate it
            const fileName = this.evaluatePathDeclaration(fileInfo.path);

            // allow for an alternate 'proxyName' as an internal workaround for when the declaration name
            // does not match the name of the service in the proxy url.
            const serviceName = serviceNames[index];

            // would not expect to have to use the default here
            // eslint-disable-next-line max-len
            const namespace = (endpointReference && endpointReference.derivedNamespace)
              || Constants.ExtensionNamespaces.BASE;

            if (this.isPathAllowed(fileName)) {
              // skip if not forReload and its already in the map
              if (forceReload || !this._loadedServicePromises.has(serviceName, namespace)) {
                // open and validate the swagger
                this._loadedServicePromises.set(serviceName, namespace,
                  this.loadService(serviceName, fileName, fileInfo.headers, namespace));
              }
            }
          });

          // add any new ones
          this._pendingProviders.forEach((provider) => {
            // this puts it in _loadedServicePromises map
            this.loadServiceProvider(provider);
          });
          this._pendingProviders = [];

          // all the pending promises
          const promises = this._loadedServicePromises.getValues();

          // recursive; by the time all the loads resolve, we may have registered and loaded more providers.
          // so, when resolved, get the map count, and make a new list of promises if needed.
          // return Promise.all(all loaded services)
          const allLoadedPromises = (promisesArray) => {
            const currentCount = this._loadedServicePromises.getKeys();

            return Promise.all(promisesArray)
              .then((results) => {
                // check for new ones
                const newCount = this._loadedServicePromises.getKeys();
                if (newCount > currentCount) {
                  return allLoadedPromises(this._loadedServicePromises.getValues());
                }
                return results;
              });
          };

          // wait for the current loads, and then keep waiting for any new loads, if needed
          return allLoadedPromises(promises);
        })
        // 'loaded' is ALL the service promises, so far. resolve with ONLY the services we asked for.
        .then((loaded) => {
          if (namesToLoad) {
            const mapped = {};
            loaded.filter((def) => def).forEach((def) => {
              mapped[def.name] = def;
            });
            return namesToLoad.map((name) => mapped[name] || {});
          }
          return [];
        });
    }

    /**
     * Clients should not invoke this method directly.
     *
     * @param serviceName
     * @param fname
     * @param declaredHeaders optional. will be merged with the catalog headers, if any (catalog takes precedence)
     * @param namespace optional, from endpoint ID reference
     * @protected
     */
    loadService(serviceName, fname, declaredHeaders, namespace) {
      // offset by the location of the container
      let catalogInfo;
      let requestInit;
      let fileName;

      return Promise.resolve()
        .then(() => {
          fileName = this.getDefinitionPath(fname, namespace);

          return ServicesLoader
            .getCatalogExtensions(this._protocolRegistry, fileName, serviceName, namespace, declaredHeaders);
        })
        .then((catInfo) => {
          catalogInfo = catInfo;

          // used by Services, and merges with "backends" extensions in the Endpoint
          // services.extensions only contains headers currently, so we can use in Request construction directly
          requestInit = (catalogInfo.services && catalogInfo.services.extensions);

          const metadata = (catalogInfo.services && catalogInfo.services.metadata);

          if (metadata) {
            // if the catalog has a "paths" to declare how to fetch the openapi3, use that
            return Services.getOpenApiObjectUsingMetadata(serviceName, catalogInfo.url, metadata, requestInit);
          }
          return Services.getOpenApiObject(serviceName, catalogInfo.url, requestInit);
        })
        .then((openApi) => this
          .createServiceDefinition(serviceName, fileName, catalogInfo, openApi, requestInit, namespace))
        .catch((e) => {
          logger.error('service load error: ', e);
          // throw e;
          return null; // allow the rest of the loads to pass
        });
    }

    /**
     * construct a ServiceDefinition
     * @param serviceName name of the service; the property name from the app-flow.json declaration
     * @param fileName service metadata (openapi/swagger) path
     * @param catalogInfo services/backends information from any vb-catalog references
     * @param openApi service metadata object (OpenApiCommonObject)
     * @param requestInit additional config for a Request object (headers, etc).
     * @param namespace {string}
     * @returns {ServiceDefinition}
     * @private
     */
    createServiceDefinition(serviceName, fileName, catalogInfo, openApi, requestInit, namespace) {
      const service = new ServiceDefinition(serviceName, fileName, this._protocolRegistry, catalogInfo, openApi,
        this._relativePath, requestInit, namespace, this.isUnrestrictedRelative);

      // notify listeners
      try {
        ServicesLoader.notify(service);
      } catch (e) {
        logger.error(e);
      }

      Object.keys(service._getEndpoints()).forEach((endpointName) => {
        const key = `${service.name}/${endpointName}`;
        // don't use getEndpoint(), we don't need to load transform modules at this point
        this._endpointMap.set(key, namespace, service._endpoints[endpointName]);
      });
      return service;
    }

    /**
     * if we're using a "paths" object from the catalog,
     * we need to merge the 'x-vb' from the "servers" object with the 'x-vb' from both the
     * "info" object for the openapi3 "services" fragment, and its operation (ex. "get") object.
     *
     * for example, use the proxy when fetching the /describe below, use the "accepts" for the fetch,
     * and apply "some/transforms" to the result.
     *
     * this is analogous to what we do today in just swagger/openapi3 with no catalog.json involved; we
     * look at "info", "services", and "paths", when we construct a merged "x-vb" (listed least-to-most precedence).
     *
     *
     * "services": {
     * "demo": {
     *   "openapi": "3.0",
     *   "info": {
     *     "title": "uses new inner-service-openapi3-metadata syntax",
     *     "x-vb": {
     *       "transforms": {
     *         "path": "some/transforms"
     *       }
     *     }
     *   },
     *   "servers": [
     *     {
     *       "url": "vb-catalog://services/demolevel2",
     *       "x-vb": {
     *           "authentication": {
     *               "forceProxy": "cors"
     *           }
     *       }
     *     }
     *   ],
     *   "paths": {
     *     "somepath/describe": {
     *       "get": {
     *         "x-vb": {
     *           "headers": {
     *            "Accepts":  "application/vnd.oracle.adf.openapi3+json"
     *           }
     *         }
     *       }
     *     }
     *   }
     * },
     *
     * @param serviceName
     * @param serverUrl typically, from the resolved "servers" object
     * @param metadata  the 'paths.get" path (and query) will be appended to the url, and the extensions merged.
     * @param requestInit
     * @returns {Promise}
     *
     * @private
     */

    static getOpenApiObjectUsingMetadata(serviceName, serverUrl, metadata, requestInit) {
      let mergedExtensions;
      return Promise.resolve()
        .then(() => {
          mergedExtensions = ServiceUtils.getExtensionsFromMetadata(serverUrl, metadata, requestInit);

          const { url } = mergedExtensions;
          delete mergedExtensions.url;

          return Services.getOpenApiObject(serviceName, url, mergedExtensions);
        });
    }

    /**
     * first, loads the service definition (swagger/openapi) referenced by the endpoint ID.
     * then finds the endpoint, and calls endpoint.load(), to load any extensions (transforms).
     * returns the Endpoint if found, even when the transforms module is not loaded properly
     * @param endpointReference {EndpointReference}}
     * @returns {Promise<Endpoint|null>}
     */
    getEndpoint(endpointReference) {
      let endpoint;

      // if we get a string for some reason, convert it
      if (typeof endpointReference === 'string') {
        // eslint-disable-next-line no-param-reassign
        endpointReference = new EndpointReference(endpointReference);
      }

      // only load the requested service
      return Promise.resolve()
        .then(() => {
          if (!this.isNamespaceMatch(endpointReference.namespace)) {
            return false;
          }

          const servicesToLoad = [endpointReference.serviceId];
          return this.load(servicesToLoad, false, endpointReference)
            .then(() => true);
        })
        .then((match) => {
          if (match) {
            const endpointKey = `${endpointReference.serviceId}/${endpointReference.operationId}`; // no the namespace
            endpoint = this._endpointMap.get(endpointKey, endpointReference.namespace);
            if (!endpoint) {
              endpoint = this._endpointMap.get(endpointKey, endpointReference.containerNamespace);
            }

            // can only use the endpoint if...
            // - it exists
            // - And one of the following
            // -- the extension of this services is the same one using the endpoint
            // -- the endpoint does not have a service (should not be here but...)
            // -- the service definition of the endpoint indicates that it's accessible to other extensions

            if (endpoint
              && (this.namespace === endpointReference.containerNamespace
                || !endpoint.service
                || endpoint.service.isExtensionAccessible())) {
              return endpoint.load();
            }
          }

          return this.searchDelegates((delegate) => delegate.getEndpoint(endpointReference));
        })
        .catch((e) => {
          logger.error('Error loading endpoint', endpointReference, e);
          return endpoint;
        })
        .then((ep) => ep || null);
    }

    /**
     * we match if EITHER:
     *  - there's no namespace in the endpoint ID
     *  - there is a namespace in the endpoint ID, and it matches ours
     * @param namespace
     * @returns {boolean}
     */
    isNamespaceMatch(namespace) {
      return this.namespace === namespace || !namespace;
    }


    /**
     * create a path relative to the container, if needed.
     * We will only prepend the container path if either:
     *  - (a) we are loaded by the Application, OR
     *  - (b) we start with a '.' (dot)
     *
     * case (a) insures that Application service paths work they always have - they are always prepended
     * (which for an app-level service, should be a no-op, but just in case. it comes up in unit tests).
     * @param fname {string}}
     * @param namespace {string} unused
     * @returns {string}
     */
    // eslint-disable-next-line no-unused-vars
    getDefinitionPath(fname, namespace) {
      // allow requireJS to map the path, if applicable
      // first, figure out what it will add as the baseUrl
      const base = requirejs.toUrl('.');

      let filename = fname;

      let path;

      // check if its mapped, by seeing if requireJS gives us some new url, or just puts the base on it
      if (!ServiceUtils.isAbsolute(filename) && !filename.startsWith(Constants.RELATIVE_FOLDER_PREFIX)) {
        const testPath = requirejs.toUrl(filename);
        if (!testPath.startsWith(base)) {
          filename = testPath;
        }
      }

      // for relative paths (not absolute, not protocol/host), that path doesn't start with a '/',
      // add the current container prefix.
      //
      // here, 'absolute' means 'protocol and host', because both paths that start with "/", and paths that do NOT,
      // are relative to the context (either requireJS, or browser/app).
      //
      // for example, the flow's path will be prefixed to one, two, and three below. And NOT to four, five.
      // also note that for a flow other than app-flow, "three" would have been rejected earlier.
      // All but "five" are relative.
      // "services": {
      //    "one":  "x/y/z/service.json",
      //    "two": "./x/y/z/service.json",
      //    "three": "../../x/y/z/service.json",
      //    "four": "/x/y/z/service.json",
      //    "five": "https://x/y/z/service.json",
      //  }
      if (!ServiceUtils.isAbsolute(filename) && !filename.startsWith(Constants.PATH_SEPARATOR)) {
        // log a warning when a (non-app-flow) flow uses a path without a "./";
        // using a path like "some/path/service.json" is strange within a flow, because flow's can't
        // reach outside of themselves, but the meaning of that path is ambiguous
        if (!this.isUnrestrictedRelative && !filename.startsWith(Constants.RELATIVE_FOLDER_PREFIX)) {
          logger
            .warn(`deprecated: service definition path without current folder prefix is ambiguous: ${filename}`);
        }

        // don't use a relative container path that is a lonely slash ('/') as a prefix (not sure this happens).
        const prefix = this._relativePath !== Constants.PATH_SEPARATOR ? (this._relativePath || '') : '';
        path = `${prefix}${filename}`;
      } else {
        path = filename;
      }

      return path;
    }

    /**
     * don't allow dot-dot in paths unless its declared in the Application
     * @param filePath
     * @returns {*}
     */
    isPathAllowed(filePath) {
      const allowed = (this.isUnrestrictedRelative || filePath.indexOf(Constants.PARENT_FOLDER) === -1);
      if (!allowed) {
        logger.error('Found invalid service definition path (use of', Constants.PARENT_FOLDER, '):', filePath);
      }
      return allowed;
    }


    /**
     * get the service definition, and treat as JSON (no swagger parsing)
     * Will timeout (reject) after Constants.Services.definitionTimeout (30) seconds
     *
     * @param serviceName
     * @param url this method prepends this parameter with the current Router.baseUrl
     * @param additionalExtensions { headers: <Object>, transforms: { path: <string> } }
     * @returns {Promise} resolved with service definition object
     *
     * @private
     *
     * @todo: Services should have its own protocolRegistry, and a parent-fallback similar to flow/app service defs.
     */
    // eslint-disable-next-line class-methods-use-this
    static getOpenApiObject(serviceName, url, additionalExtensions) {
      return Utils.getRuntimeEnvironment()
        .then((env) => {
          // loading Router on-demand to avoid loading JET earlier than necessary
          // remove 'dots' in the middle of the path
          const path = URI(url).normalizePath().toString();
          // 'additionalExtensions' has VB properties that we define for extensions, which includes 'headers';
          // Because it has a 'headers' property, it resembles an 'init param for a fetch() call, so we can use it as
          // an 'init param, and fetch/Request will ignore what it does not care about.
          const initParam = Object.assign({}, additionalExtensions);
          return env.getServiceDefinition(path, initParam)
            .then((def) => {
              const context = initParam.resolvedUrl && { definitionUrl: initParam.resolvedUrl };
              return Services.getOpenApi(def, context);
            });
        });
    }

    /**
     * creates the appropriate model for swagger (2) or openapi3 (3+)
     * @param def
     * @param {object} [context] abstraction of Application context. optional, used for variables substitution.
     * @returns {Promise<OpenApiObjectCommon>}
     * @private
     */
    static getOpenApi(def, context) {
      try {
        const config = context
          ? Object.assign({}, context, { initParams: ConfigLoader.initParams })
          : { initParams: ConfigLoader.initParams };
        return OpenApiObjectFactory.get(def, config);
      } catch (ex) {
        const msg = `unable to resolve service references: ${ex}`;
        logger.error(msg);
        return Promise.reject(ex); // reject(), instead of throw, in case caller doesn't use .catch()
      }
    }

    /**
     * used by runtimeManager to remove a service, so it can be reloaded
     * @param serviceName
     */
    disposeService(serviceName) {
      this._loadedServicePromises.set(serviceName, this.namespace, null); // todo: namespace?
      // delete any endpoint that has a key like "<serviceName>/< * >", ignore namespace for now
      this._endpointMap.delete((id) => id.startsWith(`${serviceName}${Constants.PATH_SEPARATOR}`));
    }

    /**
     * used to evaluate path expressions, on-demand (we used to evaluate them all at once, initially)
     *
     * @param path
     * @returns {*}
     */
    evaluatePathDeclaration(path) {
      return Expression.getEvaluated(path, this._expressionContext);
    }


    /**
     * log a message about service paths expressions that use anything _other_ than $initParams should
     * be removed (unless someone can present a reasonable use-case).
     *
     * There are cases where we would like to load services before the Application is created, so expressions
     * that reference variables, etc, cannot be evaluated.
     *
     * only $initParams (ConfigLoader) exist before Application, so only expressions limited to those
     * can be consistent between pre-Application and post-Application access.
     * @param serviceMap
     * @private
     */
    static checkForDeprecatedPathExpressions(serviceMap) {
      // check for any expression references using $application, $flow, etc. and log an error.
      // we want to nudge devs toward using ONLY $initParams in expressions, so we can be consistent
      // between what is allowed in service path expressions before and after Application is created.
      const invalidReferences = [];
      Object.keys(serviceMap || {}).forEach((key) => {
        const pathOrObject = serviceMap[key];

        const path = (typeof pathOrObject === 'string') ? pathOrObject : pathOrObject.path;
        if (path && Expression.isExpression(path)) {
          const re = /\$(application|flow|page|variables|metadata)/g;
          const match = re.exec(path);
          if (match) {
            invalidReferences.push(path);
          }
        }
      });
      if (invalidReferences.length) {
        logger.warn('DEPRECATED: Application "services" contains expressions with '
          + `references other than "$initParams". These should be changed: ${JSON.stringify(invalidReferences)}`);
      }
    }
  }

  return Services;
});

