'use strict';

define('vb/private/vx/v2/extensionRegistry',[
  'vb/private/vx/baseExtensionRegistry',
  'vb/private/vx/v2/extension',
  'vb/private/configLoader',
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/log',
], (BaseExtensionRegistry, ExtensionV2, ConfigLoader, Constants, Utils, Log) => {
  const logger = Log.getLogger('/vb/private/vx/v2/extensionRegistry');

  /**
   * The regex used to find and extract the APP UIs id in an extension
   * App UIs are always located in a self/applications folder and the descriptor
   * is app.json.
   * @type {RegExp}
   */
  const appPackageRegex = new RegExp(`^${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}\
/${Constants.DefaultPaths.APPLICATIONS}(.*)/app.json$`);

  // const serviceRegex = /^services\/self\/([\w_$-]*)\/openapi[\w.]*\.json$/;
  const serviceRegex = new RegExp(`^${Constants.DefaultPaths.SERVICES}${Constants.ExtensionFolders.SELF}\
/([\\w_$-]*)/openapi[\\w.]*\\.json$`);
  // const catalogRegex = /^services\/self\/catalog.json$/;
  const catalogRegex = new RegExp(`^${Constants.DefaultPaths.SERVICES}${Constants.ExtensionFolders.SELF}\
/catalog.json$`);

  /**
   * A class to retrieve the extensions for the current application from the extension manager
   * The extension manager URL is defined in the app-flow.json under the extension property.
   */
  class ExtensionRegistry extends BaseExtensionRegistry {
    constructor(application) {
      super(application);
      /**
       * An object to store the App UI extension content. The keys are App UI ids.
       * It is initialized when the application loads using getAppPackages()
       * @type {Object}
       */
      this.appPackages = {};

      this.log = logger;
    }

    static get extensionManagerVersion() {
      return 'v2';
    }

    /**
     * The regex to find openapi3 files;
     * The () group is used to capture the service name from the path.
     * @return {RegExp}
     */
    static get serviceRegex() {
      return serviceRegex;
    }

    /**
     * The regular expresion to find a catalog in a list of extension files
     * @return {RegExp}
     */
    static get catalogRegex() {
      return catalogRegex;
    }

    createExtension(def) {
      return new ExtensionV2(def, this);
    }

    initialize(registryUrl) {
      super.initialize(registryUrl);

      this.loadManifestPromise = this.loadManifestPromise || this.loadManifest(registryUrl)
        .then((manifest) => {
          const bundles = {};
          const bundlesInfo = {};

          // Create a requirejs config with the bundle info from the digest
          manifest.requirejsInfo.forEach((info) => {
            bundlesInfo[info.id] = [];
            const bundleIds = bundlesInfo[info.id];
            if (info.metadata && info.metadata.configurations && info.metadata.configurations.build
              && info.metadata.configurations.build.bundles) {
              Object.keys(info.metadata.configurations.build.bundles).forEach((bundleId) => {
                bundleIds.push(bundleId);
              });
              Object.assign(bundles, info.metadata.configurations.build.bundles);
            }
          });

          ConfigLoader.setConfiguration({ bundles });

          // The bundlesInfo will be used during the extension creation the map the bundle URL
          manifest.bundlesInfo = bundlesInfo;
          // We're done using this info, so discard it
          delete manifest.requirejsInfo;

          return manifest;
        })
        .catch((err) => {
          // Swallow the error so that it doesn't break the application, but no extension will be loaded
          this.log.error('Error loading extension registry, no App UI will be loaded', err);
          return {};
        });
    }

    /**
     * For v2, the base path is prefixed with 'ui/'
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    getBasePathForUi(path, container) {
      return `${Constants.DefaultPaths.UI}${this.getBasePath(path, container)}`;
    }

    /**
     * Retrieve the base path for an extension layout in v2
     * Convert dynamicLayouts/{path} or dynamicLayouts/self/{path} when the container
     * is in an App UI to dynamicLayouts/{extId}/{path}
     * extId is the extension id of the container (could be base)
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    // eslint-disable-next-line class-methods-use-this
    getBasePathForLayout(path, container) {
      // When the path does not start with dynamicLayouts/ or ui/, it's a custom path
      // and leave it alone
      if (path.startsWith(Constants.DefaultPaths.LAYOUTS) || path.startsWith(Constants.DefaultPaths.UI)) {
        const pathElements = Utils.addTrailingSlash(path).split('/');

        // If the extensionId is base, we need to insert base into the path,
        // e.g., dynamicLayouts/layoutId => dynamicLayouts/base/layoutId.
        if (container.extensionId === 'base') {
          pathElements.splice(1, 0, 'base');
        } else {
          // substitute the extension id, e.g., dynamicLayouts/self/foo -> dynamicLayouts/extA/foo
          pathElements[1] = container.extensionId;
        }

        return pathElements.join('/');
      }

      return path;
    }

    /**
     * Retrieve an array with all the App UI ids available in the extensions
     * @return {Array<String>} array of App UI ids available
     */
    getAppUis() {
      return this.getExtensions().then((extensions) => {
        const results = [];
        // Traverse the array of extension from first to last. The extension manager is responsible
        // for properly ordering this array of extensions given the dependencies in the extension manager.
        extensions.forEach((extension) => {
          const files = extension.files || [];

          // Look for the package json files
          files.forEach((file) => {
            const match = file.match(appPackageRegex);
            const id = match && match[1];
            if (id) {
              if (this.appPackages[id]) {
                // WARNING: when multiple extension defined the same App UI the last one wins
                this.log.warn('App UI', id, 'content has be redefined by extension', extension.id);
              } else {
                this.log.info('Found App UI', id, 'in extension', extension.id);
              }
              this.appPackages[id] = extension;
              results.push(id);
            }
          });
        });

        return results;
      });
    }

    /**
     * Given an App UI id, return the extension that defines it
     * @param  {String} id the App UI id
     * @return {Object}    the App UI definition
     */
    getAppUiExtension(id) {
      return this.appPackages[id];
    }

    /**
     * Loads all the extensions for a specific V2 Bundle given its path. It returns a promise
     * that resolves in an array of V2 Bundle Extension objects.
     * @param  {String} path the path of the V2 Bundle Definition for which we are looking for extensions
     * @param  {BundleV2Definition} bundleDefinition the bundle for which the extensions are being loaded
     * @return {Promise} a promise to an array of V2 Bundle Extension objects
     */
    loadTranslationExtensions(path, bundleDefinition) {
      return this.getExtensions().then((extensions) => {
        const promises = [];

        // Calculate the base path for translations resource extensions.
        const basePath = `${Constants.DefaultPaths.TRANSLATIONS}${this.getBasePath(path, bundleDefinition)}`;
        const extensionPath = `${basePath}-x`;
        const Clazz = bundleDefinition.constructor.extensionClass;
        const extPath = `${extensionPath}.js`;

        // Traverse the array of extension from first to last. The extension manager is responsible
        // for properly ordering this array of extensions given the dependencies in the extension manager.
        extensions.forEach((extension) => {
          const files = extension.files || [];

          // If the manifest contains an extension for this artifact, creates an extension object for it
          if (files.indexOf(extPath) >= 0) {
            const ext = new (Clazz)(extension, extensionPath, bundleDefinition);
            const promise = ext.load().then(() => ext);
            promises.push(promise);
          }
        });

        // All files are then loaded in parallel
        return Promise.all(promises);
      });
    }

    /**
     * Create a dependency graph from the given extension as the root based on the dependencies
     * property of the extension.
     *
     * @param extensions all extensions
     * @param extension the root extension
     * @returns {[]}
     */
    getExtensionDependencies(extensions, { id = 'base', version } = {}) {
      const dependencies = [];

      extensions.forEach((extension) => {
        const depVersion = extension.dependencies[id];

        // TODO: need to check version
        // If id is base, then include extensions without a dependency to base. Otherwise, include
        // only extensions that has a dependency to id.
        if ((id === 'base' && !depVersion) || depVersion) {
          const dependency = {
            extension,
            dependencies: this.getExtensionDependencies(extensions, extension),
          };

          dependencies.push(dependency);
        }
      });

      return dependencies;
    }

    /**
     * Retrieve a map of all extensions that define translation bundles.
     * @return {Promise<Map<string,object>>} map of extId to extension for all that define a translation bundle
     */
    getTranslations() {
      return this.getExtensions().then((extensions) => {
        const results = {};
        // Traverse the array of extension from first to last. The extension manager is responsible
        // for properly ordering this array of extensions given the dependencies in the extension manager.
        extensions.forEach((extension) => {
          // Look for the translations configuration files
          // translations-config are always located in translations folder and the descriptor
          // is translations-config.json.
          if (extension.fileExists('translations/translations-config.json')) {
            results[extension.id] = extension;
          }
        });

        return results;
      });
    }
  }

  return ExtensionRegistry;
});

